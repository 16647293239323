<div class="layout-vertical" [formGroup]="form">
  <div class="layout-top">
    <div class="alert alert-danger" *ngIf="exceptions.length > 0">
      <div *ngFor="let exception of exceptions">
        <div *ngIf="exception.code">
          <span><i class="fas fa-exclamation-triangle"></i></span> {{ 'dataSourceImportation.JobImportationTaskValidationMessages.' + exception.code | translate}}
        </div>
        <div *ngIf="!exception.code">
          <span><i class="fas fa-exclamation-triangle"></i></span> {{exception.message}}
        </div>
      </div>
    </div>
  </div>
  <div class="layout-center">
    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'dataSourceImportation.edit.configuration' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <label for="name" class="field" [formGroup]="form" fieldControlName="name" appMarkAsterisk
        description="{{ 'dataSourceImportation.edit.name' | translate }}"></label>

      <dx-text-box id="name" class="text-box" formControlName="name" valueChangeEvent="keyup" maxLength="250">
      </dx-text-box>
    </div>

    <div class="padding-normal">
      <label for="idKey" class="field" [formGroup]="form" fieldControlName="idKey" appMarkAsterisk
        description="{{ 'dataSourceImportation.edit.key' | translate }}"></label>

      <dx-text-box id="idKey" class="text-box" formControlName="idKey" valueChangeEvent="keyup" maxLength="250">
      </dx-text-box>
    </div>

    <div class="padding-normal">
      <label for="typeId" class="field" [formGroup]="form" fieldControlName="typeId" appMarkAsterisk
        description="{{ 'dataSourceImportation.edit.importationType' | translate }}"></label>

      <dx-select-box id="typeId" formControlName="typeId" [items]="types" valueExpr="id" displayExpr="description">
      </dx-select-box>
    </div>

    <div class="padding-normal">
      <label for="isActive" class="field">{{ 'dataSourceImportation.edit.isActive' | translate }}</label>

      <div>
        <dx-check-box id="isActive" class="text-box" formControlName="isActive">
        </dx-check-box>
      </div>
    </div>

    <div class="padding-normal" *ngIf="isApi">
      <div class="layout-horizontal">
        <div class="layout-left">
          <label for="cron" class="field" [formGroup]="form" fieldControlName="cron" appMarkAsterisk
            description="{{ 'dataSourceImportation.edit.cron' | translate }}"></label>
        </div>
        <div class="layout-right">
          <a href="https://www.freeformatter.com/cron-expression-generator-quartz.html" target="_blank">{{
            'dataSourceImportation.edit.cronBuilder' | translate}}</a>
        </div>
      </div>

      <dx-text-box id="cron" class="text-box" formControlName="cron" valueChangeEvent="keyup" maxLength="100">
      </dx-text-box>
    </div>

    <div *ngIf="isApi">
      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'dataSourceImportation.edit.apiParameters' | translate }}
        </div>
        <div class="layout-right">
          <button id="testApi" class="compact color-set-transparent" (click)="retreiveFieldTitlesDataClick()" [disabled]="!model?.id || hasDataSourceResult">
            <i class="fas fa-solid fa-paper-plane"></i>
          </button>
        </div>
      </div>
  
      <div class="padding-normal">
        <label for="apiUrl" class="field" [formGroup]="form" fieldControlName="apiUrl" appMarkAsterisk
          description="{{ 'dataSourceImportation.edit.apiUrl' | translate }}"></label>
  
        <dx-text-box id="apiUrl" class="text-box" formControlName="apiUrl" valueChangeEvent="keyup" maxLength="4000">
        </dx-text-box>
      </div>
  
      <div class="padding-normal">
        <label for="apiAuthenticationToken" class="field">{{ 'dataSourceImportation.edit.apiAuthenticationToken' |
          translate
          }}</label>
  
        <dx-text-box id="apiAuthenticationToken" class="text-box" formControlName="apiAuthenticationToken"
          valueChangeEvent="keyup" maxLength="1000">
        </dx-text-box>
      </div>

      <div class="padding-normal">
        <label for="apiJsonPath" class="field">{{ 'dataSourceImportation.edit.apiJsonPath' | translate}}</label>
  
        <dx-text-box id="apiJsonPath" formControlName="apiJsonPath" valueChangeEvent="keyup" maxLength="250">
        </dx-text-box>
      </div>
    </div>

    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'dataSourceImportation.edit.fieldTitles' | translate }}
      </div>

      <div class="layout-right">
        <button class="color-set-transparent" (click)="deleteFieldTitleClick()" [disabled]="!selectedProperty || isApi">
          <i class="fas fa-solid fa-trash"></i>
        </button>

        <button class="color-set-transparent" (click)="addFieldTitleClick()" [disabled]="isApi">
          <i class="fas fa-solid fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="padding-normal">
      <label for="fieldTitlesKey" class="field" [formGroup]="form" fieldControlName="fieldTitlesKey" appMarkAsterisk
        description="{{ 'dataSourceImportation.edit.fieldTitlesKey' | translate }}"></label>

      <dx-select-box id="fieldTitlesKey" formControlName="fieldTitlesKey" [items]="fields">
      </dx-select-box>
    </div>

    <div formArrayName="fieldTitles">
      <div class="padding-normal">
          <app-list #fieldTitleList 
            [dataSource]="fieldTitleDataSource" 
            [selectionEnabled]="true" 
            [showCheckBoxes]="false"
            [displayLoading]="false"
            [showInstructions]="false"
            (selectedItemsChange)="selectedFieldTitlesItemsChange($event)">
            <ng-template #listTemplate let-item>
              <div class="layout-center">
                <div class="layout-horizontal defaultPadding">
                  <div class="layout-left">
                    <div>
                      {{item.key}}
                    </div>
                  </div>
          
                  <div class="layout-right">
                    <button class="color-set-transparent" (click)="propertyItemClick(item)">
                      <i class="fas fa-solid fa-pen"></i>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
        </app-list>
      </div>
    </div>

    <app-data-source-property #dataSourcePropertyPopup></app-data-source-property>

    <app-data-source-importation-result 
      [id]="id" [allowEdit]="id && form.controls.typeId.value === 2 && fieldTitles.length > 0 && !form.dirty"
      (dataSourceChanged)="dataSourceChanged($event)">
    </app-data-source-importation-result>
  </div>

  <div class="layout-bottom">
    <div class="layout-horizontal height-normal padding-horizontal-normal">
      <div class="layout-right space">
        <button type="submit" [disabled]="!form.valid || !form.dirty" class="color-set-green compact"
          (click)="saveButtonClick()">
          <div><span>{{ 'dataSourceImportation.edit.save' | translate }}</span></div>
        </button>
        <button class="color-set-gray compact" [disabled]="!form.dirty" (click)="cancelButtonClick()">
          <div><span>{{ 'dataSourceImportation.edit.cancel' | translate }}</span></div>
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal #modalPopup></app-modal>

<div class="popup" [class.show]="retreivingFieldTitlesData">
  <div class="mainOverlay">
    <div class="box">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left padding-normal">
          Importation des titres de champs
        </div>
      </div>
      <div class="content padding-normal">
        Importation en cours...

        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</div>