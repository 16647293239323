<app-popup [visible]="visible" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-left">
          <button>
            <i class="fas fa-info"></i>
            <div><span>{{ 'unauthorized.title' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
    <div class="layout-center content">
      {{ 'unauthorized.message' | translate }}
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white height-normal padding-horizontal-normal">
        <div class="layout-right">
          <button class="compact color-set-blue" (click)="retry()">
            <div><span>{{ 'unauthorized.retry' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>