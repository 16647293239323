// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.
import { Injectable } from "@angular/core";
import { DatabaseContext } from "./databaseContext";
import { DatabaseTableInformation } from "./databaseTableInformation";
import { AlertCondition } from "./models/database/alertCondition.database";
import { AlertConditionDetail } from "./models/database/alertConditionDetail.database";
import { AlertControlType } from "./models/database/alertControlType.database";
import { AlertEmailDetail } from "./models/database/alertEmailDetail.database";
import { AlertEvent } from "./models/database/alertEvent.database";
import { AlertEventType } from "./models/database/alertEventType.database";
import { AlertExecutableDetail } from "./models/database/alertExecutableDetail.database";
import { AlertFormDetail } from "./models/database/alertFormDetail.database";
import { AlertTextingDetail } from "./models/database/alertTextingDetail.database";
import { AllTables } from "./models/database/allTables.database";
import { ApplicationLog } from "./models/database/applicationLog.database";
import { Area } from "./models/database/area.database";
import { AttachmentType } from "./models/database/attachmentType.database";
import { Audit } from "./models/database/audit.database";
import { AuditAdditionalInfo } from "./models/database/auditAdditionalInfo.database";
import { AuditAlertSummary } from "./models/database/auditAlertSummary.database";
import { AuditAlertSummarySatelliteDataItem } from "./models/database/auditAlertSummarySatelliteDataItem.database";
import { AuditAttachment } from "./models/database/auditAttachment.database";
import { AuditDataTable } from "./models/database/auditDataTable.database";
import { AuditMessage } from "./models/database/auditMessage.database";
import { AuditNumber } from "./models/database/auditNumber.database";
import { AuditRemark } from "./models/database/auditRemark.database";
import { AuditSubscription } from "./models/database/auditSubscription.database";
import { AutomaticAuditToExport } from "./models/database/automaticAuditToExport.database";
import { AutomaticDocumentExportationOption } from "./models/database/automaticDocumentExportationOption.database";
import { AutomaticDocumentExportationTelemetry } from "./models/database/automaticDocumentExportationTelemetry.database";
import { AutomaticDocumentWarning } from "./models/database/automaticDocumentWarning.database";
import { AutomaticExportationOption } from "./models/database/automaticExportationOption.database";
import { AutomaticStepExportationOption } from "./models/database/automaticStepExportationOption.database";
import { ChangeTracking } from "./models/database/changeTracking.database";
import { ChangeTrackingForceUpdate } from "./models/database/changeTrackingForceUpdate.database";
import { ChangeTrackingSkipConvert } from "./models/database/changeTrackingSkipConvert.database";
import { ChangeTrackingSynchronizationStats } from "./models/database/changeTrackingSynchronizationStats.database";
import { ChangeTrackingSynchronizationStatus } from "./models/database/changeTrackingSynchronizationStatus.database";
import { ChangeTrackingSystem } from "./models/database/changeTrackingSystem.database";
import { CheckboxAppearance } from "./models/database/checkboxAppearance.database";
import { CheckBoxChoice } from "./models/database/checkBoxChoice.database";
import { City } from "./models/database/city.database";
import { ControlType } from "./models/database/controlType.database";
import { Counter } from "./models/database/counter.database";
import { Country } from "./models/database/country.database";
import { Customer } from "./models/database/customer.database";
import { CustomerAddress } from "./models/database/customerAddress.database";
import { CustomerContact } from "./models/database/customerContact.database";
import { CustomField } from "./models/database/customField.database";
import { CustomFieldAlertSatelliteDataItem } from "./models/database/customFieldAlertSatelliteDataItem.database";
import { CustomFieldAlertTemplate } from "./models/database/customFieldAlertTemplate.database";
import { CustomFieldButton } from "./models/database/customFieldButton.database";
import { CustomFieldButtonDocumentation } from "./models/database/customFieldButtonDocumentation.database";
import { CustomFieldCheckBox } from "./models/database/customFieldCheckBox.database";
import { CustomFieldComboBox } from "./models/database/customFieldComboBox.database";
import { CustomFieldDataSource } from "./models/database/customFieldDataSource.database";
import { CustomFieldDescription } from "./models/database/customFieldDescription.database";
import { CustomFieldDesignState } from "./models/database/customFieldDesignState.database";
import { CustomFieldGridColumnOption } from "./models/database/customFieldGridColumnOption.database";
import { CustomFieldLabel } from "./models/database/customFieldLabel.database";
import { CustomFieldLayoutContainer } from "./models/database/customFieldLayoutContainer.database";
import { CustomFieldPictureBox } from "./models/database/customFieldPictureBox.database";
import { CustomFieldRadioButton } from "./models/database/customFieldRadioButton.database";
import { CustomFieldRequiredByStep } from "./models/database/customFieldRequiredByStep.database";
import { CustomFieldRichTextBox } from "./models/database/customFieldRichTextBox.database";
import { CustomFieldRichTextBoxTemplateText } from "./models/database/customFieldRichTextBoxTemplateText.database";
import { CustomFieldSignature } from "./models/database/customFieldSignature.database";
import { CustomFieldTextBox } from "./models/database/customFieldTextBox.database";
import { CustomFieldValueItem } from "./models/database/customFieldValueItem.database";
import { CustomFieldValueList } from "./models/database/customFieldValueList.database";
import { CustomTabControl } from "./models/database/customTabControl.database";
import { CustomTable } from "./models/database/customTable.database";
import { DatabaseUpdater } from "./models/database/databaseUpdater.database";
import { DataChange } from "./models/database/dataChange.database";
import { DataChangeActionType } from "./models/database/dataChangeActionType.database";
import { DataChangeFieldMapping } from "./models/database/dataChangeFieldMapping.database";
import { DataSourceImportation } from "./models/database/dataSourceImportation.database";
import { DataSourceImportationException } from "./models/database/dataSourceImportationException.database";
import { DataSourceImportationResult } from "./models/database/dataSourceImportationResult.database";
import { DataSourceImportationType } from "./models/database/dataSourceImportationType.database";
import { DocumentCheckboxProperty } from "./models/database/documentCheckboxProperty.database";
import { DynamicTabAuditTemplate } from "./models/database/dynamicTabAuditTemplate.database";
import { DynamicTabStructureItem } from "./models/database/dynamicTabStructureItem.database";
import { DynamicTabTemplate } from "./models/database/dynamicTabTemplate.database";
import { EmailSignature } from "./models/database/emailSignature.database";
import { EntitySecurityGroup } from "./models/database/entitySecurityGroup.database";
import { FormPhoto } from "./models/database/formPhoto.database";
import { ImageSize } from "./models/database/imageSize.database";
import { KeyTable } from "./models/database/keyTable.database";
import { Language } from "./models/database/language.database";
import { Mobile } from "./models/database/mobile.database";
import { MobileAudit } from "./models/database/mobileAudit.database";
import { OptionList } from "./models/database/optionList.database";
import { Parameter } from "./models/database/parameter.database";
import { ParameterPhoto } from "./models/database/parameterPhoto.database";
import { Permission } from "./models/database/permission.database";
import { PermissionList } from "./models/database/permissionList.database";
import { PermissionListGroup } from "./models/database/permissionListGroup.database";
import { PhoneProvider } from "./models/database/phoneProvider.database";
import { Photo } from "./models/database/photo.database";
import { Program } from "./models/database/program.database";
import { ProgramAttachment } from "./models/database/programAttachment.database";
import { ProgramCoreField } from "./models/database/programCoreField.database";
import { ProgramDataTemplate } from "./models/database/programDataTemplate.database";
import { ProgramReferenceDocument } from "./models/database/programReferenceDocument.database";
import { ProgramTemplateDocument } from "./models/database/programTemplateDocument.database";
import { ProgramType } from "./models/database/programType.database";
import { ProgramUserGroup } from "./models/database/programUserGroup.database";
import { ProgramVersion } from "./models/database/programVersion.database";
import { ReferenceDocument } from "./models/database/referenceDocument.database";
import { ReferenceDocumentFolder } from "./models/database/referenceDocumentFolder.database";
import { ReferenceDocumentItem } from "./models/database/referenceDocumentItem.database";
import { ReminderUnitType } from "./models/database/reminderUnitType.database";
import { Session } from "./models/database/session.database";
import { SessionManager } from "./models/database/sessionManager.database";
import { SharedParameter } from "./models/database/sharedParameter.database";
import { StreetType } from "./models/database/streetType.database";
import { Synchronization } from "./models/database/synchronization.database";
import { SynchronizationProcess } from "./models/database/synchronizationProcess.database";
import { SynchronizationRange } from "./models/database/synchronizationRange.database";
import { SynchronizationRangeItem } from "./models/database/synchronizationRangeItem.database";
import { SynchronizationServerToMobileTable } from "./models/database/synchronizationServerToMobileTable.database";
import { Task } from "./models/database/task.database";
import { TaskImportationType } from "./models/database/taskImportationType.database";
import { TaskType } from "./models/database/taskType.database";
import { TemplateTabControl } from "./models/database/templateTabControl.database";
import { TemplateTabControlLink } from "./models/database/templateTabControlLink.database";
import { TemplateText } from "./models/database/templateText.database";
import { TemplateTextFolder } from "./models/database/templateTextFolder.database";
import { TemplateTextItem } from "./models/database/templateTextItem.database";
import { UserAccount } from "./models/database/userAccount.database";
import { UserAccountGroup } from "./models/database/userAccountGroup.database";
import { UserAccountMobile } from "./models/database/userAccountMobile.database";
import { UserGroup } from "./models/database/userGroup.database";
import { UserPassword } from "./models/database/userPassword.database";
import { UserPreferenceValue } from "./models/database/userPreferenceValue.database";
import { UserType } from "./models/database/userType.database";
import { WFPermissionMode } from "./models/database/wFPermissionMode.database";
import { WFPermissionType } from "./models/database/wFPermissionType.database";
import { WFStep } from "./models/database/wFStep.database";
import { WFStepAttribution } from "./models/database/wFStepAttribution.database";
import { WFStepExecutor } from "./models/database/wFStepExecutor.database";
import { WorkflowEmailEvent } from "./models/database/workflowEmailEvent.database";
import { WorkflowEmailEventDestinationType } from "./models/database/workflowEmailEventDestinationType.database";
import { WorkflowEvent } from "./models/database/workflowEvent.database";
import { WorkflowEventType } from "./models/database/workflowEventType.database";
import { WorkflowTextingEvent } from "./models/database/workflowTextingEvent.database";


@Injectable({
    providedIn: "root"
})
export class DatabaseTableServer {
    public tableInformations: DatabaseTableInformation[] = [
      { tableName: AlertCondition.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertConditionDetail.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertControlType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: AlertEmailDetail.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertEvent.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertEventType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: AlertExecutableDetail.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertFormDetail.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AlertTextingDetail.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AllTables.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: ApplicationLog.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Area.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AttachmentType.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Audit.tableName, isAuditRelated: true, primaryKey: '$$id, externalNumber' },
      { tableName: AuditAdditionalInfo.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditAlertSummary.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditAlertSummarySatelliteDataItem.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditAttachment.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditDataTable.tableName, isAuditRelated: true, primaryKey: '$$id, auditId, tableName, tableId' },
      { tableName: AuditMessage.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AuditNumber.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: AuditRemark.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AuditSubscription.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AutomaticAuditToExport.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AutomaticDocumentExportationOption.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AutomaticDocumentExportationTelemetry.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AutomaticDocumentWarning.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: AutomaticExportationOption.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: AutomaticStepExportationOption.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ChangeTracking.tableName, isAuditRelated: false, primaryKey: '++id, tableName' },
      { tableName: ChangeTrackingForceUpdate.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ChangeTrackingSkipConvert.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ChangeTrackingSynchronizationStats.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: ChangeTrackingSynchronizationStatus.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: ChangeTrackingSystem.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CheckboxAppearance.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CheckBoxChoice.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: City.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ControlType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: Counter.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Country.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Customer.tableName, isAuditRelated: false, primaryKey: '$$id, name' },
      { tableName: CustomerAddress.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomerContact.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomField.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldAlertSatelliteDataItem.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldAlertTemplate.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldButton.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldButtonDocumentation.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldCheckBox.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldComboBox.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldDataSource.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldDescription.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldDesignState.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: CustomFieldGridColumnOption.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldLabel.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldLayoutContainer.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldPictureBox.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldRadioButton.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldRequiredByStep.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldRichTextBox.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldRichTextBoxTemplateText.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldSignature.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldTextBox.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomFieldValueItem.tableName, isAuditRelated: false, primaryKey: '$$id, valueListId, description' },
      { tableName: CustomFieldValueList.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomTabControl.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: CustomTable.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DatabaseUpdater.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DataChange.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: DataChangeActionType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: DataChangeFieldMapping.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: DataSourceImportation.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DataSourceImportationException.tableName, isAuditRelated: false, primaryKey: '$$id, dataSourceImportationId' },
      { tableName: DataSourceImportationResult.tableName, isAuditRelated: false, primaryKey: '$$id, dataSourceImportationId' },
      { tableName: DataSourceImportationType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: DocumentCheckboxProperty.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DynamicTabAuditTemplate.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: DynamicTabStructureItem.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: DynamicTabTemplate.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: EmailSignature.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: EntitySecurityGroup.tableName, isAuditRelated: false, primaryKey: '$$id, entityId, source' },
      { tableName: FormPhoto.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: ImageSize.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: KeyTable.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: Language.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: Mobile.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: MobileAudit.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: OptionList.tableName, isAuditRelated: false, primaryKey: '$$id, entityId, source' },
      { tableName: Parameter.tableName, isAuditRelated: false, primaryKey: '$$id, name' },
      { tableName: ParameterPhoto.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Permission.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: PermissionList.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: PermissionListGroup.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: PhoneProvider.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Photo.tableName, isAuditRelated: true, primaryKey: '$$id, auditId' },
      { tableName: Program.tableName, isAuditRelated: false, primaryKey: '$$id, numberPrefix' },
      { tableName: ProgramAttachment.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ProgramCoreField.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ProgramDataTemplate.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ProgramReferenceDocument.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ProgramTemplateDocument.tableName, isAuditRelated: false, primaryKey: '$$id, programId' },
      { tableName: ProgramType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: ProgramUserGroup.tableName, isAuditRelated: false, primaryKey: '$$id, userGroupId' },
      { tableName: ProgramVersion.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ReferenceDocument.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ReferenceDocumentFolder.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ReferenceDocumentItem.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: ReminderUnitType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: Session.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: SessionManager.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: SharedParameter.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: StreetType.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: Synchronization.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: SynchronizationProcess.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: SynchronizationRange.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: SynchronizationRangeItem.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: SynchronizationServerToMobileTable.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: Task.tableName, isAuditRelated: false, primaryKey: '$$id, optionListId' },
      { tableName: TaskImportationType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: TaskType.tableName, isAuditRelated: false, primaryKey: '$$id, dataSourceImportationId, name' },
      { tableName: TemplateTabControl.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: TemplateTabControlLink.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: TemplateText.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: TemplateTextFolder.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: TemplateTextItem.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: UserAccount.tableName, isAuditRelated: false, primaryKey: '$$id, name' },
      { tableName: UserAccountGroup.tableName, isAuditRelated: false, primaryKey: '$$id, userId' },
      { tableName: UserAccountMobile.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: UserGroup.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: UserPassword.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: UserPreferenceValue.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: UserType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: WFPermissionMode.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: WFPermissionType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: WFStep.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: WFStepAttribution.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: WFStepExecutor.tableName, isAuditRelated: false, primaryKey: '$$id, wFStepId' },
      { tableName: WorkflowEmailEvent.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: WorkflowEmailEventDestinationType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: WorkflowEvent.tableName, isAuditRelated: false, primaryKey: '$$id' },
      { tableName: WorkflowEventType.tableName, isAuditRelated: false, primaryKey: '++id' },
      { tableName: WorkflowTextingEvent.tableName, isAuditRelated: false, primaryKey: '$$id' },
      
    ]

    public registerAllTables(databaseContext: DatabaseContext) {
        AlertCondition.selfRegister(databaseContext);
      AlertConditionDetail.selfRegister(databaseContext);
      AlertControlType.selfRegister(databaseContext);
      AlertEmailDetail.selfRegister(databaseContext);
      AlertEvent.selfRegister(databaseContext);
      AlertEventType.selfRegister(databaseContext);
      AlertExecutableDetail.selfRegister(databaseContext);
      AlertFormDetail.selfRegister(databaseContext);
      AlertTextingDetail.selfRegister(databaseContext);
      AllTables.selfRegister(databaseContext);
      ApplicationLog.selfRegister(databaseContext);
      Area.selfRegister(databaseContext);
      AttachmentType.selfRegister(databaseContext);
      Audit.selfRegister(databaseContext);
      AuditAdditionalInfo.selfRegister(databaseContext);
      AuditAlertSummary.selfRegister(databaseContext);
      AuditAlertSummarySatelliteDataItem.selfRegister(databaseContext);
      AuditAttachment.selfRegister(databaseContext);
      AuditDataTable.selfRegister(databaseContext);
      AuditMessage.selfRegister(databaseContext);
      AuditNumber.selfRegister(databaseContext);
      AuditRemark.selfRegister(databaseContext);
      AuditSubscription.selfRegister(databaseContext);
      AutomaticAuditToExport.selfRegister(databaseContext);
      AutomaticDocumentExportationOption.selfRegister(databaseContext);
      AutomaticDocumentExportationTelemetry.selfRegister(databaseContext);
      AutomaticDocumentWarning.selfRegister(databaseContext);
      AutomaticExportationOption.selfRegister(databaseContext);
      AutomaticStepExportationOption.selfRegister(databaseContext);
      ChangeTracking.selfRegister(databaseContext);
      ChangeTrackingForceUpdate.selfRegister(databaseContext);
      ChangeTrackingSkipConvert.selfRegister(databaseContext);
      ChangeTrackingSynchronizationStats.selfRegister(databaseContext);
      ChangeTrackingSynchronizationStatus.selfRegister(databaseContext);
      ChangeTrackingSystem.selfRegister(databaseContext);
      CheckboxAppearance.selfRegister(databaseContext);
      CheckBoxChoice.selfRegister(databaseContext);
      City.selfRegister(databaseContext);
      ControlType.selfRegister(databaseContext);
      Counter.selfRegister(databaseContext);
      Country.selfRegister(databaseContext);
      Customer.selfRegister(databaseContext);
      CustomerAddress.selfRegister(databaseContext);
      CustomerContact.selfRegister(databaseContext);
      CustomField.selfRegister(databaseContext);
      CustomFieldAlertSatelliteDataItem.selfRegister(databaseContext);
      CustomFieldAlertTemplate.selfRegister(databaseContext);
      CustomFieldButton.selfRegister(databaseContext);
      CustomFieldButtonDocumentation.selfRegister(databaseContext);
      CustomFieldCheckBox.selfRegister(databaseContext);
      CustomFieldComboBox.selfRegister(databaseContext);
      CustomFieldDataSource.selfRegister(databaseContext);
      CustomFieldDescription.selfRegister(databaseContext);
      CustomFieldDesignState.selfRegister(databaseContext);
      CustomFieldGridColumnOption.selfRegister(databaseContext);
      CustomFieldLabel.selfRegister(databaseContext);
      CustomFieldLayoutContainer.selfRegister(databaseContext);
      CustomFieldPictureBox.selfRegister(databaseContext);
      CustomFieldRadioButton.selfRegister(databaseContext);
      CustomFieldRequiredByStep.selfRegister(databaseContext);
      CustomFieldRichTextBox.selfRegister(databaseContext);
      CustomFieldRichTextBoxTemplateText.selfRegister(databaseContext);
      CustomFieldSignature.selfRegister(databaseContext);
      CustomFieldTextBox.selfRegister(databaseContext);
      CustomFieldValueItem.selfRegister(databaseContext);
      CustomFieldValueList.selfRegister(databaseContext);
      CustomTabControl.selfRegister(databaseContext);
      CustomTable.selfRegister(databaseContext);
      DatabaseUpdater.selfRegister(databaseContext);
      DataChange.selfRegister(databaseContext);
      DataChangeActionType.selfRegister(databaseContext);
      DataChangeFieldMapping.selfRegister(databaseContext);
      DataSourceImportation.selfRegister(databaseContext);
      DataSourceImportationException.selfRegister(databaseContext);
      DataSourceImportationResult.selfRegister(databaseContext);
      DataSourceImportationType.selfRegister(databaseContext);
      DocumentCheckboxProperty.selfRegister(databaseContext);
      DynamicTabAuditTemplate.selfRegister(databaseContext);
      DynamicTabStructureItem.selfRegister(databaseContext);
      DynamicTabTemplate.selfRegister(databaseContext);
      EmailSignature.selfRegister(databaseContext);
      EntitySecurityGroup.selfRegister(databaseContext);
      FormPhoto.selfRegister(databaseContext);
      ImageSize.selfRegister(databaseContext);
      KeyTable.selfRegister(databaseContext);
      Language.selfRegister(databaseContext);
      Mobile.selfRegister(databaseContext);
      MobileAudit.selfRegister(databaseContext);
      OptionList.selfRegister(databaseContext);
      Parameter.selfRegister(databaseContext);
      ParameterPhoto.selfRegister(databaseContext);
      Permission.selfRegister(databaseContext);
      PermissionList.selfRegister(databaseContext);
      PermissionListGroup.selfRegister(databaseContext);
      PhoneProvider.selfRegister(databaseContext);
      Photo.selfRegister(databaseContext);
      Program.selfRegister(databaseContext);
      ProgramAttachment.selfRegister(databaseContext);
      ProgramCoreField.selfRegister(databaseContext);
      ProgramDataTemplate.selfRegister(databaseContext);
      ProgramReferenceDocument.selfRegister(databaseContext);
      ProgramTemplateDocument.selfRegister(databaseContext);
      ProgramType.selfRegister(databaseContext);
      ProgramUserGroup.selfRegister(databaseContext);
      ProgramVersion.selfRegister(databaseContext);
      ReferenceDocument.selfRegister(databaseContext);
      ReferenceDocumentFolder.selfRegister(databaseContext);
      ReferenceDocumentItem.selfRegister(databaseContext);
      ReminderUnitType.selfRegister(databaseContext);
      Session.selfRegister(databaseContext);
      SessionManager.selfRegister(databaseContext);
      SharedParameter.selfRegister(databaseContext);
      StreetType.selfRegister(databaseContext);
      Synchronization.selfRegister(databaseContext);
      SynchronizationProcess.selfRegister(databaseContext);
      SynchronizationRange.selfRegister(databaseContext);
      SynchronizationRangeItem.selfRegister(databaseContext);
      SynchronizationServerToMobileTable.selfRegister(databaseContext);
      Task.selfRegister(databaseContext);
      TaskImportationType.selfRegister(databaseContext);
      TaskType.selfRegister(databaseContext);
      TemplateTabControl.selfRegister(databaseContext);
      TemplateTabControlLink.selfRegister(databaseContext);
      TemplateText.selfRegister(databaseContext);
      TemplateTextFolder.selfRegister(databaseContext);
      TemplateTextItem.selfRegister(databaseContext);
      UserAccount.selfRegister(databaseContext);
      UserAccountGroup.selfRegister(databaseContext);
      UserAccountMobile.selfRegister(databaseContext);
      UserGroup.selfRegister(databaseContext);
      UserPassword.selfRegister(databaseContext);
      UserPreferenceValue.selfRegister(databaseContext);
      UserType.selfRegister(databaseContext);
      WFPermissionMode.selfRegister(databaseContext);
      WFPermissionType.selfRegister(databaseContext);
      WFStep.selfRegister(databaseContext);
      WFStepAttribution.selfRegister(databaseContext);
      WFStepExecutor.selfRegister(databaseContext);
      WorkflowEmailEvent.selfRegister(databaseContext);
      WorkflowEmailEventDestinationType.selfRegister(databaseContext);
      WorkflowEvent.selfRegister(databaseContext);
      WorkflowEventType.selfRegister(databaseContext);
      WorkflowTextingEvent.selfRegister(databaseContext);
      
    }
}
