import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { ListDataSourceResult } from 'src/app/components/list/listDatasourceResult';
import { ListComponent } from 'src/app/components/list/list.component';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { Permission } from 'src/app/core/data/models/database/permission.database';
import { UserAccountGroup } from 'src/app/core/data/models/database/userAccountGroup.database';
import { UserGroup } from 'src/app/core/data/models/database/userGroup.database';
import { UserGroupRepository } from 'src/app/core/data/repositories/userGroupRepository';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit, OnDestroy {

  public pageTitle: string;
  public groupListDataSource: Function;
  public dataSourceResult: ListDataSourceResult = new ListDataSourceResult();
  public selectedGroups: UserGroup[] = [];
  public numberOfUser: Map<string, number> = new Map<string, number>();

  @ViewChild('deletePopup') deletePopupComponent: SimplePopupComponent;
  @ViewChild('list') listComponent: ListComponent;

  constructor(
    public router: Router,
    private headerState: HeaderState,
    private translate: TranslateService,
    private userGroupRepository: UserGroupRepository,
    private baseRepository: BaseRepository) {
  }

  public async ngOnInit(): Promise<void> {
    this.headerState.isAlert = true;
    this.pageTitle = this.translate.instant("groups.title")

    this.groupListDataSource = async (context): Promise<ListDataSourceFunctionResult> => {
      let groups = await this.userGroupRepository.getGroups();
      let filteredGroups = groups.filter(group => ComparatorService.stringMatch(group.name, context.filter));
      return new ListDataSourceFunctionResult({
        items: filteredGroups,
        itemCount: filteredGroups.length
      });
    }
  }

  public ngOnDestroy(): void {
    this.headerState.isAlert = false;
  }

  public async navigateToNewGroup(): Promise<void> {
    this.router.navigateByUrl("/groups/new");
  }

  public async navigate(group: UserGroup): Promise<void> {
    this.router.navigateByUrl(`/groups/${group.id}`);
  }

  public async setNumberOfUser(datasourceResult: ListDataSourceResult): Promise<void> {
    let usersGroups: UserAccountGroup[] = await UserAccountGroup.table.toArray();

    datasourceResult.getItemsAsArray().forEach(group => {
      this.numberOfUser.set(group.id, usersGroups.filter(userGroup => userGroup.groupId === group.id).length);
    });
  }

  public async deleteGroups() {
    let selectedGroupsIds = this.selectedGroups.map(group => group.id);
    let permissions = (await Permission.table.toArray()).filter(permission => selectedGroupsIds.includes(permission.groupId));
    let userAccountGroups = (await UserAccountGroup.table.toArray()).filter(userAccountGroup => selectedGroupsIds.includes(userAccountGroup.groupId));

    permissions.forEach(permission => this.baseRepository.delete(Permission.table, permission))
    userAccountGroups.forEach(userAccountGroup => this.baseRepository.delete(UserAccountGroup.table, userAccountGroup))
    this.selectedGroups.forEach(group => this.baseRepository.delete(UserGroup.table, group))

    this.selectedGroups.length = 0;
    this.groupListDataSource();
    this.listComponent.updateData();
  }
}
