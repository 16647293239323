import { Injectable } from "@angular/core";
import { Validation } from "../../validation";
import { ValidationDictionary } from "../../validationDictionary";
import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { SynchronizationService } from "./synchronizationService";

@Injectable({
  providedIn: 'root',
})
export class FeedMobileCommand {
  constructor(
    private synchronizationService: SynchronizationService,
  ) { }

  public async canSkip(): Promise<boolean> {
    let currentChangeTrackingNumberParameter = await LocalParameter.table.get(LocalParameter.CURRENT_CHANGE_TRACKING_NUMBER_PARAMETER_NAME);
    let targetChangeTrackingNumberParameter = await LocalParameter.table.get(LocalParameter.TARGET_CHANGE_TRACKING_NUMBER_PARAMETER_NAME);

    let isNewMobile = currentChangeTrackingNumberParameter && currentChangeTrackingNumberParameter.value === 0;
    let synchronizationInProgress = targetChangeTrackingNumberParameter && targetChangeTrackingNumberParameter > currentChangeTrackingNumberParameter;

    return !isNewMobile && !synchronizationInProgress;
  }

  public async run() : Promise<ValidationDictionary>{
    let validationDictionary = new ValidationDictionary();

    try {
      if (!await this.canSkip()) {
        await this.synchronizationService.getUpdates(true, false);
      }
    } catch (error) {
      validationDictionary.add(new Validation({key: "FeedMobileCommand", message: error.message}));
    }

    return validationDictionary;
  }
}