import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { UserGroup } from 'src/app/core/data/models/database/userGroup.database';
import { GroupState } from './groupState';

@Injectable({
  providedIn: 'root'
})
export class GroupResolver implements Resolve<void>  {

  constructor(
    private groupState: GroupState, 
    private baseRepository: BaseRepository) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this.groupState.group = new UserGroup();
    if (route.paramMap.has("id")) {
      this.groupState.group = await this.baseRepository.get(UserGroup.table, route.paramMap.get("id"));
    }
  }
}
