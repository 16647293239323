import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { AuditCopyService } from './auditCopyService';
import { CopyStep } from './copyStep';

@Component({
  selector: 'app-audit-copy',
  templateUrl: './audit-copy.component.html',
  styleUrls: ['./audit-copy.component.scss']
})
export class AuditCopyComponent implements OnInit {

  public inError: boolean;
  public copyingStep: CopyStep = CopyStep.validating;
  get CopyStep() { return CopyStep }

  constructor(
    private headerState: HeaderState,
    private auditCopyService: AuditCopyService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.headerState.title = "";
    this.headerState.useBackButton = false;
    this.inError = false;
    // url format : /forms/:auditNumber/copy
    let auditNumber = this.router.url.split("/")[2];
    await this.validateAuditNumber(auditNumber);
    await this.copyAudit(auditNumber);
  }

  public navigateToHome(): void {
    this.router.navigate([`/home`]);
  }

  private async validateAuditNumber(auditNumber: string): Promise<void> {
    if (await this.auditCopyService.validateAuditNumber(auditNumber)) {
      this.copyingStep = CopyStep.copying;
    } else {
      this.router.navigate([`/forms/notfound/${auditNumber}`]);
    }
  }

  private async copyAudit(auditNumber: string): Promise<void> {
    let newAuditNumber = await this.auditCopyService.copyAudit(auditNumber);
    if (newAuditNumber) {
      this.router.navigate([`/forms/${newAuditNumber}`]);
    }
    else {
      this.inError = true;
    }
  }
}
