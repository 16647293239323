import { DynamicFormCustomAction } from "src/app/components/dynamic-form/dynamicFormCustomAction";
import { SectionState } from "src/app/pages/audit/audit-sections/sectionState";
import { Control } from "./control";
import { SectionType } from "./sectionType";

export class Section {
  id: string;
  name: string;
  key: string;
  description: string;
  position: number;
  controls: Control[];
  type: SectionType;
  dataTableName: string;

  static getControlFromStructureItemKey(section: Section, structureItemId: string){
    let allControls = Section.getflatControls(section.controls);

    return allControls.find(x => x.alertTemplates.find(y => y.structureItemId === structureItemId))
  }

  // See the comment in getAllControls.
  private _allControls;

  /**
  * Returns a list of all controls recursively in the section.
  */
  // This function is static and receive a section object. Why not setting as none static? Because
  // the object is created by the deserialization and this is not creating the javascript prototype
  // that has the functions.
  static getAllControls(section: Section): Control[]{
    if (!section._allControls){
      section._allControls = Section.getflatControls(section.controls);
    }

    return section._allControls;
  }

  static getflatControls(controls: Control[]): Control[] {
    let result: Control[] = [];

    for (const item of controls) {
      if (item.children && item.children.length > 0) {
        result.push(...this.getflatControls(item.children))
      }
      else {
        result.push(item);
      }
    }

    return result;
  }

  public constructor(args?: Partial<Section>) {
    Object.assign(this, args);
  }
}
