// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Photo extends ChangeTrackingEntity {
  public static table: Dexie.Table<Photo, string>;
  public static tableName: string = 'Photo';

  public data: string;  public description: string;  public source: string;  public format: string;  public isCompressed: boolean;  public id: string;  public auditId: string;  public timeStamp: Date;

  public constructor(args?: Partial<Photo>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Photo.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Photo.tableName);
      this.table.mapToClass(Photo);
  }
}
