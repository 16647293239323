import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseControlComponent } from '../basecontrol/basecontrol.component';
import * as markerjs2 from 'markerjs2';
import * as cropro from 'cropro';
import { TranslateService } from '@ngx-translate/core';
import { FormField } from 'src/app/core/data/models/formField';
import { FormFieldImageExtendedValue } from 'src/app/core/data/models/formFieldImageExtendedValue';
import { Subscription } from 'rxjs';
import { CameraComponent } from '../camera/camera.component';
import { AuditState } from 'src/app/pages/audit/auditState';
import { ImageService } from 'src/app/core/services/image.service';
import { CompressionPopupComponent } from './compression-popup/compression-popup/compression-popup.component';

@Component({
  selector: 'app-picture-box',
  templateUrl: './picture-box.component.html',
  styleUrls: ['./picture-box.component.scss']
})
export class PictureBoxComponent extends BaseControlComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("camera") camera: CameraComponent
  @ViewChild("imageInput") imageInput: ElementRef;
  @ViewChild("compressionPopup") compressionPopup: CompressionPopupComponent;

  readOnly: boolean = false;

  markerAreaState: any;
  originalImageContainer: any;
  sourceImageElement: any;
  targetRoot: any;

  clearAnnotationsPopupConfirmButtonOptions;
  clearAnnotationsPopupCloseButtonOptions;
  clearAnnotationsPopupVisible = false;
  clearAnnotationsPopupMessage: string;
  showCropAreaClicked = false;
  rotateLeftClicked = false;
  rotateRightClicked = false;

  defaultImage: any = "/assets/Solid_white.svg.png";

  image: any;
  
  width: number = 400;
  height: number = 400;
  allowSelectImage: boolean = false;
  allowEditImage: boolean = false;
  allowTakePhoto: boolean = false;

  canEditImage: boolean = false;

  private skipValueChanges = false;
  private previousMarkerAreaState;
  private previousOriginalImageContainer;
  private valueChangesSubscription: Subscription;

  constructor(
      private translate: TranslateService, 
      private auditState: AuditState,
      private imageService: ImageService) {
    super();

    this.image = this.defaultImage;

    this.clearAnnotationsPopupConfirmButtonOptions = {
      icon: 'bi bi-check-lg',
      text: this.translate.instant('components.customFields.pictureBox.clearAnnotationsContinueButton'),

      onClick: async () => {
        this.clearAnnotations();

        if (this.showCropAreaClicked) {
          this.showCropArea();
          this.showCropAreaClicked = false;
        } else if (this.rotateLeftClicked) {
          this.rotateLeft();
          this.rotateLeftClicked = false;
        } else if (this.rotateRightClicked) {
          this.rotateRight();
          this.rotateRightClicked = false;
        }

        this.clearAnnotationsPopupVisible = false;
      }
    };

    this.clearAnnotationsPopupCloseButtonOptions = {
      icon: 'undo',
      text: this.translate.instant('components.customFields.pictureBox.clearAnnotationsCloseButton'),
      onClick: () => {
        if (this.showCropAreaClicked) {
          this.showCropAreaClicked = false;
        }

        this.clearAnnotationsPopupVisible = false;
      }
    };
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.setSourceImage(document.getElementById("source-img-" + this.input.dataColumnName));
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setPictureBoxProperties();
    this.readOnly = this.auditState.readonly;

    this.clearAnnotationsPopupMessage = this.translate.instant('components.customFields.pictureBox.clearAnnotationsPopupMessage');

    const markerAreaState = this.input.extendedValues.find(x => x.key === FormField.PictureBoxMarkerAreaStatePropertyName);
    const originalImageContainer = this.input.extendedValues.find(x => x.key === FormField.PictureBoxOriginalImagePropertyName);

    this.previousMarkerAreaState = Object.assign({}, markerAreaState);
    this.previousOriginalImageContainer = Object.assign({}, originalImageContainer);

    this.loadImage(this.form.controls[this.input.key].value, markerAreaState, originalImageContainer);

    // This will detect changes from the datasource to reset to the original image when user cancel changes.
    this.valueChangesSubscription = this.form.valueChanges.subscribe(x => {
      if (this.skipValueChanges)
        return;

      if (Object.keys(x)[0] == this.input.key) {
        this.loadImage(Object.values(x)[0], this.previousMarkerAreaState, this.previousOriginalImageContainer);
      }
    })
  }

  private setPictureBoxProperties() {
    this.allowSelectImage = super.getExtendedProperty("AllowSelectImage") as boolean;
    this.allowEditImage = super.getExtendedProperty("AllowEditImage") as boolean;
    this.allowTakePhoto = super.getExtendedProperty("AllowTakePhoto") as boolean;
    this.width = super.getExtendedProperty("Width") as number;
    this.height = super.getExtendedProperty("Height") as number;
    if (super.getExtendedProperty("UseCompression") as boolean)
      this.imageService.setAdminCompressionLevel(super.getExtendedProperty("CompressionLevel") as number);
  }

  private loadImage(newImage: any, newMarkerAreaState, newOriginalImageContainer) {
    let sanitizedImage = this.getWebImage(newImage);

    if (sanitizedImage) {
      this.canEditImage = true;
      this.image = sanitizedImage;
    }

    this.markerAreaState = newMarkerAreaState;

    newOriginalImageContainer.value = this.getWebImage(newOriginalImageContainer.value);

    this.originalImageContainer = newOriginalImageContainer;

    this.setSourceImage(document.getElementById("source-img-" + this.input.dataColumnName));
  }

  /**
  * Returns a structure representing an image that can be interpreted as a valid image
  * for a picture box.
  */
  getWebImage(image) {
    if (image) {
      const binaryImageMetadataSeparator = ",";

      if ((image as string).includes(binaryImageMetadataSeparator))
        return image;
      else {
        const metaDataPrefix: string = "data:image/jpeg;base64,"

        return metaDataPrefix + image;
      }
    }
    else
      return null;
  }

  async selectImage(imageFile) {
    let imageUrl = await this.toBase64(imageFile.target.files[0]);
    this.onPictureTaken(imageUrl);
  } 

  onPictureTaken(imageAsDataUrl: string) {
    this.imageService.ConvertImageToJpegAndCompress(imageAsDataUrl, this.UpdateImageParameters.bind(this))
  }

  private UpdateImageParameters(imageAsDataUrl: string) {
    this.image = imageAsDataUrl;

    this.originalImageContainer.value = imageAsDataUrl;

    this.changeImage(imageAsDataUrl);

    this.markerAreaState.value = null;

    if (!this.canEditImage) {
      this.canEditImage = true;
    }
  }

  private changeImage(image: any) {
    this.skipValueChanges = true;
    this.form.controls[this.input.key].setValue(FormFieldImageExtendedValue.GetRawImage(image));
    this.form.controls[this.input.key].markAsDirty();
    this.skipValueChanges = false;
  }

  async onLeftRotationClick() {
    this.rotateLeft();
  }

  async rotateLeft() {
    this.rotate(this.image, 270).then(x => {
      this.image = x;

      this.originalImageContainer.value = x;
      this.changeImage(x);

      this.markerAreaState.value = null;
    });
  }

  async onRightRotationClick() {
    this.rotateRight();
  }

  async rotateRight() {
    this.rotate(this.image, 90).then(x => {
      this.image = x;

      this.originalImageContainer.value = x;
      this.changeImage(x);

      this.markerAreaState.value = null;
    });
  }

  // Resize and Rotate an Image on Upload Using JavaScript
  // https://dikuw.wordpress.com/2018/01/30/resize-and-rotate-an-image-on-upload-using-javascript/
  private async rotate(image: any, angle: number) {
    // Converting a base64 string to a blob in JavaScript - Ionic Blog
    // https://ionicframework.com/blog/converting-a-base64-string-to-a-blob-in-javascript/
    let blobImage = await (await fetch(image)).blob();

    return new Promise(function (resolve, reject) {
      var dataURL = "x";
      var reader = new FileReader();

      reader.onloadend = function () {
        let tempImg = new Image();

        tempImg.src = reader.result as string;

        tempImg.onload = function () {
          var tempW = tempImg.width;
          var tempH = tempImg.height;

          var canvas = document.createElement('canvas');

          canvas.width = tempW;
          canvas.height = tempH;

          var ctx = canvas.getContext("2d");

          canvas.width = tempH;
          canvas.height = tempW;

          var ctx = canvas.getContext("2d");

          ctx.translate(tempH, 0);
          ctx.rotate(angle * Math.PI / 180);

          let pivotXPosition = 0;
          let pivotYPosition = 0;

          if (angle > 180) {
            pivotXPosition = -tempW;
            pivotYPosition = -tempH;
          }

          ctx.drawImage(tempImg, pivotXPosition, pivotYPosition, tempW, tempH);
          dataURL = canvas.toDataURL("image/jpeg");

          resolve(dataURL);
        };

        tempImg.onerror = function (err) {
          reject("can't load the image");
        }
      };

      reader.readAsDataURL(blobImage);
    });
  }

  setSourceImage(source) {
    this.sourceImageElement = source;

    if (!this.originalImageContainer.value) {
      this.originalImageContainer.value = this.image;
    }
  }

  cropButtonClick() {
    this.showCropArea();
  }

  clearAnnotations() {
    this.markerAreaState.value = null;

    this.image = this.originalImageContainer.value;

    (document.getElementById("sample-img-" + this.input.dataColumnName) as HTMLImageElement).src = this.originalImageContainer.value;
    (document.getElementById("source-img-" + this.input.dataColumnName) as HTMLImageElement).src = this.originalImageContainer.value;

    this.changeImage(this.originalImageContainer.value);
  }

  // https://markerjs.com/demos/cropro/all-defaults
  showCropArea() {
    let image = document.getElementById("sample-img-" + this.input.dataColumnName) as HTMLImageElement;
    const cropArea = new cropro.CropArea(image);

    this.configureCropArea(cropArea, image);

    cropArea.show();
  }

  /**
   * Refer to this page for more information on the cropro class
   * https://markerjs.com/reference/cropro/
   *
   * CropArea
   * https://markerjs.com/reference/cropro/classes/croparea.html
   */
  configureCropArea(cropArea: cropro.CropArea, image: HTMLImageElement) {
    cropArea.displayMode = 'popup';
    cropArea.zoomToCropEnabled = false;
    cropArea.renderAtNaturalSize = true;

    cropArea.addRenderEventListener((imgURL) => {
      image.src = imgURL;
      this.sourceImageElement.src = imgURL;

      this.image = imgURL;

      this.originalImageContainer.value = imgURL;
      this.changeImage(imgURL);

      this.markerAreaState.value = null;
    });
  }

  // https://markerjs.com/demos/all-defaults
  showMarkerArea() {
    const markerArea = new markerjs2.MarkerArea(this.sourceImageElement);
    this.configureMarkerArea(markerArea);
    markerArea.show();

    if (this.markerAreaState.value) {
      markerArea.restoreState(JSON.parse(this.markerAreaState.value));
    }
  }

  /**
   * Refer to this page for more information on the markerjs2 class
   * https://markerjs.com/reference/
   *
   * MarkerArea
   * https://markerjs.com/reference/classes/markerarea.html
   */
  configureMarkerArea(markerArea: markerjs2.MarkerArea) {
    let markers = [
      markerjs2.FreehandMarker,
      markerjs2.TextMarker,
      markerjs2.CalloutMarker,
      markerjs2.LineMarker,
      markerjs2.CurveMarker,
      markerjs2.ArrowMarker,
      markerjs2.MeasurementMarker,
      markerjs2.FrameMarker,
      markerjs2.EllipseFrameMarker,
      markerjs2.HighlightMarker,
    ];

    markerArea.availableMarkerTypes = markers;

    markerArea.renderAtNaturalSize = true;
    markerArea.settings.displayMode = 'popup';
    markerArea.addRenderEventListener((imgURL, state) => {

      if (state.markers.length > 0) {
        this.markerAreaState.value = JSON.stringify(state);

        if (!this.originalImageContainer.value) {
          this.originalImageContainer.value = this.image;
        }
      }

      this.image = imgURL;

      this.changeImage(imgURL);
    });
  }

  onReturnToOriginalImageButtonClick() {
    if (this.markerAreaState.value || (this.image.toString().length !== this.originalImageContainer.value.toString().length)) {
      this.clearAnnotationsPopupVisible = true;
    }
  }

  private async toBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  public async deleteClick(){
    this.image = this.defaultImage;

    this.canEditImage = false;

    this.sourceImageElement.src = this.defaultImage;

    this.changeImage(null);
  }

  public openCompressPopup(): void {
    this.compressionPopup.display();
  }

  public resetImageInputValue(): void {
    this.imageInput.nativeElement.value = null;
  }
}