import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import _ from 'lodash';
import { throwIfEmpty } from 'rxjs';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { DataSourceImportationResultViewModel } from '../data-source-importation-result/dataSourceImportationResultViewModel';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-data-source-selector',
  templateUrl: './data-source-selector.component.html',
  styleUrls: ['./data-source-selector.component.scss']
})
export class DataSourceSelectorComponent implements OnInit {
  @Input() dataSourceImportationId = "";
  @Input() visible = false;
  @Input() canClose = true;
  @Input() boxFixed = false;
  @Input() minWidth = "";

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter;

  @ViewChild(PopupComponent) popup: PopupComponent;
  @ViewChild(ListComponent) list: ListComponent;

  private promiseResolver = (boolean) => { };

  public selectedItem: DataSourceImportationResultViewModel;

  public dataSource: Function;
  public itemCount: number;

  constructor() {

  }

  ngOnInit(): void {
    if (!this.dataSourceImportationId)
      return;

    this.dataSource = async (args) => {
      let dataSources = await DataSourceImportationResult.table.where("dataSourceImportationId").equals(this.dataSourceImportationId).toArray();

      if (args.filter) {
        dataSources = dataSources.filter(x => x.idKey.includes(args.filter));
      }

      let viewModel = _.map(dataSources, x => new DataSourceImportationResultViewModel({ id: x.id, title: x.idKey, selected: false }));

      this.itemCount = viewModel.length;

      return new ListDataSourceFunctionResult({
        items: _.sortBy(viewModel, x => x.title),
        itemCount: viewModel.length
      })
    };
  }

 public async display() {
    this.selectedItem = null; 
    
    this.popup.width = this.minWidth;

    this.list.updateData();

    this.popup.display();

    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);

    return new Promise<boolean>((resolve) => {
      this.promiseResolver = resolve;
    })
  }

  public close() {
    this.popup.close();
    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);
  }

  selectedItemsChange(item: DataSourceImportationResultViewModel) {
    this.selectedItem = item;
  }

  navigateToDatasource(item: DataSourceImportationResultViewModel) {
    this.selectedItem = item;
  }

  okButtonClick() {
    this.promiseResolver(true);
    this.close();
  }

  cancelButtonClick() {
    this.promiseResolver(false);
    this.close();
  }
}
