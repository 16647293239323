import { Injectable } from "@angular/core";
import { AuthenticationMiddleware } from "./core/authentication/authenticationMiddleware";
import { SynchronizationMiddleware } from "./core/data/synchronization/synchronizationMiddleware";
import { ValidationDictionary } from "./core/validationDictionary";
import { StoratePersistanceMiddleware } from "./storatePersistanceMiddleware";

@Injectable({
  providedIn: 'root'
})
export class AppMiddleware {
  // Even if not used, by being required by the app module, it creates the instance of the object
  // therefore initializing the application with required information
  constructor(
    private authenticationMiddleware: AuthenticationMiddleware,
    private storatePersistanceMiddleware: StoratePersistanceMiddleware,
    private synchronizationMiddleware: SynchronizationMiddleware,
  ) { }

  public async run() : Promise<ValidationDictionary> {
    let canContinue = await this.authenticationMiddleware.run();

    let validationDictionary = new ValidationDictionary();

    if (canContinue) {
      validationDictionary = await this.synchronizationMiddleware.run();
    }

    if (canContinue && validationDictionary.isValid()){
      await this.storatePersistanceMiddleware.run();
    }

    return validationDictionary;
  }
}