import { Component, HostBinding } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { SynchronizationContext } from './core/data/synchronization/synchronizationContext';
import { SynchronizationService } from './core/data/synchronization/synchronizationService';
import { AppInfoService } from './core/services/app-info.service';
import { ScreenService } from './core/services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  requireSynchronizationKey = "requireSynchronization"

  constructor(
    private screen: ScreenService,
    public appInfo: AppInfoService,
    public serviceWorkerUpdate: SwUpdate,
    public synchronizationService: SynchronizationService,
    public synchronizationContext: SynchronizationContext,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(environment.language);
  }
}
