import { Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import DOMPurify from 'dompurify';
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe {
    constructor(private sanitizer: DomSanitizer) { }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(html));
    }
}
