<div *ngIf="supportNativeCamera">
  <input style="display: none" type="file" capture="user" accept="image/*" (change)="confirmPhoto($event)" #cameraInput>
</div>

<div *ngIf="!supportNativeCamera && visible">
  <div class="camera color-set-black" *ngIf="!imageUrl">
    <div class="layout-vertical">
      <div class="layout-top">
        <div class="layout-horizontal padding-normal">
          <div class="layout-left" *ngIf="flashlightEnabled">
            <button class="big round color-set-gray" (click)="toggleFlashlight()">
              <i class="fa-solid fa-bolt-lightning"></i>
            </button>
          </div>
          <div class="layout-left" *ngIf="flashlightEnabled">
            <button class="big round color-set-gray" *ngIf="flashEnabled" (click)="toggleFlash()">
              <i class="fa-solid fa-bolt-lightning"></i>
            </button>

            <button class="big round color-set-gray" *ngIf="!flashEnabled" (click)="toggleFlash()">
              <i class="fa-solid fa-slash"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="layout-center align-center">
        <video #video autoplay [srcObject]="camera"></video>
      </div>

      <div class="layout-bottom">
        <div class="layout-horizontal padding-normal">
          <div class="layout-left" *ngIf="this.cameras.length > 1">
            <button class="big round color-set-gray" (click)="switchCamera()">
              <i class="fas fa-sync"></i>
            </button>
          </div>
          <div class="layout-center">
            <button class="big round color-set-orange" (click)="capturePhoto()">
              <i class="fas fa-camera"></i>
            </button>
          </div>
          <div class="layout-right">
            <button class="big round color-set-gray" (click)="close()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="image color-set-black" *ngIf="imageUrl">
    <div class="layout-vertical">
      <div class="layout-center align-center">
        <img #image [src]="imageUrl" />
      </div>

      <div class="layout-bottom">
        <div class="layout-horizontal padding-normal">
          <div class="layout-center">
            <button class="big round color-set-green" (click)="confirmPhoto()">
              <i class="fas fa-check"></i>
            </button>
          </div>
          <div class="layout-left">
            <button class="big round color-set-invert-red" (click)="clearPhoto()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>