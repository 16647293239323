import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuditViewModel } from './userAuditViewModel';
import { AuditListSearchOptionsQueryParamUrlService } from './auditListSearchOptionsQueryParamUrlService';
import { UserAuditRepository } from 'src/app/core/data/repositories/userAuditRepository';
import { ListDataSourceResult } from 'src/app/components/list/listDatasourceResult';
import { ListComponent } from 'src/app/components/list/list.component';
import { AuditListSearchComponent } from './audit-list-search/audit-list-search.component';
import { NewAuditComponent } from './new-audit/new-audit.component';
import { AuditListSearchOptions } from './auditListSearchOptions';
import { FieldMetadata } from 'src/app/components/list/list-option/field-metadata';
import moment from 'moment';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { AdditionalOptionsComponent } from '../audit/additional-options/additional-options.component';
import { ListOptions } from 'src/app/components/list/listOptions';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit, AfterViewInit {

  public moment = moment

  public auditListDataSource: Function;
  public selectedAudits: Audit[];
  public searchOptions: AuditListSearchOptions = new AuditListSearchOptions();
  public filterCount: number = 0;
  public auditNumber: string;

  private queryParamMap;

  public dataSourceResult: ListDataSourceResult = new ListDataSourceResult();

  @ViewChild('list') list: ListComponent;
  @ViewChild('addAudit') addAuditPopup: NewAuditComponent;
  @ViewChild('searchAudit') searchAuditPopup: AuditListSearchComponent;
  @ViewChild('additionalOptions') additionalOptionsPopup: AdditionalOptionsComponent;

  public metadata = [
    new FieldMetadata({ key: "number", label: "Number" }),
    new FieldMetadata({ key: "programId", label: "Form Template" }),
  ]

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private auditListSearchOptionsQueryParamUrlService: AuditListSearchOptionsQueryParamUrlService,
    private userAuditRepository: UserAuditRepository) {
  }

  async ngOnInit(): Promise<void> {
    this.auditListDataSource = async (context: ListComponent) => {
      this.searchOptions = await this.auditListSearchOptionsQueryParamUrlService.parseQueryParameters(this.queryParamMap);

      if (!this.searchOptions) {
        this.searchOptions = await this.auditListSearchOptionsQueryParamUrlService.restore();
      }

      return await this.userAuditRepository.getSortedUserAuditListWithSearchOptions(this.searchOptions, context.filter);
    }
  }

  ngAfterViewInit() {
    this.route.queryParamMap.subscribe(async (params) => {
      this.queryParamMap = params;

      this.list.updateData();
    });
  }

  public showAdd() {
    this.addAuditPopup.show();
  }

  public showSearch() {
    this.searchAuditPopup.open();
  }

  public async enter(item: UserAuditViewModel) {
    await this.navigateToAudit(item);
  }

  public onListResultChange(result) {
    this.dataSourceResult = result;
  }

  public openAditionnalOptionsPopup(): void {
    this.additionalOptionsPopup.openAdditionalOptionsPopup();
  }

  public updateAuditNumber(event: Audit): void {
    this.auditNumber = event?.number;
  }

  private async navigateToAudit(item: UserAuditViewModel) {
    this.router.navigate([`/forms/${item.number}`]);
  }
}
