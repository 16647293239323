<div class="layout-horizontal height-normal color-set-lightblue">
  <div class="layout-left padding-horizontal-normal">
    <h2>{{auditState.section.description}}</h2>
  </div>
</div>

<dx-list class="list" [dataSource]="dataSource" [grouped]="true" [searchEnabled]="true" selectionMode="single">
  <div *dxTemplate="let item of 'item'">
    <div class="layout-horizontal color-set-white list-item" [ngClass]="item.statusClass">
      <div class="layout-left">
        <button class="userButton">
          <div>{{ userService.getInitials(item.createdBy) }}</div>
        </button>
        <div class="layout-left">
          <div>
            <div><strong>{{item.controlDescription}} - {{item.description}}</strong></div>
            <div>{{item.value}}</div>
            <div><small>{{item.createdDate}}</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *dxTemplate="let item of 'group'">
    <div class="layout-horizontal defaultPadding gray">
      <div class="layout-left">
        {{item.key}}
      </div>
    </div>
  </div>
</dx-list>