import { CustomFieldControlType } from "./form/customFieldControlType";
import { RequiredStep } from "src/app/core/data/models/form/requiredStep";
import { AlertTemplateControl } from "src/app/core/data/models/form/alertTemplateControl";
import { ControlFont } from "./form/controlFont";
import { AlertControl } from "src/app/components/customFields/controls/alert/alertControl";
import { AlertControlCondition } from "src/app/components/customFields/controls/alert/alertControlCondition";
import { DataType } from "./form/dataType";
import { ExtendedProperty } from "./form/extendedProperty";
import { first, Observable, Subject } from "rxjs";
import { AlertConditionChangedEventArgs } from "./alertConditionChangedEventArgs";
import { FormFieldExtendedValue } from "./formFieldExtendedValue";
import { AlertTemplateField } from "./alertTemplateField";
import { Section } from "./form/section";
import { AlertConditionChangingEventArgs } from "./alertConditionChangingEventArgs";
import { FormFieldValueFormatter } from "./formFieldValueFormatter";

export class FormField<T> {
  private alertConditionChanged: Subject<AlertConditionChangedEventArgs>;
  private alertConditionChanging: Subject<AlertConditionChangingEventArgs>;

  public static PictureBoxMarkerAreaStatePropertyName: string = "MarkerAreaState";
  public static PictureBoxOriginalImagePropertyName: string = "OriginalImage";

  $onAlertConditionChanged: Observable<AlertConditionChangedEventArgs>;
  $onAlertConditionChanging: Observable<AlertConditionChangingEventArgs>;

  constructor() {
    this.alertConditionChanged = new Subject<AlertConditionChangedEventArgs>();
    this.$onAlertConditionChanged = this.alertConditionChanged.asObservable();

    this.alertConditionChanging = new Subject<AlertConditionChangingEventArgs>();
    this.$onAlertConditionChanging = this.alertConditionChanging.asObservable();
  }

  private _alertConditionLookupSuspended;

  suspendAlertConditionLookup() {
    this._alertConditionLookupSuspended = true;
  }

  resumeAlertConditionLookup() {
    this._alertConditionLookupSuspended = false;
  }

  private _value: T;

  get value(): T {
    return this._value;
  }
  set value(newValue: T) {
    if (this._value !== newValue) {
      this._value = newValue;

      if (this._alertConditionLookupSuspended)
        return;

      if (this.alert) {
        this.evaluateAlertCondition(newValue);
      }
    }
  }

  public evaluateAlertCondition(value){
    switch (this.controlType) {
      case CustomFieldControlType.CheckBox:
        // TODO: Find what to set to the empty last parameter.
        this.setAlertControlCondition(this.alert.checkBooleanCondition(<any>value), null, this.section.id, true, this.referenceTableId, "");
        break;
      case CustomFieldControlType.ComboBox:
        // TODO: Find what to set to the empty last parameter.
        this.setAlertControlCondition(this.alert.checkListCondition(<any>value), null, this.section.id, true, this.referenceTableId, "");
        break;
      case CustomFieldControlType.TextBox:
        let extendedProperties = this.options.find(x => x.key == "extendedProperties").value as ExtendedProperty[];

        let dataTypeProperty = extendedProperties.find(x => x.key === "DataType");

        if (dataTypeProperty.value as DataType === DataType.Decimal)
          // TODO: Find what to set to the empty last parameter.
          this.setAlertControlCondition(this.alert.checkNumericCondition(<any>value), null, this.section.id, true, this.referenceTableId, "");
        break;
    }
  }

  referenceTableId: string;

  async setAlertControlCondition(alertControlCondition: AlertControlCondition, customTableId: string, sectionId: string, notifyComponents: boolean, referenceTableId: string, id: string) {
    let currentAlertTemplate = this.alertTemplate;

    let newAlertTemplate: AlertTemplateField;

    if (alertControlCondition) {
      if (this.alertTemplates.length > 0) {
        let alertTemplateControl = this.alertTemplates.find(x => x.conditionDetailId == alertControlCondition.id);

        if (alertTemplateControl) {
          let alertTemplateField = new AlertTemplateField();

          alertTemplateField.dataTableName = this.dataTableName;
          alertTemplateField.conditionDetailId = alertTemplateControl.conditionDetailId;
          alertTemplateField.structureItemId = alertTemplateControl.structureItemId;
          alertTemplateField.templateId = alertTemplateControl.templateId;
          alertTemplateField.customTableId = customTableId;
          alertTemplateField.sectionId = sectionId;
          alertTemplateField.referenceTableId = referenceTableId;
          alertTemplateField.id = id;

          newAlertTemplate = alertTemplateField;
        }
        else
          newAlertTemplate = null;
      }
    }
    else {
      newAlertTemplate = null;
    }

    if (notifyComponents) {
      // Where components listens alert condition changing, there are async statements and without this promise callback,
      // after triggering the next alert condition changing, there would be an incomplete alert template data.
      // This is a trick to force waiting the subscribers to notify the publisher that everything has been processed.
      let callBack: (value: void | PromiseLike<void>) => void;

      let promise = new Promise<void>((resolve) => {
        callBack = resolve;
      });

      this.alertConditionChanging.next(new AlertConditionChangingEventArgs(alertControlCondition, currentAlertTemplate, newAlertTemplate, callBack));

      await promise;

      this.alertTemplate = newAlertTemplate;

      this.alertConditionChanged.next(new AlertConditionChangedEventArgs(alertControlCondition, currentAlertTemplate));

      this.alertControlCondition = alertControlCondition;
    }
    else {
      this.alertTemplate = newAlertTemplate;
      
      this.alertControlCondition = alertControlCondition;
    }
  }

  get controlKey(): string {
    return this.dataTableName + "." + this.dataColumnName;
  }

  key: string;
  sectionKey: string;
  sectionDescription: string;
  controlType: CustomFieldControlType;
  options: { key: string; value: object }[] = [];
  description: string;
  dataTableName?: string;
  dataColumnName?: string;
  font: ControlFont;
  required?: boolean;
  readOnly: boolean;
  instructionId?: string;
  alertId: string;
  alert: AlertControl;
  alertTemplates: AlertTemplateControl[];
  requiredSteps: RequiredStep[];
  children: FormField<any>[] = [];
  validators: string[];
  extendedValues: FormFieldExtendedValue[];
  alertControlCondition: AlertControlCondition;
  alertTemplate: AlertTemplateField;
  section: Section;
  valueFormatter: FormFieldValueFormatter;
  generateLabel: boolean;

  private editableControlTypes = [CustomFieldControlType.TextBox, CustomFieldControlType.RichTextBox, CustomFieldControlType.DateTimePicker,
  CustomFieldControlType.PictureBox, CustomFieldControlType.MaskedEditTextBox, CustomFieldControlType.CheckBox, CustomFieldControlType.ComboBox, CustomFieldControlType.RadioButton,
  CustomFieldControlType.Signature];

  public isInputControl() {
    return this.editableControlTypes.findIndex(x => x === this.controlType) >= 0;
  }

  public static flatFormFields(formFields: FormField<any>[]): FormField<any>[] {
    let result: FormField<any>[] = [];

    for (const item of formFields) {
      if (item.children.length > 0) {
        result.push(...this.flatFormFields(item.children))
      }
      else {
        result.push(item);
      }
    }

    return result;
  }
}
