export class StringParser {
    public static getValueAsDecimal(value: string, nbrOfDecimals: number): number {
        if (value != null) {            
            if (value.trim() != "") {
                let multiplier = Math.pow(10, nbrOfDecimals);
                return Math.round(parseFloat(value) * multiplier) / multiplier;
            }
        }

        return null;
    }

    public static getValueAsInt(value: string): number {
        if (value != null) {
            if (value.trim() != "") {
                return parseInt(value);
            }
        }

        return null;
    }
}