import { ArrayUtility } from "src/app/core/arrayUtility";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { FormGroup } from "@angular/forms";
import { FormField } from "src/app/core/data/models/formField";
import { Injectable } from "@angular/core";
import { AuditAlertSummary } from "../data/models/database/auditAlertSummary.database";
import { SectionState } from "src/app/pages/audit/audit-sections/sectionState";
import { AuditSectionState } from "../data/viewModels/auditSectionState";
import { AuditService } from "src/app/pages/audit/auditService";
import { Audit } from "../data/models/database/audit.database";
import { OtherSectionDataItem } from "src/app/components/dynamic-form/otherSectionDataItem";

@Injectable()
export class DynamicFormService {
  constructor(private baseRepository: BaseRepository, private auditService: AuditService) { }

  public async saveAlerts(alertSummary: AuditAlertSummary[]): Promise<boolean> {
    for (const alert of alertSummary) {
      // Force to have each alert to treat again after each save.
      // This will let the service extract additionnal information even after
      // alert has been triggered.
      alert.treated = false;

      alert.markAsUpdated();

      this.baseRepository.save<AuditAlertSummary>(AuditAlertSummary.table, alert);
    }

    return true;
  }

  public async saveFields(auditId: string, otherSectionDataItems: OtherSectionDataItem[]){
    if (otherSectionDataItems.length == 0)
      return;

    let auditDataTables = await AuditDataTable.table.where("auditId").equals(auditId).toArray();

    for (const otherSectionDataItem of otherSectionDataItems) {
      let auditDataTable = auditDataTables.find(x => x.tableName == otherSectionDataItem.dataTableName);

      if (auditDataTable){
        auditDataTable[otherSectionDataItem.dataColumnName] = otherSectionDataItem.value;

        await this.baseRepository.update(AuditDataTable.table, auditDataTable);
      }
    }
  }

  public async save(auditId: string, form: FormGroup, formFields: FormField<any>[], customTableId: string, sectionStates: AuditSectionState[]): Promise<boolean> {
    if (formFields.length > 0) {

      let auditDataTable = await AuditDataTable.table.where("tableId").equals(customTableId).first();

      let flattedFormFields = FormField.flatFormFields(formFields);

      flattedFormFields = flattedFormFields.filter(x => x.isInputControl())

      let groupedFormFields = ArrayUtility.groupBy(
        flattedFormFields,
        (x) => x.dataTableName
      );

      for (const groupedFormField of groupedFormFields) {
        if (!auditDataTable) {
          throw new Error("Audit Data Table should always exists at this moment.");
        }

        for (const formField of groupedFormField[1]) {
          let formControl = form.controls[formField.key];

          let value: any = formControl.value;

          if (formControl.dirty) {
            if (formField.valueFormatter)
              value = formField.valueFormatter.format(value);

						auditDataTable[formField.dataColumnName] = value === undefined ? null : value;

            if (formField.extendedValues) {
              for (const extendedValue of formField.extendedValues) {
                auditDataTable[extendedValue.dataColumnName] = extendedValue.getValue();
              }
            }
          }
        }

        auditDataTable[AuditSectionState.StateField] = form.valid ? SectionState.Valid : SectionState.EmptyRequiredFields;

        await this.baseRepository.update(AuditDataTable.table, auditDataTable);

        let audit = await this.baseRepository.get(Audit.table, auditId);

        await this.auditService.updateAuditHeader(audit);
        await this.auditService.addAutomaticAuditToExportToQueue(audit);
      }
    }

    return true;
  }
}
