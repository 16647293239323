import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataType } from 'src/app/core/data/models/form/dataType';
import { DataSourceItem } from 'src/app/core/data/viewModels/dataSourceItem';
import { CustomFieldValueListViewModel } from 'src/app/components/custom-field-value-lists/customFieldValueListViewModel';
import { CustomFieldValueItemRepository } from 'src/app/core/data/repositories/customFieldValueItemRepository';
import { CustomFieldValueItem } from 'src/app/core/data/models/database/customFieldValueItem.database';
import dxPopup from 'devextreme/ui/popup';
import DataGrid from 'devextreme/ui/data_grid';
import { CustomFieldValueListRepository } from 'src/app/core/data/repositories/customFieldValueListRepository';
import { StringParser } from 'src/app/core/utilities/stringParser';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { BaseListDetailsState } from '../baseListDetailsState';
import { CustomFieldValueListService } from '../customFieldValueListService';
import { CustomFieldValueItemService } from '../customFieldValueItemService';

@Component({
  selector: 'app-custom-field-value-list-details',
  templateUrl: './custom-field-value-list-details.component.html',
  styleUrls: ['./custom-field-value-list-details.component.scss'],
})
export class CustomFieldValueListDetailsComponent implements OnInit {
  dataTypeItems;
  emailItems;

  defaultDataType = DataType.String;
  numericWithDecimalsDataTypeValue = DataType.Decimal;
  dataTypeDisabled = false;
  changeDataTypePopupVisible = false;
  changeDataTypePopupConfirmButtonOptions: any;
  changeDataTypePopupCancelButtonOptions: any;

  descriptionColumnDataType: string;

  lockIconHidden = false;

  deletePopupConfirmButtonOptions: any;
  deletePopupCloseButtonOptions: any;
  deletePopupVisible = false;
  isDeleteCustomFieldValueItem = false;
  deletePopupMessage: string;

  dataGridInstance: DataGrid;
  popupInstance: dxPopup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private translate: TranslateService,

    private customFieldValueListService: CustomFieldValueListService,
    private customFieldValueListRepository: CustomFieldValueListRepository,
    private customFieldValueListItemService: CustomFieldValueItemService,

    public baseListDetailsState: BaseListDetailsState,
    private baseRepository: BaseRepository
  ) {
    const that = this;

    this.changeDataTypePopupConfirmButtonOptions = {
      icon: 'bi bi-check-lg',
      text: that.translate.instant('customFieldValueListPage.dataTypePopupMessageConfirmButton'),

      onClick: function (e) {
        that.dataTypeDisabled = false;
        that.changeDataTypePopupVisible = false;
      },
    };

    this.changeDataTypePopupCancelButtonOptions = {
      icon: 'undo',
      text: that.translate.instant('customFieldValueListPage.popupCancelButton'),

      onClick: function (e) {
        that.changeDataTypePopupVisible = false;
      }
    };

    this.deletePopupConfirmButtonOptions = {
      icon: 'bi bi-trash-fill',
      text: that.translate.instant('customFieldValueListPage.deletePopupDeleteButton'),

      onClick: function (e) {
        if (that.isDeleteCustomFieldValueItem) {
          that.deleteSelectedRows();
          that.isDeleteCustomFieldValueItem = false;
        } else {
          that.deleteCustomFieldValueList();
        }
        that.deletePopupVisible = false;
        that.deletePopupMessage = '';
      }
    };

    this.deletePopupCloseButtonOptions = {
      icon: 'undo',
      text: that.translate.instant('customFieldValueListPage.popupCancelButton'),
      onClick: function (e) {
        that.deletePopupVisible = false;
        that.isDeleteCustomFieldValueItem = false;
      }
    };

    this.nameExists = this.nameExists.bind(this);
  }

  async ngOnInit(): Promise<void> {
    this.baseListDetailsState.setCurrentPath('data-sources');
    await this.loadData();
  }

  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }

  async loadData() {
    await this.baseListDetailsState.attachRoute(this.route);

    if (this.baseListDetailsState.isNewItem) {
      this.baseListDetailsState.currentItem = new CustomFieldValueListViewModel();
    }

    this.fillEmailItems();

    this.setDataTypeState();
    this.fillDataTypeItems();

    if (this.baseListDetailsState.currentItem.dataType == DataType.String) {
      this.descriptionColumnDataType = 'string';
    } else {
      this.descriptionColumnDataType = 'number';
    }
  }

  fillEmailItems() {
    this.emailItems = new Array<DataSourceItem<number>>();
    this.emailItems = this.customFieldValueListRepository.getEmailItems(this.baseListDetailsState.currentItem);
  }

  fillDataTypeItems() {
    this.dataTypeItems = new Array<DataSourceItem<number>>();
    this.dataTypeItems = this.customFieldValueListRepository.getDataTypeItems();
  }

  lockButtonMouseEnter() {
    this.lockIconHidden = true;
  }

  lockButtonMouseLeave() {
    this.lockIconHidden = false;
  }

  lockButtonClick() {
    this.changeDataTypePopupVisible = true;
  }

  async nameValueChanged(e: Event) {
    if (e['value'] != null) {
      if (e['value'] != this.baseListDetailsState.currentItem.name) {
        let newValue = e['value'].toString().trim();

        if (!this.baseListDetailsState.dataSource.some((x) => x.name.trim().toLowerCase() === newValue.toLowerCase() && x.id != this.baseListDetailsState.currentItem.id)) {
          if (newValue !== '') {
            if (this.baseListDetailsState.isNewItem) {
              this.createNewCustomFieldValueList(newValue);
            } else {
              if (this.baseListDetailsState.currentItem.name != newValue) {
                this.baseListDetailsState.currentItem.name = newValue;

                await this.customFieldValueListService.update(this.baseListDetailsState.currentItem);
              }
            }
          }
        }
      }
    }
  }

  // Crée un nouveau CustomFieldValueList, l'insère dans la BD et dans la liste,
  // le set comme currentItem et redirige vers l'id du nouveau CustomFieldValueList
  async createNewCustomFieldValueList(newValue: string) {
    let newCustomFieldValueList = new CustomFieldValueListViewModel({ name: newValue, dataType: this.defaultDataType });

    newCustomFieldValueList = await this.customFieldValueListService.insert(newCustomFieldValueList);
    this.baseListDetailsState.dataSource.push(newCustomFieldValueList);
    this.baseListDetailsState.setCurrentItem(newCustomFieldValueList.id);
    this.baseListDetailsState.currentItem.customFieldValueItems = new Array<CustomFieldValueItem>();

    await this.router.navigate(['data-sources/' + this.baseListDetailsState.currentItem.id]).then((x) => {
      this.baseListDetailsState.isNewItem = false;
    });
  }

  // Retourne la valeur de la description de la row selon le datatype sélectionné.
  getRowDescriptionValue(row: any): string {
    let result = row.description;

    if (row.description != null) {
      if (this.baseListDetailsState.currentItem.dataType == DataType.Decimal) {
        result = StringParser.getValueAsDecimal(row.description.toString(), this.baseListDetailsState.currentItem.nbrDecimals).toString();
      }
      if (this.baseListDetailsState.currentItem.dataType == DataType.Int32) {
        result = StringParser.getValueAsInt(row.description).toString();
      }
    }

    return result;
  }

  // Set la valeur de la description de l'item correspondant à la row en paramètre dans la liste de CustomFieldValueItems.
  setCustomFieldValueItemDescriptionValue(row: any) {
    let index = this.baseListDetailsState.currentItem.customFieldValueItems.indexOf(row);
    this.baseListDetailsState.currentItem.customFieldValueItems[index].description = this.getRowDescriptionValue(row);
  }

  // Supprime les rows sélectionnées dans la dataGrid
  deleteSelectedRows() {
    if (this.dataGridInstance.getSelectedRowKeys().length != 0) {
      for (let id of this.dataGridInstance.getSelectedRowKeys()) {
        let index = this.baseListDetailsState.currentItem.customFieldValueItems.findIndex((x) => x.id === id);
        this.baseListDetailsState.currentItem.customFieldValueItems.splice(index, 1);

        this.baseRepository.delete(CustomFieldValueItem.table, this.baseListDetailsState.currentItem.customFieldValueItems[index]);
      }

      this.setDataTypeState();
    }
  }

  // Valide si le nom existe déjà ou non
  nameExists(e) {
    if (e['value'] !== this.baseListDetailsState.currentItem.name) {
      return !this.baseListDetailsState.dataSource.some((x) => x.name.trim().toLowerCase() === e['value'].toString().trim().toLowerCase());
    }

    return true;
  }

  async rowInserted(e: Event) {
    let rowData = e['data'];

    this.setDataTypeState();

    rowData = this.setRowData(rowData);    
    rowData.valueListId = this.baseListDetailsState.currentItem.id;

    await this.customFieldValueListItemService.insert(rowData);
  }

  async rowUpdated(e: Event) {
    let rowData = e['data'];

    rowData = this.setRowData(rowData);

    await this.baseRepository.update(CustomFieldValueItem.table, rowData);
  }

  setRowData(rowData: any): any {
    rowData.description = this.getRowDescriptionValue(rowData);
    this.setCustomFieldValueItemDescriptionValue(rowData);

    if (this.baseListDetailsState.currentItem.dataType == DataType.Int32) {
      rowData.valueAsInt = StringParser.getValueAsInt(rowData.description);
    }

    if (this.baseListDetailsState.currentItem.dataType == DataType.Decimal) {
      rowData.valueAsDecimal = StringParser.getValueAsDecimal(rowData.description, this.baseListDetailsState.currentItem.nbrDecimals);
    }

    return rowData;
  }

  async dataTypeValueChanged(e: Event) {
    if (this.baseListDetailsState.currentItem.dataType != e['value'] && !this.baseListDetailsState.isNewItem) {
      this.baseListDetailsState.currentItem.dataType = e['value'];

      this.setDescriptionColumnDataType();

      this.deleteCustomFieldValueListItems();

      await this.customFieldValueListService.update(this.baseListDetailsState.currentItem);
    }
  }

  // Set la valeur du dataType pour la colonne description de la dataGrid
  setDescriptionColumnDataType() {
    if (this.baseListDetailsState.currentItem.dataType == DataType.String) {
      this.descriptionColumnDataType = 'string';
    } else {
      this.descriptionColumnDataType = 'number';
    }
  }

  async deleteCustomFieldValueListItems() {
    if (this.baseListDetailsState.currentItem.customFieldValueItems?.length > 0) {
      for (let item of this.baseListDetailsState.currentItem.customFieldValueItems) {
        await this.baseRepository.delete(CustomFieldValueItem.table, item);
      }

      this.baseListDetailsState.currentItem.customFieldValueItems.splice(0, this.baseListDetailsState.currentItem.customFieldValueItems.length);
    }
  }

  async decimalsValueChanged(e: Event) {
    if (this.baseListDetailsState.currentItem.nbrDecimals != e['value']) {
      this.baseListDetailsState.currentItem.nbrDecimals = e['value'];

      await this.customFieldValueListService.update(this.baseListDetailsState.currentItem);
    }
  }

  async attributeValueChanged(e: Event, attributeNumber: number) {
    let propertyValue = this.baseListDetailsState.currentItem['attribute' + attributeNumber];

    if (propertyValue != e['value']) {
      this.baseListDetailsState.currentItem['attribute' + attributeNumber] = e['value'];
      await this.customFieldValueListService.update(this.baseListDetailsState.currentItem);
    }
  }

  async emailNameValueChanged(e: Event) {
    if (this.baseListDetailsState.currentItem.emailNameAttribute != e['value']) {
      this.baseListDetailsState.currentItem.emailNameAttribute = e['value'];

      await this.customFieldValueListService.update(this.baseListDetailsState.currentItem);
    }
  }

  async emailAddressValueChanged(e: Event) {
    if (this.baseListDetailsState.currentItem.emailAddressAttribute != e['value']) {
      this.baseListDetailsState.currentItem.emailAddressAttribute = e['value'];

      await this.customFieldValueListService.update(this.baseListDetailsState.currentItem);
    }
  }

  // Fait apparaître le popup de confirmation pour la suppression d'une CustomFieldValueList
  showDeleteCustomFieldValueListPopup() {
    this.deletePopupMessage = this.translate.instant('customFieldValueListPage.deleteCustomFieldValueListPopupMessage', { dataSourceName: this.baseListDetailsState.currentItem.name });
    this.deletePopupVisible = true;
  }

  // Fait apparaître le popup de confirmation pour la suppression d'un CustomFieldValueListItem
  showDeleteCustomFieldValueItemPopup() {
    if (this.dataGridInstance.getSelectedRowKeys().length != 0) {
      let selectedRowsDescriptions = this.getSelectedRowsDescription();

      this.deletePopupMessage = this.translate.instant('customFieldValueListPage.deleteCustomFieldValueItemsPopupMessage', { dataSourceItems: selectedRowsDescriptions.join(`\n`) });
      this.isDeleteCustomFieldValueItem = true;
      this.deletePopupVisible = true;
    }
  }

  // Retourne la description de toutes les rows sélectionnées dans la datagrid.
  getSelectedRowsDescription(): string[] {
    let result = new Array<string>();

    for (let id of this.dataGridInstance.getSelectedRowKeys()) {
      let index = this.baseListDetailsState.currentItem.customFieldValueItems.findIndex((x) => x.id === id);
      result.push(this.baseListDetailsState.currentItem.customFieldValueItems[index].description);
    }

    return result;
  }

  // TODO FB: Déplacer dans le state
  async deleteCustomFieldValueList() {
    await this.deleteCustomFieldValueListItems();
    await this.customFieldValueListService.delete(this.baseListDetailsState.currentItem);
    this.baseListDetailsState.redirectAfterDeletion();
  }

  setDataTypeState() {
    this.dataTypeDisabled = this.baseListDetailsState.currentItem.customFieldValueItems.length > 0;
  }
}
