import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HeaderStateLeftButtonType } from "./headerStateLeftButtonType";

@Injectable({
  providedIn: 'root',
})
export class HeaderState {
  public title: string;
  public leftButtonType: HeaderStateLeftButtonType;
  public isAlert: boolean;
  public formSectionUrl: string[];
	public useBackButton: boolean;

	constructor() {
		this.setDefaultButtonState();
	}

	changeLeftButton(url: string){
		this.formSectionUrl.length = 0;
		this.formSectionUrl.push(url);
	}

	setDefaultButtonState(){
		if (this.formSectionUrl)
			this.formSectionUrl.length = 0;
		else
			this.formSectionUrl = [];

		this.formSectionUrl.push("/home")

		this.leftButtonType = HeaderStateLeftButtonType.default;

		this.useBackButton = false;
	}

  private backButtonClick$ = new Subject();

  backButtonClick = this.backButtonClick$.asObservable();

  private titleClick$ = new Subject();

  titleClick = this.titleClick$.asObservable();

  raiseTitleClick() {
    this.titleClick$.next(null);
  }

  raiseBackButtonClick() {
    this.backButtonClick$.next(null);
  }
}
