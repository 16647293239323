<div *ngIf="!loading">
  <app-list [items]="healthChecks">
    <ng-template #listTemplate let-item>
      <div class="layout-horizontal padding-vertical-normal">
        <div class="layout-left padding-horizontal-normal">
          {{item.status}}
        </div>
        <div class="layout-center align-left">
          <div class="font-bold padding-horizontal-normal">
            {{item.name}}
          </div>
          <div class="padding-horizontal-normal">
            {{item.description}}
          </div>
          <div class="padding-horizontal-normal" *ngIf="item.info">
            {{item.info}}
          </div>
        </div>
      </div>
    </ng-template>
  </app-list>
</div>

<app-popup [visible]="loading" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-blue">
        <div class="layout-left">
          {{ 'healthPage.loading' | translate }}
        </div>
      </div>
      <div class="layout-center padding-normal height-normal">
        {{ 'healthPage.loadingMessage' | translate }}
      </div>
    </div>
  </div>
</app-popup>