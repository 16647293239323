import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { filter, pairwise } from 'rxjs';
import { ListComponent } from 'src/app/components/list/list.component';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { DangerPopupButton, SimplePopupButton } from 'src/app/components/popup/simplePopup/simplePopupButton';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { EntitySecurityGroup } from 'src/app/core/data/models/database/entitySecurityGroup.database';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { EntitySecurityGroupPermissionRepository } from '../entity-security-group/entitySecurityGroupPermissionRepository';
import { EntitySecurityGroupPermissionService } from '../entity-security-group/entitySecurityGroupPermissionService';
import { TaskTypeListViewModel } from './taskTypeListViewModel';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-task-type-list',
  templateUrl: './task-type-list.component.html',
  styleUrls: ['./task-type-list.component.scss']
})
export class TaskTypeListComponent implements OnInit {
  public selectedTaskType: TaskType;

  dataSource: Function;
  itemCount: number;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  @ViewChild('list') list: ListComponent;

  constructor(
    private baseRepository: BaseRepository,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private synchronizationService: SynchronizationService,
    private entitySecurityGroupPermissionService: EntitySecurityGroupPermissionService,
    private authenticatedUser: AuthenticatedUser) { }

  async ngOnInit(): Promise<void> {
    this.dataSource = async (): Promise<ListDataSourceFunctionResult> => {
      let taskTypes = (await TaskType.table.toArray()).filter(x => !x.isDeleted);

      taskTypes = await this.entitySecurityGroupPermissionService.getAllowedEntities(taskTypes, "TaskType", this.authenticatedUser.id);
      
      let items = _.map(taskTypes, x => new TaskTypeListViewModel({ id: x.id, name: x.name, selected: false }));

      this.itemCount = items.length;

      return new ListDataSourceFunctionResult({ items: _.sortBy(items, x => x.name), itemCount: items.length });
    }

    this.route.queryParams.subscribe(async params => {
      if (!params.skipSynchronization) {
        await this.synchronizationService.getUpdates(true, false);
      }
    });
  }

  navigateToTaskType(item: TaskTypeListViewModel) {
    this.router.navigate([`/task-types/${item.id}`]);
  }

  addClick() {
    this.router.navigate([`/task-types/new`]);
  }

  selectTaskType(task) {
    this.selectedTaskType = task;
  }

  async deleteClick() {
    this.modalPopup.display(new SimplePopupOptions({
      titleIcon: 'fas fa-exclamation-triangle',
      title: this.translateService.instant("taskType.list.deletePopup.title"),
      content: this.translateService.instant("taskType.list.deletePopup.message"),
      buttons: [
        new DangerPopupButton({ key: "delete", description: this.translateService.instant("taskType.list.deletePopup.deleteButton"), class: "danger" }),
        new SimplePopupButton({ description: this.translateService.instant("taskType.list.deletePopup.cancelButton") })
      ],
      onModalResult: async (result) => {
        if (result === "delete") {
          var item = await TaskType.table.get(this.selectedTaskType.id);

          item.isDeleted = true;

          await this.baseRepository.update(TaskType.table, item)

          this.list.updateData();
        }
      }
    }));
  }
}
