import { DataSourceImportation } from "src/app/core/data/models/database/dataSourceImportation.database";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root"})
export class DataSourceService{
  public async createDummyItem(dataSourceId: string){
    let dataSource = await DataSourceImportation.table.get(dataSourceId);

    let fields = JSON.parse(dataSource.fieldTitles);

    let dummyObject = {};

    fields.forEach(item => {
      dummyObject[item.key] = "";
    });   

    return dummyObject;
  }
}