import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent {
  constructor(
    private router: Router
  ) { }

  public visible: boolean = true;

  public async retry() {
    await this.router.navigate(["login"]);
  }
}
