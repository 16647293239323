<app-popup #popup>
    <div class="layout-vertical">
      <div class="layout-top">
        <div class="layout-horizontal color-set-blue height-normal defaultPadding">
          {{'components.customFields.pictureBox.quality' | translate}}
        </div>
      </div>
      <div class="layout-center defaultPadding">
        <div>{{'components.customFields.pictureBox.qualityInformation' | translate}}</div>
        <input #slider class="slider padding-vertical-normal" type="range" min="1" max="100" value="100">
        <div class="layout-horizontal">
          <div class="layout-left">{{'components.customFields.pictureBox.qualityLow' | translate}}</div>
          <div class="layout-center">{{'components.customFields.pictureBox.qualityMedium' | translate}}</div>
          <div class="layout-right">{{'components.customFields.pictureBox.qualityOriginal' | translate}}</div>
        </div>
      </div>
      <div class="layout-bottom">
        <div class="layout-horizontal">
          <div class="layout-right">
            <div class="defaultPadding">
              <button class="color-set-green" type="button" (click)="saveCompressionLevel()">
                <div>
                  <span>{{'general.save' | translate}}</span>
                </div>
              </button>
            </div>
            <div class="defaultPadding">
              <button class="color-set-blue" type="button" (click)="close()">
                <div>
                  <span>{{'general.cancel' | translate}}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-popup>