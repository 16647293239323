import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataSourceImportation } from "src/app/core/data/models/database/dataSourceImportation.database";
import { Validation } from "src/app/core/validation";
import { ValidationDictionary } from "src/app/core/validationDictionary";
import { DataSourceService } from "../task-list/dataSourceService";
import { FilterExpression } from "../task-list/filterExpression";
import { FilterExpressionFormatterFactory } from "../task-list/filterExpressionFormatterFactory";

@Injectable({providedIn: "root"})
export class DataSourceFilterValidator{
  constructor(
    private filterExpressionFormatterFactory: FilterExpressionFormatterFactory,
    private translateService: TranslateService,
    private dataSourceService: DataSourceService){
  }
  
  public async validate(dataSourceId: string, expression: string): Promise<ValidationDictionary>{
    let filterExpressionFormatters = this.filterExpressionFormatterFactory.create();
    let validationDictionary = new ValidationDictionary();

    try {
      let filterExpression = new FilterExpression(expression, filterExpressionFormatters);

      let dummyResult = [];

      dummyResult.push(await this.dataSourceService.createDummyItem(dataSourceId));

      filterExpression.execute(dummyResult);
    } catch (error) {
      validationDictionary.add(new Validation({message: this.translateService.instant("optionList.edit.validations.invalidFilterExpression", {message: error.message})}));
    }

    return validationDictionary;
  }
}