import { AlertControlCondition } from "./alertControlCondition";

export class AlertControl {
  id: string;
  description: string;
  conditions: Array<AlertControlCondition>;

  constructor() {
    this.conditions = new Array<AlertControlCondition>();
  }

  checkBooleanCondition(value: boolean | null): AlertControlCondition {
    for (const condition of this.conditions) {
      if (condition.matchBoolean(value))
        return condition;
    }

    return null;
  }

  checkNumericCondition(value: number | null): AlertControlCondition {
    for (const condition of this.conditions) {
      if (condition.matchNumeric(value))
        return condition;
    }

    return null;
  }

  checkListCondition(value: string | null): AlertControlCondition {
    for (const condition of this.conditions) {
      if (condition.matchList(value))
        return condition;
    }

    return null;
  }
}
