<app-popup [visible]="true" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padding-horizontal-normal height-normal">
        {{ 'authentication.loginComponent.title' | translate}}
      </div>
    </div>
  </div>

  <div class="layout-center" *ngIf="loginFailed">
    <div class="layout-horizontal height-small color-set-invert-red padding-horizontal-normal">
      <div class="layout-center align-left">
        {{ 'authentication.loginComponent.loginFailed' | translate}}
      </div>
    </div>
  </div>

  <div class="layout-center padding-normal">
    {{ 'authentication.loginComponent.message' | translate}}
  </div>

  <div class="layout-bottom">
    <div class="layout-horizontal padding-horizontal-normal padding-vertical-compact">
      <div class="layout-right">
        <button class="color-set-orange compact" (click)="login()">
          <div><span>{{ 'authentication.loginComponent.button' | translate}}</span></div>
        </button>
      </div>
    </div>
  </div>
</app-popup>