import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs/internal/Subscription";

@Directive({
  selector: '[appSectionFieldRequired]'
})
export class SectionFieldRequiredDirective implements OnInit, OnDestroy{
	@Input() formGroup: FormGroup;
  @Input() fieldControlName: string;
  @Input() sectionFieldRequiredVisible: boolean = true;

	private control: AbstractControl;

	private  valueChangesSubscription: Subscription;

	constructor(private elementRef: ElementRef, private renderer: Renderer2){
		
	}

	ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  } 

	async ngOnInit() : Promise<void> {
    this.control =this.formGroup.controls[this.fieldControlName];

    setTimeout(() => this.evaluate(), 100);

    this.valueChangesSubscription = this.control.valueChanges.subscribe(val => {
      this.evaluate();
    });
  }

	private async evaluate(){
    const isRequired = this.control.hasValidator(Validators.required) || this.control.hasValidator(Validators.requiredTrue);

    if (this.sectionFieldRequiredVisible){
      if (isRequired && this.control.invalid) {
        this.renderer.removeClass(this.elementRef.nativeElement, "validSection");
        this.renderer.addClass(this.elementRef.nativeElement, "invalidSection")
      }
      else {
        this.renderer.removeClass(this.elementRef.nativeElement, "invalidSection");
        this.renderer.addClass(this.elementRef.nativeElement, "validSection")
      }
    }
  }
}