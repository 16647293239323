import { OnInit, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  public visible: boolean;
  public supportNativeCamera: boolean;
  public imageUrl: any;

  // Properties for native camera support
  public cameraInputElement: HTMLInputElement;

  // Properties custom camera support
  public imageElement: HTMLImageElement;
  public imageWidth: any;
  public imageHeight: any;

  public cameras: MediaDeviceInfo[];
  public camera: MediaStream;
  public flashlightEnabled: boolean;
  public flashEnabled: boolean;
  public flashlightActivated: boolean;
  public videoElement: HTMLVideoElement;

  private canvas: HTMLCanvasElement = document.createElement("canvas");
  private cameraIndex = 0;

  @Output() photoChange: EventEmitter<boolean> = new EventEmitter();

  // Set and dispose of the video element while also making sure the
  // video element is not kept alive. The mediaStream must be called
  // idealy only once while open to avoid not being able to fully
  // dispose the camera. For this reason I kept the job of opening
  // and closing the camera to this component / html element while
  // considering the html element can be removed though ngIf conditions.
  @ViewChild('video', { read: ElementRef, static: false }) set videoElementSet(content: ElementRef<HTMLVideoElement>) {
    if (content) {
      this.videoElement = content.nativeElement;
      this.openCamera();
    } else {
      this.closeCamera();
      delete this.videoElement
    }
  }

  // Set local context element considering ngIf conditions on the html element.
  @ViewChild('image', { read: ElementRef, static: false }) set imageElementSet(content: ElementRef<HTMLImageElement>) {
    if (content) {
      this.imageElement = content.nativeElement;
    }
  }

  // Set local context element considering ngIf conditions on the html element.
  @ViewChild('cameraInput', { read: ElementRef, static: false }) set cameraInputSet(content: ElementRef<HTMLInputElement>) {
    if (content) {
      this.cameraInputElement = content.nativeElement;
    }
  }

  async ngOnInit(): Promise<void> {
    // Get device list without actually opening the camera.
    let devices = await navigator.mediaDevices.enumerateDevices();

    this.cameras = devices.filter(x => x.kind === 'videoinput');
  }

  async open() {
    this.visible = true;
    this.supportNativeCamera = this.supportCapture() && !environment.disableNativeCamera;

    // If the browser supports native camera, trigger the input
    // click and open native camera. Otherwise, custom camera will
    // open through being visible with above properties change
    // and @ViewChild() custom setter.
    if (this.supportNativeCamera) {
      setTimeout(() => {
        // Delay click to allow dom tree to be updated considering
        // the change of camera support. When not delayed two click
        // are required to open the camera which is not desired.
        this.cameraInputElement.click();
      });
    }
  }

  async capturePhoto() {
    let delayMs = 0;

    // Flashlight can be enabled if the browser doesn't supports native
    // camera and the flashlight is supported which currently only
    // in Chrome mobile and desktop. Actual camera flash needs to be
    // simulated for this reason we open the flashlight and set it to
    // take the photo half a second later and close the flashlight
    // an other 0.3 seconds later.
    // Note: Flash is currently in beta an could not be tested in
    // Chrome on Windows 10 due to the lack of access to a camera
    // with a flash. More information need to be gathered from 
    // client devices to know if it fully work.
    if (this.flashEnabled) {
      delayMs = 500;
      await this.setFlashlight(true);

      setTimeout(() => {
        this.setImageUrl();

        if (this.flashEnabled) {
          setTimeout(async () => {
            await this.setFlashlight(false);
          }, 300);
        }
      }, delayMs);
    } else {
      this.setImageUrl();
    }
  }

  private setImageUrl() {
    this.canvas.width = this.videoElement.videoWidth;
    this.canvas.height = this.videoElement.videoHeight;

    this.canvas.getContext("2d").drawImage(this.videoElement, 0, 0);
    this.imageUrl = this.canvas.toDataURL();;
  }

  async confirmPhoto(cameraEvent) {
    // When native camera is supported, the photo taken is saved into the 
    // input triggered by the change event after the confirmation has been
    // made by the user. But the conversion of the image to base64 not yet
    // done so we complete the conversion so it can be transmitted with this
    // component photoChange event.
    if (this.supportNativeCamera) {
      this.imageUrl = await this.toBase64(cameraEvent.target.files[0]);
    }

    this.photoChange.emit(this.imageUrl);
    this.clearPhoto();
    await this.close();
  }

  async clearPhoto() {
    // Makes sure the full canvas is cleared before the next photo is taken 
    // so that the previous photo cropped into the current photo.
    this.canvas.getContext("2d").clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.imageUrl = null;

    if (this.imageElement) {
      this.imageElement.src = null;
    }
  }

  async close() {
    this.clearPhoto();
    this.visible = false;
  }

  public async openCamera() {
    // Set the camera constraint to filter only the camera with the specified
    // deviceId found the the device list through enumerateDevices();
    // The height and width are set to make sure the image is taken with
    // maximum resolution up to 4K. 
    // Note: This could be configured in the future to be displayed to the user
    // or
    const contraints: MediaStreamConstraints = {
      video: {
        deviceId: this.cameras[this.cameraIndex].deviceId,
        width: { ideal: 4096 },
        height: { ideal: 2160 }
      }
    }

    this.camera = await navigator.mediaDevices.getUserMedia(contraints);


    if (environment.cameraFlash) {
      const track = await this.camera.getVideoTracks()[0];
      // @ts-ignore Ignore because ImageCapture is not in the typings 
      // and is supported only in Chrome
      const imageCapture = new ImageCapture(track);
      const photoCapabilities = await imageCapture.getPhotoCapabilities();

      this.flashlightEnabled = photoCapabilities.fillLightMode && photoCapabilities.fillLightMode.length > 0;
    }
  }

  public async switchCamera() {
    this.closeCamera();
    this.cameraIndex = this.cameraIndex === this.cameras.length - 1 ? 0 : this.cameraIndex + 1;
    await this.openCamera();
  }

  public closeCamera() {
    if (this.camera) {
      // This close all tracks for the camera. However it does not mean
      // the camera is closed. It can happen that the camera is still used
      // via an other component.
      this.camera.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  public async setFlashlight(status) {
    const track = await this.camera.getVideoTracks()[0];

    await track.applyConstraints({
      // @ts-ignore this does not compile but is supported
      // in chrome;
      advanced: [{ torch: status }]
    });
  }

  public toggleFlash() {
    this.flashEnabled = !this.flashEnabled;
  }

  public async toggleFlashlight() {
    this.flashlightActivated = !this.flashlightActivated;
    await this.setFlashlight(this.flashlightActivated);
  }

  // Feature check to know if the browser supports the camera
  // natively. Should be true in Android and iOS.
  private supportCapture() {
    let attribute = "capture";
    var i = document.createElement('input');
    i.setAttribute(attribute, "true");
    return !!i[attribute];
  }

  private async toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
