<nav class="layout-horizontal height-large color-set-blue">
  <div class="layout-left">
    <button (click)="onBackButtonClick()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="layout-center">
    <div id="title" *ngIf="title">{{title}}</div>
    <div [class.font-small]="title" *ngIf="subTitle">{{subTitle}}</div>
    <img *ngIf="!title && !subTitle" src="./assets/logo-white.png" class="logo">
  </div>
  
  <div class="layout-right">
      <button class="round compact color-set-gray margin-right" (click)="onProfilButtonClick()">
        <i>{{authenticatedUser.initials}}</i>
      </button>
  </div>
</nav>