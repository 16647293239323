import { Mobile } from "../models/database/mobile.database";
import platform from 'platform';
import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { Injectable } from "@angular/core";
import { MobileHttpClient } from "./mobileHttpClient";
import { SynchronizationContext } from "./synchronizationContext";
import { SynchronizationHttpClient } from "./synchronizationHttpClient";
import { ValidationDictionary } from "../../validationDictionary";
import { Validation } from "../../validation";

@Injectable({
  providedIn: 'root',
})
export class InitializeMobileCommand {
  constructor(
    private mobileHttpClient: MobileHttpClient,
    private synchronizationContext: SynchronizationContext,
    private synchronizationHttpClient: SynchronizationHttpClient
  ) { }

  private newMobile = new Mobile({ description: platform.description });

  private async canSkip(): Promise<boolean> {
    let mobileIdParamater = await LocalParameter.table.get(LocalParameter.MOBILE_ID_PARAMETER_NAME);

    return mobileIdParamater && mobileIdParamater.value;
  }

  public async run(newMobile = this.newMobile) : Promise<ValidationDictionary> {
    let validationDictionary = new ValidationDictionary();

    try {
      if (!await this.canSkip()) {
        if (!navigator.onLine) {
          console.log("Browser need to be online to initialize this device.")
        }

        let mobile = await this.mobileHttpClient.createMobile(newMobile)

        await LocalParameter.table.put(new LocalParameter({
          name: LocalParameter.MOBILE_ID_PARAMETER_NAME,
          value: mobile.id.toUpperCase()
        }));

        await LocalParameter.table.put(new LocalParameter({
          name: LocalParameter.CURRENT_CHANGE_TRACKING_NUMBER_PARAMETER_NAME,
          value: 0
        }));

        await LocalParameter.table.put(new LocalParameter({
          name: LocalParameter.TARGET_CHANGE_TRACKING_NUMBER_PARAMETER_NAME,
          value: 0
        }));
      }

      let mobileIdParameter = await LocalParameter.table.get(LocalParameter.MOBILE_ID_PARAMETER_NAME);
      this.synchronizationContext.mobileId = mobileIdParameter.value;

      if (navigator.onLine) {
        await this.updateRequireSynchronizationFlag();
      }
    } catch (error) {
      validationDictionary.add(new Validation({key: "InitializeMobileCommand", message: error.message }));
    }

    return validationDictionary;
  }

  private async updateRequireSynchronizationFlag() {
    let currentChangeTrackingNumnerParameter = await LocalParameter.table.get(LocalParameter.CURRENT_CHANGE_TRACKING_NUMBER_PARAMETER_NAME);
    let latestChangeTrackingNumber = await this.synchronizationHttpClient.getLastestChangeTrackingToApplyForMobile();

    this.synchronizationContext.requireSynchronization = latestChangeTrackingNumber > currentChangeTrackingNumnerParameter.value;
  }
}