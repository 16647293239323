// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class CustomFieldValueItem extends ChangeTrackingEntity {
  public static table: Dexie.Table<CustomFieldValueItem, string>;
  public static tableName: string = 'CustomFieldValueItem';

  public description: string;  public externalId: string;  public position: number;  public valueAsInt: number;  public valueAsDecimal: number;  public keyIdentifier: string;  public attribute1: string;  public attribute2: string;  public attribute3: string;  public attribute4: string;  public attribute5: string;  public id: string;  public valueListId: string;  public dynamicParentLinkId: string;  public isDeleted: boolean;  public timeStamp: Date;

  public constructor(args?: Partial<CustomFieldValueItem>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return CustomFieldValueItem.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(CustomFieldValueItem.tableName);
      this.table.mapToClass(CustomFieldValueItem);
  }
}
