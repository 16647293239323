import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { UserType } from 'src/app/core/data/enums/userType'
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { SynchronizationContext } from 'src/app/core/data/synchronization/synchronizationContext';
import { environment } from 'src/environments/environment';
import {Location} from '@angular/common';

@Component({
  templateUrl: './mainMenu.component.html',
  styleUrls: ['./mainMenu.component.scss']
})

export class MainMenuComponent implements OnInit {
  public menuItems = [];
  public adminMenuItems = [];

  public userTypes = UserType;
  public navigator = navigator;

  constructor(
    private router: Router,
    public synchronizationContext: SynchronizationContext,
    public authenticatedUser: AuthenticatedUser,
    public authenticationService: AuthenticationService,
    private location: Location
  ) {
    this.menuItems.push({
      title: 'home.menuItems.forms',
      href: '/forms',
      icon: 'fas fa-list'
    });

    if (environment.enableFeatures.tasks) {
      this.menuItems.push({
        title: 'home.menuItems.taskListTitle',
        href: '/tasks',
        icon: 'fas fa-list-check',
        requireOnline: true
      });
    }

    this.menuItems.push({
      title: 'home.menuItems.profile',
      href: '/profile',
      icon: 'far fa-user'
    });

    this.menuItems.push({
      title: 'home.menuItems.systemInformation',
      href: '/administration/system',
      icon: 'fas fa-info'
    });

    if (environment.enableFeatures.dataSourceImportations) {
      this.adminMenuItems.push({
        title: 'home.menuItems.dataSourceImportations',
        href: '/data-source-importations',
        requireOnline: true,
        icon: 'bi-card-list'
      })
    }

    if (environment.enableFeatures.tasks) {
      this.adminMenuItems.push({
        title: 'home.menuItems.taskTypeListTitle',
        href: '/task-types',
        icon: 'fas fa-list-check',
        requireOnline: true,
      });

    }

    if (environment.enableFeatures.securityGroups) {
      this.adminMenuItems.push({
        title: 'home.menuItems.groups',
        href: '/groups',
        icon: 'fas fa-users',
        requireOnline: true,
      });
    }

    this.adminMenuItems.push({
      title: 'home.menuItems.systemHealth',
      href: '/administration/health',
      icon: 'fas fa-heartbeat',
      requireOnline: true
    });
  }

  ngOnInit(): void {
    this.location.replaceState('/home');
  }

  goToLink(item) {
    if (item.requireOnline && !navigator.onLine) {
      return;
    }

    this.router.navigate([item.href]);
  }
}
