// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Task extends ChangeTrackingEntity {
  public static table: Dexie.Table<Task, string>;
  public static tableName: string = 'Task';

  public id: string;  public taskTypeId: string;  public optionListId: string;  public userAccountId: string;  public dataSourceImportationResultId: string;  public position: number;  public timeStamp: Date;

  public constructor(args?: Partial<Task>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Task.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Task.tableName);
      this.table.mapToClass(Task);
  }
}
