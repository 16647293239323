import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements AfterViewInit {
  constructor(
    public authenticationService: AuthenticationService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.authenticationService.logoff();
    }, environment.authentication.logoutDelay || 3000);
  }
}
