/**
* Allow to define the order in which checkboxes change of states.
* By default in Windows, the order is Indeterminate>Unchecked>Checked but some customers
* wants Indeterminate>Checked>Unchecked so a global configuration can be change to define
* the order of states for all checkboxes.
*/
export enum ThreeStatesCheckBoxOrder{
  IndeterminateChecked = 0,
  IndeterminateUnchecked = 1,
}
