import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StorageState } from 'src/app/core/data/storage/storageState';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { SynchronizationStatus } from 'src/app/core/data/synchronization/synchronizationStatus';
import { SynchronizationContext } from 'src/app/core/data/synchronization/synchronizationContext';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Component({
  selector: 'app-synchronization-context',
  templateUrl: './synchronization-context.component.html',
  styleUrls: ['./synchronization-context.component.scss']
})
export class SynchronizationContextComponent implements AfterViewInit, OnInit {
  public SynchronizationStatus = SynchronizationStatus;
  public navigator = navigator;

  public viewInitComplete = false;

  // This will prevent to click on the synchronize button when the user is not authenticated.
  // There are some cases when creating a new database where the user is not still existing
  // but the synchronize button is displayed. And the synchronize button requires to have a valid
  // authenticated user to works properly.
  public isAuthenticated: boolean;

  constructor(
    public synchronizationService: SynchronizationService,
    public synchronizationContext: SynchronizationContext,
    public storageState: StorageState,
    public authenticationService: AuthenticationService
  ) { 
  }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated = await this.authenticationService.isAuthenticated();
  }

  ngAfterViewInit(): void {
    // HACK: This is used to avoid displaying the hide animation
    // on page load. By delaying view init, it keeps animation at 0s
    // duration set in the SCSS.
    setTimeout(() => this.viewInitComplete = true, 500);
  }

  public version = environment.version;

  async synchronize() {
    await this.synchronizationService.getUpdates(true, false);
  }
}
