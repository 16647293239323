<div class="layout-vertical">

  <div class="layout-top">
    <app-header-list [title]="pageTitle">
    </app-header-list>
  </div>

  <div class="layout-center">
    <app-list class="listDefaultStyle" #list [dataSource]="groupListDataSource" [(selectedItems)]="selectedGroups"
      (dataSourceResultChange)="setNumberOfUser($event)" [numberOfRequiredItems]="0" [enableFilter]="true"
      [canToggleSelection]="true" [numberOfSelectableItem]="-1" (activatedItem)="navigate($event)">
      <ng-template #rightOptionalButton>
        <button class="color-set-transparent" (click)="deleteGroups()" [disabled]="!selectedGroups.length">
          <i class="fas fa-trash"></i>
        </button>
      </ng-template>
      <ng-template #listTemplate let-item>
        <div class="layout-vertical">
          <div class="layout-center">
            <div class="layout-horizontal defaultPadding">
              <div class="layout-left">
                <div>
                  <div class="name">
                    {{item.name}}
                  </div>
                  <div class="users">
                    {{numberOfUser.get(item.id)}} {{ 'groups.users' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>

  <div class="layout-bottom">
    <button class="big round shadow fixed color-set-orange" type="button"
      (click)="navigateToNewGroup()">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</div>

<app-modal #deletePopup [boxFixed]="true"></app-modal>