<section id="document-list">
	<div class="document-list-item" *ngFor="let document of documents; trackBy:identify">
		<span>
			{{document.name}}
		</span>
		
		<button 
			type="button" 
			class="btn btn-link"
			[ngClass]="{ disabled : !synchronizationContext.navigator.onLine }" 
			[attr.disabled]="synchronizationContext.navigator.onLine ? null: ''"
			(click)="print(document.id)">{{"audit.documents.produceReport" | translate}}</button>
	</div>
</section>
