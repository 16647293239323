<div class="layout-horizontal fill color-set-gray" *ngIf="display">
  <div class="layout-center">
    <div>
      <button type="button" (click)="goToFirstPage()"><i class="bi bi-chevron-double-left"></i></button>
      <button type="button" (click)="goToPreviousPage()"><i class="bi bi-chevron-left"></i></button>
      <input class="basic page" (ngModelChange)="goToPage(page)" [(ngModel)]="page" [min]="firstPage" [max]="pageCount"
        type="number" />
      <button type="button" (click)="goToNextPage()"><i class="bi bi-chevron-right"></i></button>
      <button type="button" (click)="goToLastPage()"><i class="bi bi-chevron-double-right"></i></button>
    </div>
  </div>
</div>