import { environment } from "src/environments/environment";

/**
* Provices static methods related to geography and google maps.
*/
export class GeolocationService{
  /**
  * Returns the current geographic position.
  * If a static position is defined in the config.js file, this will be the one returns.
  */
  public static getCurrentLocation(position: GeolocationPosition){
    let staticCurrentLocation = environment.currentLocation;

      if (staticCurrentLocation){
        return {
          lat: Number(staticCurrentLocation.latitude),
          lng: Number(staticCurrentLocation.longitude),
        }
      }
      else{
        return {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }
  }
  
  /**
  * Returns the distance bird eye's view between to geographic point.
  */
  // algorithm - Calculate distance between two latitude-longitude points? (Haversine formula) - Stack Overflow
  // https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
	public static getDistanceInKm(lat1, lon1, lat2, lon2) {
		var p = 0.017453292519943295;    // Math.PI / 180
		var c = Math.cos;
		var a = 0.5 - c((lat2 - lat1) * p)/2 + 
						c(lat1 * p) * c(lat2 * p) * 
						(1 - c((lon2 - lon1) * p))/2;
	
		return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
	}
}