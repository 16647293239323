import { Injectable } from '@angular/core';
import { PermissionList } from '../data/models/database/permissionList.database';

// This service create a link between the permission in Nspek web and the ones used in Nspek windows using
// the column UniqueKey that are hardcoded. Nspek web uses the additionnal table PermissionList and PermissionListGroup,
// each permissions being assigned to a PermissionList, but it is not assign in Nspek windows.
//
// To be removed once group and permissions isn't part of Nspek windows anymore
@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private permissionList: PermissionList[];
  private permissionsMap: Map<string, string> = new Map();
  private permissionsMapReversed: Map<string, string> = new Map();

  public getPermissionIdFromUniqueKey(uniqueKey: string): string {
    return this.permissionsMap.get(uniqueKey);
  }

  public getUniqueKeyFromPermissionId(permissionId: string): string {
    return this.permissionsMapReversed.get(permissionId);
  }

  // Can't be done in constructor because of async
  async createCorrespondanceMap() {

    this.permissionList = await PermissionList.table.toArray();

    // Permission keys are hardcoded in Nspek windows, the corresponding code for the web is needed
    this.addKeyToMaps("Key=AddAudit;", this.findPermission("permissions.audit.add"));
    this.addKeyToMaps("Key=SaveAudit;", this.findPermission("permissions.audit.edit"));
    this.addKeyToMaps("Key=RemoveAudit;", this.findPermission("permissions.audit.delete"));
    this.addKeyToMaps("Key=SaveOthersAudit", this.findPermission("permissions.audit.editOthers"));

    this.addKeyToMaps("Key=HandleSharedOptions;", this.findPermission("permissions.option.parameter"));

    this.addKeyToMaps("Key=ViewProgram;", this.findPermission("permissions.form.visualize"));
    this.addKeyToMaps("Key=AddProgram;", this.findPermission("permissions.form.add"));
    this.addKeyToMaps("Key=SaveProgram;", this.findPermission("permissions.form.edit"));
    this.addKeyToMaps("Key=RemoveProgram;", this.findPermission("permissions.form.delete"));

    this.addKeyToMaps("Key=ViewUserGroup;", this.findPermission("permissions.userGroup.visualize"));
    this.addKeyToMaps("Key=AddUserGroup;", this.findPermission("permissions.userGroup.add"));
    this.addKeyToMaps("Key=SaveUserGroup;", this.findPermission("permissions.userGroup.edit"));
    this.addKeyToMaps("Key=RemoveUserGroup;", this.findPermission("permissions.userGroup.delete"));

    this.addKeyToMaps("Key=ViewUserAccount;", this.findPermission("permissions.user.visualize"));
    this.addKeyToMaps("Key=AddUserAccount;", this.findPermission("permissions.user.add"));
    this.addKeyToMaps("Key=SaveUserAccount;", this.findPermission("permissions.user.edit"));
    this.addKeyToMaps("Key=RemoveUserAccount;", this.findPermission("permissions.user.delete"));

    this.addKeyToMaps("Key=ViewConfigurationWizard", this.findPermission("permissions.general.configuration"));
    this.addKeyToMaps("Key=ModifyDropDownLists", this.findPermission("permissions.general.editList"));
    this.addKeyToMaps("Key=UpdateAuditSearchingQuery", this.findPermission("permissions.general.editFiltre"));

    this.addKeyToMaps("Key=ManageSeverityLevel", this.findPermission("permissions.warningInstruction.warningLevel"));
    this.addKeyToMaps("Key=ManageAlertMessages", this.findPermission("permissions.warningInstruction.messages"));
    this.addKeyToMaps("Key=ManageAlertConditions", this.findPermission("permissions.warningInstruction.conditions"));
    this.addKeyToMaps("Key=ManageInstructions", this.findPermission("permissions.warningInstruction.instructions"));

    this.addKeyToMaps("Key=TakeBackupServerDatabase", this.findPermission("permissions.database.server"))
  }

  private addKeyToMaps(key1: string, key2: string) {
    this.permissionsMap.set(key1, key2);
    this.permissionsMapReversed.set(key2, key1);
  }

  private findPermission(code: string): string {
    return this.permissionList.find(x => x.code == code).id
  }
}
