import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationAuthorizationRouting {
  constructor(
  ) { }

  static unauthorizedRoute = 'unauthorized';
  static loginRoute = 'login';
  static logoutRoute = 'logout';

  static register(routes: Routes): Routes {
    return routes.concat([
      {
        path: `${AuthenticationAuthorizationRouting.unauthorizedRoute}/:code`,
        component: UnauthorizedComponent,
      },
      {
        path: AuthenticationAuthorizationRouting.loginRoute,
        component: LoginComponent,
      },
      {
        path: AuthenticationAuthorizationRouting.logoutRoute,
        component: LogoutComponent,
      }
    ]);
  }
}
