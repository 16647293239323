<div class="container">
    <div class="title">
        {{"profilePage.profilePicturePage.title" | translate}}
    </div>
</div>
<div class="container">
    <div class="grid-container">
        <div class="image-grid">
            <ng-container *ngFor="let image of images">
                <img src="{{image.path}}" alt="" class="image" (click)="changeProfilePicturePath(image.path)">
            </ng-container>
        </div>
    </div>    
</div>