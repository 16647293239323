import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdditionalOptionsComponent } from 'src/app/pages/audit/additional-options/additional-options.component';
import { PopupComponent } from '../../popup/popup.component';

@Component({
  selector: 'app-header-audit',
  templateUrl: './header-audit.component.html',
  styleUrls: ['./header-audit.component.scss']
})
export class HeaderAuditComponent {

  public actions;
  
  @Input() title: string;
  @Input() auditNumber: string;
  
  @Output() leftButtonClick = new EventEmitter<void>();
  @Output() additionalOptionsButtonClick = new EventEmitter<void>();
  
  @ViewChild('additionalOptions') additionalOptionsPopup: AdditionalOptionsComponent;

  public onBackButtonClick(): void {
    this.leftButtonClick.emit();
  }

  public openAdditionalOptionsPopup(): void {
    this.additionalOptionsPopup.openAdditionalOptionsPopup();
  }
}
