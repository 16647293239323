import { Injectable } from "@angular/core";
import { UserAccount } from "../models/database/userAccount.database";

@Injectable({
  providedIn: 'root',
})

export class UserAccountRepository {
  static readonly SYNCHRONIZER_ID = 3;

  constructor() { }

  async getUsers(): Promise<UserAccount[]> {
    let users = await UserAccount.table.toCollection().sortBy("name");
    let usersWithoutSynchronizer = users.filter((userAccount: UserAccount) => {
      return userAccount.typeId !== UserAccountRepository.SYNCHRONIZER_ID && !userAccount.isDeleted
    });

    return usersWithoutSynchronizer;
  }
}
