import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable()
export class ConnectedGuardService implements CanActivate {

    onlineOffline: boolean = navigator.onLine;
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!this.onlineOffline) {
            this.router.navigate(['home']);
        }

        return this.onlineOffline;
    }
}
