import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Mobile } from '../models/database/mobile.database';

@Injectable({
  providedIn: 'root',
})
export class MobileHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Mobile`;

  constructor(private http: HttpClient) { }

  async createMobile(mobile: Mobile): Promise<Mobile> {
    return await firstValueFrom(this.http.post<Mobile>(`${this.configUrl}/Create`, mobile));
  }

  async getMobile(mobileId: string): Promise<Mobile> {
    return await firstValueFrom(this.http.get<Mobile>(`${this.configUrl}/${mobileId}`));
  }

  async deleteMobile(mobileId: string): Promise<void> {
    return await firstValueFrom(this.http.post<void>(`${this.configUrl}/Delete`, mobileId));
  }
}