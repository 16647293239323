<nav class="layout-horizontal color-set-black">
  <div class="layout-left">
    <button class="small font-small">
      <i class="fas fa-signal"></i>
      <div>
        <span *ngIf="navigator.onLine">{{ 'navigator.onLine' | translate}}</span>
        <span *ngIf="!navigator.onLine">{{'synchronizationContext.offline' | translate}}</span>
      </div>
    </button>

    <button class="small font-small" *ngIf="navigator.onLine">
      <i class="fas fa-check" *ngIf="!synchronizationContext.inProgress"></i>
      <div *ngIf="!synchronizationContext.inProgress">
        <span>{{'synchronizationContext.status.ready'| translate}}</span>
      </div>

      <i class="fas fa-sync fa-spin" *ngIf="synchronizationContext.inProgress"></i>
      <div *ngIf="synchronizationContext.inProgress">
        <span>{{'synchronizationContext.status.inProgress'| translate}}</span>
      </div>
    </button>
  </div>
  <div class="layout-right">
    <button class="small font-small">
      <div>
        <span>{{ 'synchronizationContext.version' | translate }}{{version}}</span>
      </div>
    </button>
    <button class="small font-small" *ngIf="storageState.available" title="{{ 'navigator.storage.notificationNotAllowedWarning' | translate}}">
      <i class="fas fa-exclamation-triangle" *ngIf="!storageState.isPersisted"></i>
      <div>
        <span>{{ 'synchronizationContext.remainingStorage' | translate }}{{storageState.availableStorage}}</span>
      </div>
    </button>
  </div>
</nav>

<button id="synchronizationButton" class="hide color-set-orange"
  [style.visibility]="synchronizationContext.isAccessibleByLocation ? 'visible' : 'hidden'"
  [class.skipAnimation]="!viewInitComplete"
  [class.show]="synchronizationContext.requireSynchronization && navigator.onLine"
  [class.pulse]="synchronizationContext.requireSynchronization && navigator.onLine"
  [class.hide]="!synchronizationContext.requireSynchronization || !navigator.onLine" (click)="synchronize()"
  [disabled]="!navigator.onLine && !isAuthenticated">
  <div> <span> {{'synchronizationContext.synchronize' | translate}}</span> </div>
</button>

<div class="popup" [class.show]="synchronizationContext.type === 'full' && synchronizationContext.inProgress"
  [class.hide]="!synchronizationContext.inProgress">
  <div class="mainOverlay">
    <div class="box ">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left">
          <button><i class="fas fa-sync fa-spin"></i></button>
          <span>
            {{ 'synchronizationContext.popup.inProgressTitle' | translate }}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="margin-bottom-normal">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar"
              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </div>
        <div>
          <div>
            {{'synchronizationContext.popup.inProgressMessage' | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</div>