<app-popup [visible]="true" [canClose]="false" [boxFixed]="true" [vertical]="true">
  <div class="layout-horizontal color-set-blue">
    <div class="layout-left padding-normal">
      {{"audit.copy.progressTitle" | translate}}
    </div>
  </div>

  <div *ngIf="!inError" class="content padding-normal">
    <div [ngSwitch]="copyingStep">
      <div *ngSwitchCase="CopyStep.validating">{{"audit.validation" | translate}}</div>
      <div *ngSwitchCase="CopyStep.copying">{{"audit.copy.copying" | translate}}</div>
    </div>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar"
        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
      </div>
    </div>
  </div>

  <div *ngIf="inError" class="content padding-normal">
    {{"audit.copy.error" | translate}}
  </div>

  <div *ngIf="inError" class="layout-bottom">
    <div class="layout-horizontal color-set-white height-normal padding-horizontal-normal">
      <div class="layout-right">
        <button class="compact color-set-blue" (click)="navigateToHome()">
          <div><span>{{ 'unauthorized.retry' | translate }}</span></div>
        </button>
      </div>
    </div>
  </div>
</app-popup>