import { ListComponent } from "src/app/components/list/list.component";
import { ListOptions } from "src/app/components/list/listOptions";
import { TaskMapComponent } from "../task-map/task-map.component";

export class HtmlTemplateRenderer{
  private _mapItemFunction: Function;
  private _mapGroupFunction: Function;

  constructor(listOptions: ListOptions) {
    this._mapItemFunction = listOptions.createMapItemFunction();
    this._mapGroupFunction = listOptions.createMapGroupFunction();
  }

  public execute(item, group){
    if (item){
      this._mapItemFunction(item, { 
        template: ListComponent.templatePropertyField, 
        notSelectedMapMarker: TaskMapComponent.notSelectedMapMarkerPropertyName, 
        selectedMapMarker: TaskMapComponent.selectedMapMarkerPropertyName 
      });
    }

    if (group){
      this._mapGroupFunction(group, {
        template: ListComponent.templatePropertyField, 
        notSelectedMapMarker: TaskMapComponent.notSelectedMapMarkerPropertyName, 
        selectedMapMarker: TaskMapComponent.selectedMapMarkerPropertyName 
      });
    }
  }
}