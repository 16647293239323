import { ValidationDictionary } from "src/app/core/validationDictionary";

export class TryGetResult<T>{
  get success(){
    return this.validationDictionary.isValid();
  }
  
  validationDictionary: ValidationDictionary;
  value: T;

  constructor(){
    this.validationDictionary = new ValidationDictionary();
  }
}