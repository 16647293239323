// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Parameter extends ChangeTrackingEntity {
  public static table: Dexie.Table<Parameter, string>;
  public static tableName: string = 'Parameter';

  public name: string;  public value: string;  public dataType: string;  public id: string;  public timeStamp: Date;

  public constructor(args?: Partial<Parameter>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Parameter.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Parameter.tableName);
      this.table.mapToClass(Parameter);
  }
}
