import { TranslateService } from "@ngx-translate/core";
import { SimplePopupComponent } from "./simplePopup/simplePopup.component";
import { PrimaryPopupButton, SecondaryPopupButton } from "./simplePopup/simplePopupButton";
import { SimplePopupOptions } from "./simplePopup/simplePopupOptions";

export class PopupUtility{
	constructor() {
	}

	public static displayYesNoQuestion(modalPopup: SimplePopupComponent, translateService: TranslateService, title: string, description: string, yesCallBack, noCallBack?){
		modalPopup.display(new SimplePopupOptions({
      titleIcon: 'fas fa-solid fa-question',
      title: title,
      content: description,
      buttons: [
        new PrimaryPopupButton({ key: "yes", description: translateService.instant("popup.yes"), class: "danger" }),
        new SecondaryPopupButton({ description: translateService.instant("popup.no") })
      ],
      onModalResult: async (result) => {
        if (result === "yes") 
					yesCallBack();
				else if (result == "no"){
					if (noCallBack)
						noCallBack();
				}
      }
    }));
	}

	public static displayInformation(modalPopup: SimplePopupComponent, translateService: TranslateService, title: string, description: string, icon: string = "fa-question", okCallBack?){
		modalPopup.display(new SimplePopupOptions({
      titleIcon: `fas fa-solid ${icon}`,
      title: title,
      content: description,
      buttons: [
        new PrimaryPopupButton({ key: "ok", description: "Ok" }),
      ],
      onModalResult: async (result) => {
        if (okCallBack){
          okCallBack();
        }
      }
    }));
	}
}