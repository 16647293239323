// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AlertConditionDetail extends ChangeTrackingEntity {
  public static table: Dexie.Table<AlertConditionDetail, string>;
  public static tableName: string = 'AlertConditionDetail';

  public keyIdentifier: string;  public maximumValue: number;  public minimumValue: number;  public checkBoxChoiceId: number;  public severityLevel: number;  public alertVisibility: boolean;  public color: number;  public name: string;  public description: string;  public id: string;  public alertConditionId: string;  public programDropDownListEntryId: string;  public isDeleted: boolean;  public timeStamp: Date;

  public constructor(args?: Partial<AlertConditionDetail>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AlertConditionDetail.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AlertConditionDetail.tableName);
      this.table.mapToClass(AlertConditionDetail);
  }
}
