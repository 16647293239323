<div class="field-zone" *ngIf="includeInWebInterface && !treatLikeUrl"
    [style.color]="foreColor" 
    [style.background-color]="backColor"
    [style.color]="foreColor" 
    [style.background-color]="backColor" 
    [style.font-family]="font?.name"
    [style.font-weight]="font?.bold ? 'bold' : ''"
    [style.font-style]="font?.italic ? 'italic' : ''"
    [style.text-decoration]="
        font?.underline && font?.strikeout ? 'underline line-through' : 
        font?.underline ? 'underline' : font?.strikeout ? 'line-through' : ''"
    [style.font-size]="font ? (font?.size * 1.3) + 'px': ''">    
    {{ description }}
</div>

<div class="field-zone" *ngIf="includeInWebInterface && treatLikeUrl">
	<a class="dx-field-label"      
		[style.color]="foreColor" 
		[style.background-color]="backColor" 
		[style.font-family]="font?.name"
		[style.font-weight]="font?.bold ? 'bold' : ''"
		[style.font-style]="font?.italic ? 'italic' : ''"
		[style.text-decoration]="
				font?.underline && font?.strikeout ? 'underline line-through' : 
				font?.underline ? 'underline' : font?.strikeout ? 'line-through' : ''"
		[style.font-size]="font ? (font.size * 1.3) + 'px': ''"
		[href]="description" >
		{{ description }}
	</a>
</div>