import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Document`;

  constructor(private http: HttpClient) { }

  print(auditId: string, documentId: string, sendToId: string) {
    this.http.post<void>(
			`${this.configUrl}/Print`, null, 
			{ params: { auditId: auditId, documentId: documentId, sendToId: sendToId }})
				.pipe(catchError(x => of(`${x}`))).subscribe(x => {
					if (x)
						alert(x);
				});
  }
}