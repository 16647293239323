import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Dexie } from 'dexie';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { ListComponent } from 'src/app/components/list/list.component';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { DangerPopupButton, SimplePopupButton } from 'src/app/components/popup/simplePopup/simplePopupButton';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { Permission } from 'src/app/core/data/models/database/permission.database';
import { PermissionList } from 'src/app/core/data/models/database/permissionList.database';
import { PermissionListGroup } from 'src/app/core/data/models/database/permissionListGroup.database';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { UserAccountGroup } from 'src/app/core/data/models/database/userAccountGroup.database';
import { UserGroup } from 'src/app/core/data/models/database/userGroup.database';
import { UserAccountRepository } from 'src/app/core/data/repositories/userAccountRepository';
import { PermissionService } from 'src/app/core/services/permissionService';
import { UserService } from 'src/app/core/services/userService';
import { AddUserComponent } from './add-user/add-user.component';
import { GroupState } from './groupState';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy {

  public pageTitle: string;
  public pageSubTitle: string;

  public permissionsListGroup: PermissionListGroup[];
  public permissionsList: PermissionList[][];
  public selectedPermissionsList: PermissionList[][];
  public permissions: Permission[];

  public selectedUsers: UserAccount[] = new Array<UserAccount>();
  public users: UserAccount[];
  public usersAccountGroup: UserAccountGroup[];
  public userDatasource: Function;

  @ViewChild('addUserComponent') addUserComponent: AddUserComponent;
  @ViewChild('userList') userListComponent: ListComponent;
  @ViewChild('canLeavePopup') canLeavePopupComponent: SimplePopupComponent;

  public groupForm: FormGroup = this.formBuilder.group({
    name: [, Validators.required],
    description: [,]
  });

  constructor(
    private headerState: HeaderState,
    private translate: TranslateService,
    public groupState: GroupState,
    private userAccountRepository: UserAccountRepository,
    public userService: UserService,
    private formBuilder: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private permissionService: PermissionService,
    private baseRepository: BaseRepository) { }

  public async ngOnInit(): Promise<void> {
    this.headerState.isAlert = true;
    this.headerState.backButtonClick.subscribe(this.canLeave);
    this.headerState.titleClick.subscribe(this.canLeave);
    this.pageTitle = this.groupState.group.name;

    this.userDatasource = async (): Promise<ListDataSourceFunctionResult> => {
      if (this.users) {
        return new ListDataSourceFunctionResult({
          itemCount: this.users.length,
          items: this.users
        });
      } else {
        this.usersAccountGroup = (await UserAccountGroup.table.toArray()).filter(userAccountGroup => userAccountGroup.groupId === this.groupState.group.id);
        let usersAccountGroupUserIds = this.usersAccountGroup.map(uag => uag.userId);
        this.users = (await this.userAccountRepository.getUsers()).filter(user => usersAccountGroupUserIds.includes(user.id));
        return new ListDataSourceFunctionResult({
          itemCount: this.users.length,
          items: this.users
        });
      }
    };

    this.setUpForm();
    this.loadPermission();
  }

  public ngOnDestroy(): void {
    this.headerState.isAlert = false;
  }

  public permissionSelected(): void {
    this.groupForm.markAsDirty();
  }

  public addUser(): void {
    this.addUserComponent.popup.display();
  }

  public deleteUser(): void {
    this.users = this.users.filter(user => {
      return !this.selectedUsers.includes(user);
    })
    this.selectedUsers.forEach(userAccount => this.addUserComponent.users.push(userAccount));
    this.selectedUsers.splice(0);
    this.userListComponent.updateData();
    this.groupForm.markAsDirty();
  }

  public updateUsers(newUsers: Array<UserAccount>): void {
    for (let newUser of newUsers) {
      this.users.push(newUser);
    }
    this.groupForm.markAsDirty();
    this.userListComponent.updateData();
  }

  public async save(): Promise<void> {
    this.saveGroup();
    this.saveUsers();
    this.savePermissions();
    this.groupForm.markAsPristine();
  }

  public profilLeave(): void {
    this.canLeave(["/profile"]);
  }

  public backLeave(): void {
    this.canLeave(['/groups']);
  }

  private setUpForm(): void {
    this.groupForm.setValue({
      name: this.groupState.group.name ?? '',
      description: this.groupState.group.description ?? '',
    });
    this.groupForm.get("name").valueChanges.subscribe((newValue) => {
      this.groupState.group.name = newValue;
      this.pageTitle = newValue;
    });
    this.groupForm.get("description").valueChanges.subscribe((newValue) => {
      this.groupState.group.description = newValue;
    });
  }

  private async loadPermission(): Promise<void> {
    await this.permissionService.createCorrespondanceMap();

    let plg = await PermissionListGroup.table.toArray();
    let pl = await PermissionList.table.toArray();
    this.permissions = (await Permission.table.toArray()).filter(permission => permission.groupId == this.groupState.group.id);

    // One array of PermissionList for each permission group section
    this.permissionsList = new Array<PermissionList[]>(plg.length);
    this.selectedPermissionsList = new Array<PermissionList[]>(plg.length);

    // Fills each permission group
    for (let i in plg) {
      this.permissionsList[i] = pl.filter(x => x.permissionListGroupId == plg[i].id);

      this.selectedPermissionsList[i] = []
      // Both Nspek windows and web uses UniqueKey when saving Permission, once Permission are removed from windows,
      // SelectedPermissions can be added with this :
      // this.selectedPermissionsList[i] = this.permissionsList[i].filter(permissionList => this.permissions.some(x => x.permissionListId == permissionList.id));
      for (let permission of this.permissions) {
        let permissionList = this.permissionsList[i].find(permissionList => permissionList.id == this.permissionService.getPermissionIdFromUniqueKey(permission.uniqueKey));
        if (permissionList) {
          this.selectedPermissionsList[i].push(permissionList);
        }
      }
    }

    // PermissionListGroup must be loaded last because the ngFor using this table needs
    // the data from this.permissionsList and this.selectedPermissionsList
    this.permissionsListGroup = await PermissionListGroup.table.toArray();
  }

  private saveGroup(): void {
    this.groupState.group.name = this.groupForm.get('name').value;
    this.groupState.group.description = this.groupForm.get('description').value;
    if (typeof this.groupState.group.id === "undefined") {
      this.groupState.group.isDeleted = false;
      this.groupState.group.id = Dexie.Observable.createUUID();
      this.groupState.group.timeStamp = new Date();
      this.baseRepository.insert(UserGroup.table, this.groupState.group)
      this.router.navigateByUrl(`/groups/${this.groupState.group.id}`);
    } else {
      this.baseRepository.update(UserGroup.table, this.groupState.group)
    }
  }

  private saveUsers(): void {
    // UserAccountGroup that were removed
    let usersIds = this.users.map(user => user.id);
    let usersAccountGroupToRemove = this.usersAccountGroup.filter(uag => !usersIds.includes(uag.userId))
    usersAccountGroupToRemove.forEach(uag => this.baseRepository.delete(UserAccountGroup.table, uag))

    // New UserAccountGroup
    let usersAccountGroupUserIds = this.usersAccountGroup.map(uag => uag.userId);
    for (let user of this.users) {
      if (!usersAccountGroupUserIds.includes(user.id)) {
        let newUserAccountGroup = new UserAccountGroup({
          groupId: this.groupState.group.id,
          userId: user.id,
          id: Dexie.Observable.createUUID()
        });
        this.baseRepository.insert(UserAccountGroup.table, newUserAccountGroup);
      }
    }
  }

  private savePermissions(): void {
    let selectedPermissionsIds = this.selectedPermissionsList.flat().map(x => x.id);

    // Permission that were removed
    let permissionsToRemove = this.permissions.filter(permission => !selectedPermissionsIds.includes(this.permissionService.getPermissionIdFromUniqueKey(permission.uniqueKey)));
    permissionsToRemove.forEach(permission => this.baseRepository.delete(Permission.table, permission));

    // New Permission
    let permissionsId = this.permissions.map(permission => this.permissionService.getPermissionIdFromUniqueKey(permission.uniqueKey))
    for (let selectedPermissionId of selectedPermissionsIds) {
      if (!permissionsId.includes(selectedPermissionId)) {
        let newPermission = new Permission({
          groupId: this.groupState.group.id,
          accessValue: 1,
          permissionListId: selectedPermissionId,
          id: Dexie.Observable.createUUID(),
          // To be removed once group and permission isn't part of Nspek windows anymor
          uniqueKey: this.permissionService.getUniqueKeyFromPermissionId(selectedPermissionId)
        })
        this.baseRepository.insert(Permission.table, newPermission);
      }
    }
  }

  private canLeave(navigateArgs: string[]): void {
    if (this.groupForm.dirty) {
      let discardKey = "Discard"
      this.canLeavePopupComponent.display(new SimplePopupOptions({
        titleIcon: 'fas fa-exclamation-triangle',
        content: this.translate.instant("autoSave.confirmLeavingWithoutSaving"),
        buttons: [
          new DangerPopupButton({ key: discardKey, description: this.translate.instant('general.leave'), class: "danger" }),
          new SimplePopupButton({ description: this.translate.instant('general.cancel') })
        ],
        onModalResult: async (result) => {
          if (result === discardKey) {
            this.router.navigate(navigateArgs);
          }
        }
      }));
    } else {
      this.router.navigate(navigateArgs);
    }
  }
}
