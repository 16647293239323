// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class CustomFieldRichTextBoxTemplateText extends ChangeTrackingEntity {
  public static table: Dexie.Table<CustomFieldRichTextBoxTemplateText, string>;
  public static tableName: string = 'CustomFieldRichTextBoxTemplateText';

  public id: string;  public customFieldRichTextBoxId: string;  public templateTextId: string;  public timeStamp: Date;

  public constructor(args?: Partial<CustomFieldRichTextBoxTemplateText>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return CustomFieldRichTextBoxTemplateText.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(CustomFieldRichTextBoxTemplateText.tableName);
      this.table.mapToClass(CustomFieldRichTextBoxTemplateText);
  }
}
