import { Validation } from "./validation";

export class ValidationDictionary{
  private validations: Validation[] = [];

  add(validation: Validation){
    this.validations.push(validation);
  }

  isValid(){
    return this.validations.length == 0;
  }

  getValidations(): Validation[]{
    return this.validations;
  }

  getFirstValidationMessage(){
    return this.validations[0].message;
  }

  clear(){
    this.validations = [];
  }
}