import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataImportationHttpClient{
  private configUrl = `${environment.apiBaseUrl}/Audit`;

  constructor(private http: HttpClient) { }

  async applyDataTemplate(auditId: string, programDataTemplateId: string, dataSearchItem: string){
    return firstValueFrom(
      this.http.post<void>(
        `${this.configUrl}/ApplyDataTemplate`, null, { params: { auditId, programDataTemplateId, dataSearchItem }}));
  }
}