// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class CustomField extends ChangeTrackingEntity {
  public static table: Dexie.Table<CustomField, string>;
  public static tableName: string = 'CustomField';

  public name: string;  public description: string;  public linkControlName: string;  public typeId: number;  public required: boolean;  public outputFormat: string;  public decimalParts: number;  public maxLength: number;  public usePredefinedValues: boolean;  public controlTypeId: number;  public defaultValueAsBoolean: boolean;  public defaultValueAsString: string;  public defaultValueAsNumeric: number;  public inputMask: string;  public systemValueListId: number;  public idKey: string;  public isDropDownList: boolean;  public maximumValue: number;  public minimumValue: number;  public parentLinkName: string;  public fieldAlias: string;  public alertSatelliteDataItems: string;  public id: string;  public alertId: string;  public instructionId: string;  public customFieldValueListId: string;  public customTableId: string;  public stateId: number;  public programId: string;  public generateWebLabel: boolean;  public timeStamp: Date;  public isDeleted: boolean;  public isCalculatedField: boolean;  public calculatedFieldExpression: string;  public calculatedFieldExecutionOrder: number;

  public constructor(args?: Partial<CustomField>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return CustomField.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(CustomField.tableName);
      this.table.mapToClass(CustomField);
  }
}
