import { DynamicTabInstanceViewModel } from "./dynamicTabInstanceViewModel";
import { SectionState } from "./sectionState";

export class DynamicTabFolderViewModel{
  public id: string;
  public idKey: string;
  public description: string;
  public instances: DynamicTabInstanceViewModel[];
  public instancesVisible: boolean;
  public sectionId: string;
  public templateId: string;
	public tableName: string;
  public position: number;
	public state: SectionState = SectionState.Valid;
  public inEdition: boolean;
	public maximumItems?: number;

	/**
	* Returns a value indicating if a new instance can be added to the folder.
	* When a maximum number of items is configured to the folder, this will be the limitation.
	* In other cases, there is no limit.
	*/
	public canAddInstance(){
		if (this.maximumItems)
			return this.instances.length < this.maximumItems;
		else
			return true;
	}
	
  public constructor(args?: Partial<DynamicTabFolderViewModel>) {
    Object.assign(this, args);
  }
}