import { Component } from '@angular/core';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { HeaderState } from "src/app/components/headers/header/headerState";
import { Router } from "@angular/router";
import { HeaderStateLeftButtonType } from './headerStateLeftButtonType';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    public authenticatedUser: AuthenticatedUser,
    public headerState: HeaderState
  ) { }

	leftButtonTypes = HeaderStateLeftButtonType;

  onTitleClick() {
    this.headerState.raiseTitleClick();
  }

	onBackButtonClick() {
    this.headerState.raiseBackButtonClick();
  }
}
