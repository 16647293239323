import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { UserAccount } from "../data/models/database/userAccount.database";

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedUser extends UserAccount {
  public initials = ''
}
