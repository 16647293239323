import { FormFieldValueFormatter } from "./formFieldValueFormatter";

export class TextBoxFormFieldValueFormatter implements FormFieldValueFormatter{
	format(value: any) {
		if (value && typeof value === 'string' || value instanceof String){
			return value.replace(/\r?\n/g, "\r\n");
		}
		else
			return value;
	}
}
