import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuditState } from "src/app/pages/audit/auditState";
import { SectionViewModel } from "src/app/pages/audit/audit-sections/sectionViewModel";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { Subscription } from "rxjs";
import { SectionState } from "./sectionState";
import { AuditSectionState } from "src/app/core/data/viewModels/auditSectionState";
import { DynamicTabFolderViewModel } from "./dynamicTabFolderViewModel";
import { DynamicTabInstanceViewModel } from "./dynamicTabInstanceViewModel";
import { Router } from "@angular/router";
import { SectionMapper } from "./sectionMapper";
import { HeaderState } from "src/app/components/headers/header/headerState";

@Component({
  selector: "app-audit-sections",
  templateUrl: "./audit-sections.component.html",
  styleUrls: ["./audit-sections.component.scss"],
})
export class AuditSectionsComponent implements OnInit, OnDestroy {

  public sectionTypes = SectionType;
  public sectionStates = SectionState;
  public sections: SectionViewModel[] = [];
  public activeSection: SectionViewModel;
  private refreshSectionStatesSubscription: Subscription;

  @ViewChild('dynamicTabInstanceView') dynamicTabInstanceView;

  constructor(
    public readonly auditState: AuditState,
    private router: Router,
    private readonly headerState: HeaderState,
  ) { }

  async ngOnInit(): Promise<void> {
    this.headerState.isAlert = true;
    this.refreshSectionStatesSubscription = this.auditState.refreshSectionStates.subscribe(async () => {
      this.refreshStates(this.sections);
    })

    let sections = await new SectionMapper().map(this.auditState.form.sections);

    this.refreshStates(sections);

    this.sections = sections;

  }

  ngOnDestroy(): void {
    this.refreshSectionStatesSubscription?.unsubscribe();
    this.headerState.isAlert = false;
  }

  public onSectionClick(section: SectionViewModel) {
    if (section.type !== this.sectionTypes.DynamicTab) {
      this.auditState.nativateToSection(section.id);
    } else {
      section.instancesVisible = !section.instancesVisible;
    }
  }

  public onDynamicFolderClick(folder: DynamicTabFolderViewModel) {
    folder.instancesVisible = !folder.instancesVisible;
  }

  public onAddDynamicTabInstance(folder: DynamicTabFolderViewModel) {
    this.dynamicTabInstanceView.show(null, folder);
  }

  public onDynamicInstanceClick(instance: DynamicTabInstanceViewModel) {
    this.router.navigate([`/forms/${this.auditState.auditNumber}/instances/${instance.id}`]);
  }

  public navigateToAuditList(): void {
    this.router.navigate([`/forms`]);
  }

  private async refreshStates(sections: SectionViewModel[]) {
    for (let section of sections) {
      for (const folder of section.folders) {
        for (const instance of folder.instances) {
          instance.state = AuditSectionState.getState(this.auditState.sectionStates.find(x => x.dataTableName === instance.folder.tableName && x.customTableId == instance.customTableId)?.state);

          // When instance state is not valid, the folder and the section are also considered as invalid.
          if (instance.state !== SectionState.Valid) {
            folder.state = instance.state;
            section.state = instance.state;
          }
        }
      }

      if (section.dataTableName && section.state === SectionState.Valid)
        section.state = AuditSectionState.getState(this.auditState.sectionStates.find(x => x.dataTableName === section.dataTableName)?.state);
    }
  }
}
