import { Component, OnInit, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormField } from "src/app/core/data/models/formField";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { ExtendedProperty } from "src/app/core/data/models/form/extendedProperty";

@Component({
  selector: 'app-basecontrol',
  templateUrl: './basecontrol.component.html',
  styleUrls: ['./basecontrol.component.scss']
})
export class BaseControlComponent implements OnInit, AfterViewInit {
  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: FormGroup = new FormGroup({});

  public formControl: AbstractControl;
  public extendedProperties: ExtendedProperty[];

  ngOnInit(): void {
    this.extendedProperties = this.input.options.find(x => x.key === "extendedProperties").value as ExtendedProperty[];
  }

  ngAfterViewInit(): void {
    this.formControl = this.form.controls[this.input.key];
  }

  public setValue(value) {
    this.formControl.setValue(value);
    this.formControl.markAsDirty();

    this.input.value = value;
  }

  hasExtendedProperty(key: string): boolean {
    return this.extendedProperties.find(x => x.key === key) != null;
  }

  getExtendedProperty(key: string, defaultValue?: any): any {
    let extendedProperty = this.extendedProperties.find(x => x.key === key);

    if (extendedProperty)
      return extendedProperty.value;
    else
      return defaultValue;
  }
}
