import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { AdditionalOptionButton } from './additionalOptionButton';

@Component({
  selector: 'app-additional-options',
  templateUrl: './additional-options.component.html',
  styleUrls: ['./additional-options.component.scss']
})
export class AdditionalOptionsComponent implements OnInit {

  @Input() auditNumber: string;
  @ViewChild('additionalOptions') additionalOptionsPopup: PopupComponent;
  @ViewChild('deleteConfirmation') deleteConfirmationPopup: PopupComponent;

  public buttons: Array<AdditionalOptionButton>;

  constructor(
    public router: Router
    ) { }

  ngOnInit(): void {
    this.buttons = 
    [
      {
        text: "audit.copy", 
        textClass: "", 
        icon:"fa fa-clone", 
        action: this.navigateToAuditCopy.bind(this)
      },
      {
        text: "audit.delete", 
        textClass: "color-set-red", 
        icon:"fa-solid fa-trash color-set-red", 
        action:this.openDeleteConfirmationPopup.bind(this)
      },
    ];
  }

  public openAdditionalOptionsPopup(): void {
    this.additionalOptionsPopup.display();
  }

  public closeAdditionalOptionsPopup(): void {
    this.additionalOptionsPopup.close();
  }  

  public openDeleteConfirmationPopup(): void {
    this.deleteConfirmationPopup.display();
  }

  public closeDeleteConfirmationPopup(): void {
    this.deleteConfirmationPopup.close();
  }
  
  public navigateToAuditCopy(): void {
    this.router.navigate([`/forms/${this.auditNumber}/copy`]);
  }

  public navigateToAuditDelete(): void {
    this.router.navigate([`/forms/${this.auditNumber}/delete`]);
  }
}
