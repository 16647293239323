<div class="details-header container-header">
    <div class="container-header-left-section">
        <p class="container-header-title noPadding">{{ this.baseListDetailsState.isNewItem ? ('customFieldValueListPage.newCustomFieldValueListTitle' | translate) : baseListDetailsState.currentItem?.name}}</p>
    </div>
    <div class="container-header-center-section"></div>
    <div class="container-header-right-section">
        <div *ngIf="!this.baseListDetailsState.isNewItem" class="container-header-icon-container" 
            (click)="showDeleteCustomFieldValueListPopup()">
            <i class="bi bi-trash-fill container-header-icon"></i>
        </div>                
        <dx-popup                    
            [width]="'auto'"
            [height]="'auto'"                      
            [deferRendering]="false"
            [dragEnabled]="false"
            [closeOnOutsideClick]="true"
            [showCloseButton]="false"                    
            [(visible)]="deletePopupVisible">
            <dxi-toolbar-item
                widget="dxButton"
                toolbar="bottom"
                location="center"                
                [options]="deletePopupConfirmButtonOptions">
            </dxi-toolbar-item>
            <dxi-toolbar-item
                widget="dxButton"
                toolbar="bottom"
                location="center"
                [options]="deletePopupCloseButtonOptions">                                                
            </dxi-toolbar-item>
            <dxo-position
                at="center"
                my="center">
            </dxo-position>        
            <div *dxTemplate="let data of 'title'">
                <div class="container-header noPadding"></div>
            </div>
            <div class="popup-content">
                {{deletePopupMessage}}
            </div> 
        </dx-popup>
        <dx-popup                    
            [width]="'auto'"
            [height]="'auto'"                      
            [deferRendering]="false"
            [dragEnabled]="false"
            [closeOnOutsideClick]="true"
            [showCloseButton]="false"                    
            [(visible)]="changeDataTypePopupVisible">
            <dxi-toolbar-item
                widget="dxButton"
                toolbar="bottom"
                location="center"                
                [options]="changeDataTypePopupConfirmButtonOptions">
            </dxi-toolbar-item>
            <dxi-toolbar-item
                widget="dxButton"
                toolbar="bottom"
                location="center"
                [options]="changeDataTypePopupCancelButtonOptions">                                                
            </dxi-toolbar-item>
            <dxo-position
                at="center"
                my="center">
            </dxo-position>        
            <div *dxTemplate="let data of 'title'">
                <div class="container-header noPadding"></div>
            </div>
            <div class="popup-content">
                <p>{{'customFieldValueListPage.dataTypePopupMessage' | translate}}</p>
            </div> 
        </dx-popup>
    </div>
</div>
<div class="details">
    <div class="information section">
        <div class="section-header">
            <div class="section-header-left-section">
                <p class="section-title noPadding">{{'customFieldValueListPage.informationSectionTitle' | translate}}</p>
            </div>
            <div class="section-header-center-section">
            </div>
            <div class="section-header-right-section">
            </div>
        </div>
        <div class="section-content">    
            <label for="name" class="label">{{'customFieldValueListPage.nameLabel' | translate}}</label>                                                          
            <dx-text-box class="name-textbox" 
                id="name"
                [maxLength]="100"                          
                [showClearButton]="true"
                [placeholder]="this.baseListDetailsState.isNewItem ? ('customFieldValueListPage.newCustomFieldValueListNamePlaceholder' | translate) : ''"
                (onValueChanged)="nameValueChanged($event)"
                [value]="baseListDetailsState.currentItem?.name">
                <dx-validator>
                    <dxi-validation-rule 
                        type="custom"
                        message="{{'customFieldValueListPage.nameAlreadyExistsMessage' | translate}}"
                        [validationCallback]="nameExists">
                    </dxi-validation-rule>
                </dx-validator>
            </dx-text-box>     

            <div class="data-type-field">
                <label for="data-type" class="label non-top-label">{{'customFieldValueListPage.dataTypeLabel' | translate}}</label>            
                    <div class="select-box-row">
                        <dx-select-box class="data-type-selectbox"
                        id="data-type"
                        [dataSource]="dataTypeItems"
                        valueExpr="id"
                        displayExpr="text"  
                        (onValueChanged)="dataTypeValueChanged($event)"
                        [disabled]="dataTypeDisabled || baseListDetailsState.isNewItem"
                        [value]="baseListDetailsState.isNewItem == true ? defaultDataType :  baseListDetailsState.currentItem?.dataType">
                    </dx-select-box>
                    <dx-button *ngIf="dataTypeDisabled && !baseListDetailsState.isNewItem" class="data-type-lock-button" 
                        (mouseenter)="lockButtonMouseEnter()" 
                        (mouseleave)="lockButtonMouseLeave()"
                        (click)="lockButtonClick()">
                        <i class="bi bi-unlock-fill unlock-icon" *ngIf="lockIconHidden"></i>
                        <i class="bi bi-lock-fill lock-icon" *ngIf="!lockIconHidden"></i>
                    </dx-button>
                </div>
            </div>
            
            <div class="decimals-field">
                <label for="decimals" class="label non-top-label">{{'customFieldValueListPage.decimalsLabel' | translate}}</label>
                <dx-number-box class="decimals-numberbox" 
                    id="decimals"
                    [min]="0"
                    [max]="5"
                    [showSpinButtons]="true"
                    (onValueChanged)="decimalsValueChanged($event)"
                    [value]=" baseListDetailsState.currentItem?.nbrDecimals == null ? 0 :  baseListDetailsState.currentItem?.nbrDecimals"
                    [disabled]="baseListDetailsState.isNewItem || baseListDetailsState.currentItem?.dataType != numericWithDecimalsDataTypeValue">
                </dx-number-box>
            </div>                  
        </div>
    </div>
    <div class="data section">
        <div class="section-header">
            <div class="section-header-left-section">
                <p class="section-title noPadding">{{'customFieldValueListPage.dataSectionTitle' | translate}}</p>
            </div>
            <div class="section-header-center-section">
            </div>
            <div class="section-header-right-section">
                <div *ngIf="!this.baseListDetailsState.isNewItem" class="section-header-icon-container" (click)="dataGridInstance.addRow()">
                    <i class="bi bi-plus-lg section-header-icon"></i>
                </div>  
                <div *ngIf="!this.baseListDetailsState.isNewItem" class="section-header-icon-container" (click)="showDeleteCustomFieldValueItemPopup()">
                    <i class="bi bi bi-trash-fill section-header-icon"></i>
                </div> 
            </div>
        </div>
        <div class="section-content">            
            <dx-data-grid class="data-grid" 
                height="500px" 
                [dataSource]="baseListDetailsState.currentItem?.customFieldValueItems"
                keyExpr="id"
                (onInitialized)="saveGridInstance($event)"                
                (onRowInserted)="rowInserted($event)"
                (onRowUpdated)="rowUpdated($event)"
                [allowColumnResizing]="true"
                [columnMinWidth]="25"
                [columnResizingMode]="'widget'"
                [columnAutoWidth]="true"
                [showRowLines]="true"
                [showBorders]="true"
                [disabled]="this.baseListDetailsState.isNewItem">     
                <dxo-selection
                    selectAllMode="page"
                    showCheckBoxesMode="always"
                    mode="multiple"
                ></dxo-selection>                       
                <dxi-column 
                    caption="{{'customFieldValueListPage.dataGridDescriptionTitle' | translate}}" 
                    dataField="description"
                    [dataType]="descriptionColumnDataType"></dxi-column>
                <dxi-column
                    caption="{{'customFieldValueListPage.dataGridExternalIdTitle' | translate}}" 
                    dataField="externalId"></dxi-column>
                <dxi-column 
                    [caption]=" baseListDetailsState.currentItem?.attribute1 != null ?  baseListDetailsState.currentItem?.attribute1 : ('customFieldValueListPage.dataGridAttribute1Title' | translate)" 
                    dataField="attribute1"></dxi-column>
                <dxi-column 
                    [caption]=" baseListDetailsState.currentItem?.attribute2 != null ?  baseListDetailsState.currentItem?.attribute2 : ('customFieldValueListPage.dataGridAttribute2Title' | translate)" 
                    dataField="attribute2"></dxi-column>
                <dxi-column 
                    [caption]=" baseListDetailsState.currentItem?.attribute3 != null ?  baseListDetailsState.currentItem?.attribute3 : ('customFieldValueListPage.dataGridAttribute3Title' | translate)" 
                    dataField="attribute3"></dxi-column>
                <dxi-column 
                    [caption]=" baseListDetailsState.currentItem?.attribute4 != null ?  baseListDetailsState.currentItem?.attribute4 : ('customFieldValueListPage.dataGridAttribute4Title' | translate)" 
                    dataField="attribute4"></dxi-column>
                <dxi-column 
                    [caption]=" baseListDetailsState.currentItem?.attribute5 != null ?  baseListDetailsState.currentItem?.attribute5 : ('customFieldValueListPage.dataGridAttribute5Title' | translate)" 
                    dataField="attribute5"></dxi-column>
                <dxo-editing 
                    mode="cell" 
                    [allowUpdating]="true"                             
                    [selectTextOnEditStart]="true"
                    startEditAction="dblClick">
                </dxo-editing>
                <dxo-keyboard-navigation 
                    enterKeyAction="startEdit" 
                    enterKeyDirection="column">
                </dxo-keyboard-navigation>
                <dxo-scrolling showScrollbar="always"></dxo-scrolling>
                <dxo-pager showInfo="true" showNavigationButtons="true"></dxo-pager>                
                <dxo-paging pageSize="25"></dxo-paging>
            </dx-data-grid>
        </div>
    </div>
    <div class="attributes section">
        <div class="section-header">
            <div class="section-header-left-section">
                <p class="section-title noPadding">{{'customFieldValueListPage.attributeSectionTitle' | translate}}</p>
            </div>
            <div class="section-header-center-section">
            </div>
            <div class="section-header-right-section">
            </div>
        </div>
        <div class="section-content">
            <label for="attribute1" class="label">{{'customFieldValueListPage.attribute1' | translate}}</label>
            <dx-text-box class="attribute-textbox"
                id="attribute1" 
                [maxLength]="100" 
                [showClearButton]="true"
                (onValueChanged)="attributeValueChanged($event, 1)"
                [value]=" baseListDetailsState.currentItem?.attribute1"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-text-box>

            <label for="attribute2" class="label non-top-label">{{'customFieldValueListPage.attribute2' | translate}}</label>
            <dx-text-box class="attribute-textbox" 
                id="attribute2" 
                [maxLength]="100" 
                [showClearButton]="true"
                (onValueChanged)="attributeValueChanged($event, 2)"
                [value]=" baseListDetailsState.currentItem?.attribute2"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-text-box>

            <label for="attribute3" class="label non-top-label">{{'customFieldValueListPage.attribute3' | translate}}</label>
            <dx-text-box class="attribute-textbox" 
                id="attribute3" 
                [maxLength]="100" 
                [showClearButton]="true"
                (onValueChanged)="attributeValueChanged($event, 3)"
                [value]=" baseListDetailsState.currentItem?.attribute3"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-text-box>

            <label for="attribute4" class="label non-top-label">{{'customFieldValueListPage.attribute4' | translate}}</label>
            <dx-text-box class="attribute-textbox" 
                id="attribute4"
                [maxLength]="100"
                [showClearButton]="true"
                (onValueChanged)="attributeValueChanged($event, 4)"
                [value]=" baseListDetailsState.currentItem?.attribute4"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-text-box>

            <label for="attribute5" class="label non-top-label">{{'customFieldValueListPage.attribute5' | translate}}</label>
            <dx-text-box class="attribute-textbox" 
                id="attribute5"
                [maxLength]="100" 
                [showClearButton]="true"
                (onValueChanged)="attributeValueChanged($event, 5)"
                [value]=" baseListDetailsState.currentItem?.attribute5"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-text-box>
        </div>
    </div>
    <div class="emails section">
        <div class="section-header">
            <div class="section-header-left-section">
                <p class="section-title noPadding">{{'customFieldValueListPage.emailSectionTitle' | translate}}</p>
            </div>
            <div class="section-header-center-section">
            </div>
            <div class="section-header-right-section">
            </div>
        </div>
        <div class="section-content">
            <label for="" class="label">{{'customFieldValueListPage.emailNameLabel' | translate}}</label>
            <dx-select-box class="email-name-selectbox"
                id="email-name"
                placeholder="{{'customFieldValueListPage.emailNamePlaceholder' | translate}}"
                [dataSource]="emailItems"
                valueExpr="id"
                displayExpr="text"
                (onValueChanged)="emailNameValueChanged($event)"
                [value]=" baseListDetailsState.currentItem?.emailNameAttribute"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-select-box>

            <label for="" class="label non-top-label">{{'customFieldValueListPage.emailAddressLabel' | translate}}</label>
            <dx-select-box class="email-address-selectbox"
                id="email-address"
                placeholder="{{'customFieldValueListPage.emailAddressPlaceholder' | translate}}"
                [dataSource]="emailItems"
                valueExpr="id"
                displayExpr="text"
                (onValueChanged)="emailAddressValueChanged($event)"
                [value]=" baseListDetailsState.currentItem?.emailAddressAttribute"
                [disabled]="this.baseListDetailsState.isNewItem">
            </dx-select-box>
        </div>
    </div>
</div>
