export class SimplePopupButton {
  key: string;
  description: string;
  class: string;

  public constructor(args?: Partial<SimplePopupButton>) {
    Object.assign(this, args);
  }
}

export class PrimaryPopupButton extends SimplePopupButton {
  public constructor(args?: Partial<SimplePopupButton>) {
    super(args);
    this.class = "primary";
  }
}

export class SecondaryPopupButton extends SimplePopupButton {
  public constructor(args?: Partial<SimplePopupButton>) {
    super(args);
    this.class = "secondary";
  }
}

export class DangerPopupButton extends SimplePopupButton {
  public constructor(args?: Partial<SimplePopupButton>) {
    super(args);
    this.class = "danger";
  }
}