import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Parameter } from "../models/database/parameter.database";

@Injectable()
export class SynchronizationGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      let userIdParameter = await Parameter.table.where("name").equals(environment.constants.USERID_PARAMETER_NAME).first();

      if (!userIdParameter) {
        resolve(false);
      }

      resolve(true);
    });
  }
}