<app-popup [visible]="true" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padding-horizontal-normal height-normal">
        {{ 'authentication.logoutComponent.title' | translate}}
      </div>
    </div>
  </div>

  <div class="layout-center padding-normal">
    {{ 'authentication.logoutComponent.message' | translate}}
  </div>
</app-popup>