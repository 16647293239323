import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { HeaderState } from "src/app/components/headers/header/headerState";
import { HeaderStateLeftButtonType } from '../header/headerStateLeftButtonType';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header-detail',
  templateUrl: 'header-detail.component.html',
  styleUrls: ['./header-detail.component.scss'],
})
export class HeaderDetailComponent {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() useBackButton: boolean = false;
  @Input() leftButtonType: HeaderStateLeftButtonType = HeaderStateLeftButtonType.default;
  @Output() leftButtonClick = new EventEmitter<void>();
  @Output() profilButtonClick = new EventEmitter<void>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authenticatedUser: AuthenticatedUser,
    public headerState: HeaderState
  ) { }

	leftButtonTypes = HeaderStateLeftButtonType;

  onTitleClick() {
    this.headerState.raiseTitleClick();
  }

	onBackButtonClick() {
    this.leftButtonClick.emit();
  }

  onProfilButtonClick() {
    this.profilButtonClick.emit();
  }
}
