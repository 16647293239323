import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progression-popup',
  templateUrl: './progression-popup.component.html',
  styleUrls: ['./progression-popup.component.scss']
})
export class ProgressionPopupComponent {
  constructor() { }

  @Input() visible: boolean = false;
  @Input() title: string = "";
  @Input() message: string = "";
}
