import { Injectable } from "@angular/core";
import { ChangeTrackingEntity } from "src/app/core/data/changeTracking/changeTrackingEntity";
import { EntitySecurityGroupPermissionRepository } from "./entitySecurityGroupPermissionRepository";

@Injectable({ providedIn: "root"})
export class EntitySecurityGroupPermissionService{
  public constructor(private entitySecurityGroupPermissionRepository: EntitySecurityGroupPermissionRepository) {
  }

  /**
  * Returns a list of all allowed entities based on the security groups associated with each of them and the authenticated user.
  */
  async getAllowedEntities(entities: ChangeTrackingEntity[], source: string, authenticatedUserId: string){
    let entitySecurityGroupPermissions = await this.entitySecurityGroupPermissionRepository.loadEntitySecurityGroupPermissions(source);
      
    let result = [];

    for (const entity of entities) {
      let entitySecurityGroupPermission = entitySecurityGroupPermissions.find(x => x.entityId === entity.id);
      let allowedTaskType: boolean = false;

      if (entitySecurityGroupPermission){
        if (entitySecurityGroupPermission.hasAccess(authenticatedUserId)){
          allowedTaskType = true;
        }
      }
      else{
        allowedTaskType = true;
      }

      if (allowedTaskType){
        result.push(entity);
      }
    }

    return result;
  }
}