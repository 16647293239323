import { Injectable } from "@angular/core";
import { EntityState } from "src/app/core/data/changeTracking/entityState";
import { AuditAlertSummary } from "src/app/core/data/models/database/auditAlertSummary.database";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";

@Injectable({ providedIn: "root" })
export class AlertSummaryBuilder {
  constructor(
    private authenticatedUser: AuthenticatedUser
  ) { }

  create(auditId: string, value: string, source: string, conditionId: string, controlDescription: string, controlKey: string): AuditAlertSummary {
    let result = new AuditAlertSummary();

    result.entityState = EntityState.New;
    result.userAccountId = this.authenticatedUser.id;
    result.createdDate = new Date(Date.now());
    result.auditId = auditId;
    result.alertConditionDetailId = conditionId;
    result.controlDescription = controlDescription;
    result.controlKey = controlKey;
    result.value = value;
    result.source = source;

    return result;
  }
}