<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-top">
      <app-header-audit (leftButtonClick)="navigateToAuditSections()" 
        [auditNumber]="auditState.auditNumber"
        [title]="auditState.auditNumber + ' > ' + auditState.section.description">
      </app-header-audit>
    </div>
  </div>
  <div [ngSwitch]="auditState.section.type" class="layout-center">
    <app-audit-section-information *ngSwitchCase="sectionTypes.Undefined" #informationTab>
    </app-audit-section-information>

    <div *ngSwitchCase="sectionTypes.CustomFields">
      <div *ngIf="auditState.readonly" class="layout-horizontal color-set-orange">
        <div class="layout-left">
          <button><i class="fas fa-info"></i></button>
          <div><span>{{ 'auditDetail.readonly' | translate }}</span></div>
        </div>
      </div>
      <app-dynamic-form [(id)]="auditState.section.id" [customActions]="customActions" #customFieldTab>
      </app-dynamic-form>
    </div>

    <app-alert-summary *ngSwitchCase="sectionTypes.AlertSummary" [title]="title"></app-alert-summary>

    <app-audit-documents *ngSwitchCase="sectionTypes.Documents"></app-audit-documents>

    <div *ngSwitchDefault>{{ 'auditDetail.notSupportedTabType' | translate}}</div>
  </div>
  <app-audit-navigation class="layout-bottom" *ngIf="!auditState.instanceId"></app-audit-navigation>
</div>