import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnChanges {
  @Input() page: number;
  @Input() itemCount: number;

  @Output() pageChange = new EventEmitter<number>();

  public firstPage = 1;
  public pageCount: number;
  public display: boolean = false;

  private debouncePageTimeout;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // Listen to changes occuring the in dataset to update the page count
    // and hide the paging if there is only one page
    if (changes["itemCount"] || changes["pageSize"]) {
      this.pageCount = Math.ceil(changes["itemCount"].currentValue / environment.numberOfItemPerPage);
      this.display = this.pageCount > 1;
    }
  }

  public async goToFirstPage(): Promise<void> {
    this.page = this.firstPage;
    this.pageChange.emit(this.page);
  }

  public async goToPreviousPage(): Promise<void> {
    if (this.page - 1 >= this.firstPage) {
      this.page--;
      this.pageChange.emit(this.page);
    }
  }

  public async goToNextPage(): Promise<void> {
    if (this.page + 1 <= this.pageCount) {
      this.page++;
      this.pageChange.emit(this.page);
    }
  }

  public async goToPage(page) {
    if (this.debouncePageTimeout) {
      this.debouncePageTimeout.unsubscribe();
    }

    this.debouncePageTimeout = timer(300).subscribe(async () => {
      let pageCount = this.pageCount;

      if (this.page < this.firstPage) {
        this.page = this.firstPage
      } else if (page > pageCount) {
        this.page = pageCount
      }

      this.pageChange.emit(this.page);

      this.debouncePageTimeout.unsubscribe();
    });
  }

  public async goToLastPage(): Promise<void> {
    this.page = this.pageCount;
    this.pageChange.emit(this.page);
  }
}
