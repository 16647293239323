// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DynamicTabTemplate extends ChangeTrackingEntity {
  public static table: Dexie.Table<DynamicTabTemplate, string>;
  public static tableName: string = 'DynamicTabTemplate';

  public description: string;  public name: string;  public keyIdentifier: string;  public id: string;  public customFieldTabControlId: string;  public customTabControlId: string;  public timeStamp: Date;  public isDeleted: boolean;

  public constructor(args?: Partial<DynamicTabTemplate>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DynamicTabTemplate.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DynamicTabTemplate.tableName);
      this.table.mapToClass(DynamicTabTemplate);
  }
}
