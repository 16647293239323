import { AlertControlCondition } from "src/app/components/customFields/controls/alert/alertControlCondition";
import { AlertTemplateField } from "./alertTemplateField";

export class AlertConditionChangingEventArgs{
  constructor(
    public condition: AlertControlCondition,
    public currentAlertTemplate: AlertTemplateField,
    public newAlertTemplate: AlertTemplateField,
    public callBack){
  }
}
