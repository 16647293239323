import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ListComponent } from '../../list/list.component';
import { PopupComponent } from '../../popup/popup.component';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent {
  @Input() public title: string;

  // Lists inputs and outputs
  @Input() public items: any[];
  @Input() public dataSource: Function;

  @Input() public numberOfRequiredItems: number = 0;
  @Input() public numberOfSelectableItem: number = 1;
  @Input() public selectedItems: any | any[];
  @Input() public readOnly: boolean = false;

  @Output() selectedItemsChange = new EventEmitter<any>();
  @Output() activatedItem = new EventEmitter<any>();

  @ViewChild('list') list: ListComponent;
  @ViewChild('popup') popup: PopupComponent;

  @ContentChild('dropdownListTemplate') dropdownListTemplate: TemplateRef<any>;
  @ContentChild('dropdownSelectedTemplate') dropdownSelectedTemplate: TemplateRef<any>;

  /**
   * Public for HTML
   * These properties needs to be known by the drowdown implementation as they will
   * sent to the implementer query function for appropriate filtering.
   */
  public Array = Array;

  constructor() { }

  public showDropdown() {
    if (this.readOnly)
      return;
      
    this.popup.display();

    // Require a very slight delay for the focus to occur correctly. Delaying
    // the focus on the next instruction set seems to work correctly.
    setTimeout(() => {
      this.list.inputFilterElement.nativeElement.focus();
    });
  }

  public onSelectedItemsChange(item: any) {
    this.selectedItemsChange.emit(item);

    if (this.numberOfSelectableItem === 1) {
      this.popup.close();
    }
  }
}
