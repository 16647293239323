import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class TableCache extends ChangeTrackingEntity {
  public static table: Dexie.Table<TableCache, string>;
  public static tableName: string = 'TableCache';

  public id: string;
  public tableName: string;
  public data: Array<object>;

  public constructor(args?: Partial<TableCache>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return TableCache.tableName;
  }

  public getDatabasePrimaryKeyType() {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
    this.table = databaseContext.table(TableCache.tableName);
    this.table.mapToClass(TableCache);
  }
}
