import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Logger } from '../log/logger';
import { VersionHttpClient } from '../security/versionHttpClient';

@Injectable()
export class VersionService {
  constructor(
    private versionHttpClient: VersionHttpClient,
    private logger: Logger
  ) { }

  public async requireUpdate(): Promise<boolean> {
    const requiredVersion = await this.versionHttpClient.getVersion();

    this.logger.logInformation(`Current version: ${environment.version}, Required version: ${requiredVersion}`);

    return environment.version !== requiredVersion;
  }
}
