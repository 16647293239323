<!-- <div class="layout-horizontal padding-horizontal-normal color-set-lightblue height-normal">
  <div class="layout-left">
    {{'advancedSearch.filter' | translate }
  </div>
</div>
<div *ngFor="let fieldMetadata of fieldsMetadata">
  <div>
    <div *ngIf="fieldMetadata.translate">{{fieldMetadata.label | translate }}</div>
    <div *ngIf="fieldMetadata.translate">{{fieldMetadata.labelDescription | translate }}</div>
    <div *ngIf="!fieldMetadata.translate">{{fieldMetadata.label }}</div>
    <div *ngIf="!fieldMetadata.translate">{{fieldMetadata.labelDescription }}</div>
    <input [type]="fieldMetadata.type">
  </div>
</div> -->

<!-- <div class="layout-horizontal height-normal">
  <div class="layout-center">
    <app-dropdown [dataSource]="dataSource">
      <ng-template #dropdownListTemplate #dropdownSelectedTemplate let-item>
        <div class="layout-horizontal height-normal padding-horizontal-normal">
          <div class="layout-left">
            {{ item.label }}
          </div>
        </div>
      </ng-template>
    </app-dropdown>
  </div>
</div> -->


<!-- <app-list [dataSource]="dataSource" [selectionEnabled]="true" [numberOfRequiredItems]="0" [numberOfSelectableItem]="-1">

</app-list> -->

<app-popup #listOptionPopup [canClose]="true" [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-center align-left">
          <button><i class="fas fa-search"></i>
            <div><span>{{ 'advancedSearch.title' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="listOptionPopup.close()"></i></button>
        </div>
      </div>
    </div>

    <div class="layout-center" *ngIf="listOptionPopup.visible">
      <div class="layout-horizontal padding-horizontal-normal color-set-lightblue height-normal">
        <div class="layout-center align-left">
          {{'advancedSearch.orderby' | translate }
        </div>
      </div>
      <div class="layout-horizontal padding-horizontal-normal padding-vertical-compact height-normal">
        <div class="layout-center align-left">
          <app-dropdown class="width-100" [dataSource]="dataSource">
            <ng-template #dropdownListTemplate #dropdownSelectedTemplate let-item>
              <div class="layout-horizontal height-normal padding-horizontal-normal">
                <div class="layout-left">
                  {{ item.label }}
                </div>
              </div>
            </ng-template>
          </app-dropdown>
        </div>
      </div>
      <div class="layout-horizontal padding-horizontal-normal color-set-lightblue height-normal">
        <div class="layout-center align-left">
          {{'advancedSearch.groupby' | translate }
        </div>
      </div>
      <div class="layout-horizontal padding-horizontal-normal padding-vertical-compact height-normal">
        <div class="layout-center align-left">
          <app-dropdown class="width-100" [dataSource]="dataSource">
            <ng-template #dropdownListTemplate #dropdownSelectedTemplate let-item>
              <div class="layout-horizontal height-normal padding-horizontal-normal">
                <div class="layout-left">
                  {{ item.label }}
                </div>
              </div>
            </ng-template>
          </app-dropdown>
        </div>
      </div>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal padding-compact">
        <div class="layout-right">
          <button class="compact color-set-blue">
            <div>
              <span>{{ 'advancedSearch.apply' | translate }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>