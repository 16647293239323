import { Injectable } from "@angular/core";
import { AuditListSearchOptions } from "src/app/pages/audit-list/auditListSearchOptions";
import { environment } from "src/environments/environment";
import { BaseRepository } from "../baseRepository";
import { PagedList } from "../paging/pagedList";
import { UserAudit } from "../models/databaseLocal/userAudit.database";
import _ from 'lodash';
import moment from "moment";
import { WFStep } from "../models/database/wFStep.database";
import { ComparatorService } from "../../services/comparator.service";
import { ListDataSourceFunctionResult } from "src/app/components/list/listDatasourceFunctionResult";

@Injectable({
  providedIn: 'root',
})
export class UserAuditRepository extends BaseRepository {
  async getSortedUserAuditListWithSearchOptions(searchOptions: AuditListSearchOptions, filter: string): Promise<ListDataSourceFunctionResult> {
    let audits = await UserAudit.table.toArray();

    let filteredAudits = [];

    for (let audit of audits) {
      let keep = true;

      if (keep && searchOptions.synchronizedOnly) {
        keep = audit.synchronized;
      }

      if (keep && searchOptions.templateIds && searchOptions.templateIds.length > 0) {
        keep = _.includes(searchOptions.templateIds, audit.programId);
      }

      if (keep && searchOptions.workflowStepNames && searchOptions.workflowStepNames.length > 0) {
        let workflowSteps = await WFStep.table.toArray();
        let filteredWorkflowStep = workflowSteps.filter(workflowStep => _.includes(searchOptions.workflowStepNames, workflowStep.name));
        let filteredWorkflowStepIds = filteredWorkflowStep.map(workflowStep => workflowStep.id);

        keep = _.includes(filteredWorkflowStepIds, audit.stepId);
      }

      if (keep && searchOptions.number && searchOptions.number.length > 0) {
        keep = _.includes(searchOptions.number, audit.number);
      }

      if (keep && searchOptions.externalNumber && searchOptions.externalNumber.length > 0) {
        keep = _.includes(searchOptions.externalNumber, audit.externalNumber);
      }

      if (keep && searchOptions.assignedToIds && searchOptions.assignedToIds.length > 0) {
        keep = _.includes(searchOptions.assignedToIds, audit.responsibleId);
      }

      if (keep && searchOptions.fromCreatedDate) {
        keep = moment(audit.createdDate).isSameOrAfter(moment(searchOptions.fromCreatedDate));
      }

      if (keep && searchOptions.fromCreatedDate) {
        keep = moment(audit.createdDate).isSameOrBefore(moment(searchOptions.toCreatedDate));
      }

      if (keep && searchOptions.fromUpdatedDate) {
        keep = moment(audit.createdDate).isSameOrAfter(moment(searchOptions.fromUpdatedDate));
      }

      if (keep && searchOptions.toUpdatedDate) {
        keep = moment(audit.updatedDate).isSameOrBefore(moment(searchOptions.toUpdatedDate));
      }

      if (keep && filter) {
        // This is a first iteration, to test the feature in combinaison of the
        // filters above. In the future, this should be improved by looping
        // though object keys and filter base on property type to be used as a default
        // in many situation.
        keep = ComparatorService.stringMatch(audit.id || '', filter)
          || ComparatorService.stringMatch(audit.number || '', filter)
          || ComparatorService.stringMatch(audit.externalNumber || '', filter)
          || ComparatorService.stringMatch(moment(audit.createdDate).format('l LT'), filter)
          || ComparatorService.stringMatch(moment(audit.updatedDate).format('l LT'), filter)
          || ComparatorService.stringMatch(moment(audit.effectiveDate).format('l LT'), filter)
          || ComparatorService.stringMatch(audit.stepId || '', filter)
          || ComparatorService.stringMatch(audit.stepName || '', filter)
          || ComparatorService.stringMatch(audit.programId || '', filter)
          || ComparatorService.stringMatch(audit.programDescription || '', filter)
          || ComparatorService.stringMatch(audit.createdById || '', filter)
          || ComparatorService.stringMatch(audit.createdByName || '', filter)
          || ComparatorService.stringMatch(audit.responsibleId || '', filter)
          || ComparatorService.stringMatch(audit.responsibleName || '', filter)
      }

      if (keep) {
        filteredAudits.push(audit);
      }
    }

    let itemCount = filteredAudits.length;
    let sortedItems = _.orderBy(filteredAudits, "updatedDate", "desc");

    return new ListDataSourceFunctionResult({
      itemCount: itemCount,
      items: sortedItems
    });
  }
}