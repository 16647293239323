import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import dxScrollView from 'devextreme/ui/scroll_view';
import { FormField } from "src/app/core/data/models/formField";
import { FormFieldControlService } from "src/app/core/services/formFieldControlService";
import { AuditState } from "src/app/pages/audit/auditState";
import { DynamicFormService } from '../../core/services/dynamicFormService';
import { IEntityAutoSaveGuard } from 'src/app/core/sections/IEntityAutoSaveGuard';
import { CanLeaveEntityAutoSaveResult } from 'src/app/core/sections/canLeaveEntityAutoSaveResult';
import { TranslateService } from '@ngx-translate/core';
import { SectionStateService } from 'src/app/core/services/sectionStateService';
import { PopupComponent } from '../popup/popup.component';
import { DynamicFormCustomAction } from './dynamicFormCustomAction';
import { SimplePopupOptions } from '../popup/simplePopup/simplePopupOptions';
import { DangerPopupButton, SimplePopupButton } from '../popup/simplePopup/simplePopupButton';
import { AuditService } from 'src/app/pages/audit/auditService';
import { SectionMapper } from 'src/app/pages/audit/audit-sections/sectionMapper';
import { DynamicSection } from 'src/app/core/data/models/form/dynamicSection';
import { SimplePopupComponent } from '../popup/simplePopup/simplePopup.component';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import _ from 'lodash';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { CalculatedField } from './calculatedField';
import { InstructionState } from '../instructions/instructionState';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements AfterViewInit, OnChanges, OnDestroy, IEntityAutoSaveGuard {
  @Input() id: string;
  @Input() customActions: DynamicFormCustomAction[];

  @ViewChild('dynamicTabInstanceView') dynamicTabInstanceView;

  @ViewChild(PopupComponent) instructionPopup;
  @ViewChild(PopupComponent, { read: ElementRef }) instructionPopupElement: ElementRef;
  @ViewChild(SimplePopupComponent) deleteSectionModal: SimplePopupComponent;

  public form: FormGroup = new FormGroup({});
  public formFields: FormField<any>[] = new Array<FormField<any>>();

  private dxScrollView: dxScrollView;

  private isDeleted: boolean = false;

  private calculatedField: CalculatedField;

  constructor(
    private formFieldService: FormFieldControlService,
    public auditState: AuditState,
    public instructionState: InstructionState,
    private dynamicFormService: DynamicFormService,
    private translate: TranslateService,
    private router: Router,
    private sectionStateService: SectionStateService,
    private auditService: AuditService,
    private baseRepository: BaseRepository
  ) {
      // Allow to detect when the current section change based on bottom navigation toolbar because this url routing
      // don't trigger the ngOnViewInit.
      this.auditState.onNavigationChange = () => {
        // This will prevent subscribing to the value change twice when navigating to a section without using the navigation bar.
        // Calculated fields is initialized in ngOnInitView which is called after the onNavigationChange when not using the navigation bar.
        if (this.calculatedField && this.calculatedField.sectionId != this.auditState.section.id){
          setTimeout(() => {
            if (this.calculatedField){
              this.calculatedField.changeFormGroup(this.form);

              this.calculatedField.subscribeToValueChange();
            }
          }, 300);

          this.calculatedField.sectionId = this.auditState.section.id;
        }
      }
  }

  ngOnDestroy(): void {
    this.calculatedField.unsubscribeToValueChange();
    this.calculatedField = null;
  }

  onBackToSectionClick() {
    this.auditState.nativateToSection(this.auditState.reference);
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHander(event: any) {
    let canLeaveResult = await this.canLeave();

    if (canLeaveResult.success) {
      if (this.form.dirty){
        event.returnValue = this.translate.instant("validations.dirtyStateChanges");

        return false;
      }
    }
    else {
      event.returnValue = canLeaveResult.message;

      return false;
    }
  }

  async canLeave(): Promise<CanLeaveEntityAutoSaveResult> {
    let result = new CanLeaveEntityAutoSaveResult();

    if (this.isDeleted || !this.form.dirty) {
      result.success = true;

      return result;
    }

    let validateRequiredFields = !this.form.dirty || this.auditState.validateRequiredFields();

    if (validateRequiredFields) {
      result.success = this.form.valid

      if (!result.success) {
        result.message = this.translate.instant("validations.oneOrMoreFieldsRequired");
      }
    }
    else {
      result.success = true;
    }

    return result;
  }

  async saveChanges(): Promise<boolean> {
    if (this.form.dirty && !this.isDeleted) {
      let result;

      result = await this.dynamicFormService.save(this.auditState.audit.id, this.form, this.auditState.formFields, this.auditState.customTableId, this.auditState.sectionStates);

      await this.dynamicFormService.saveFields(this.auditState.audit.id, this.calculatedField.otherSectionDataItems);

      await this.sectionStateService.load(this.auditState.audit.id, this.auditState.form, this.auditState.sectionStates);

      this.auditState.raiseRefreshSectionStates();

      if (result) {
        result = await this.dynamicFormService.saveAlerts(this.auditState.alertSummary);

        this.auditState.alertSummary = this.auditState.alertSummary.filter(x => x.entityState != EntityState.Deleted);
      }

      this.form.markAsPristine();

      return result;
    }
    else
      return true;
  }

  async ngAfterViewInit(): Promise<void> {
    this.calculatedField = new CalculatedField(this.form, this.auditState, this.baseRepository, this.translate);

    this.calculatedField.load();

    await this.calculatedField.initializeDataSource();

    this.calculatedField.subscribeToValueChange();

    this.instructionState.popupShowInstructionEvent.subscribe((controlId) => {
      if (controlId) {
        let instructionToDisplay = this.instructionPopupElement.nativeElement.querySelector("#" + controlId);

        this.instructionPopup.display();
        this.dxScrollView.scrollToElement(instructionToDisplay);
      }
    });
  }

  saveScrollViewInstance(e) {
    this.dxScrollView = e.component;
  }

  // It's necessary to use onChanges to configure the form fields because the same component will be updated with different fields
  // from the audit-section-detail component.
  // Because onInit is only triggered once, it couldn't be done this way.
  async ngOnChanges(): Promise<void> {
    this.formFields = this.auditState.formFields;

    this.form = this.formFieldService.toFormGroup(this.formFields);

    this.formFieldsOriginalValues = this.auditState.getFormFieldsOriginalValues();
  }

  private formFieldsOriginalValues: Record<string, any>[];

  public onRevertChangesClick() {
    for (const fieldOriginalValue of this.formFieldsOriginalValues) {
      this.form.controls[fieldOriginalValue.key].setValue(fieldOriginalValue.value);
    }

    this.form.markAsPristine();
  }

  public async onSaveChangesClick() {
    let canLeaveResult = await this.canLeave();

    if (canLeaveResult.success) {
      await this.saveChanges();
    }
    else
      alert(canLeaveResult.message);
  }

  public executeCustomAction(name: string, parameters: [key: string, value: object]) {
    switch (name) {
      case "deleteDynamicTabInstance":
        this.deleteDynamicTabInstance();

        break;
      case "editDynamicTabInstance":
        this.editDynamicTabInstance();

        break;
      default:
        break;
    }
  }

  private editDynamicTabInstance() {
    let sectionMapper = new SectionMapper();

    let folder = this.getActiveFolder()

    let folderViewModel = sectionMapper.mapFolder(folder, this.auditState.section.id);

    this.dynamicTabInstanceView.show(this.auditState.instanceId, folderViewModel);
  }

  private deleteDynamicTabInstance() {
    const deleteKey = "Delete"

    this.deleteSectionModal.display(new SimplePopupOptions({
      titleIcon: 'fas fa-exclamation-triangle',
      title: this.translate.instant("auditList.deleteDynamicTabInstanceTitle"),
      content: this.translate.instant("auditList.deleteDynamicTabInstanceDescription"),
      buttons: [
        new DangerPopupButton({ key: deleteKey, description: this.translate.instant('auditList.delete'), class: "danger" }),
        new SimplePopupButton({ description: this.translate.instant('auditList.cancel') })
      ],
      onModalResult: async (result) => {
        if (result === deleteKey) {
          await this.auditService.deleteDynamicTabAuditTemplate(this.auditState.audit.id, this.auditState.instanceId);

          let folder = this.getActiveFolder()

          _.remove(folder.instances, x => x.id === this.auditState.instanceId);

          await this.auditState.refreshState();

          this.isDeleted = true;

          await this.router.navigate([`/forms/${this.auditState.auditNumber}/sections`]);
        }
      }
    }));
  }

  private getActiveFolder() {
    for (const section of this.auditState.form.sections) {
      if (!!section["folders"]) {
        let dynamicSection = section as DynamicSection;

        for (const folder of dynamicSection.folders) {
          for (const instance of folder.instances) {
            if (instance.id === this.auditState.instanceId)
              return folder;
          }
        }
      }
    }
  }
}
