<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-left">
        {{ 'taskType.list.title' | translate}} ({{ itemCount }})
      </div>
      <div class="layout-right">
        <button [disabled]="!selectedTaskType" (click)="deleteClick()"><i class="fas fa-solid fa-trash"></i></button>
      </div>
    </div>
  </div>

  <div class="layout-center">
    <app-list #list [dataSource]="dataSource" [numberOfSelectableItem]="1" (activatedItem)="navigateToTaskType($event)"
      [canToggleSelection]="true" (selectedItemsChange)="selectTaskType($event)" [enableFilter]="true">
      <ng-template #listTemplate let-item>
        <div class="layout-vertical">
          <div class="layout-center">
            <div class="layout-horizontal defaultPadding">
              <div class="layout-left">
                <div>
                  {{item.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
</div>

<button type="button" class="big round color-set-orange shadow fixed" (click)="addClick()"><i
    class="fas fa-plus"></i></button>

<app-modal #modalPopup></app-modal>