<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-horizontal color-set-gray leftPadding">
      <div class="layout-right">
        <button (click)="toggleDirections()" [class.active]="directionEnabled"><i class="fas fa-car"></i></button>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="warningMessage">
      <span><i class="fas fa-exclamation-triangle"></i></span> {{ warningMessage | translate}}
    </div>
  </div>
  <div class="layout-center fill">
    <google-map height="100%" width="100%" [center]="center" [options]="mapOptions" (mapClick)="mapClick()">
      <map-marker *ngFor="let marker of markers" [position]="marker.position" [options]="marker.options"
        [icon]="marker.icon" (mapClick)="markerClick(marker)">
      </map-marker>
      <map-directions-renderer *ngIf="directionEnabled && directionResult" [directions]="directionResult" (click)="click()" >
      </map-directions-renderer>
      <!-- <map-polygon [options]="regionOptions"></map-polygon> -->
    </google-map>
  </div>

  <div class="layout-bottom" *ngIf="activeTask && !directionEnabled">
    <div class="activeTask" [innerHTML]="activeTask[templatePropertyResult]">
    </div>
  </div>

  <div class="layout-bottom" *ngIf="directionEnabled">
    <div class="layout-horizontal leftPadding color-set-gray">
      <div class="layout-left">
        Directions ({{ waypointOptimizationInformation }})
      </div>
      <div class="layout-right" (click)="toggleDirectionDetails()">
        <button (click)="toggleWaypointsOptimizationClick()"><i class="fas" 
          [class.fa-gauge-high]="!taskFilter.optimizeWaypoints"
          [class.fa-sort]="taskFilter.optimizeWaypoints"></i></button>
        
        <button (click)="refreshDirectionsClick()"><i class="fas fa-refresh"></i></button>

        <button (click)="directionActionEnabledClick()"><i class="fas" [class.fa-chevron-up]="!directionActionEnabled"
            [class.fa-chevron-down]="directionActionEnabled"></i></button>
      </div>
    </div>

    <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="directionActionEnabled">

      <div class="waypoint default-height"
        *ngFor="let waypoint of waypoints" cdkDrag
        [cdkDragDisabled]="taskFilter.optimizeWaypoints">

        <div class="layout-horizontal leftPadding">

          <div style="width:40px" *ngIf="!taskFilter.optimizeWaypoints"><img src="assets/UpDownArrows.png" height="25" width="25" alt=""></div>
  
          <div class="layout-left" [innerHTML]="waypoint.text" >
          </div>
  
          <div class="layout-right">
            <button (click)="openMap(selectedTasks[waypoint.index])">
              <i class="fas fas fa-map"></i></button>
          </div>
  
        </div>

        <div>({{waypoint.letter}}) {{waypoint.distance}}, {{waypoint.duration}}</div>

      </div>

    </div>
  </div>
</div>