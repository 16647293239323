import * as _ from "lodash"

export class AlertControlCondition {
  id: string;
  name: string;
  description: string;

  minimumValue: number | null;
  maximumValue: number | null;
  booleanValue: boolean | null;
  listValue: string | null;
  color: string;

  matchBoolean(value: boolean | null): boolean {
    return value === this.booleanValue;
  }

  matchNumeric(value: number | null): boolean {
    if (_.isNumber(this.minimumValue) && _.isNumber(this.maximumValue)) {
      if (value === null)
        return false;

      return value >= this.minimumValue && value <= this.maximumValue;
    }
    else if (_.isNumber(this.minimumValue))
      return value >= this.minimumValue;
    else if (_.isNumber(this.maximumValue))
      return value <= this.maximumValue;
  }

  matchList(value: string | null): boolean {
    return value === this.listValue;
  }
}
