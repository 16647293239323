<div class="zone">
  <div class="layout-horizontal height-normal color-set-lightblue">
    <div class="layout-left">
      {{ 'syncronizationError.errorMessage' | translate }}
    </div>
  </div>
  <div *ngIf="synchronizationContext.status === SynchronizationStatus.unreachable">
    {{ 'syncronizationError.unreachable' | translate }}
  </div>
  <div *ngIf="changeTrackingMessage && synchronizationContext.status !== SynchronizationStatus.unreachable">
    <p>
      {{ 'syncronizationError.errorMessage' | translate }}
    </p>
    <p>
      {{ changeTrackingMessage }}
    </p>
  </div>
</div>

<div class="zone-row">
  <div class="layout-horizontal height-normal color-set-lightblue">
    <div class="layout-left">
      Mobile Information
    </div>
  </div>

  <div *ngIf="!synchronizationContext.mobileId">
    It seems we could not create your mobile. This usually happens when you are not authenticated properly. Please clear
    your cache and cookies and refresh the page, if the problem persist contact your administrator.
  </div>
  <table class="table">
    <tbody>
      <tr>
        <th scope=" row">Identifier</th>
        <td>{{synchronizationContext.mobileId}}</td>
      </tr>
      <tr *ngIf="mobile">
        <th scope="row">Number</th>
        <td>M{{mobile.number}}</td>
      </tr>
      <tr *ngIf="mobile">
        <th scope="row">Name</th>
        <td>{{ mobile.name }}</td>
      </tr>
      <tr *ngIf="mobile">
        <th scope="row">Description</th>
        <td>{{ mobile.description }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="zone" *ngIf="authenticatedUser && authenticatedUser.id">
  <div class="layout-horizontal height-normal color-set-lightblue">
    <div class="layout-left">
      User Information
    </div>
  </div>

  <table class="table">
    <tbody>
      <tr>
        <th>id</th>
        <td>{{authenticatedUser.id}}</td>
      </tr>
      <tr>
        <th>code</th>
        <td>{{authenticatedUser.code}}</td>
      </tr>
      <tr>
        <th>name</th>
        <td>{{authenticatedUser.name}}</td>
      </tr>
      <tr>
        <th>typeId</th>
        <td>{{authenticatedUser.typeId}}</td>
      </tr>
      <tr>
        <th>title</th>
        <td>{{authenticatedUser.title}}</td>
      </tr>
      <tr>
        <th>email</th>
        <td>{{authenticatedUser.email}}</td>
      </tr>
      <tr>
        <th>phone</th>
        <td>{{authenticatedUser.phone}}</td>
      </tr>
      <tr>
        <th>extension</th>
        <td>{{authenticatedUser.extension}}</td>
      </tr>
      <tr>
        <th>inactive</th>
        <td>{{authenticatedUser.inactive}}</td>
      </tr>
      <tr>
        <th>windowsAuthentication</th>
        <td>{{authenticatedUser.windowsAuthentication}}</td>
      </tr>
      <tr>
        <th>windowsUsername</th>
        <td>{{authenticatedUser.windowsUsername}}</td>
      </tr>
    </tbody>
  </table>
</div>