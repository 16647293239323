import { CustomFieldControlType } from "./customFieldControlType";
import { ExtendedProperty } from "src/app/core/data/models/form/extendedProperty";
import { RequiredStep } from "src/app/core/data/models/form/requiredStep";
import { AlertTemplateControl } from "src/app/core/data/models/form/alertTemplateControl";
import { ControlFont } from "./controlFont";

export class Control {
  id: string;
  name: string;
  description: string;
  position: number;
  type: CustomFieldControlType;
  children: Control[];
  value: any;
  font: ControlFont;
  generateLabel: boolean;
  isCalculatedField: boolean;
  calculatedFieldExpression: string;
  calculatedFieldExecutionOrder: number;

  extendedProperties: ExtendedProperty[];

  dataTableName: string;
  dataColumnName: string;
  required: boolean | null;
  requiredSteps: RequiredStep[];
  instructionId: string | null;
  alertId: string | null;
  alertTemplates: AlertTemplateControl[];

  sectionName: string;
  isDynamic: boolean;

  validators: string[];

  public static getExtendedProperty(control: Control, key: string): any {
    let extendedProperty = control.extendedProperties.find(
      (x) => x.key === key
    );

    if (extendedProperty) return extendedProperty.value;
    else return undefined;
  }
}
