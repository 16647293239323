import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { DynamicTabAuditTemplate } from "src/app/core/data/models/database/dynamicTabAuditTemplate.database";

export class CreateDynamicTabInstanceResult{
  public auditDataTable: AuditDataTable;
  public dynamicTabAuditTemplate: DynamicTabAuditTemplate;

  public constructor(args?: Partial<CreateDynamicTabInstanceResult>) {
    Object.assign(this, args);
  }
}