import { Injectable } from "@angular/core";
import { IMapper } from "src/app/core/data/mapping/iMapper";
import { CustomFieldValueList } from "src/app/core/data/models/database/customFieldValueList.database";
import { CustomFieldValueListViewModel } from "./customFieldValueListViewModel";

@Injectable({
    providedIn: 'root',
})
export class CustomFieldValueListViewModelMapper implements IMapper<CustomFieldValueListViewModel, CustomFieldValueList>  {
    public map(customFieldValueListViewModel: CustomFieldValueListViewModel): CustomFieldValueList {
        return new CustomFieldValueList({
            id: customFieldValueListViewModel.id,
            description: customFieldValueListViewModel.name,
            dataType: customFieldValueListViewModel.dataType,
            nbrDecimals: customFieldValueListViewModel.nbrDecimals,
            attribute1: customFieldValueListViewModel.attribute1,
            attribute2: customFieldValueListViewModel.attribute2,
            attribute3: customFieldValueListViewModel.attribute3,
            attribute4: customFieldValueListViewModel.attribute4,
            attribute5: customFieldValueListViewModel.attribute5,
            emailNameAttribute: customFieldValueListViewModel.emailNameAttribute,
            emailAddressAttribute: customFieldValueListViewModel.emailAddressAttribute
        });
    }
}