import { Dexie } from "dexie";
import { DatabaseContext } from "../../databaseContext";

export class ChangeTrackingRange {
  public static table: Dexie.Table<ChangeTrackingRange, number>;
  public static tableName: string = 'ChangeTrackingRange';

  public id: number;
  public toChangeTrackingId: number = 0;
  public dataValuesSize: number = 0;

  public constructor(args?: Partial<ChangeTrackingRange>) {
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return ChangeTrackingRange.tableName;
  }

  public getDatabasePrimaryKeyType() {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
    this.table = databaseContext.table(ChangeTrackingRange.tableName);
    this.table.mapToClass(ChangeTrackingRange);
  }
}
