// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class CustomFieldPictureBox extends ChangeTrackingEntity {
  public static table: Dexie.Table<CustomFieldPictureBox, string>;
  public static tableName: string = 'CustomFieldPictureBox';

  public allowSelectImage: boolean;  public allowEditImage: boolean;  public image: string;  public allowTakeImage: boolean;  public imageAdjustmentType: number;  public imageWidth: number;  public imageHeight: number;  public id: string;  public customFieldId: string;  public imageSizeId: string;  public timeStamp: Date;  public useCompression: boolean;  public compressionLevel: number;

  public constructor(args?: Partial<CustomFieldPictureBox>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return CustomFieldPictureBox.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(CustomFieldPictureBox.tableName);
      this.table.mapToClass(CustomFieldPictureBox);
  }
}
