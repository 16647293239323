import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { WorkflowStepPopupComponent } from "src/app/components/workflowstep/workflow-step-popup/workflow-step-popup.component";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Audit } from "src/app/core/data/models/database/audit.database";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { AuditState } from "src/app/pages/audit/auditState";

@Component({
  selector: "app-audit-navigation",
  templateUrl: "./audit-navigation.component.html",
  styleUrls: ["./audit-navigation.component.scss"],
})
export class AuditNavigationComponent implements OnInit {
  public firstSectionId: string;
  public lastSectionId: string;

  private accessibleSections: Section[];

  @ViewChild('workflowStepPopup') workflowStepPopup: WorkflowStepPopupComponent;

  constructor(
    public readonly auditState: AuditState,
    public authenticatedUser: AuthenticatedUser,
    public baseRepository: BaseRepository
  ) { }

  ngOnInit(): void {
    this.accessibleSections = this.auditState.form.sections.filter(x => x.type !== SectionType.DynamicTab);

    this.firstSectionId = this.accessibleSections[0].id;
    this.lastSectionId = this.accessibleSections[this.accessibleSections.length - 1].id;
  }

  onPreviousSectionClick() {
    let currentSectionIndex = this.accessibleSections.map((x) => x.id).indexOf(this.auditState.section.id);

    if (currentSectionIndex === 0) {
      return;
    }

    let previousSectionId = this.accessibleSections[currentSectionIndex - 1].id;

    this.auditState.nativateToSection(previousSectionId)
  }

  onNextSectionClick() {
    let currentSectionIndex = this.accessibleSections.map((x) => x.id).indexOf(this.auditState.section.id);

    if (currentSectionIndex === this.accessibleSections.length - 1) {
      return;
    }

    let nextSectionId = this.accessibleSections[currentSectionIndex + 1].id;

    this.auditState.nativateToSection(nextSectionId)
  }

  public showWorkflowStepPopup() {
    this.workflowStepPopup.display();
  }

  public async saveWorkflowStep(workflowStep) {
    await this.baseRepository.update(Audit.table, new Audit({ id: this.auditState.audit.id, stepId: workflowStep.id }), "AuditNavigationComponent.saveWorkflowStep");
    await this.auditState.updateWorkflowStep(workflowStep.id);
    this.workflowStepPopup.updateData();
  }
}
