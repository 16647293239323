import { DynamicTabFolderViewModel } from "./dynamicTabFolderViewModel";
import { SectionState } from "./sectionState";

export class DynamicTabInstanceViewModel {
  id: string;
  number: string;
  description: string;
  folder: DynamicTabFolderViewModel;
	state: SectionState = SectionState.Valid;
	customTableId: string;

  public constructor(args?: Partial<DynamicTabInstanceViewModel>) {
    Object.assign(this, args);
  }
}
