<div [formGroup]="form" class="field-zone">
	<div *ngIf="input.generateLabel">
		<label class="field">{{input.description}}</label>
		<br>
	</div>
  <div class="controlContainer">
    <dx-check-box
    [formControlName]="input.key"
    (onValueChanged)="handleValueChange($event)"
    [readOnly]="readOnly" class="control"></dx-check-box>
  </div>  
</div>
