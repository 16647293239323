export class AlertTemplateField{
  dataTableName: string;
  conditionDetailId: string;
  templateId: string;
  structureItemId: string;
  customTableId: string;
  sectionId: string;
  referenceTableId: string;
  id: string;
}
