import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SynchronizationContext } from '../data/synchronization/synchronizationContext';

@Injectable({
  providedIn: 'root'
})

// Intercept errors comming back from the webapi and check if it contains an , which is a json object
// containing the api exception returned. In this case we check it is specifically an ApiException 
// with exceptionCode 403. This exception was specially create to identify that the user has no 
// access to this request but also all web api request, which is why we redirect to an unauthorized
// page instead displaying a message or toast.
// Note: This is a first implementation of an interceptor and might need revision to work correctly with
// future interceptors.
export class UserAccountMobileInterceptor implements HttpInterceptor {
  constructor(private synchronizationContext: SynchronizationContext) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone({
      headers: req.headers.set(environment.mobileIdHeaderKey, this.synchronizationContext.mobileId || ''),
    });
    return next.handle(modifiedReq);
  }
}