<app-popup #popup [(visible)]="visible" [canClose]="true" [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue padd">
        <div class="layout-left">
          <button><i class="fas fa-plus"></i>
            <div><span>{{ 'entitySecurityGroup.newUserGroup' | translate}}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button (click)="close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <app-list #list [dataSource]="dataSource" [numberOfRequiredItems]="1" [showInstructions]="false"
        [numberOfSelectableItem]="1" [hoverable]="true" 
        [selectionEnabled]="true" (selectedItemsChange)="selectUserGroup($event)"
        [enableFilter]="true">
        <ng-template #listTemplate let-item>
          <div class="layout-horizontal height-normal padding-horizontal-normal">
            <div class="layout-left">
              {{ item.text }}
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white padding-compact">
        <div class="layout-right">
          <button name="createButton" [class.color-set-green]="selectedItem"
            [disabled]="!selectedItem" (click)="add()">
            <div><span>{{ 'entitySecurityGroup.add' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>