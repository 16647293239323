import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { interceptorProviders } from './core/interceptor/interceptorProviders';
import { ScreenService } from './core/services/screen.service';
import { AppInfoService } from './core/services/app-info.service';
import { HeaderComponent } from "src/app/components/headers/header/header.component";
import { SynchronizationContextComponent } from './components/synchronization/synchronization-context/synchronization-context.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SynchronizationMiddleware } from './core/data/synchronization/synchronizationMiddleware';
import { AuthenticationMiddleware } from './core/authentication/authenticationMiddleware';
import { AppResolver } from './app.resolver';
import { OfflineComponent } from './pages/offline/offline.component';
// import { DatabaseMiddleware } from './core/data/databaseMiddleware';
// import { StoratePersistanceMiddleware } from './core/data/storagePersistanceMiddleware';
// import { AuthenticationAuthorizationModule } from './core/authentication/authentication.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SynchronizationContextComponent],
  exports: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    }),
  ],
  providers: [
    ScreenService,
    AppInfoService,
    interceptorProviders
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Even if not used, by being required by the app module, it creates the instance of the object
  // therefore initializing the application with required information
  constructor() { }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
