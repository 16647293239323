<div class="layout-vertical" [formGroup]="form">
  <div class="layout-center">
    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{taskTypeName}}
      </div>
    </div>

    <div formArrayName="fields">
      <div class="padding-normal" *ngFor="let field of fields.controls; let i=index">
        <div [formGroupName]="i">
          <label class="field">{{field.get("alias").value}}</label>

          <dx-text-box class="text-box" formControlName="value" valueChangeEvent="keyup">
          </dx-text-box>
        </div>
      </div>
    </div>

    <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'taskType.filter.map' | translate}}
      </div>
    </div>

    <div class="field-zone">
      <label for="distanceFromFirstTask" class="field">{{ 'taskType.filter.distanceFromFirstTask' | translate}}</label>

      <dx-number-box id="distanceFromFirstTask" class="text-box" formControlName="distanceFromFirstTask"
        valueChangeEvent="keyup">
      </dx-number-box>
    </div>
  </div>

  <div class="layout-bottom">
    <div class="layout-horizontal padding-horizontal-normal height-normal">
      <div class="layout-right">
        <button class="color-set-blue compact" type="submit" [disabled]="!form.valid" (click)="searchClick()">
          <div><span> {{ 'taskType.filter.search' | translate}}</span></div>
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal #modalPopup></app-modal>