import { Injectable } from "@angular/core";
import {
  IndexableType,
  PromiseExtended,
  Table,
  Transaction,
  TransactionMode,
} from "dexie";
import { TransactionType } from "./dexiejs/transactionType";
import { IBaseRepository } from "./baseRepository.interface";
import { ChangeOperation } from "./changeTracking/changeOperation";
import { ChangeTracking } from "./models/database/changeTracking.database";
import { ChangeTrackingEntity } from "./changeTracking/changeTrackingEntity";
import { DatabasePrimaryKeyType } from "./changeTracking/databasePrimaryKeyType";
import Dexie from "dexie";
import { AuditDataTable } from "./models/database/auditDataTable.database";
import { environment } from "src/environments/environment";
import { ChangeTrackingRange } from "./models/databaseLocal/changeTrackingRange.database";
import { BaseRepositoryConstructor } from "./baseRepositoryConstructor";
import { ApplicationLog } from "./models/database/applicationLog.database";
import { EntityState } from "./changeTracking/entityState";

@Injectable({
  providedIn: "root",
})
export abstract class BaseRepository implements IBaseRepository {
  constructor(
    protected baseRepositoryConstructor: BaseRepositoryConstructor
  ) { }

  public async queryAll<T>(
    table: Table<T, IndexableType>,
    transactionName: string = "BaseRepository.queryAll"
  ): Promise<Table<T, IndexableType>> {
    return await this.baseTransaction<Promise<Table<T, IndexableType>>>(
      TransactionType.Read,
      table,
      async (transaction: Transaction) => {
        return await table;
      },
      `${transactionName}: ${table.name}`
    );
  }

  // Public methods intended as a general usecase for CRUD on a simple type without any domain logic. See protected method below
  // when custom implementation is needed. The
  public async getAll<T>(
    table: Table<T, IndexableType>,
    transactionName: string = "BaseRepository.GetAll"
  ) {
    return await this.baseTransaction<T[]>(
      TransactionType.Read,
      table,
      async (transaction: Transaction) => {
        // Return the full array without any filters, extends a custom Repository from this base class with an appropriate
        // method name to perform more complexe queries including selecting specific fields, filter rows, join tables, etc.
        return await table.toArray();
      },
      `${transactionName}: ${table.name}`
    );
  }

  public async get<T>(
    table: Table<T, IndexableType>,
    key: IndexableType,
    transactionName: string = "BaseRepository.Get"
  ) {
    return await this.baseTransaction<T>(
      TransactionType.Read,
      table,
      async (transaction: Transaction) => {
        return await table.get(key);
      },
      `${transactionName}: ${table.name}`
    );
  }

  // Public methods indended for simple usecases where a single CRUD action is required.
  public async insert<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: T | T[],
    transactionName: string = "BaseRepository.Insert",
  ): Promise<T | T[]> {
    return await this.baseTransaction<T>(
      TransactionType.ReadWrite,
      table,
      async (transaction: Transaction) => {
        return await this.baseInsert(table, entity);
      },
      `${transactionName}: ${table.name}`
    );
  }

  public async update<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: T,
    transactionName: string = "BaseRepository.Update"
  ): Promise<unknown> {
    return await this.baseTransaction<T>(
      TransactionType.ReadWrite,
      table,
      async (transaction: Transaction) => {
        return await this.baseUpdate(table, entity);
      },
      `${transactionName}: ${table.name}`
    );
  }

  public async deleteById<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    id: string,
    transactionName: string = "BaseRepository.Delete"
  ): Promise<unknown> {
    var entity = await table.get(id)

    return await this.delete(table, entity);
  }

  public async delete<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: T,
    transactionName: string = "BaseRepository.Delete"
  ): Promise<unknown> {
    return await this.baseTransaction<T>(
      TransactionType.ReadWrite,
      table,
      async (transaction: Transaction) => {
        return await this.baseDelete(table, entity);
      },
      `${transactionName}: ${table.name}`
    );
  }

  // Protected methods indended to be used in repositories extending this class where multiple action
  // are required to be completed within a single transaction while also considering change tracking
  // and logging.
  public async baseTransaction<T>(
    mode: TransactionMode,
    tables: Table<any, IndexableType>[] | Table<any, IndexableType>,
    scope: (trans: Transaction) => unknown,
    transactionName: string = "BaseRepository.UnnamedTransaction"
  ): Promise<T> {
    var startTime = performance.now();

    if (!Array.isArray(tables)) {
      tables = [tables];
    }

    // Tables need to be specified or won't be included in the transaction
    // and result in Failed to execute 'objectStore' on 'IDBTransaction' error.
    tables.push(ChangeTracking.table);
    tables.push(ChangeTrackingRange.table);
    tables.push(ApplicationLog.table);

    try {
      //this.baseRepositoryConstructor.logger.logInformation(`Start transaction '${transactionName}'`);

      let result = await this.baseRepositoryConstructor.databaseContext.transaction(mode, tables, scope);

      //this.baseRepositoryConstructor.logger.logInformation(`Completed transaction '${transactionName}' in ${performance.now() - startTime}ms.`);

      if (mode === TransactionType.ReadWrite || mode === TransactionType.ReadWriteForceNewTransaction || mode === TransactionType.ReadWriteReuseTransactionIfCompatible) {
        await this.baseRepositoryConstructor.synchronizationService.sendUpdates();
        await this.baseRepositoryConstructor.storageService.updateEstimatedQuota();
      }

      return result as PromiseExtended<T>;
    } catch (reason) {
      this.baseRepositoryConstructor.logger.logError(`Failed transaction '${transactionName}' in ${performance.now() - startTime}ms.`, reason.message);
      throw reason;
    }
  }

  /**
  * Insert, update or delete the entity based on its EntityState.
  */
  public async save<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: any
  ) {
    let result;

    let entityState = entity.entityState;

    delete entity.entityState;
    delete entity["EntityState"];

    switch (entityState) {
      case EntityState.New:
        result = await this.insert<T>(table, entity);
        break;
      case EntityState.Modified:
      case EntityState.Default:
        // Until there is an automatic change tracking of the state, it's better to save the entity
        // even if the state is equal to 'Default' because there is a high probability to forget to change
        // the property entityState when updating fields which cause a silent bug where nothing is updated.
        // Database model should have properties get/set instead of field with the ability to change entityState
        // automatically like with Entity Framework.
        result = await this.update<T>(table, entity);
        break;
      case EntityState.Deleted:
        result = await this.delete<T>(table, entity);
      default:
        break;
    }

    return result;
  }

  public async baseInsert<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: T | T[]
  ): Promise<T | T[]> {
    if (Array.isArray(entity)) {
      entity.map((entity) => {
        if (entity.getDatabasePrimaryKeyType() === DatabasePrimaryKeyType.guid && !entity.id) {
          entity.id = Dexie.Observable.createUUID();
        }

        return entity;
      });

      await this.insertChangeTrackings<T>(table, entity, ChangeOperation.Insert);
      await table.bulkAdd(entity);

      for (const item of entity) {
        item.entityState = EntityState.Default;
      }
    } else {
      if (entity.getDatabasePrimaryKeyType() === DatabasePrimaryKeyType.guid && !entity.id) {
        entity.id = Dexie.Observable.createUUID();
      }

      let entityId = await table.add(entity);

      entity.id = entity.getDatabasePrimaryKeyType() === DatabasePrimaryKeyType.guid ? entityId.toString() : parseInt(entityId.toString());

      entity.entityState = EntityState.Default;

      await this.insertChangeTrackings<T>(table, entity, ChangeOperation.Insert);
    }

    return entity;
  }

  protected async baseUpdate<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: any
  ) {
    await this.insertChangeTrackings<T>(table, entity, ChangeOperation.Update);

    let result;

    result = await table.update(entity.id, entity);

    entity.entityState = EntityState.Default;
    entity.timeStamp = new Date();

    return result;
  }

  protected async baseDelete<T extends ChangeTrackingEntity>(
    table: Table<T, IndexableType>,
    entity: any
  ) {
    await this.insertChangeTrackings<T>(table, entity, ChangeOperation.Delete);

    let result;

    result = await table.delete(entity.id);

    return result;
  }

  public async insertChangeTrackings<T extends ChangeTrackingEntity>(table: Table<T, IndexableType>, entity: T[] | T, changeOperation: ChangeOperation) {
    if (this.requireChangeTracking(table.name, entity)) {
      if (!Array.isArray(entity)) {
        entity = [entity];
      }

      let changeTrackings = entity.map((entity) => {
        return this.baseRepositoryConstructor.changeTrackingFactory.create(table.name, changeOperation, entity);
      });

      // Get the last change tracking range to continue updating it
      let lastChangeTrackingRange = await ChangeTrackingRange.table.toCollection().last();

      if (!lastChangeTrackingRange) {
        lastChangeTrackingRange = new ChangeTrackingRange();
      }

      for (let changeTracking of changeTrackings) {
        let changeTrackingId = await ChangeTracking.table.add(changeTracking);

        if (lastChangeTrackingRange.dataValuesSize < environment.synchronizationMaxDataValuesSize) {
          lastChangeTrackingRange.toChangeTrackingId = changeTrackingId;
          lastChangeTrackingRange.dataValuesSize += changeTracking.dataValuesSize;
        }

        if (lastChangeTrackingRange.dataValuesSize >= environment.synchronizationMaxDataValuesSize) {
          await ChangeTrackingRange.table.put(lastChangeTrackingRange);
          lastChangeTrackingRange = new ChangeTrackingRange();
        }
      }

      await ChangeTrackingRange.table.put(lastChangeTrackingRange);
    }
  }

  private requireChangeTracking(tableName, entity) {
    let tableToIgnore = ['AuditNumber', 'UserAudit', "TaskFilter"];

    if (tableToIgnore.find((x) => x === tableName)) {
      this.baseRepositoryConstructor.logger.logTrace(`Ignored change tracking ${tableName} : ${JSON.stringify(entity)}`)
    }

    return !tableToIgnore.find((x) => x === tableName);
  }
}
