// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class ChangeTracking extends ChangeTrackingEntity {
  public static table: Dexie.Table<ChangeTracking, number>;
  public static tableName: string = 'ChangeTracking';

  public id: number;  public tableName: string;  public tableId: string;  public tableIdInt: number;  public auditId: string;  public changeOperation: number;  public changeTrackingMarker: string;  public timeStamp: Date;  public mobileId: string;  public remoteId: number;  public dataValues: string;  public dataValuesSize: number;  public userAccountId: string;

  public constructor(args?: Partial<ChangeTracking>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return ChangeTracking.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(ChangeTracking.tableName);
      this.table.mapToClass(ChangeTracking);
  }
}
