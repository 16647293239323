import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SynchronizationRangeItem } from '../models/database/synchronizationRangeItem.database';
import { ChangeTracking } from '../models/database/changeTracking.database';
import { Synchronization } from '../models/database/synchronization.database';
import { Mobile } from '../models/database/mobile.database';
import { firstValueFrom } from 'rxjs';
import { TableColumnNameReference } from './tableColumnNameReference';

@Injectable({
  providedIn: 'root',
})
export class SynchronizationHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Synchronization`;

  constructor(private http: HttpClient) { }

  async pingSynchronizationServer(): Promise<boolean> {
    return await firstValueFrom(this.http.get<boolean>(`${this.configUrl}/PingSynchronizationServer`));
  }

  async requireSynchronization(): Promise<boolean> {
    return await firstValueFrom(this.http.get<boolean>(`${this.configUrl}/RequireSynchronization`));
  }

  async getLastestChangeTrackingToApplyForMobile(): Promise<number> {
    return await firstValueFrom(this.http.get<number>(`${this.configUrl}/GetLastestChangeTrackingToApplyForMobile`));
  }

  async getAuditToDelete() {
    return await firstValueFrom(this.http.get<string[]>(`${this.configUrl}/GetAuditToDelete`));
  }

  async createSynchronization() {
    return await firstValueFrom(this.http.get<Synchronization>(`${this.configUrl}/CreateSynchronization`));
  }

  async prepareSynchronizationData() {
    return await firstValueFrom(this.http.get<void>(`${this.configUrl}/PrepareSynchronizationData`));
  }

  async getDateTableColumnNameReference() {
    return await firstValueFrom(this.http.get<TableColumnNameReference[]>(`${this.configUrl}/GetDateTableColumnNameReference`));
  }

  async getNextSynchronizationRange() {
    return await firstValueFrom(this.http.get<SynchronizationRangeItem[]>(`${this.configUrl}/GetNextSynchronizationRange`));
  }

  async completeSynchronizationRange() {
    return await firstValueFrom(this.http.get<boolean>(`${this.configUrl}/CompleteSynchronizationRange`));
  }

  async applyChangeTracking(changeTrackings: ChangeTracking[]) {
    return await firstValueFrom(this.http.post<number>(`${this.configUrl}/ApplyChangeTracking`, changeTrackings));
  }

  async subscribeToAudit(auditNumber: string) {
    return await firstValueFrom(this.http.post(`${this.configUrl}/SubscribeToAudits`, [auditNumber]));
  }
}