import { SectionState } from "src/app/pages/audit/audit-sections/sectionState";

export class AuditSectionState{
  public static StateField = "State";

  state: SectionState;
  dataTableName: string;
  customTableId: string;
  structureItemKey: string;

  static getState(value: SectionState | undefined | null){
    if (value)
      return value;
    else 
      return SectionState.Valid;
  }
}