import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { SynchronizationContext } from 'src/app/core/data/synchronization/synchronizationContext';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent{

  public loginFailed = false;

  constructor(
    public authenticationService: AuthenticationService,
    public synchronizationContext: SynchronizationContext
  ) {
  }

  public async login() {
    if (!this.synchronizationContext.inProgress){
      // TODO AC: currenly always return to window location origin
      // Login might need to add a custom redirectUrl that would
      // return to the correct location. At this time no usecase have been
      // tested to require this and has been keep to it simplest from for now.
      this.loginFailed = !await this.authenticationService.login();
    }
  }
}
