<app-popup #popup>
  <div class="layout-vertical" [formGroup]="form">
      <div class="layout-horizontal padding-left height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'dataSourceProperty.title' | translate}}
        </div>
    </div>

    <div class="layout-center padding-normal">
      <label style="color: red;" *ngIf="showKeyChangeWarning">* En changeant la clé de cette propriété, les données relatives à cette dernière ne seront plus accessibles.</label>
      <div>{{ 'dataSourceProperty.key' | translate}}</div>
      <dx-text-box class="text-box" formControlName="key" valueChangeEvent="keyup" maxLength="250">
      </dx-text-box>

      <div>{{ 'dataSourceProperty.alias' | translate}}</div>
      <dx-text-box class="text-box" formControlName="value" valueChangeEvent="keyup">
      </dx-text-box>

      <div>{{ 'dataSourceProperty.controlType' | translate}}</div>
      <dx-select-box formControlName="controlType" [items]="controlTypes" valueExpr="id" displayExpr="description">
      </dx-select-box>
    </div>
  
      <div class="layout-bottom">
        <div class="layout-horizontal height-normal padding-horizontal-normal">
          <div class="layout-right space">
            <button type="submit" [disabled]="!form.valid || !form.dirty" class="color-set-green compact"
              (click)="saveButtonClick()">
              <div><span>{{ 'dataSourceProperty.save' | translate}}</span></div>
            </button>
            <button class="color-set-gray compact" (click)="cancelButtonClick()">
              <div><span>{{ 'dataSourceProperty.cancel' | translate}}</span></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  <app-modal #modalPopup></app-modal>
</app-popup>