// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Synchronization extends ChangeTrackingEntity {
  public static table: Dexie.Table<Synchronization, number>;
  public static tableName: string = 'Synchronization';

  public id: number;  public mobileId: string;  public completed: boolean;  public fromChangeTrackingId: number;  public toChangeTrackingId: number;  public isNewMobile: boolean;  public timeStamp: Date;

  public constructor(args?: Partial<Synchronization>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Synchronization.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Synchronization.tableName);
      this.table.mapToClass(Synchronization);
  }
}
