import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { DangerPopupButton, SimplePopupButton } from 'src/app/components/popup/simplePopup/simplePopupButton';
import { SimplePopupOptions } from 'src/app/components/popup/simplePopup/simplePopupOptions';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { DataSourceImportationResultItemComponent } from '../data-source-importation-result-item/data-source-importation-result-item.component';
import { DataSourceImportationResultViewModel } from './dataSourceImportationResultViewModel';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-data-source-importation-result',
  templateUrl: './data-source-importation-result.component.html',
  styleUrls: ['./data-source-importation-result.component.scss']
})
export class DataSourceImportationResultComponent implements OnInit {
  @Input() public id: string;

  public dataSource: Function;
  public selectedDataSource;
  itemCount: number = 0;

  @Input() public allowEdit: boolean;

  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  @ViewChild(DataSourceImportationResultItemComponent) resultItemPopup: DataSourceImportationResultItemComponent;

  @ViewChild('list') list: ListComponent;

  @Output()
  dataSourceChanged = new EventEmitter<number>();

  constructor(
    private readonly baseRepository: BaseRepository,
    private readonly router: Router,
    private readonly translateService: TranslateService) { }

  async ngOnInit(): Promise<void> {
    this.dataSource = async (args): Promise<ListDataSourceFunctionResult> => {
      let dataSources = [];

      if (this.id) {
        dataSources = await DataSourceImportationResult.table.where("dataSourceImportationId").equals(this.id).toArray();

        this.dataSourceChanged.emit(dataSources.length);

        if (args.filter)
          dataSources = dataSources.filter(x => x.idKey.includes(args.filter));
      }

      let viewModel = _.map(dataSources, x => new DataSourceImportationResultViewModel({ id: x.id, title: x.idKey, selected: false }));

      this.itemCount = viewModel.length;

      return new ListDataSourceFunctionResult({
        items: _.sortBy(viewModel, x => x.title),
        itemCount: viewModel.length
      })
    };
  }

  async addClick() {
    if (this.allowEdit) {
      await this.resultItemPopup.display(null);

      this.list.updateData();
    }
  }

  navigateToDatasource(item: DataSourceImportationResultViewModel) {
    if (this.allowEdit)
      this.resultItemPopup.display(item.id);
  }

  selectedItemsChange(dataSource) {
    this.selectedDataSource = dataSource;
  }

  async resultItemClick(item: DataSourceImportationResultViewModel) {
    if (this.allowEdit) {
      await this.resultItemPopup.display(item.id);

      this.list.updateData();
    }
  }

  async deleteClick() {
    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService, "Suppression", "Êtes-vous certain que vous voulez supprimer cet enregistrement?",
      async () => {
        var dataSource = await this.baseRepository.get<DataSourceImportationResult>(DataSourceImportationResult.table, this.selectedDataSource.id);

        await this.baseRepository.delete(DataSourceImportationResult.table, dataSource)

        this.selectedDataSource.length = 0;

        this.list.updateData();
      });
  }
}
