// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class ChangeTrackingSynchronizationStats extends ChangeTrackingEntity {
  public static table: Dexie.Table<ChangeTrackingSynchronizationStats, number>;
  public static tableName: string = 'ChangeTrackingSynchronizationStats';

  public id: number;  public toMobileStartId: number;  public toMobileEndId: number;  public fromMobileStartId: number;  public fromMobileEndId: number;  public startTimeStamp: Date;  public endTimeStamp: Date;  public changeTrackingSynchronizationStatusId: number;  public toMobileCountInsert: number;  public toMobileCountUpdate: number;  public toMobileCountDelete: number;  public fromMobileCountInsert: number;  public fromMobileCountUpdate: number;  public fromMobileCountDelete: number;  public mobileId: string;  public timeStamp: Date;

  public constructor(args?: Partial<ChangeTrackingSynchronizationStats>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return ChangeTrackingSynchronizationStats.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(ChangeTrackingSynchronizationStats.tableName);
      this.table.mapToClass(ChangeTrackingSynchronizationStats);
  }
}
