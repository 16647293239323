import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { FormField } from "src/app/core/data/models/formField";
import { FormGroup } from "@angular/forms";
import { Subscription, throwIfEmpty } from 'rxjs';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { AuditState } from 'src/app/pages/audit/auditState';
import { ButtonDataSourceSelectedEventArgs } from 'src/app/pages/audit/buttonDataSourceSelectedEventArgs';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import _ from 'lodash';
import { Section } from 'src/app/core/data/models/form/section';

@Component({
  selector: 'app-richtexteditor',
  templateUrl: './richtexteditor.component.html',
  styleUrls: ['./richtexteditor.component.scss']
})
export class RichtexteditorComponent extends BaseControlComponent implements OnInit, OnDestroy {

  constructor(private readonly auditState: AuditState){
    super();
  }

  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: FormGroup = new FormGroup({});

  @ViewChild(DxHtmlEditorComponent) control: DxHtmlEditorComponent

  templateTextIds: number;
  controlHeight: number;
  readOnly: boolean = false;

  private templateLinkMemberName: string;
  private templateInsertAtCaret: boolean;
  private templatePropertyName: string;

  private templateTextSubsription: Subscription;

  ngOnDestroy(): void {
    this.templateTextSubsription?.unsubscribe();
  }

  ngOnInit(): void{
    super.ngOnInit();

    this.templateTextIds = super.getExtendedProperty("TemplateTextIds") as number;
    this.controlHeight = super.getExtendedProperty("Height") as number;

    if (this.auditState.readonly)
      this.readOnly = true;

    if (super.hasExtendedProperty("TemplateText.DataSource.LinkMemberName")){
      this.templateLinkMemberName = super.getExtendedProperty("TemplateText.DataSource.LinkMemberName") as string;

      if (this.templateLinkMemberName){
        this.templateTextSubsription = this.auditState.$buttonDataSourceSelected.subscribe(this.onButtonDataSourceSelected.bind(this));

        this.templateInsertAtCaret = super.getExtendedProperty("TemplateText.DataSource.InsertAtCaret") as boolean;

        this.templatePropertyName = super.getExtendedProperty("TemplateText.DataSource.PropertyName") as string;
      }
    }
  }

  private async onButtonDataSourceSelected(args: ButtonDataSourceSelectedEventArgs){
    if (args.name === this.templateLinkMemberName && Section.getAllControls(this.auditState.section).find(x => x.dataColumnName === this.templateLinkMemberName)){
      let resultItem = await DataSourceImportationResult.table.get(args.dataSourceResultItemId);
      let value = resultItem[this.templatePropertyName];

      if (!value)
        return;

      this.formControl.setValue(value);

      this.formControl.markAsDirty();
    }
  }
}
