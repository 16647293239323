import { Component, Input, OnInit } from '@angular/core';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';

@Component({
  selector: 'app-header-list',
  templateUrl: './header-list.component.html',
  styleUrls: ['./header-list.component.scss']
})
export class HeaderListComponent {
  
  @Input() title: string = '';

  constructor(public authenticatedUser: AuthenticatedUser) { }
}
