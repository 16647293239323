import { Injectable } from "@angular/core";
import { IMapper } from "src/app/core/data/mapping/iMapper";
import { CustomFieldValueList } from "src/app/core/data/models/database/customFieldValueList.database";
import { CustomFieldValueListViewModel } from "./customFieldValueListViewModel";

@Injectable({
    providedIn: 'root',
})
export class CustomFieldValueListMapper implements IMapper<CustomFieldValueList, CustomFieldValueListViewModel>  {
    public map(customFieldValueList: CustomFieldValueList): CustomFieldValueListViewModel {
        return new CustomFieldValueListViewModel({
            id: customFieldValueList.id,
            name: customFieldValueList.description,
            dataType: customFieldValueList.dataType,
            nbrDecimals: customFieldValueList.nbrDecimals,
            attribute1: customFieldValueList.attribute1,
            attribute2: customFieldValueList.attribute2,
            attribute3: customFieldValueList.attribute3,
            attribute4: customFieldValueList.attribute4,
            attribute5: customFieldValueList.attribute5,
            emailNameAttribute: customFieldValueList.emailNameAttribute,
            emailAddressAttribute: customFieldValueList.emailAddressAttribute
        });
    }
}