<div [formGroup]="form" class="field-zone">
  <label class="field">{{input.description}}</label>
  <input type="hidden" [formControlName]="input.key">
  <app-dropdown #dropdown [title]="input.description" [dataSource]="dataSource" [numberOfSelectableItem]="1"
    (selectedItemsChange)="selectedItemsChange($event)" [readOnly]="readOnly">
    <ng-template #dropdownSelectedTemplate let-item> {{ item.text }} </ng-template>
    <ng-template #dropdownListTemplate let-item>
      <div class="layout-horizontal padding-horizontal-normal">
        <div class="layout-left">
          {{ item.text }}
        </div>
      </div>
    </ng-template>
  </app-dropdown>
</div>