import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";
import { ActivatedRoute } from '@angular/router';
import { AuditService } from "src/app/pages/audit/auditService";
import { AuditState } from "src/app/pages/audit/auditState";
import { ModelUtility } from "src/app/core/data/models/modelUtility";
import { Subscription } from 'rxjs';
import { IEntityAutoSaveGuard } from 'src/app/core/sections/IEntityAutoSaveGuard';
import { CanLeaveEntityAutoSaveResult } from 'src/app/core/sections/canLeaveEntityAutoSaveResult';
import { TranslateService } from '@ngx-translate/core';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { WorkflowStepPopupComponent } from 'src/app/components/workflowstep/workflow-step-popup/workflow-step-popup.component';

@Component({
  selector: 'app-audit-section-information',
  templateUrl: './audit-section-information.component.html',
  styleUrls: ['./audit-section-information.component.scss']
})
export class AuditSectionInformationComponent implements OnInit, AfterViewInit, OnDestroy, IEntityAutoSaveGuard {
  public responsibles: Array<DataSourceItem<string>>;
  public creators: Array<DataSourceItem<string>>;
  public steps: Array<DataSourceItem<string>>;
  public updateStepVisible: boolean = false;

  private routerEventSubscription: Subscription;

  @ViewChild('sectionPopup') sectionPopup: SimplePopupComponent
  @ViewChild('workflowStepPopup') workflowStepPopup: WorkflowStepPopupComponent;

  constructor(
    public authenticatedUser: AuthenticatedUser,
    public activatedRoute: ActivatedRoute,
    public auditState: AuditState,
    private readonly fb: FormBuilder,
    private auditService: AuditService,
    private translateService: TranslateService
  ) { }

  stepForm: FormGroup = this.fb.group({
    stepId: [, Validators.required],
  });

  auditForm: FormGroup = this.fb.group({
    number: [],
    stepId: [, Validators.required],
    responsibleId: [, Validators.required],
    createdById: [],
    createdDate: [],
    effectiveDate: [, Validators.required],
    updatedDate: [],
    externalNumber: []
  });

  get currentStep(): string {
    if (this.steps && this.auditForm)
      return this.steps.find(x => x.id == this.auditForm.controls["stepId"].value)?.text;
    else
      return "";
  }

  async ngOnInit(): Promise<void> {
    this.responsibles = await this.auditService.getUsers();
    this.creators = await this.auditService.getUsers();

    let audit = this.auditState.audit;

    this.auditForm.setValue({
      number: audit.number,
      stepId: audit.stepId,
      responsibleId: audit.responsibleId,
      createdById: audit.createdById,
      createdDate: audit.createdDate,
      effectiveDate: audit.effectiveDate,
      updatedDate: audit.updatedDate,
      externalNumber: this.auditState.audit.externalNumber
    });
  }

  ngAfterViewInit(): void {
    this.workflowStepPopup.updateData();
  }

  public async saveChanges(): Promise<boolean> {
    if (this.auditForm.dirty) {
      let audit = this.auditState.audit;

      audit.responsibleId = this.auditForm.controls["responsibleId"].value;
      audit.stepId = this.auditForm.controls["stepId"].value;
      audit.effectiveDate = this.auditForm.controls["effectiveDate"].value;
      audit.entityState = EntityState.Modified;
      audit.externalNumber = this.auditForm.controls.externalNumber.value;

      await this.auditService.saveAudit(this.auditState.audit);
      this.auditForm.markAsPristine();
    }

    return true;
  }

  public async onSaveChangesClick() {
    let canLeaveResult = await this.canLeave();

    if (canLeaveResult.success) {
      this.saveChanges();
    }
    else
      alert(canLeaveResult.message);
  }

  public showWorkflowStepPopup() {
    this.workflowStepPopup.display();
  }

  public async workflowStepSelected(newWorkflowStep: WFStep) {
    this.auditForm.controls["stepId"].setValue(newWorkflowStep.id);
    this.auditForm.controls["stepId"].markAsDirty();

    await this.saveChanges();
    this.auditState.updateWorkflowStep(newWorkflowStep.id);
    this.workflowStepPopup.updateData();
  }

  public onRevertChangesClick() {
    ModelUtility.applyValuesToFormGroup(this.auditForm, this.auditState.audit);

    this.auditForm.markAsPristine();
  }

  public async canLeave(): Promise<CanLeaveEntityAutoSaveResult> {
    let result = new CanLeaveEntityAutoSaveResult();

    result.success = !this.auditForm.dirty || this.auditForm.valid

    if (!result.success) {
      result.message = this.translateService.instant("validations.oneOrMoreFieldsRequired");
    }

    return result;
  }

  ngOnDestroy(): void {
    this.routerEventSubscription?.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHander(event: any) {
    let canLeaveResult = await this.canLeave();

    if (canLeaveResult.success) {
      return !this.auditForm.dirty;
    }
    else {
      return false;
    }
  }
}
