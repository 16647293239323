import { SectionState } from "src/app/pages/audit/audit-sections/sectionState";

export class DynamicInstance{
  public id: string;
  public number: string;
  public description: string;
	public customTableId: string;
	public dataTableName: string;
  public position: number;

  public constructor(args?: Partial<DynamicInstance>) {
    Object.assign(this, args);
  }
}