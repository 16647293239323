import { FormFieldExtendedValue } from "./formFieldExtendedValue";

export class FormFieldImageExtendedValue extends FormFieldExtendedValue{
  public getValue(): any{
    return FormFieldImageExtendedValue.GetRawImage(this.value);
  }

  public static GetRawImage(value: any): any{
    let result: any = value;

    if (value){
      const imageMetadataSeparator = ",";

      if ((value as string).includes(imageMetadataSeparator))
        result = (value as string).split(imageMetadataSeparator)[1];
    }

    return result;
  }
}
