import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import _, { filter } from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { Program } from 'src/app/core/data/models/database/program.database';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { UserAccountRepository } from 'src/app/core/data/repositories/userAccountRepository';
import { UserService } from 'src/app/core/services/userService';
import { AuditListSearchOptions } from "src/app/pages/audit-list/auditListSearchOptions";
import { AuditListSearchOptionsQueryParamUrlService } from '../auditListSearchOptionsQueryParamUrlService';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';

@Component({
  selector: 'app-audit-list-search',
  templateUrl: './audit-list-search.component.html',
  styleUrls: ['./audit-list-search.component.scss']
})
export class AuditListSearchComponent implements OnInit {
  @Input() public searchOptions: AuditListSearchOptions = new AuditListSearchOptions();
  @Input() public filterCount: number = 0;
  @Output() public filterCountChange = new EventEmitter<number>();

  @ViewChild("searchForm") public searchForm: PopupComponent;

  public selectedProgramDescription: string;

  public formTemplates: Program[] = [];
  public assignedToUsers: UserAccount[] = [];
  public workflowStepNames: any[] = [];

  public visible: boolean = false;

  public formTemplateDataSource: Function;
  public assignedToDataSource: Function;
  public workflowStepDataSource: Function;

  constructor(
    private userAccountRepository: UserAccountRepository,
    private userService: UserService,
    private auditListSearchOptionsQueryParamUrlService: AuditListSearchOptionsQueryParamUrlService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.formTemplateDataSource = async (listContext: ListComponent) => {
      let forms = await Program.table.toArray();

      let formWithDisplayName: any = forms.map<any>((form: any) => {
        form.displayName = `${form.numberPrefix} - ${form.description}`;
        return form;
      });

      let availableForms = formWithDisplayName.filter(x => !x.isDeleted && x.isActive
        && x.displayName.toLowerCase().includes(listContext.filter.toLowerCase()));

      // Update selected items, mostly to make sure that the selected items are still selected
      // when the search is restored from the url. Not the most efficient to revaluate
      // every time, but the added complexity to sync promise across multiple components
      // did not seem worth it for now.
      if (this.searchOptions && this.searchOptions.templateIds && this.searchOptions.templateIds.length > 0) {
        this.formTemplates = availableForms.filter(form => this.searchOptions.templateIds.indexOf(form.id) > 0);
        this.setFilterCount();
      }

      return new ListDataSourceFunctionResult({
        itemCount: availableForms.length,
        items: availableForms
      });
    };

    this.assignedToDataSource = async (listContext: ListComponent) => {
      let users = await this.userAccountRepository.getUsers();

      let usersWithInitials = users.map((user: any) => {
        user.initials = this.userService.getInitials(user.name);
        return user;
      });

      // Update selected items, mostly to make sure that the selected items are still selected
      // when the search is restored from the url. Not the most efficient to revaluate
      // every time, but the added complexity to sync promise across multiple components
      // did not seem worth it for now.
      if (this.searchOptions && this.searchOptions.assignedToIds && this.searchOptions.assignedToIds.length > 0) {
        this.assignedToUsers = usersWithInitials.filter(users => this.searchOptions.assignedToIds.indexOf(users.id) > 0);
        this.setFilterCount();
      }

      let filteredUser = usersWithInitials.filter(user => {
        return user.name.toLowerCase().includes(listContext.filter.toLowerCase());
      });

      return new ListDataSourceFunctionResult({
        itemCount: filteredUser.length,
        items: filteredUser
      });
    };

    this.workflowStepDataSource = async (listContext: ListComponent): Promise<ListDataSourceFunctionResult> => {
      let workflowSteps = await WFStep.table.toArray();

      let workflowStepsNames = workflowSteps.map((workflowStep) => {
        return workflowStep.name;
      });

      let distinctWorkflowStepsNames: any[] = workflowStepsNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      // This step in onty required because the list support only object as dataSource at this time.
      let distinctWorkflowStepsNamesObject = distinctWorkflowStepsNames.map((workflowStep) => {
        return { name: workflowStep };
      });

      // Update selected items, mostly to make sure that the selected items are still selected
      // when the search is restored from the url. Not the most efficient to revaluate
      // every time, but the added complexity to sync promise across multiple components
      // did not seem worth it for now.
      if (this.searchOptions && this.searchOptions.workflowStepNames && this.searchOptions.workflowStepNames.length > 0) {
        this.workflowStepNames = distinctWorkflowStepsNamesObject.filter(workflowStep => this.searchOptions.workflowStepNames.indexOf(workflowStep.name) > 0);
        this.setFilterCount();
      }

      let filteredWorkflowStepsNames = distinctWorkflowStepsNamesObject.filter((workflowStep) => {
        return workflowStep.name.toLowerCase().includes(listContext.filter.toLowerCase());
      });

      return new ListDataSourceFunctionResult({
        itemCount: filteredWorkflowStepsNames.length,
        items: filteredWorkflowStepsNames
      });
    };
  }

  public open() {
    if (!this.searchOptions.templateIds) {
      this.searchOptions.templateIds = null;
    }

    this.searchForm.display();
  }

  public search(): void {
    this.searchOptions.templateIds = this.formTemplates.map((program) => program.id);
    this.searchOptions.assignedToIds = this.assignedToUsers.map((userAccount) => userAccount.id);
    this.searchOptions.workflowStepNames = this.workflowStepNames.map((workflowStepName) => workflowStepName.name);

    this.close();
  }

  public clear() {
    this.formTemplates = [];
    this.assignedToUsers = [];
    this.workflowStepNames = [];
    this.searchOptions = new AuditListSearchOptions();
    this.close();
  }

  public close() {
    this.setFilterCount();
    this.auditListSearchOptionsQueryParamUrlService.save(this.searchOptions);
    this.auditListSearchOptionsQueryParamUrlService.navigate(this.searchOptions);
    this.searchForm.close();
  }

  private setFilterCount() {
    let filterCount = 0;
    for (let key in this.searchOptions) {
      let value = this.searchOptions[key];
      if (value) {
        if (Array.isArray(value)) {
          filterCount += value.length;
        } else {
          filterCount++;
        }
      }
    }

    this.filterCount = filterCount;
    this.filterCountChange.emit(this.filterCount);
  }
}