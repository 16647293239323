import { Injectable } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { environment } from "src/environments/environment";
import { WindowsAuthenticationHttpClient } from "./windowsAuthenticationHttpClient";
import { firstValueFrom } from "rxjs";
import { AuthenticationAuthorizationRouting } from "./authenticationAuthorization.routing";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public canLogOff = false;
  public url: string;

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private windowsAuthenticationHttpClient: WindowsAuthenticationHttpClient
  ) {
    this.canLogOff = environment.authentication.authenticationType !== "Windows";
  }

  async checkAuthentication(): Promise<boolean> {
    if (environment.authentication.authenticationType === "Windows") {
      try {
        let isAuthenticated = await this.windowsAuthenticationHttpClient.checkAuthentication();

        if (!isAuthenticated) {
          await this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);
        }

        return isAuthenticated;
      } catch (e) {
        return false;
      }
    } else if (environment.authentication.authenticationType === "OpenId") {
      // This should be required to be called at least once to initialize the OIDC client
      // and needs to be before the isAuthenticated() call to setup the token.
      // In the documentation, it is used only in the main app module. But as a fallback, it is also added here.
      // The .subscribe() is required even thought we use specific method to determine authentication state
      // instead of subscribing to the changes.
      this.oidcSecurityService.checkAuth().subscribe();

      let isAutenticatedResolver = null;
      let isAuthenticatedPromise = new Promise<boolean>((resolve) => {
        isAutenticatedResolver = resolve;
      })

      // When OidcSecurityService check authentication for the first time, it can take a certain amount
      // of time before it retrieve the token from the URL and fully process it. It's implementation
      // doesn't tell us when the process is completed. For this reason we set a timeout of 1 seconds
      // which should be more than enough to it to resolve before calling isAuthenticated() with the
      // real authenticated value.
      setTimeout(async () => {
        let isAuthenticated = await firstValueFrom(this.oidcSecurityService.isAuthenticated());

        isAutenticatedResolver(isAuthenticated);
      }, environment.authentication.openId.authenticationTimeout);

      let isAuthenticated = await isAuthenticatedPromise;

      if (!isAuthenticated) {
        await this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);
      }

      return isAuthenticated;
    }

    return false;
  }

  async isAuthenticated(): Promise<boolean> {
    if (environment.authentication.authenticationType === "Windows") {
      try {
        return await this.windowsAuthenticationHttpClient.checkAuthentication();
      } catch (e) {
        return false;
      }
    } else if (environment.authentication.authenticationType === "OpenId") {
      let isAuthenticated = await firstValueFrom(this.oidcSecurityService.isAuthenticated());

      return isAuthenticated;
    }
  }

  async login() {
    if (environment.authentication.authenticationType === "OpenId") {
      this.oidcSecurityService.authorize();
      return true;
    } else if (environment.authentication.authenticationType === "Windows") {
      let success = await this.checkAuthentication();

      if (success) {
        await this.router.navigate(["/home"]);
      } else {
        await this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);
      }

      return success;
    }
  }

  async logoff() {
    if (environment.authentication.authenticationType === "OpenId") {
      this.oidcSecurityService.logoffLocal();

      await this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);
    } else {
      await this.router.navigate([AuthenticationAuthorizationRouting.loginRoute]);
    }
  }
}
