<div class="layout-vertical">
  <div class="layout-center">
    <app-list [(items)]="menuItems" [paging]="false" (activatedItem)="goToLink($event)">
      <ng-template #listTemplate let-item>
        <div class="layout-horizontal height-normal">
          <div class="layout-center align-left">
            <button class="width-100 color-set-transparent" [disabled]="item.requireOnline && !navigator.onLine">
              <i [class]="item.icon"></i>
              <div><span>{{item.title | translate}}</span> </div>
            </button>
          </div>
        </div>
      </ng-template>
    </app-list>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-gray"
      *ngIf="this.authenticatedUser.typeId === this.userTypes.Administrator">
      <div class="layout-left">Administration</div>
    </div>

    <app-list *ngIf="adminMenuItems.length > 0" [(items)]="adminMenuItems" [paging]="false"
      (activatedItem)="goToLink($event)">
      <ng-template #listTemplate let-item>
        <div class="layout-horizontal height-normal" *ngIf="this.authenticatedUser.typeId === this.userTypes.Administrator">
          <div class="layout-center align-left">
            <button class="width-100 color-set-transparent" [disabled]="item.requireOnline && !navigator.onLine">
              <i [class]="item.icon"></i>
              <div><span>{{item.title | translate}} {{item.disabled}}</span> </div>
            </button>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
  <div class="layout-bottom" *ngIf="authenticationService.canLogOff">
    <div class="layout-horizontal height-normal color-set-orange">
      <div class="layout-left">
        <button *ngIf="authenticationService.isAuthenticated" (click)="router.navigate(['/logout'])">
          <div><span>Logout</span> </div>
        </button>
      </div>
    </div>
  </div>
</div>