// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class AuditAlertSummarySatelliteDataItem extends ChangeTrackingEntity {
  public static table: Dexie.Table<AuditAlertSummarySatelliteDataItem, string>;
  public static tableName: string = 'AuditAlertSummarySatelliteDataItem';

  public description: string;  public controlKey: string;  public valueAsBoolean: boolean;  public valueAsString: string;  public valueAsInteger: number;  public valueAsDecimal: number;  public valueAsDateTime: Date;  public dataType: number;  public id: string;  public auditId: string;  public auditAlertSummaryId: string;  public dataSourceExternalId: string;  public dataSourceForeignKeyId: string;  public formattedValue: string;  public timeStamp: Date;

  public constructor(args?: Partial<AuditAlertSummarySatelliteDataItem>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return AuditAlertSummarySatelliteDataItem.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(AuditAlertSummarySatelliteDataItem.tableName);
      this.table.mapToClass(AuditAlertSummarySatelliteDataItem);
  }
}
