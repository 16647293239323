import { Injectable } from "@angular/core";
import { StorageService } from "./core/data/storage/storageService";
import { ValidationDictionary } from "./core/validationDictionary";

@Injectable({
  providedIn: 'root'
})
export class StoratePersistanceMiddleware {
  constructor(private storageService: StorageService) { }

  private async canSkip() {
    return await this.storageService.isStoragePersisted();
  }

  public async run() : Promise<ValidationDictionary> {
    let validationDictionary = new ValidationDictionary();

    if (!await this.canSkip()) {
      await this.storageService.persist();
    }

    return validationDictionary;
  }
}