import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { BaseFormComponent } from 'src/app/components/forms/BaseForm.component';
import { HeaderState } from 'src/app/components/headers/header/headerState';
import { HeaderStateLeftButtonType } from 'src/app/components/headers/header/headerStateLeftButtonType';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { EntityState } from 'src/app/core/data/changeTracking/entityState';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { OptionList } from 'src/app/core/data/models/database/optionList.database';
import { TaskImportationType } from 'src/app/core/data/models/database/taskImportationType.database';
import { TaskType } from 'src/app/core/data/models/database/taskType.database';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { EntitySecurityGroupListComponent } from '../entity-security-group-list/entity-security-group-list.component';
import { EntitySecurityGroupPermissionRepository } from '../entity-security-group/entitySecurityGroupPermissionRepository';
import { EntitySecurityGroupPermissionService } from '../entity-security-group/entitySecurityGroupPermissionService';
import { OptionListEditComponent } from '../option-list-edit/option-list-edit.component';
import { FilterExpression } from '../task-list/filterExpression';
import { FilterExpressionFormatterFactory } from '../task-list/filterExpressionFormatterFactory';
import { DataSourceFilterValidator } from './dataSourceFilterValidator';

@Component({
  selector: 'app-task-type-edit',
  templateUrl: './task-type-edit.component.html',
  styleUrls: ['./task-type-edit.component.scss']
})
export default class TaskTypeEditComponent extends BaseFormComponent implements OnInit, OnDestroy {
	private taskImportationType: number = 1;

  @ViewChild(OptionListEditComponent) optionListEdit: OptionListEditComponent;
  @ViewChild(ListComponent) optionList: ListComponent;
  @ViewChild("entitySecurityGroupList") entitySecurityGroupList: EntitySecurityGroupListComponent;

	types: any;
	importations: any;
	activates: any;
	lineFields: any;

	private backButtonSubscription: Subscription;

  private dataSourceImportation: DataSourceImportation;

  optionListDataSource: Function;

  private fieldTitles: any[];

	get taskType(): TaskType{
		return this.model as TaskType;
	}

	constructor(
		private route: ActivatedRoute,
		baseRepository: BaseRepository,
		private headerState: HeaderState,
		readonly router: Router,
		readonly translateService: TranslateService,
    private entitySecurityGroupPermissionRepository: EntitySecurityGroupPermissionRepository,
    private entitySecurityGroupPermissionService: EntitySecurityGroupPermissionService,
    private authenticatedUser: AuthenticatedUser,
    private dataSourceFilterValidator: DataSourceFilterValidator) {

		super(translateService, router, baseRepository);

		this.form = new FormGroup({
			id: new FormControl("id"),
			name: new FormControl("name", [Validators.required]),
			typeId: new FormControl("typeId", [Validators.required]),
			dataSourceImportationId: new FormControl("dataSourceImportationId", [Validators.required]),
			isActivated: new FormControl("isActivated"),
			longitudeField: new FormControl("longitudeField"),
			latitudeField: new FormControl("latitudeField"),
			globalJavascriptFilter: new FormControl("globalJavascriptFilter")
		});

		this.baseUrl = "task-types";
		this.table = TaskType.table;

    this.optionListDataSource = async (context) => {
      let optionLists = [];

      if (this.id){
        optionLists = await OptionList.table.where("entityId").equals(this.id).toArray();

        optionLists = await this.entitySecurityGroupPermissionService.getAllowedEntities(optionLists, "OptionListAllowedEditors", this.authenticatedUser.id);
      }

      let result = _.map(optionLists, x => { return { key: x.id, text: x.name } });

      result = _.sortBy(result, x => x.text);

      return new ListDataSourceFunctionResult({
        itemCount: result.length,
        items: result
      });
    }
	}

	ngOnDestroy(): void {
		this.backButtonSubscription.unsubscribe();

		this.headerState.setDefaultButtonState();

    this.headerState.title = "";
	}

	private setRequiredFields(){
		if (this.form.controls.isActivated.value){
			this.form.get("longitudeField").setValidators(Validators.required);
			this.form.get("latitudeField").setValidators(Validators.required);
		}
		else{
			this.form.get("longitudeField").clearValidators();
			this.form.get("latitudeField").clearValidators();
		}

		this.form.controls.longitudeField.updateValueAndValidity();
		this.form.controls.latitudeField.updateValueAndValidity();
	}

	private async createModel(){
		let model: TaskType;

		if (!this.id) {
			model = new TaskType({entityState: EntityState.New, isDeleted: false, isActivated: false, typeId: this.types[0].id });
		}
		else {
			model = await this.baseRepository.get(TaskType.table, this.id);

			if (model)
				model.entityState = EntityState.Modified;
		}
		
		return model;
	}

	public async validate(model: TaskType, validationDictionary: string[]): Promise<string[]>{
		var duplicate = await TaskType.table.where("name").equalsIgnoreCase(model.name).and(x => x.id != this.model.id).and(x => !x.isDeleted).first();

		if (duplicate)
			validationDictionary.push(this.translateService.instant("taskType.edit.validations.duplicateMessage"));

    if (validationDictionary.length == 0){
      if (!this.entitySecurityGroupPermissionRepository.hasAccess(this.model.id?.toString(), this.authenticatedUser.id, EntitySecurityGroupPermissionRepository.TaskType)) {
        validationDictionary.push("Vous devez associer au moins un groupe de sécurité dont vous êtes membre.")
      }
    }

    if (validationDictionary.length == 0 && this.form.controls.globalJavascriptFilter.value){
      let filterExpressionValidation = await this.dataSourceFilterValidator.validate(this.form.controls.dataSourceImportationId.value, this.form.controls.globalJavascriptFilter.value)

      if (!filterExpressionValidation.isValid()){
        validationDictionary.push(filterExpressionValidation.getValidations()[0].message);
      }
    }

		return validationDictionary;
	}

  async ngOnInit(): Promise<void> {
		this.id = this.route.snapshot.paramMap.get('id');

		await this.load();	

		this.backButtonSubscription = this.headerState.backButtonClick.subscribe(() => {
				this.navigateToList({ skipSynchronization: "true" });
    });

		this.form.controls["name"].valueChanges.subscribe(x => {
			this.updateHeaderTitle(x);
		})

		this.headerState.leftButtonType = HeaderStateLeftButtonType.backButton;

		this.headerState.changeLeftButton("task-types")

		this.form.get("typeId").valueChanges.subscribe(x => {
			this.loadImportations(x);
	 	});

		this.form.get("dataSourceImportationId").valueChanges.subscribe(async x => {
      await this.loadDataSourceImportation();
      
      this.form.controls.longitudeField.setValue(null);
      this.form.controls.latitudeField.setValue(null);

			this.loadLineFields();
		});

		this.setRequiredFields();
  }

	onListFieldValueChange(e){
		if (!e.event)
			return;
			
		this.form.controls[e.element.id].setValue(_.join(e.value, ","))
		this.form.markAsDirty();
	}

	private async loadImportations(taskImportationType: number){
		if (taskImportationType == this.taskImportationType){
			var importations = await (await DataSourceImportation.table.toArray()).filter(x => !x.isDeleted && x.isActive);

			this.importations = importations;
		}
		else{
			this.importations = [];
		}
	}

  async loadDataSourceImportation(){
    let dataSourceImportationId = this.form.controls.dataSourceImportationId.value;

    if (dataSourceImportationId){
      this.dataSourceImportation = await DataSourceImportation.table.get(dataSourceImportationId);

      if (this.dataSourceImportation.fieldTitles)
        this.fieldTitles = JSON.parse(this.dataSourceImportation.fieldTitles);
    }
    else{
      this.dataSourceImportation = null;
      this.fieldTitles = null;
    }
  }

	async load() {	
    this.types = await TaskImportationType.table.toArray();

		this.model = await this.createModel();

		if (!this.model){
			this.navigateToList();

			return;
		}

    this.formSynchronizer.setValuesToControls(this.model)

    await this.loadDataSourceImportation();	
		await this.loadImportations(this.taskType.typeId);
		await this.loadLineFields();

		this.updateHeaderTitle(this.taskType.name);

		this.form.get("isActivated").valueChanges.subscribe(x => {
			this.setRequiredFields();
		})
	}

	updateHeaderTitle(value: string){
		this.headerState.title = `<div><b>${value}</b></div><div>Types de tâche</div>`;
	}

	async saveButtonClick() {
		super.saveAndRedirect();
	}

	async cancelButtonClick() {
		super.cancel();
	}

	private async loadLineFields(){
		this.lineFields = [];
		
		if (this.dataSourceImportation){
			if (this.dataSourceImportation.fieldTitles) {
				let fieldTitles = JSON.parse(this.dataSourceImportation.fieldTitles);
				
				fieldTitles.forEach(item => {
					this.lineFields.push({ key: item.key, text: item.alias ?? item.key });
				});
			}
		}
	}

  selectedOptionList: any;

  selectOptionList(item){
    this.selectedOptionList = item;
  }

  async optionListItemClick(event, item){
    // Prevent having the 'selectedItemsChange' event raised.
    event.stopPropagation();

    if (await this.optionListEdit.edit(item.key, this.lineFields)){
      this.optionList.updateData();      
    }
  }

  async addOptionList(){
    if (await this.optionListEdit.add(this.id.toString(), "TaskType", this.lineFields)){
      this.optionList.updateData();      
    }
  }

  removeOptionList(){
    PopupUtility.displayYesNoQuestion(this.modalPopup, this.translateService, "Suppression d'un item", "Êtes-vous certain de vouloir supprimer cet item?", async () => {
      await this.baseRepository.deleteById(OptionList.table, this.selectedOptionList.key);
      this.optionList.updateData();
      this.selectedOptionList = null;
    });
  }
}
