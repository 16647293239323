<nav class="layout-horizontal" *ngIf="!auditState.reference">
  <div class="layout-left">
    <button class="color-set-transparent-blue" type="button" *ngIf="auditState.section.id !== firstSectionId"
      (click)="onPreviousSectionClick()">
      <i class="bi bi-chevron-left"></i>
      <div><span> {{'audit-navigation.previous' | translate}}</span></div>
    </button>
  </div>
  <div class="layout-right" *ngIf="auditState.section.id !== lastSectionId">
    <button class="color-set-transparent-blue" type="button" (click)="onNextSectionClick()">
      <div><span>{{'audit-navigation.next' | translate}}</span></div>
      <i class="bi bi-chevron-right"></i>
    </button>
  </div>
  <div class="layout-right color-set-transparent-blue" *ngIf="auditState.section.id === lastSectionId">
    <button *ngIf="workflowStepPopup && workflowStepPopup.workflowSteps && workflowStepPopup.workflowSteps.length === 1"
      type="button" (click)="showWorkflowStepPopup()">
      <div>
        <span>{{ 'workflowStepPopup.changeStepTo' | translate }} {{ workflowStepPopup.workflowSteps[0].name }}</span>
      </div>
      <i class="bi bi-chevron-right"></i>
    </button>

    <button class="color-set-transparent-blue"
      *ngIf="workflowStepPopup && workflowStepPopup.workflowSteps && workflowStepPopup.workflowSteps.length > 1"
      type="button" (click)="showWorkflowStepPopup()">
      <div><span>{{ 'workflowStepPopup.changeStepButton' | translate }}</span></div>
      <i class="bi bi-chevron-right"></i>
    </button>
  </div>
</nav>

<app-workflow-step-popup #workflowStepPopup [userId]="authenticatedUser.id"
(workflowStepSelected)="saveWorkflowStep($event)">
</app-workflow-step-popup>