// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class WorkflowEventType extends ChangeTrackingEntity {
  public static table: Dexie.Table<WorkflowEventType, number>;
  public static tableName: string = 'WorkflowEventType';

  public id: number;  public name: string;  public timeStamp: Date;

  public constructor(args?: Partial<WorkflowEventType>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return WorkflowEventType.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(WorkflowEventType.tableName);
      this.table.mapToClass(WorkflowEventType);
  }
}
