import { Component, OnInit } from '@angular/core';
import { BaseListDetailsState } from '../baseListDetailsState';

@Component({
  selector: 'app-custom-field-value-lists-list',
  templateUrl: './custom-field-value-lists-list.component.html',
  styleUrls: ['./custom-field-value-lists-list.component.scss'],
})
export class CustomFieldValueListsListComponent implements OnInit {
  constructor(public baseListDetailsState: BaseListDetailsState) {}

  ngOnInit(): void {}  
}
