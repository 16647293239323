 import { Component, OnInit } from '@angular/core';
import { StorageState } from 'src/app/core/data/storage/storageState';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { environment } from 'src/environments/environment';
import platform from 'platform';
import { DatabaseContext } from 'src/app/core/data/databaseContext';
import { SynchronizationStatus } from 'src/app/core/data/synchronization/synchronizationStatus';
import { SynchronizationContext } from 'src/app/core/data/synchronization/synchronizationContext';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AppMiddleware } from 'src/app/app.middleware';

@Component({
  selector: 'app-system-information',
  templateUrl: './system-information.component.html',
  styleUrls: ['./system-information.component.scss']
})
export class SystemInformationComponent implements OnInit {
  public environment = environment;
  public navigator = navigator;
  public platform = platform;
  public SynchronizationStatus = SynchronizationStatus;

  public isDatabaseAvailable: boolean = false;

  constructor(
    public synchronizationService: SynchronizationService,
    public synchronizationContext: SynchronizationContext,
    public databaseContext: DatabaseContext,
    public storageState: StorageState,
    public authenticationService: AuthenticationService,
    private appMiddleware: AppMiddleware
  ) { }

  async ngOnInit(): Promise<void> {
    // This check is done explicitly here instead of from the app-routing module
    // to allow access to the view but prevent some controls to be enable when the database
    // doesn't exist.
    let validationDictionary = await this.appMiddleware.run();

    if (validationDictionary.isValid()){
      this.isDatabaseAvailable = true;
    }
  }
}