import { Injectable } from '@angular/core';
import { Logger } from '../../log/logger';
import { SynchronizationStatus } from './synchronizationStatus';
import { SynchronizationType } from './synchronizationType';
import { SynchronizationHttpClient } from './synchronizationHttpClient';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { LocalParameter } from '../models/databaseLocal/localParameter.database';

const SYNCHRONIZATION_STATUS_PARAMETER_ID = "37717e24-eab0-41dc-d0e0-9d0191f35e00";
const SYNCHRONIZATION_STATUS_PARAMETER_NAME = "SynchronizationStatus";

@Injectable({
  providedIn: 'root',
})
export class SynchronizationContext {
  public status: SynchronizationStatus;
  public mobileId: string;

  public type: SynchronizationType;
  public requireSynchronization: boolean = false;
  public inProgress = false;

  /**
  * Indicate whether the synchronization system is accessible based on the current location.
  * This help to desactivate the synchronization system for some pages.
  */
  public isAccessibleByLocation: boolean = true;

  public navigator: Navigator;

  constructor(
    private synchronizationHttpClient: SynchronizationHttpClient,
    private authenticationService: AuthenticationService,
    private logger: Logger
  ) {
    this.navigator = navigator;
  }

  public async tryStartSynchronization(type: SynchronizationType): Promise<boolean> {
    this.type = type;

    if (!navigator.onLine || !this.mobileId || this.status === SynchronizationStatus.inProgress || !await this.authenticationService.isAuthenticated()) {
      return false;
    }

    try {
      if (!await this.synchronizationHttpClient.pingSynchronizationServer()) {
        this.logger.logError("Synchronization service could not be reached");

        return false;
      }
    }
    catch (e) {
      this.logger.logError("Synchronization api could not be reached");
      return false;
    }

    return true;
  }
}

