<div [formGroup]="form" class="field-zone">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>

  <div>
    <div *ngIf="!readOnly">
      <button (click)="clearSignature()" class="signature-button btn btn-primary bi bi-x fa-lg" [style]="buttonStyle" [disabled]="readOnly"></button>
    </div>

    <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()">
    </signature-pad>
  </div>
</div>