import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { WorkflowStepService } from 'src/app/core/services/workflowStepService';
import { AuditService } from 'src/app/pages/audit/auditService';
import { AuditState } from 'src/app/pages/audit/auditState';
import { PopupComponent } from '../../popup/popup.component';

@Component({
  selector: 'app-workflow-step-popup',
  templateUrl: './workflow-step-popup.component.html',
  styleUrls: ['./workflow-step-popup.component.scss']
})
export class WorkflowStepPopupComponent implements OnInit {
  @Input() visible: boolean;
  @Input() userId: string;

  @ViewChild('popup') popup: PopupComponent;

  public loading: boolean = false;

  @Output() visibleChange: EventEmitter<any> = new EventEmitter();
  @Output() workflowStepSelected = new EventEmitter<WFStep>();

  public workflowSteps: WFStep[];
  public selectedWorkflowStep: WFStep;

  public validationMessage: string;

  constructor(
    private workflowStepService: WorkflowStepService,
    private auditService: AuditService,
    private auditState: AuditState,
    private translateService: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.updateData();

    this.popup.visibleChange.subscribe(x => {
      this.selectedWorkflowStep = null;
      this.validationMessage = "";
    });
  }

  public async updateData() {
    this.loading = true;
    this.workflowSteps = await this.workflowStepService.getNextWorkflowSteps(this.auditState.audit.stepId, this.userId);

    if (this.workflowSteps.length == 1)
      this.selectedWorkflowStep = this.workflowSteps[0];
      
    this.loading = false;
  }

  public selectWorkflowStep(selectedWorkflowStep: WFStep) {
    this.selectedWorkflowStep = selectedWorkflowStep;
  }

  public async confirmWorkflowStep() {
    let targetStep: WFStep;

    if (this.workflowSteps && this.workflowSteps.length > 1) {
      targetStep = this.selectedWorkflowStep;
    } else if (this.workflowSteps && this.workflowSteps.length === 1) {
      targetStep = this.workflowSteps[0];
    }

    let continueProcess: boolean = true;

    if (targetStep.validateRequiredFields){
      continueProcess = await this.auditService.validateRequiredFields(this.auditState.audit, this.auditState.form);

      if (!continueProcess){
        this.validationMessage = this.translateService.instant("workflowStepPopup.validations.requiredFields");
      }
    }
    else{
      continueProcess = true;
    }

    if (continueProcess){
      this.workflowStepSelected.emit(targetStep);
  
      this.selectedWorkflowStep = null;
      this.visibleChange.emit(false);
      this.visible = false;
      this.validationMessage = "";
    }
  }

  public display() {
    this.visible = true;
    this.updateData();
  }
}
