import { Injectable } from "@angular/core";
import { CustomFieldValueItem } from "../models/database/customFieldValueItem.database";

@Injectable({
  providedIn: 'root',
})
export class CustomFieldValueItemRepository {
  public async getItem(id: string): Promise<CustomFieldValueItem> {
    return await CustomFieldValueItem.table.get(id);
  }

  public async getItemsByListId(listId: string, sortProperty: string): Promise<CustomFieldValueItem[]> {
    let customFieldValueItems = await CustomFieldValueItem.table.where("valueListId").equals(listId);

    if (sortProperty == "position")
      return await customFieldValueItems.sortBy(sortProperty);
    else {
      return (await customFieldValueItems.toArray()).sort((a, b) => a.description.localeCompare(b.description, 'fr'));
    }
  }
}
