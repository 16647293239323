<app-popup [visible]="true" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left">
          <button><i class="fas fa-info"></i>
            <div><span>{{ 'audit.notFound.title' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
    <div class="layout-center padding-normal">
      {{ message }}
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal padding-horizontal-normal padding-vertical-compact height-normal">
        <div class="layout-right">
          <button class="color-set-blue compact" [routerLink]="'/forms'">
            <div><span> {{ 'audit.notFound.back' | translate }} </span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>