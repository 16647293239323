import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { timer } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Logger } from '../../log/logger';
import { VersionService } from '../../services/versionService';
import { ChangeOperation } from '../changeTracking/changeOperation';
import { DatabaseContext } from '../databaseContext';
import { DatabaseTableAll } from '../databaseTableAll';
import { TransactionType } from '../dexiejs/transactionType';
import { ApplicationLog } from '../models/database/applicationLog.database';
import { Audit } from '../models/database/audit.database';
import { AuditDataTable } from '../models/database/auditDataTable.database';
import { ChangeTracking } from '../models/database/changeTracking.database';
import { DataSourceImportationResult } from '../models/database/dataSourceImportationResult.database';
import { Synchronization } from '../models/database/synchronization.database';
import { SynchronizationRangeItem } from '../models/database/synchronizationRangeItem.database';
import { ChangeTrackingRange } from '../models/databaseLocal/changeTrackingRange.database';
import { LocalParameter } from '../models/databaseLocal/localParameter.database';
import { UserAudit } from '../models/databaseLocal/userAudit.database';
import { SynchronizationHttpClient } from './synchronizationHttpClient';
import { TableColumnNameReference } from './tableColumnNameReference';
import { SynchronizationType } from './synchronizationType';
import { SynchronizationContext } from './synchronizationContext';

@Injectable({
  providedIn: 'root',
})
export class SynchronizationService {
  private synchronizationDebounce = null;
  private synchronizationPromise: Promise<boolean> = null;
  private synchronizationPromiseResolve = null;

  public USER_ID_PARAMETER = "UserId";

  constructor(
    private authenticationService: AuthenticationService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private synchronizationContext: SynchronizationContext,
    private databaseContext: DatabaseContext,
    private databaseTable: DatabaseTableAll,
    private versionService: VersionService,
    private router: Router,
    private logger: Logger,
  ) { }

  async getUpdates(display: boolean, redirectToHome: boolean): Promise<boolean> {
    let hasError = false;

    let targetChangeTracking = await this.synchronizationHttpClient.getLastestChangeTrackingToApplyForMobile();

    await LocalParameter.table.put(new LocalParameter({
      name: LocalParameter.TARGET_CHANGE_TRACKING_NUMBER_PARAMETER_NAME,
      value: targetChangeTracking
    }));

    // let currentChangeTrackingParameter = await LocalParameter.table.get(LocalParameter.CURRENT_CHANGE_TRACKING_NUMBER_PARAMETER_NAME);
    // let currentChangeTracking = currentChangeTrackingParameter?.value || 0;

    // if (targetChangeTracking <= currentChangeTracking) {
    //   return;
    // }

    this.synchronizationContext.inProgress = true;

    // Set synchronization status to pending to display synchronization popup
    // early and block user from doing any other action.
    let synchronizationType = display ? SynchronizationType.full : SynchronizationType.fullHidden;

    // Because we debounce the synchronization, we need to have an external promise that will
    // be resolved when the synchronization is done while considering that the debounce
    // subscription can be unsubscribed multiple times in between.
    if (!this.synchronizationPromise) {
      this.synchronizationPromise = new Promise<boolean>((resolve) => {
        this.synchronizationPromiseResolve = resolve;
      });
    }

    // Debounce the synchronization to avoid multiple requests at the same time and shield
    // against sending the same data multiple times.
    if (this.synchronizationDebounce) {
      this.synchronizationDebounce.unsubscribe();
    }

    this.synchronizationDebounce = timer(environment.synchronizationDebounceMilliseconds).subscribe(async () => {
      if (await this.synchronizationContext.tryStartSynchronization(synchronizationType)) {
        try {
          // Local changes saved as ChangeTracking are sent to server before any synchronization to secure
          // the client against any error that could occur. By succesfully sending all changes, we can assume
          // the local indexedDB database can be fully destroyed and recreated if needed be. Of course we don't
          // for performance reasons.
          await this.applyChangeTrackings();
        } catch (exception) {
          hasError = true;
          this.logger.logError(exception);
        }

        // If we still have any ChangeTracking after they have been applied, it means at least one of them is
        // failling and need to prohibit further progress of the synchronization.
        let remainingChangeTrackingCount = await ChangeTracking.table.where("tableName").notEqual(ApplicationLog.tableName).count()
        if (remainingChangeTrackingCount > 0) {
          hasError = true;
          this.logger.logError(`ChangeTracking could not all be synchronized to server. ${remainingChangeTrackingCount} remaining, first one is likely causing the problem.`)
        }

        if (!hasError) {
          hasError = await this.checkForUpdate(hasError);
        }

        if (!hasError) {
          hasError = await this.deleteAuditWithoutSubscriptions(hasError);
        }

        if (!hasError) {
          let synchronization: Synchronization = null;

          try {
            let synchronizations = await Synchronization.table.toArray();
            if (synchronizations.length === 0) {
              synchronization = await this.synchronizationHttpClient.createSynchronization();

              await Synchronization.table.put(synchronization);

              // Ask the server to prepare all the data to be synchronized multiple ranges (or chunk) in
              // the following steps. Currently this could take a while and no information can be given to the
              // user. However I believe that by using SignalR eventually, we will be able to push progress
              // information to the user.
              await this.synchronizationHttpClient.prepareSynchronizationData();
            } else {
              synchronization = await Synchronization.table.toCollection().first();
            }

            // UserAudit is a special case in the synchronization which assume that the data will always
            // be cleared because it will always receive a new copy of the updated data. We do this to avoid
            // having to consider the changeTracking for this table because they are very volatile and would
            // need to be filtered by user.
            await UserAudit.table.clear();

            let hasNextSynchronizationRange = false;

            let databaseTables = this.databaseTable.getTableNames();

            let dateTableColumnNameReference = await this.synchronizationHttpClient.getDateTableColumnNameReference();

            do {
              // TODO AC: Test to reset database connection for performance
              // Initial test inconclusive as it seem to refresh the page.
              // To remove if unused later.
              // // this.databaseContext.close()
              // // this.databaseContext.open()

              // The server has the job of tracking everything that is send to the client database. For
              // this reason we can keep calling the last synchronization that has not completed. It will
              // work as long as the client properly update the synchronization range within a single
              // transaction. That single transaction is critical to ensure the recovery in case of browser
              // failure of any kind (F5, Browser crashes, etc).
              let synchronizationRangeItems = await this.synchronizationHttpClient.getNextSynchronizationRange();

              // Filters changes that should not be processed due to the fact the entity has been deleted
              // in a subsequent change.
              synchronizationRangeItems = synchronizationRangeItems.filter(x => {
                let canProcess = (x.changeOperation !== ChangeOperation.Delete && x.dataValues)
                  || x.changeOperation === ChangeOperation.Delete;

                if (!canProcess) {
                  this.logger.logInformation("Ignored item due to being deleted in a subsequent change.");
                  this.logger.logInformation(JSON.stringify(x));
                }

                return canProcess;
              });

              if (synchronizationRangeItems.length === 0) {
                // When synchronizationRange is empty it implies that nothing has to be updated. For this reason
                // we juste complete the range which will complete the synchronization itself. It can happens
                // if there is actually nothing to synchronize at all like when no audit are present in the system
                // at all or at least available for the current user groups.
                // It continue to the next synchronizationRange as a fail safe, however 'hasNextSynchronizationRange'
                // should always be false. If for some reason there is some data left it will continue properly.
                hasNextSynchronizationRange =
                  await this.synchronizationHttpClient.completeSynchronizationRange();
                continue;
              }

              // We can use the first tableName and changeOperation only because they are divided by
              // tableName, changeOperation and max synchronizationRange chunk size by the server.
              let tableName = synchronizationRangeItems[0].tableName;
              let changeOperation = synchronizationRangeItems[0].changeOperation;

              // I have used DexieJs directly here without the base repository wrapper to be able to have the
              // transaction that spans all changes within the synchronization range. It ignore inserting
              // ChangeTracking which would normaly be done with the base repository but is not required
              // in this case as we are synchronizing the database. It target all database tables, a behavior
              // I currently do not expect to be used anywhere
              // else in the application.
              await this.databaseContext.transaction(TransactionType.ReadWrite, databaseTables, async (transaction) => {
                // While the changeTracking should already have been filtered by the server to not to return any
                // items for a deleted table. We double checked here for safety in case the data would have been prepared
                // manually, likely to fix an error on a mobile.
                if (databaseTables.indexOf(tableName) === -1) {
                  // TODO AC: Add logging
                  this.logger.logWarning(`Ignored ${synchronizationRangeItems.length} items for ${tableName}`)
                  return;
                }

                let table = this.databaseContext.table(tableName);

                // Because synchronization range are split by size and table and changeOperation we can be sure all items
                // to be for the same table as the first one. A refactor might come in place to make this
                // clearer, mostly to provide more details to the users / completion progress, but at
                // this time, it was good enough.

                if (changeOperation === ChangeOperation.Delete) {
                  this.logger.logInformation(`Deleting ${synchronizationRangeItems.length} items for ${tableName}`);
                  await table.bulkDelete(synchronizationRangeItems.map((synchronizationRangeItem): any => {
                    if (tableName === AuditDataTable.tableName) {
                      return synchronizationRangeItem.id;
                    } else {
                      if (synchronizationRangeItem.tableId)
                        return synchronizationRangeItem.tableId.toUpperCase();
                      else
                        return synchronizationRangeItem.tableIdInt;
                    }
                  }));
                } else {
                  let camelCaseEntities = this.getWebEntities(synchronizationRangeItems, dateTableColumnNameReference);

                  if (camelCaseEntities.length > 0) {
                    switch (tableName) {
                      case DataSourceImportationResult.tableName:
                        // This transformations let convert the JSON data column in this table to 
                        // a list of columns.
                        camelCaseEntities = this.transformDataSourceImportationResultEntities(camelCaseEntities);

                        break;
                    }
                  }

                  if (changeOperation === ChangeOperation.Insert) {
                    // Iteration 3: Using Bulk add again with a catch to improve performance and timeout errors while ignoring
                    // dupplicates.
                    // Iteration 2: Used to be Bulk put instead of insert to avoid error in cases of duplicate keys. It assume that all entities
                    // with the change operation type insert contains all its data and if there actually is a duplicate 
                    // key and the data would be different, the last one will win.
                    // This should not occur, but it is a fail safe.
                    // Iteration 1: Used bulkAdd, but caused problems dues to sometime having dupplicate id.

                    //// Possible performance improvement to cache data but it is not really needed at this time.
                    //// Kept for reference purposes.
                    // this.logger.logInformation(`Inserting ${synchronizationRangeItems.length} items for ${tableName}`);
                    // await TableCache.table.add(new TableCache({ tableName: tableName, data: camelCaseEntities }));
                    // this.logger.logInformation(`Completed cache insert for ${tableName}`);

                    this.logger.logInformation(`Inserting ${synchronizationRangeItems.length} items for ${tableName}`);
                    // await this.databaseContext.transaction(TransactionType.ReadWriteReuseTransactionIfCompatible, tableName, async (transaction) => {
                    //   let query = table.bulkAdd(camelCaseEntities)

                    //   query.catch((e) => {
                    //     console.error(e);
                    //     console.error(`${e.failures.length} ${tableName} did not succeed. However, ${camelCaseEntities.length - e.failures.length} ${tableName} was added successfully`);
                    //   });

                    //   await query;
                    // });

                    // Bulk put instead of insert to avoid error in cases of duplicate keys. It assume that all entities
                    // with the change operation type insert contains all its data and if there actually is a duplicate 
                    // key and the data would be different, the last one will win.
                    // This should not occur, but it is a fail safe.
                    await table.bulkPut(camelCaseEntities);

                    this.logger.logInformation(`Completed insert for ${tableName}`);
                  } else if (changeOperation === ChangeOperation.Update) {
                    this.logger.logInformation(`Updating ${synchronizationRangeItems.length} items for ${tableName}`);
                    for (let entity of camelCaseEntities) {
                      await table.update(entity.id, entity);
                    }
                  }
                }

                this.synchronizationDebounce.unsubscribe();
              });

              // Because an error can occur, a confirmation is send to the server when data is successfully
              // synchronized, by doing so, the server marks the synchronization range completed and returns
              // if an other range can be requested. We continue this loop until completed.
              hasNextSynchronizationRange =
                await this.synchronizationHttpClient.completeSynchronizationRange();
            } while (hasNextSynchronizationRange);

            await Synchronization.table.delete(synchronization.id);

            let currentRoute = this.router.url;

            if (redirectToHome) {
              // This catch shouln't be needed but it is a fail safe. In case
              // there is an error on the redirection.
              try {
                await this.router.navigateByUrl('/', { skipLocationChange: true });
                if (!await this.router.navigate([currentRoute])) {
                  // TODO AC: Add popup indicating page is not available anymore.
                  await this.router.navigate(["/home"])
                }
              } catch (e) {
                this.logger.logError(e);
                await this.router.navigate(["/home"])
              }
            } else {
              if (this.authenticationService.url) {
                this.router.navigate([this.authenticationService.url])
              }
            }

            this.synchronizationPromiseResolve(true);
            this.synchronizationContext.inProgress = false;
            this.synchronizationPromise = null;

            await LocalParameter.table.put(new LocalParameter({
              name: LocalParameter.CURRENT_CHANGE_TRACKING_NUMBER_PARAMETER_NAME,
              value: targetChangeTracking
            }));

            // Hide button when completed successfully
            this.synchronizationContext.requireSynchronization = false;
          } catch (exception) {
            hasError = true;

            if (exception instanceof HttpErrorResponse)
              this.logger.logError("Aborting synchronization due to failure", exception, 'synchronizationService.getUpdates()');
            else {
              this.logger.logError("Aborting synchronization due to failure", exception.message, 'synchronizationService.getUpdates()');
            }
          }
        }
      } else {
        hasError = true;
      }

      if (hasError) {
        this.synchronizationPromiseResolve(false);
        this.synchronizationContext.inProgress = false;
        this.synchronizationPromise = null;

        if (!this.router.url.startsWith("/unauthorized"))
          await this.router.navigate(["synchronization-error"]);
      }
    });

    return this.synchronizationPromise;
  }

  private async deleteAuditWithoutSubscriptions(hasError: boolean) {
    try {
      await this.deleteAuditWithoutSubscription();
    } catch (exception) {
      hasError = true;
      this.logger.logError(exception);
    }
    return hasError;
  }

  private async checkForUpdate(hasError: boolean) {
    try {
      if (await this.versionService.requireUpdate()) {
        document.location.reload();
      }
    } catch (exception) {
      hasError = true;
      this.logger.logError(exception);
    }
    return hasError;
  }

  public async sendUpdates(): Promise<void> {
    let changeTrackings = await ChangeTracking.table.toArray();
    if (changeTrackings.length === 0) {
      return;
    }

    let synchronzationType = SynchronizationType.push;
    this.synchronizationContext.inProgress = true;

    if (this.synchronizationDebounce) {
      this.synchronizationDebounce.unsubscribe();
    }

    this.synchronizationDebounce = timer(environment.synchronizationDebounceMilliseconds).subscribe(async () => {
      if (await this.synchronizationContext.tryStartSynchronization(synchronzationType)) {
        try {
          await this.applyChangeTrackings();
          this.synchronizationContext.inProgress = false;
        } catch (exception) {
          this.synchronizationContext.inProgress = false;
          // In this case when we sent data and it fails, do not prohibit user to continue.
          // Even it might result in data loss, the full synchronization will redirect to an error
        }
      }

      this.synchronizationDebounce.unsubscribe();
    });
  }

  private getWebEntities(synchronizationRangeItems: SynchronizationRangeItem[], dateTableColumnNameReference: TableColumnNameReference[]): any[] {
    return synchronizationRangeItems.map((synchronizationRangeItem) => {
      let entity = JSON.parse(synchronizationRangeItem.dataValues);

      this.convertStrignifiedDateToJavascriptDate(synchronizationRangeItem, entity, dateTableColumnNameReference);

      return this.getCamelCaseEntity(entity, synchronizationRangeItem.tableName);
    });
  }

  private transformDataSourceImportationResultEntities(entities: any[]): any[] {
    return entities.map((entity) => {

      let entityData = entity.data ? this.getCamelCaseEntity(JSON.parse(entity.data)) : {};

      // Remove to avoid any conflict with original key as currently
      // it would not be allowed to have an id in the imported data.
      delete entityData.id;

      let result = _.merge(entity, entityData);

      delete result.data;

      return result;
    });
  }

  // Date converted to JSON are now string that need to be cast to date again
  // before being inserted in the database, to do so, we get the list table and
  // related column names that are dates and only for those case to date.
  // Some exception need to be taken into account for AuditDataTable table name
  // must be infered from the original tables and for UserAudit which does not exist
  // on the server but for which all dates related to the Audit table.
  private convertStrignifiedDateToJavascriptDate(synchronizationRangeItem: SynchronizationRangeItem, entity: any, dateTableColumnNameReference: TableColumnNameReference[]) {
    let tableNameForDateConversion = null;
    switch (synchronizationRangeItem.tableName) {
      case AuditDataTable.tableName:
        tableNameForDateConversion = entity.TableName;
        break;

      case UserAudit.tableName:
        tableNameForDateConversion = Audit.tableName;
        break;

      default:
        tableNameForDateConversion = synchronizationRangeItem.tableName;
    }

    for (let reference of dateTableColumnNameReference.filter(x => x.tableName === tableNameForDateConversion)) {
      if (entity[reference.columnName]) {
        entity[reference.columnName] = new Date(entity[reference.columnName]);
      }
    }
  }

  private getCamelCaseEntity(entity: any, tableName?: string): any {
    // Dynamic custom table columns must be skipped
    if (tableName === AuditDataTable.tableName) {
      return this.toCamelCase(entity, [
        'Id',
        'TableName',
        'TableId',
        'AuditId',
        'DataValues',
        'DataValuesSize',
        'TimeStamp'
      ]);
    } else if (tableName === DataSourceImportationResult.tableName) {
      let mergedEntity = entity;

      if (entity?.Data) {
        mergedEntity = _.merge(entity, JSON.parse(entity.Data))

        delete mergedEntity.Data;
      }
      else
        mergedEntity = entity;

      return this.toCamelCase(mergedEntity);
    } else {
      return this.toCamelCase(entity);
    }
  }

  private toCamelCase(object, onlyTheseProperties: string[] = []): any {
    let camelCaseEntity = {};

    for (let property in object) {
      if (onlyTheseProperties.length > 0 && !onlyTheseProperties.includes(property)) {
        camelCaseEntity[property] = object[property];
      } else {
        camelCaseEntity[property[0].toLowerCase() + property.substring(1, property.length)] = object[property];
      }
    }

    return camelCaseEntity;
  }

  private async deleteAuditWithoutSubscription() {
    let auditToDelete = (await this.synchronizationHttpClient.getAuditToDelete()).map((auditId => auditId.toUpperCase()));

    if (auditToDelete.length > 0) {
      let auditRelatedTableInformations = this.databaseTable.getAuditRelatedTables();

      for (let auditRelatedTableInformation of auditRelatedTableInformations) {
        let auditIndexKey = 'auditId';

        if (auditRelatedTableInformation.tableName === Audit.tableName) {
          auditIndexKey = 'id';
        }

        let deleteCount = await this.databaseContext.table(auditRelatedTableInformation.tableName)
          .where(auditIndexKey)
          .anyOf(auditToDelete)
          .delete();

        this.logger.logInformation(`Deleted ${deleteCount} items for ${auditRelatedTableInformation.tableName}`);
      }
    }
  }

  private async applyChangeTrackings() {
    let changeTrackingRanges = await ChangeTrackingRange.table.toArray();

    let changeTrackingTotalCount = await ChangeTracking.table.count();

    this.logger.logInformation(`Synchronizing ${changeTrackingTotalCount} changes...`);

    for (let changeTrackingRange of changeTrackingRanges) {
      let changeTrackings = await ChangeTracking.table.where('id').belowOrEqual(changeTrackingRange.toChangeTrackingId).toArray();

      // this.logger.logInformation(`Synchronizing ${changeTrackings.length} changeTracking to server`);

      let lastSuceededChangeTrackingId = await this.synchronizationHttpClient.applyChangeTracking(changeTrackings);

      // this.logger.logInformation(`Synchronized ${changeTrackings.length} changeTracking to server`);

      await ChangeTracking.table.where('id').belowOrEqual(lastSuceededChangeTrackingId).delete();

      // this.logger.logInformation(`Deleted changeTracking below or equals #${lastSuceededChangeTrackingId}`);

      await ChangeTrackingRange.table.delete(changeTrackingRange.id)

      this.logger.logInformation(`Synchronized ${changeTrackings.length} changes, removing changeTrackingRange #${changeTrackingRange.id}`);
    }
  }
}
