// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class OptionList extends ChangeTrackingEntity {
  public static table: Dexie.Table<OptionList, string>;
  public static tableName: string = 'OptionList';

  public id: string;  public name: string;  public source: string;  public entityId: string;  public filterMode: number;  public textFilter: string;  public filterScript: string;  public sortBy: string;  public groupBy: string;  public groupByHtmlTemplate: string;  public templateType: number;  public lineTemplate1: string;  public lineTemplate2: string;  public lineTemplate3: string;  public htmlTemplate: string;  public timeStamp: Date;

  public constructor(args?: Partial<OptionList>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return OptionList.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(OptionList.tableName);
      this.table.mapToClass(OptionList);
  }
}
