import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-audit-not-found',
  templateUrl: './audit-not-found.component.html',
  styleUrls: ['./audit-not-found.component.scss']
})
export class AuditNotFoundComponent implements OnInit {

	public message: string;

  constructor(
		private translateService: TranslateService,
		private route: ActivatedRoute) { }

  ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			let auditNumber = params.get("number");
			
			this.message = this.translateService.instant("audit.notFound.message", { number: auditNumber});
		});
  }

}
