import { Injectable } from "@angular/core";
import { UserGroup } from "../models/database/userGroup.database";

@Injectable({
  providedIn: 'root',
})
export class UserGroupRepository {

  async getGroups(): Promise<UserGroup[]> {
    let groups = await UserGroup.table.toCollection().sortBy("name");
    let groupsNotDeleted = groups.filter((group: UserGroup) => {
      return group.isDeleted === false;
    });
    return groupsNotDeleted;
  }
}
