import { Component, OnInit } from '@angular/core';
import { BaseListDetailsState } from 'src/app/components/custom-field-value-lists/baseListDetailsState';

@Component({
  selector: 'app-custom-field-value-lists',
  templateUrl: './custom-field-value-lists.component.html',
  styleUrls: ['./custom-field-value-lists.component.scss']
})
export class CustomFieldValueListsComponent implements OnInit {

  constructor(private baselistDetailsState: BaseListDetailsState) {  }

  ngOnInit(): void {
    this.baselistDetailsState.path = 'data-sources';
  }
}



