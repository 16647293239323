import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { PopupComponent } from '../popup.component';
import { SimplePopupOptions } from './simplePopupOptions';

@Component({
  selector: 'app-modal',
  templateUrl: './simplePopup.component.html',
  styleUrls: ['./simplePopup.component.scss']
})
export class SimplePopupComponent {
  @ViewChild(PopupComponent) popup: PopupComponent;

  @Input() visible = false;
  @Input() canClose = true;
  @Input() boxFixed = false;

  @Input() options: SimplePopupOptions = new SimplePopupOptions();

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter;

  constructor() {
  }

  public async display(modalOptions: SimplePopupOptions): Promise<string> {
    this.options = modalOptions;
    this.popup.display();
    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);

    return this.options.asyncResult;
  }

  public close(result?: string) {
    this.popup.close();
    this.visible = this.popup.visible;
    this.visibleChange.emit(this.visible);

    if (this.options.onModalResult)
      this.options.onModalResult(result);

    this.options.asyncResultExecutor(result);
  }
}
