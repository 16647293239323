import { Component } from '@angular/core';
import { first, firstValueFrom } from 'rxjs';
import { DataSourceImportationHttpClient } from 'src/app/core/services/dataSourceImportations/dataSourceImportationHttpClient';

@Component({
  selector: 'app-data-source-url-parametrization',
  templateUrl: './data-source-url-parametrization.component.html',
  styleUrls: ['./data-source-url-parametrization.component.scss']
})
export class DataSourceUrlParametrizationComponent {

  constructor(private datasourceImportationHttpClient: DataSourceImportationHttpClient) { 
    this.url = "localhost/Data={CurrentDate}";
    this.dataSourceImportationId = "803F8E53-F78C-437F-BD52-1837B55C17AF";
  }

  result: any;
  url: string;
  dataSourceImportationId: string;

  async sendClick(){
    this.result = await this.datasourceImportationHttpClient.GetParameterizedUrl(this.dataSourceImportationId ?? "", this.url ?? "");
  }
}
