export class ButtonDataSourceSelectedEventArgs{
  /**
  * The id of the selected data source result item from a button.
  */
  public dataSourceResultItemId: string;

  /**
  * The name of the associated field of the button.
  */
  public name: string;

  public constructor(args?: Partial<ButtonDataSourceSelectedEventArgs>) {
    Object.assign(this, args);
  }
}