import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { TokenApiResult } from './tokenApiResult';

@Injectable({
  providedIn: 'root',
})
export class DataSourceImportationHttpClient{
	private configUrl = `${environment.apiBaseUrl}/DataSourceImportation`;

  constructor(private http: HttpClient) { }

  async retreiveFieldTitles(dataSourceImportationId: string, url: string, authenticationToken: string, jsonPath: string) : Promise<TokenApiResult> {
    return firstValueFrom(this.http.post<TokenApiResult>(`${this.configUrl}/RetrieveFieldTitles`, null, { params: { dataSourceImportationId, url, authenticationToken, jsonPath }}));
  }

  /**
  * Returns the url specified with all tokens replaced by the corresponding dynamic value.
  */
  async GetParameterizedUrl(dataSourceImportationId: string, url: string) : Promise<string> {
    return firstValueFrom(this.http.get(`${this.configUrl}/GetParameterizedUrl`, 
      { 
        params: { dataSourceImportationId, url },
        responseType: "text"
      }));
  }
}