import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { AuditAlertSummary } from 'src/app/core/data/models/database/auditAlertSummary.database';
import { AuditAlertSummarySatelliteDataItem } from 'src/app/core/data/models/database/auditAlertSummarySatelliteDataItem.database';
import { AuditAttachment } from 'src/app/core/data/models/database/auditAttachment.database';
import { AuditDataTable } from 'src/app/core/data/models/database/auditDataTable.database';
import { AuditRemark } from 'src/app/core/data/models/database/auditRemark.database';
import { AuditSubscription } from 'src/app/core/data/models/database/auditSubscription.database';
import { AutomaticAuditToExport } from 'src/app/core/data/models/database/automaticAuditToExport.database';
import { AutomaticDocumentExportationTelemetry } from 'src/app/core/data/models/database/automaticDocumentExportationTelemetry.database';
import { AutomaticDocumentWarning } from 'src/app/core/data/models/database/automaticDocumentWarning.database';
import { DynamicTabAuditTemplate } from 'src/app/core/data/models/database/dynamicTabAuditTemplate.database';
import { FormPhoto } from 'src/app/core/data/models/database/formPhoto.database';
import { MobileAudit } from 'src/app/core/data/models/database/mobileAudit.database';
import { Photo } from 'src/app/core/data/models/database/photo.database';
import { Program } from 'src/app/core/data/models/database/program.database';
import { WFStep } from 'src/app/core/data/models/database/wFStep.database';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { environment } from 'src/environments/environment';
import { AuditService } from '../auditService';

@Injectable({
  providedIn: 'root',
})
export class AuditCopyService {
  private auditToCopy: Audit;
  private newAudit: Audit;

  constructor(
    private auditService: AuditService,
    private baseRepository: BaseRepository,
    private synchronizationService: SynchronizationService,
    private authenticationService: AuthenticationService,
    private authenticatedUser: AuthenticatedUser,
  ) {  }

  public async validateAuditNumber(auditNumber: string): Promise<boolean> {
    return await this.auditService.validateAuditNumberExist(auditNumber);
  }

  public async copyAudit(auditNumber: string): Promise<string> {
    // To cancel redirection
    this.authenticationService.url = "";
    await this.synchronizationService.getUpdates(false, false);

    await this.createNewAudit(auditNumber);
    await this.auditService.createAuditHeader(this.newAudit);

    await this.CreateChangeTrackingEntity(DynamicTabAuditTemplate, await this.filterTableFromAudit(DynamicTabAuditTemplate));
    await this.CreateChangeTrackingEntity(FormPhoto, await this.filterTableFromAudit(FormPhoto));
    await this.CreateChangeTrackingEntity(Photo, await this.filterTableFromAudit(Photo));
    await this.CreateChangeTrackingEntity(AuditAlertSummary, await this.filterTableFromAudit(AuditAlertSummary));
    await this.CreateChangeTrackingEntity(AuditAlertSummarySatelliteDataItem, await this.filterTableFromAudit(AuditAlertSummarySatelliteDataItem));
    await this.CreateChangeTrackingEntity(AuditAttachment, await this.filterTableFromAudit(AuditAttachment));
    await this.CreateChangeTrackingEntity(AuditRemark, await this.filterTableFromAudit(AuditRemark));
    await this.CreateChangeTrackingEntity(AuditDataTable, await this.filterTableFromAudit(AuditDataTable));
    await this.CreateChangeTrackingEntity(AutomaticAuditToExport, await this.filterTableFromAudit(AutomaticAuditToExport));
    await this.CreateChangeTrackingEntity(MobileAudit, await this.filterTableFromAudit(MobileAudit));
    await this.CreateChangeTrackingEntity(AutomaticDocumentExportationTelemetry, await this.filterTableFromAudit(AutomaticDocumentExportationTelemetry));
    await this.CreateChangeTrackingEntity(AutomaticDocumentWarning, await this.filterTableFromAudit(AutomaticDocumentWarning));
    await this.CreateAuditSubscriptionChangeTracking();

    return this.newAudit.number;
  }

  private async filterTableFromAudit(table) {
    return (await table.table.toArray()).filter(row => row.auditId === this.auditToCopy.id);
  }

  private async createNewAudit(auditNumber: string) {
    this.auditToCopy = (await Audit.table.toArray()).find(audit => audit.number === auditNumber);

    this.newAudit = new Audit();
    this.newAudit = (await Audit.table.toArray()).find(audit => audit.number === auditNumber);
    let now = new Date();

    this.newAudit.timeStamp = now;
    this.newAudit.createdDate = now;
    this.newAudit.updatedDate = now;
    this.newAudit.effectiveDate = now;
    this.newAudit.id = "";
    this.newAudit.number = await this.auditService.getNextNumber(
      (await Program.table.toArray()).find(program => program.id === this.newAudit.programId));

    await this.setNewAuditStep();

    await this.baseRepository.insert(Audit.table, this.newAudit);
  }

  private async setNewAuditStep() {
    let steps: Array<WFStep> = await WFStep.table.toArray();
    let auditStep: WFStep = steps.find(step => step.id === this.newAudit.stepId);
    if (auditStep.number !== 1) {
      this.newAudit.stepId = steps.filter(step => step.programId === auditStep.programId)
        .find(step => step.number === 1).id;
    }
  }

  private async CreateChangeTrackingEntity(table, entries) {
    for (var entry of entries) {
      let newEntry = new table();
      newEntry = entry;
      let now = new Date();
      newEntry.timeStamp = now;
      newEntry.auditId = this.newAudit.id;
      newEntry.id = await Dexie.Observable.createUUID();
      switch(table) {
        case AuditAlertSummary:
        case AuditAttachment:
        case AuditRemark:
          newEntry.createdDate = now;
        case AutomaticDocumentExportationTelemetry:
          newEntry.startTime = now;
        case AuditDataTable:
          newEntry.tableId = newEntry.id;
      }
      await this.baseRepository.insert(table.table, newEntry);
    }
  }

  private async CreateAuditSubscriptionChangeTracking() {
    let newAuditSubscription = new AuditSubscription();

    let now = new Date();

    newAuditSubscription.timeStamp = now;
    newAuditSubscription.startDate = now;

    let endDate = new Date();
    endDate.setDate(endDate.getDate() + environment.auditSubscriptionDays);

    newAuditSubscription.endDate = endDate;

    newAuditSubscription.auditId = this.newAudit.id;
    newAuditSubscription.userAccountId = this.authenticatedUser.id;

    await this.baseRepository.insert(AuditSubscription.table, newAuditSubscription);
  }
}
