import { Component, OnInit } from '@angular/core';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";
import { CustomFieldValueItemRepository } from "src/app/core/data/repositories/customFieldValueItemRepository";
import { AuditState } from 'src/app/pages/audit/auditState';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadioButtonComponent extends BaseControlComponent implements OnInit {
  constructor(private customFieldValueItemRepository: CustomFieldValueItemRepository, private auditState: AuditState) {
    super();
  }

  dataSourceId: string;

  readOnly: boolean = false;

  items: DataSourceItem<string>[];

  ngOnInit(): void {
    super.ngOnInit();

    this.dataSourceId = super.getExtendedProperty("DataSourceId") as string;

    let sortOrder = super.getExtendedProperty("SortOrder") as number;
    let sortProperty: string = "description";

    switch (sortOrder) {
      case 2:
        sortProperty = "position";
        break;
    }

    this.readOnly = this.auditState.readonly;
    
    this.customFieldValueItemRepository.getItemsByListId(this.dataSourceId, sortProperty).then(x => {
      this.items = x.map(x => new DataSourceItem<string>(x.id, x.description));
    });
  }
}
