import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
} from "@angular/router";
import { ArrayUtility } from "src/app/core/arrayUtility";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { EntityState } from "src/app/core/data/changeTracking/entityState";
import { Audit } from "src/app/core/data/models/database/audit.database";
import { AuditAlertSummary } from "src/app/core/data/models/database/auditAlertSummary.database";
import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { DynamicTabAuditTemplate } from "src/app/core/data/models/database/dynamicTabAuditTemplate.database";
import { DynamicTabStructureItem } from "src/app/core/data/models/database/dynamicTabStructureItem.database";
import { CustomFieldControlType } from "src/app/core/data/models/form/customFieldControlType";
import { ExtendedProperty } from "src/app/core/data/models/form/extendedProperty";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { FormField } from "src/app/core/data/models/formField";
import { FormFieldExtendedValue } from "src/app/core/data/models/formFieldExtendedValue";
import { FormFieldControlService } from "src/app/core/services/formFieldControlService";
import { AuditState } from "src/app/pages/audit/auditState";
import Dexie from "dexie";
import { SectionConst } from "src/app/core/sections/sectionConst";
import { FormFieldImageExtendedValue } from "src/app/core/data/models/formFieldImageExtendedValue";
import { AlertTemplateService } from "src/app/core/services/alertTemplateService";
import { HeaderState } from "src/app/components/headers/header/headerState";
import { DynamicSection } from "src/app/core/data/models/form/dynamicSection";
import { ComparatorService } from "src/app/core/services/comparator.service";
import { DynamicTabTemplate } from "src/app/core/data/models/database/dynamicTabTemplate.database";
import { AlertConditionDetail } from "src/app/core/data/models/database/alertConditionDetail.database";

@Injectable()
export class AuditSectionDetailResolver implements Resolve<void> {
  constructor(
    private auditState: AuditState,
    private headerState: HeaderState,
    private formfieldService: FormFieldControlService,
    private baseRepository: BaseRepository
  ) { }

  dynamicTabInstances: DynamicTabAuditTemplate[];

  async resolve(route: ActivatedRouteSnapshot) {
    let idDetail: string = SectionConst.information;

    let section: Section;

    let customTableId: string;

    this.auditState.instanceId = null;
    this.auditState.instancePosition = null;

    // For the moment, when the Type Query Param is in the url, it mean that the current page is a dynamic tab audit alert.
    // In the future, switch case will be done to extract different query params based on the type.
    if (route.paramMap.has("alertDetailId")) {
      ({ customTableId, section } = await this.processAlert(route.paramMap.get("alertDetailId")));
    }
    else if (route.paramMap.has("instanceDetailId")) {
      ({ customTableId, section } = await this.processInstance(route.paramMap.get("instanceDetailId")));
    }
    else {
      ({ idDetail, section } = await this.processDefault(idDetail, route, section));
    }

    // It happends that the section is not found. This patch is to avoid the error and
    // will always redirect to the information section.
    if (!section) {
      section = this.auditState.form.sections.find((x) => x.id === SectionConst.information);
    }

    this.auditState.alertSummary = new Array<AuditAlertSummary>();

    switch (section.type) {
      case SectionType.CustomFields:
      case SectionType.Header:
        this.auditState.formFields = await this.loadCustomFields(section, customTableId);

        // Alert summary must be loaded for each custom field section because custom field section
        // can add or remove alert from this list that will be saved when section saved.
        await this.injectAlertSummaryIntoControls(section, this.dynamicTabInstances);

        break;
      case SectionType.AlertSummary:
        this.auditState.alertSummary = await this.loadAlertSummary(section, this.auditState.audit);
        break;
      default:
        break;
    }

    this.auditState.section = section;

    return;
  }

  private async processDefault(idDetail: string, route: ActivatedRouteSnapshot, section: Section) {
    idDetail = route.paramMap.get("idDetail") || this.auditState?.section?.id || idDetail;

    this.auditState.customTableId = "";
    this.auditState.reference = ""

    section = this.auditState.form.sections.find(
      (x) => x.id === idDetail
    );

    if (section) {
      switch (section.type) {
        case SectionType.CustomFields:
        case SectionType.Header:
          let sectionData = await AuditDataTable.table.where("auditId").equals(this.auditState.audit.id).filter(x => x.tableName === section.dataTableName).first();

          if (!sectionData){
            sectionData = new AuditDataTable();

            sectionData.tableName = section.dataTableName;
            sectionData.auditId = this.auditState.audit.id;
            sectionData.tableId = Dexie.Observable.createUUID();
            sectionData.id = sectionData.tableId;

            this.baseRepository.insert(AuditDataTable.table, sectionData);
          }

          this.auditState.customTableId = sectionData["tableId"].toString();

          break;
      }
    }

    return { idDetail, section };
  }

  private async processInstance(instanceDetailId: string) {
    let dynamicTabAuditTemplate = await this.baseRepository.get(DynamicTabAuditTemplate.table, instanceDetailId);

    let section: Section;
    let sectionFound: Boolean;
    let folderId: string;

    for (const formSection of this.auditState.form.sections) {
      if (!!formSection["folders"]) {
        let dynamicSection = formSection as DynamicSection;

        for (const folder of dynamicSection.folders) {
          if (folder.idKey === dynamicTabAuditTemplate.dynamicTabStructureItem) {
            for (const templateSection of dynamicSection.templates) {
              if (folder.templateId === templateSection.templateId) {
                section = templateSection;
                folderId = folder.id;

                sectionFound = true;
                break;
              }
            }
          }
        }

        if (sectionFound)
          break;
      }
    }

    // This id will be required to located in the custom field table the record who holds back data.
    let customTableId = dynamicTabAuditTemplate.customTableId;

    this.auditState.customTableId = customTableId;

    // In the detail section, the header title is the description of the section.
    // In dynamic tab instance component, the editable section is the template linked to the folder.
    // However, the template has a general template name which is not user friendly at all.
    // This hack will change the template section description to the description of the dynamic tab instance information.
    section.description = dynamicTabAuditTemplate.number + " - " + dynamicTabAuditTemplate.description;

    this.auditState.instanceId = instanceDetailId;
    this.auditState.instancePosition = dynamicTabAuditTemplate.position;
    this.auditState.folderId = folderId;
    this.auditState.parentSectionId = section.id;

    return { customTableId, section };
  }

  private async processAlert(alertDetailId: string) {
    let dynamicTabAuditTemplate = await this.baseRepository.get(DynamicTabAuditTemplate.table, alertDetailId);

    this.headerState.isAlert = true;

    let section: Section;

    let dynamicTabStructureItem: DynamicTabStructureItem;

    let structureItems = await DynamicTabStructureItem.table.filter(x => x.keyIdentifier === dynamicTabAuditTemplate.dynamicTabStructureItem).toArray();

    for (const templateSection of this.auditState.form.alertTemplates) {
      for (const structureItem of structureItems) {
        if (structureItem.templateId === templateSection.id) {
          dynamicTabStructureItem = structureItem;
          section = templateSection;

          if (this.auditState.instanceId)
            this.auditState.reference = section.id;
          else
            this.auditState.reference = this.auditState.section.id;

          break;
        }
      }

      if (section) {
        break;
      }
    }

    let customTableId = dynamicTabAuditTemplate.customTableId;

    this.auditState.customTableId = dynamicTabAuditTemplate.customTableId;

    let referenceControl = Section.getControlFromStructureItemKey(this.auditState.section, dynamicTabStructureItem.id);

    section.description = this.auditState.section.description + " > " + referenceControl.description;

    return { customTableId, section };
  }

  /**
  * Inject alert summary information into controls.
  *
  * @remarks
  * When displaying a section, controls must not evaluate alert based on their data; they must let the alert
  * summary inject information already collected from previous data manipulation of control by the user.
  * This will prevent if the configuration of an alert changes to display wrong information.
  */
  private async injectAlertSummaryIntoControls(section: Section, dynamicTabAuditTemplates: DynamicTabAuditTemplate[]) {
    this.auditState.alertSummary = await this.loadAlertSummary(section, this.auditState.audit);

    const currentSectionAlertSummary = this.auditState.alertSummary.filter(x => x.controlKey.split(".")[0] === section.key);

    let formFields = FormField.flatFormFields(this.auditState.formFields);

    for (const alert of currentSectionAlertSummary) {
      let alertControlKeys = alert.controlKey.split(".");

      if (this.auditState.instanceId && alertControlKeys.length === 3 && ComparatorService.localeCompare(alertControlKeys[2], this.auditState.customTableId) !== 0) {
        continue;
      }

      const formField = formFields.find(x => alert.controlKey.startsWith(x.controlKey));

      if (formField && formField.alertId) {
        let condition = formField.alert.conditions.find(x => x.id === alert.alertConditionDetailId);
        let customTableId: string;
        let referenceTableId: string;
        let alertFound: boolean = false;
        let id: string;

        if (condition != null) {
          if (formField.alertTemplates.length > 0){
            let alertTemplate = formField.alertTemplates.find(x => x.conditionDetailId === condition.id);

            // Alert template can not exists when only when a control linked to an alert already exists with audit having triggered alerts
            // and a link to an alert template is added.
            if (alertTemplate) {
              let structureItem = await this.baseRepository.get(DynamicTabStructureItem.table, alertTemplate.structureItemId);

              let auditTemplateInstance = dynamicTabAuditTemplates.find(x => x.dynamicTabStructureItem === structureItem.keyIdentifier);

              if (auditTemplateInstance) {
                customTableId = auditTemplateInstance.customTableId;
                referenceTableId = auditTemplateInstance.referenceTableId;
                id = auditTemplateInstance.id;

                alertFound = true;
              }

              if (alertFound)
              formField.setAlertControlCondition(condition, customTableId, section.id, false, referenceTableId, id);
            }
          }
          else{
            formField.setAlertControlCondition(condition, null, section.id, false, formField.referenceTableId, id)
          }
        }
      }
    }
  }

  private async loadAlertSummary(section: Section, audit: Audit): Promise<AuditAlertSummary[]> {
    let result = new Array<AuditAlertSummary>();
    let alerts = await (await this.baseRepository.queryAll<AuditAlertSummary>(AuditAlertSummary.table)).where("auditId").equals(audit.id).toArray();

    // As performance improvement, deleted condition could not be sent to the mobiles.
    // That implies to probably serialize condition and condition details into the FormSerializer because the current mobile databases
    // don't have the intelligence to pool alert conditions if they don't has been changed by the user after the current update in code to
    // exclude logicially deleted conditions.
    let alertConditionDetails = await (await this.baseRepository.queryAll<AlertConditionDetail>(AlertConditionDetail.table)).filter(x => !x.isDeleted).toArray();

    this.auditState.alertSummary = new Array<AuditAlertSummary>();

    for (const alert of alerts) {
      let alertConditionDetail = alertConditionDetails.find(x => x.id === alert.alertConditionDetailId);

      if (!alertConditionDetail)
        continue;

      alert.entityState = EntityState.Default;

      result.push(alert);
    }

    return result;
  }

  /**
  * @remarks
  * For the moment, the method doesn't support grids. This mean, only one AuditDataTable is loaded by section.
  */
  private async loadCustomFields(section: Section, customTableId: string): Promise<FormField<any>[]> {
    let formFields = await this.formfieldService.getFormFields(section);
    let flattedFormFields = FormField.flatFormFields(formFields);

    flattedFormFields = flattedFormFields.filter(x => x.isInputControl())

    if (flattedFormFields.length > 0) {
      let auditDataTables = await (
        await this.baseRepository.queryAll<AuditDataTable>(
          AuditDataTable.table
        )
      )
        .filter((x) => {
          return (
            // With dynamic tab audit template like with dynamic tab, each custom field table related to could have more than
            // one row because of the nature of this type of tab. Therefore, the exact record who holds the data must be located
            // with the customTableId retreived from the dynamic tab audit template.
            // In all other cases, there is only one and one record in each custom field table by audit and it's the reason only
            // the auditId is required to locate the record.
            (customTableId ? x.tableId === customTableId : (x.auditId == this.auditState.audit.id && x.tableName == section.dataTableName))
          );
        })
        .toArray();

      let auditDataTable: AuditDataTable;

      // Create the audit data table immediatly when loading the section because the custom table id
      // related to will have to be use by alert templates before saving the changes of the section.
      // Usually, the AuditDataTable will exists from the creation of the audit but if a new tab is added
      // to the form after audits has already been created, the new tab for these audits will not have
      // AuditDataTable instances.
      if (auditDataTables.length == 0) {
        auditDataTable = new AuditDataTable();
        auditDataTable.tableName = section.dataTableName;
        auditDataTable.tableId = Dexie.Observable.createUUID();
        auditDataTable.id = auditDataTable.tableId;
        auditDataTable.auditId = this.auditState.audit.id;

        await this.baseRepository.insert(
          AuditDataTable.table,
          auditDataTable
        );
      }
      else
        auditDataTable = auditDataTables[0];

      await this.loadDynamicTabInstances(auditDataTable.tableId);

      let groupedFormFields = ArrayUtility.groupBy(flattedFormFields, x => x.dataTableName);

      for (const groupedFormField of groupedFormFields) {
        for (const formField of groupedFormField[1]) {
          let value: any = auditDataTable[formField.dataColumnName];

          if (value === null || value === undefined) {
            if (formField.controlType === CustomFieldControlType.CheckBox) {
              let extendedProperties: ExtendedProperty[] = formField.options.find(x => x.key === "extendedProperties").value as ExtendedProperty[];

              let threeStatesOption = extendedProperties.find(x => x.key === "ThreeState");

              if (!threeStatesOption?.value) {
                value = false;
              }
            }
          }

          formField.suspendAlertConditionLookup();
          formField.value = value;
          formField.resumeAlertConditionLookup();

          if (formField.controlType === CustomFieldControlType.PictureBox) {
            formField.extendedValues = new Array<FormFieldExtendedValue>();

            let markerAreaStatePropertyName = formField.dataColumnName + "_" + FormField.PictureBoxMarkerAreaStatePropertyName;
            let markerAreaStateValue = auditDataTable[markerAreaStatePropertyName];

            let originalImagePropertyName = formField.dataColumnName + "_" + FormField.PictureBoxOriginalImagePropertyName;
            let originalImageValue = auditDataTable[originalImagePropertyName];

            formField.extendedValues.push(new FormFieldExtendedValue({ key: FormField.PictureBoxMarkerAreaStatePropertyName, value: markerAreaStateValue, dataColumnName: markerAreaStatePropertyName }));
            formField.extendedValues.push(new FormFieldImageExtendedValue({ key: FormField.PictureBoxOriginalImagePropertyName, value: originalImageValue, dataColumnName: originalImagePropertyName }));
          }
        }
      }
    }

    return formFields;
  }

  async loadDynamicTabInstances(referenceTableId: string) {
    let allInstances = (await this.baseRepository.queryAll(
      DynamicTabAuditTemplate.table
    ));

    let list = await allInstances.filter((x) => {
      return (
        x.auditId === this.auditState.audit.id
      );
    })
      .toArray();

    this.dynamicTabInstances = await allInstances.filter((x) => {
      return (
        x.auditId === this.auditState.audit.id && x.referenceTableId == referenceTableId
      );
    })
      .toArray();
  }
}
