import { IFilterExpressionFormatter } from "./iFilterExpressionFormatter";

export class FilterExpression{
  private _function: Function;
  private _filterExpressionFormatters: IFilterExpressionFormatter[];

  constructor(expression: string, filterExpressionFormatters: IFilterExpressionFormatter[]){
    this._filterExpressionFormatters = filterExpressionFormatters;  
    this._function = this.createFunction(expression);  
  }

  private createFunction(expression: string){
    let formattedExpression = expression;

    if (this._filterExpressionFormatters){
      for (const filterExpressionFormatter of this._filterExpressionFormatters) {
        formattedExpression = filterExpressionFormatter.execute(formattedExpression);
      }
    }

    return new Function("x", "return " + formattedExpression + ";")
  }

  /**
  * Returns a list of all items corresponding to the filtered expression.
  */
  public execute(items: any[]): any[]{
    return items.filter(x => {
      return this._function(x)
    });
  }
}