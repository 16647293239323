import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { UserAccount } from 'src/app/core/data/models/database/userAccount.database';
import { UserAccountGroup } from 'src/app/core/data/models/database/userAccountGroup.database';
import { UserAccountRepository } from 'src/app/core/data/repositories/userAccountRepository';
import { UserService } from 'src/app/core/services/userService';
import { GroupState } from '../groupState';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public users: UserAccount[] = new Array<UserAccount>();
  public selectedUsers: UserAccount[] = new Array<UserAccount>();

  @Output() public usersAdded: EventEmitter<Array<UserAccount>> = new EventEmitter<Array<UserAccount>>();
  @ViewChild('popup') public popup: PopupComponent;
  @ViewChild('list') userListComponent: ListComponent;

  constructor(
    private userAccountRepository: UserAccountRepository,
    public groupState: GroupState,
    public userService: UserService) { }

  public async ngOnInit(): Promise<void> {
    if (!this.users.length) {
      this.users = await this.fetchUsers();
    }
    this.userListComponent.updateData();
  }

  public onVisibilityChange(): void {
    if (this.popup.visible) {
      this.userListComponent.updateData();
    }
  }

  public addUsersToGroup(): void {
    this.usersAdded.emit(this.selectedUsers);
    this.users = this.users.filter(user => {
      return !this.selectedUsers.includes(user);
    })
    this.selectedUsers.splice(0);
    this.userListComponent.updateData();
    this.popup.close();
  }

  private async fetchUsers(): Promise<UserAccount[]> {
    let users = await this.userAccountRepository.getUsers();
    let usersAccountGroup = await UserAccountGroup.table.toArray();

    let usersAccountGroupIdFiltered = usersAccountGroup.filter(uag => uag.groupId === this.groupState.group?.id).map(uag => uag.userId);
    let usersFiltered = users.filter((user) => { return !usersAccountGroupIdFiltered.includes(user.id) })

    return usersFiltered;
  }
}
