import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { ComponentModule } from '../components/component.module';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentModule,
    TranslateModule,
    AuthModule.forRoot({
      config: environment.authentication.openId
    })
  ],
  declarations: [
    UnauthorizedComponent,
    LoginComponent,
    LogoutComponent
  ],
  exports: [
    AuthModule
  ],
})
export class AuthenticationAuthorizationModule { }
