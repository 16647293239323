import _ from "lodash";

export class ListDataSourceResult {
  public itemCount: number = 0;
  public groups: any[] = [];
  public groupedItems: object;

  public constructor(args?: Partial<ListDataSourceResult>) {
    Object.assign(this, args);
  }

  public getItemsAsArray() {
    return _.merge(Object.values(this.groupedItems));
  }
}