export class UserService {
  getInitials(username: string): string {
		if (username.indexOf(" ") > 0){
			return username.split(' ').reduce((previous, current, index, array) => {
				return previous += current[0];
			}, '').toUpperCase();
		}
		else
			return username[0].toUpperCase();
  }
}