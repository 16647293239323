// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class ProgramVersion extends ChangeTrackingEntity {
  public static table: Dexie.Table<ProgramVersion, string>;
  public static tableName: string = 'ProgramVersion';

  public number: number;  public createdDate: Date;  public updatedDate: Date;  public id: string;  public programId: string;  public webModel: string;  public timeStamp: Date;

  public constructor(args?: Partial<ProgramVersion>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return ProgramVersion.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(ProgramVersion.tableName);
      this.table.mapToClass(ProgramVersion);
  }
}
