<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-left">
        {{ 'auditList.toolbar.title' | translate }} ({{ dataSourceResult?.itemCount || 0 }})
      </div>
      <div class="layout-right">
        <button [class.active]="filterCount" (click)="showSearch()">
          <i class="fas fa-search"></i>
          <div class="badgeHolder" *ngIf="filterCount">
            <div class="badge">{{filterCount}}</div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="layout-top">
    <app-audit-list-search #searchAudit [(searchOptions)]="searchOptions" [(filterCount)]="filterCount">
    </app-audit-list-search>
  </div>
  <div class="layout-center">
    <app-list #list class="listDefaultStyle" [dataSource]="auditListDataSource" [(dataSourceResult)]="dataSourceResult" [numberOfRequiredItems]="0" 
      (activatedItem)="enter($event)" [enableFilter]="true" [advancedMetadata]="metadata" [canToggleSelection]="true" [numberOfSelectableItem]="1"
      (selectedItemsChange)="updateAuditNumber($event)" [(selectedItems)]="selectedAudits">
      <ng-template #rightOptionalButton>
        <button class="color-set-transparent" [disabled]="!selectedAudits" (click)="openAditionnalOptionsPopup()">
          <i class="fa fa-ellipsis-h"></i>
        </button>
      </ng-template>
      <ng-template #listTemplate let-item>
        <div class="layout-vertical width-100">
          <div class="layout-center">
            <div class="layout-horizontal defaultPadding">
              <div class="layout-left">
                <div>
                  <div class="description">
                    {{item.number}}: {{item.programDescription}}
                  </div>
                  <div class="step">
                    {{ 'auditList.step' | translate }}: {{item.stepName}}, {{ 'auditList.responsible' | translate}}: {{item.responsibleName}}
                  </div>
                  <div class="last-update">
                    {{ 'auditList.lastUpdate' | translate }}: {{ moment(item.updatedDate).format('l LT') }}
                  </div>
                </div>
              </div>
              <div class="layout-right">
                <i *ngIf="!item.synchronized" class="fa-solid fa-cloud-arrow-down"></i>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
</div>

<button class="big round shadow fixed color-set-orange" type="button" (click)="showAdd()">
  <i class="fas fa-plus"></i>
</button>

<app-new-audit #addAudit></app-new-audit>
<app-additional-options #additionalOptions [auditNumber]="auditNumber"></app-additional-options>