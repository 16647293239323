import { CanLeaveEntityAutoSaveResult } from "./canLeaveEntityAutoSaveResult";
import { IEntityAutoSaveGuard } from "./IEntityAutoSaveGuard";

export class DefaultSectionGuard implements IEntityAutoSaveGuard {
    async canLeave(): Promise<CanLeaveEntityAutoSaveResult> {
        return new CanLeaveEntityAutoSaveResult({ success: true });
    }

    saveChanges(): Promise<boolean> {
        return new Promise<boolean>((resolve) => { resolve(true)});
    }

}