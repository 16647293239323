<app-popup [visible]="true" [canClose]="false">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-left" *ngIf="!failed">
          <button>
            <i class="fas fa-sync fa-spin"></i>
            <div><span> {{ 'formtemplate.fill-redirect.title' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-left" *ngIf="failed">
          <button><i class="fas fa-exclamation-triangle"></i>
            <div><span> {{ 'formtemplate.fill-redirect.failedTitle' |
                translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
    <div class="layout-center padding-normal" *ngIf="!failed">
      {{message}}
    </div>
    <div class="layout-center padding-normal" *ngIf="failed">
      {{failedMessage}}
    </div>
    <div class="layout-bottom" *ngIf="failed">
      <div class="layout-horizontal height-normal color-set-white">
        <div class="layout-right">
          <button class="compact color-set-blue" (click)="goToList()">
            <div><span> {{ 'formtemplate.fill-redirect.failedButton' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>