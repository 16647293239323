<div class="layout-vertical">
  <div class="layout-top">
    <app-header-audit (leftButtonClick)="navigateToAuditList()" [auditNumber]="auditState.auditNumber"
      [title]="auditState.auditNumber">
    </app-header-audit>
  </div>

  <div class="layout-center">
    <div *ngFor="let section of sections">
      <div class="section layout-horizontal height-normal color-set-white"
        [class.active]="section.id === auditState.section.id || section.id === auditState.reference"
        [class.validSection]="section.state === sectionStates.Valid"
        [class.invalidSection]="section.state !== sectionStates.Valid" (click)="onSectionClick(section)">
        <div class="layout-left" *ngIf="section.type === sectionTypes.DynamicTab">
          <button>
            <i *ngIf="!section.instancesVisible" class="fas fa-chevron-right"></i>
            <i *ngIf="section.instancesVisible" class="fas fa-chevron-down"></i>
          </button>
        </div>
        <div class="layout-center align-left padding-horizontal-normal">
          <span [class.textOnly]="section.type !== sectionTypes.DynamicTab">{{section.description}}</span>
        </div>
      </div>

      <ng-container *ngIf="section.instancesVisible">
        <div *ngFor="let folder of section.folders" class="indent">
          <div class="layout-horizontal height-normal color-set-white section"
            [class.validSection]="folder.state === sectionStates.Valid"
            [class.invalidSection]="folder.state !== sectionStates.Valid">
            <div class="layout-left" (click)="onDynamicFolderClick(folder)">
              <button>
                <i *ngIf="!folder.instancesVisible && folder.templateId" class="fas fa-chevron-right"></i>
                <i *ngIf="folder.instancesVisible && folder.templateId" class="fas fa-chevron-down"></i>
                <div><span>{{folder.description}}</span></div>
              </button>
            </div>
            <div class="layout-right">
              <button (click)="onAddDynamicTabInstance(folder)"
                [disabled]="folder.inEdition || !folder.canAddInstance()" *ngIf="folder.templateId">
                <i class="fas fa-plus" [ngClass]="{ disabled: folder.inEdition || !folder.canAddInstance() }"></i>
              </button>
            </div>
          </div>

          <div *ngIf="folder.instancesVisible" class="indent">
            <div class="layout-horizontal color-set-white height-normal padding-horizontal-normal section"
              (click)="onDynamicInstanceClick(instance)" *ngFor="let instance of folder.instances"
              [class.validSection]="instance.state === sectionStates.Valid"
              [class.invalidSection]="instance.state !== sectionStates.Valid">
              <div class="layout-center align-left">
                {{ instance.number + ' - ' + instance.description }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-new-dynamic-tab-instance #dynamicTabInstanceView></app-new-dynamic-tab-instance>
