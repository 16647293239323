<app-popup #additionalOptions [boxFixed]="true" [topCentered]="true" [vertical]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal height-normal color-set-blue">
        <div class="layout-left">
          <button (click)="closeAdditionalOptionsPopup()">
            <i class="fa fa-chevron-left"></i>
            <div class="padding-normal">
              {{ "audit.copy.actions" | translate }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <app-list [items]="buttons">
        <ng-template #listTemplate let-item>
          <button class="additional-options" (click)="item.action()">
            <i [class]="item.icon"></i>
            <span [class]="item.textClass">{{ item.text | translate }}</span>
          </button>
        </ng-template>
      </app-list>
    </div>
  </div>
</app-popup>

<app-popup #deleteConfirmation [visible]="false" [canClose]="true" [boxFixed]="true" [vertical]="true">
  <div class="content padding-normal layout-horizontal color-set-blue">
    {{"general.confirmation" | translate}}
  </div>

  <div class="content padding-normal">
    {{"audit.delete.confirmation" | translate}}
  </div>

  <div class="layout-bottom">
    <div class="layout-horizontal color-set-white height-normal padding-horizontal-normal">
      <div class="layout-right">
        <div class="rightPadding">
          <button class="compact color-set-invert-red" (click)="navigateToAuditDelete()">
            <div><span>{{ 'general.delete' | translate }}</span></div>
          </button>
        </div>
        <div>
          <button class="compact color-set-blue" (click)="closeDeleteConfirmationPopup()">
            <div><span>{{ 'general.cancel' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>