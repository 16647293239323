import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { UserHttpClient } from "../../security/userHttpClient";
import { Injectable } from "@angular/core";
import { ValidationDictionary } from "../../validationDictionary";
import { Validation } from "../../validation";

@Injectable({
  providedIn: 'root',
})
export class InitializeUserCommand {
  constructor(
    private userHttpClient: UserHttpClient
  ) { }

  private async canSkip(): Promise<boolean> {
    let userIdParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);

    return userIdParameter && userIdParameter.value;
  }

  public async run() : Promise<ValidationDictionary> {
    let validationDictionary = new ValidationDictionary();

    try {
      if (!await this.canSkip()) {
        let userId = await this.userHttpClient.getUserId();
  
        await LocalParameter.table.put(new LocalParameter({
          name: LocalParameter.USER_ID_PARAMETER_NAME,
          value: userId.toUpperCase()
        }));
      }
    } catch (error) {
      validationDictionary.add(new Validation({key: "InitializeUserCommand", message: error.message}));
    }

    return validationDictionary;
  }
}