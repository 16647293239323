import Dexie from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class TaskFilter extends ChangeTrackingEntity{
	public static table: Dexie.Table<TaskFilter, string>;
  public static tableName: string = 'TaskFilter';
	
	public constructor(args?: Partial<TaskFilter>) {
		super();

    this.optimizeWaypoints = true;

		Object.assign(this, args);
	}

	id: string;
	taskTypeId: string;
  optionListId: string;
	distanceFromFirstTask: number;
	filterFields: string;
  optimizeWaypoints: boolean;

	public getDatabaseTableName() { 
    return TaskFilter.tableName;
  }

  public getDatabasePrimaryKeyType()  { 
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
    this.table = databaseContext.table(TaskFilter.tableName);
    this.table.mapToClass(TaskFilter);
  }

  getTaskTypeOptionListId(){
    return this.taskTypeId + "|" + this.optionListId;
  }

  setTaskTypeOptionListId(value: string){
    let tokens = value.split("|");

    this.taskTypeId = tokens[0];
    this.optionListId = tokens[1];
  }
}