<div class="layout-vertical">
  <div class="layout-top">
    <div>
      <app-synchronization-context id="synchronizationContext"></app-synchronization-context>
    </div>
    <div>
      <app-header></app-header>
    </div>
  </div>
  <div class="layout-center">
    <router-outlet></router-outlet>
  </div>
</div>