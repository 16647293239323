import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SynchronizationContext } from 'src/app/core/data/synchronization/synchronizationContext';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { DocumentHttpClient } from 'src/app/core/services/documents/documentHttpClient';
import { AuditState } from '../auditState';
import { DocumentViewModel } from './documentViewModel';

@Component({
	selector: 'app-audit-documents',
	templateUrl: './audit-documents.component.html',
	styleUrls: ['./audit-documents.component.scss']
})
export class AuditDocumentsComponent implements OnInit {
  navigator: Navigator;

	constructor(
		private readonly auditState: AuditState, 
		private readonly documentHttpClient: DocumentHttpClient,
		private readonly synchronizationContext: SynchronizationContext,
		private readonly authenticatedUser: AuthenticatedUser,
		private readonly translateService: TranslateService) { 

    this.navigator = synchronizationContext.navigator;
  }

	documents: DocumentViewModel[];

	ngOnInit(): void {
		this.documents = this.getDocuments();
	}

	private getDocuments(): DocumentViewModel[] {
		return this.auditState.documents.map(x => new DocumentViewModel({ id: x.id, name: x.name }));
	}
	
	print(documentId: string){
		this.documentHttpClient.print(this.auditState.audit.id, documentId, this.authenticatedUser.id);

		alert(this.translateService.instant("audit.sendDocumentByEmailConfirmation"));
	}

	identify(index, item) {
    return item.id;
  }
}
