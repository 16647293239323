import { Injectable } from "@angular/core";
import { DatabaseContext } from "src/app/core/data/databaseContext";
import { Logger } from "src/app/core/log/logger";
import { CustomFieldValueListViewModel } from "src/app/components/custom-field-value-lists/customFieldValueListViewModel";
import { DataSourceItem } from "../viewModels/dataSourceItem";
import { TranslateService } from "@ngx-translate/core";
import { SynchronizationContext } from "../synchronization/synchronizationContext";

@Injectable({
  providedIn: 'root',
})
export class CustomFieldValueListRepository {
  constructor(
    public databaseContext: DatabaseContext,
    protected logger: Logger,
    protected synchronizationContext: SynchronizationContext,
    private translate: TranslateService) { }

  public getEmailItems(customFieldValueList: CustomFieldValueListViewModel): Array<DataSourceItem<number>> {
    let emailItems = new Array<DataSourceItem<number>>();

    emailItems.push(new DataSourceItem<number>(1, customFieldValueList.attribute1 == null ? this.translate.instant('customFieldValueListPage.attribute1') : customFieldValueList.attribute1));
    emailItems.push(new DataSourceItem<number>(2, customFieldValueList.attribute2 == null ? this.translate.instant('customFieldValueListPage.attribute2') : customFieldValueList.attribute2));
    emailItems.push(new DataSourceItem<number>(3, customFieldValueList.attribute3 == null ? this.translate.instant('customFieldValueListPage.attribute3') : customFieldValueList.attribute3));
    emailItems.push(new DataSourceItem<number>(4, customFieldValueList.attribute4 == null ? this.translate.instant('customFieldValueListPage.attribute4') : customFieldValueList.attribute4));
    emailItems.push(new DataSourceItem<number>(5, customFieldValueList.attribute5 == null ? this.translate.instant('customFieldValueListPage.attribute5') : customFieldValueList.attribute5));

    return emailItems;
  }

  public getDataTypeItems(): Array<DataSourceItem<number>> {
    let dataTypeItems = new Array<DataSourceItem<number>>();

    dataTypeItems.push(new DataSourceItem<number>(16, this.translate.instant('customFieldValueListPage.dataTypeStringText')));
    dataTypeItems.push(new DataSourceItem<number>(11, this.translate.instant('customFieldValueListPage.dataTypeNumericWithoutDecimalsText')));
    dataTypeItems.push(new DataSourceItem<number>(7, this.translate.instant('customFieldValueListPage.dataTypeNumericWithDecimalsText')));

    return dataTypeItems;
  }
}


