import { Component, Input } from '@angular/core';
import { FormField } from "src/app/core/data/models/formField";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-groupbox',
  templateUrl: './groupbox.component.html',
  styleUrls: ['./groupbox.component.scss']
})
export class GroupboxComponent {
  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: FormGroup = new FormGroup({});
}
