<nav class="layout-horizontal height-large color-set-blue">
  <button [routerLink]="['/home']">
    <i class="fas fa-bars"></i>
  </button>
  <div class="layout-center">
    <h1 id="title" *ngIf="title">{{title}}</h1>
  </div>
  <div class="layout-right">
    <button class="round compact color-set-gray margin-right" [routerLink]="['/profile']">
      <i>{{authenticatedUser.initials}}</i>
    </button>
  </div>
</nav>