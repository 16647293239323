import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IEntityAutoSaveGuard } from "./IEntityAutoSaveGuard";

@Injectable()
export class EntityAutoSaveGuard implements CanDeactivate<IEntityAutoSaveGuard> {
	constructor(private translateService: TranslateService){
	}

	async canDeactivate(component: IEntityAutoSaveGuard): Promise<boolean> {
		const result = await component.canLeave();

		if (!result.success) {
			return confirm(result.message + "\r\r " + this.translateService.instant("autoSave.confirmLeavingWithoutSaving"));
		}
		else {
			return await component.saveChanges();
		}
	}
}