export class AuditListSearchOptions {
  public templateIds: string[];
  public assignedToIds: string[];
  public number: string;
  public externalNumber: string;
  public workflowStepNames: string[];
  public fromCreatedDate: Date;
  public toCreatedDate: Date;
  public fromUpdatedDate: Date;
  public toUpdatedDate: Date;
  public synchronizedOnly: boolean = false;

  public constructor(args?: Partial<AuditListSearchOptions>) {
    Object.assign(this, args);
  }
}
