<app-popup #searchForm [boxFixed]="true">
  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left">
          <button><i class="fas fa-search"></i>
            <div><span>{{ 'searchForm.toolbar.title' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="close()"></i></button>
        </div>
      </div>
    </div>
    <div class="layout-center">
      <div class="field-zone">
        <label for="formTemplate">{{ 'searchForm.formTemplate' | translate }}</label>
        <app-dropdown [title]="'searchForm.formTemplate' | translate" [dataSource]="formTemplateDataSource"
          [selectedItems]="formTemplates" [numberOfSelectableItem]="-1">
          <ng-template #dropdownSelectedTemplate let-item>{{ item.displayName }}</ng-template>
          <ng-template #dropdownListTemplate let-item>
            <div class="sidePadding">
              {{ item.displayName }}
            </div>
          </ng-template>
        </app-dropdown>
      </div>
      <div class="field-zone">
        <label for="formTemplate">{{ 'searchForm.workflowSteps' | translate }}</label>
        <app-dropdown [title]="'searchForm.workflowSteps' | translate" [dataSource]="workflowStepDataSource"
          [selectedItems]="workflowStepNames" [numberOfSelectableItem]="-1">
          <ng-template #dropdownSelectedTemplate let-item>{{ item.name }}</ng-template>
          <ng-template #dropdownListTemplate let-item>
            <div class="sidePadding">
              {{ item.name }}
            </div>
          </ng-template>
        </app-dropdown>
      </div>
      <div class="field-zone">
        <label for="auditNumber">{{ 'searchForm.formNumber' | translate }}</label>
        <div class="layout-horizontal noPadding">
          <div class="layout-center fill">
            <input type="text" class="basic" name="auditNumber" placeholder="ABC-MX-XXXX"
              [(ngModel)]="searchOptions.number">
          </div>
        </div>
      </div>
      <div class="field-zone">
        <label for="externalNumber">{{ 'searchForm.externalNumber' | translate }}</label>
        <input type="text" class="basic" name="externalNumber" placeholder="EXT0001"
          [(ngModel)]="searchOptions.externalNumber">
      </div>
      <div class="field-zone">
        <label for="responsible">{{ 'searchForm.assignedTo' | translate }}</label>
        <app-dropdown [title]="'searchForm.assignedTo' | translate" [dataSource]="assignedToDataSource"
          [selectedItems]="assignedToUsers" [numberOfSelectableItem]="-1">
          <ng-template #dropdownSelectedTemplate let-item>{{ item.name }}</ng-template>
          <ng-template #dropdownListTemplate let-item>
            <span class="initials">{{item.initials}}</span> {{ item.name }}
          </ng-template>
        </app-dropdown>
      </div>
      <div class="field-zone">
        <label for="fromCreatedDate">{{ 'searchForm.fromCreatedDate' | translate }}</label>
        <dx-date-box type="datetime" [(value)]="searchOptions.fromCreatedDate"></dx-date-box>
      </div>
      <div class="field-zone">
        <label for="toCreatedDate">{{ 'searchForm.toCreatedDate' | translate }}</label>
        <dx-date-box type="datetime" [(value)]="searchOptions.toCreatedDate"></dx-date-box>
      </div>
      <div class="field-zone">
        <label for="fromUpdatedDate">{{ 'searchForm.fromUpdatedDate' | translate }}</label>
        <dx-date-box type="datetime" [(value)]="searchOptions.fromUpdatedDate"></dx-date-box>
      </div>
      <div class="field-zone">
        <label for="toUpdatedDate">{{ 'searchForm.toUpdatedDate' | translate }}</label>
        <dx-date-box type="datetime" [(value)]="searchOptions.toUpdatedDate"></dx-date-box>
      </div>
      <div class="field-zone">
        <label for="hasSubscription">{{ 'searchForm.subscription' | translate }}</label>
        <div>
          <dx-check-box [(value)]="searchOptions.synchronizedOnly"></dx-check-box> {{ 'searchForm.subscriptionOnly' |
          translate }}
        </div>
      </div>
    </div>
    <div class="layout-bottom">
      <div class="layout-horizontal padding-vertical-compact padding-horizontal-normal color-set-white">
        <div class="layout-right space">
          <button id="clearButton" class="color-set-green compact" (click)="clear()">
            <div><span>{{ 'searchForm.clear' | translate }}</span></div>
          </button>
          <button id="searchButton" class="color-set-green compact" (click)="search()">
            <div><span>{{ 'searchForm.search' | translate }}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>