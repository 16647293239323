export enum SectionType {
    Undefined = 0,
    CustomFields = 2,
    Photo = 6,
    Header = 7,
    Remark = 8,
    AlertSummary = 9,
    Attachment = 10,
    DynamicTab = 11,
    AlertTemplate = 12,
		Documents = 13
}