import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListDataSourceResult } from './listDatasourceResult';
import { ListOptionComponent } from './list-option/list-option.component';
import { ListDataSourceFunctionResult } from './listDatasourceFunctionResult';
import { ListOptions } from './listOptions';
import { template } from 'lodash';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @Input() public items: any[];

  @Input() public dataSource: (context: ListComponent) => ListDataSourceFunctionResult;
  @Input() public dataSourceResult: ListDataSourceResult;
  @Input() public showCheckBoxes: boolean = true;
  @Output() public dataSourceResultChange = new EventEmitter<ListDataSourceResult>();

  @Input() public canToggleSelection: boolean = false;

  @Input() public showInstructions: boolean = true;

  @Input() public displayLoading: boolean = true;

  @Input() public listOptions: ListOptions = new ListOptions();

  public static templatePropertyField = "nspek-ef119f87275e41b2bc925dff7d0d78b4"

  public static noGroupTypeField = 'none'

  public templateProperty = ListComponent.templatePropertyField;

  private _selectionEnabled: boolean = false;

  @Input() public selectionEnabled : boolean;
  @Input() public numberOfRequiredItems: number = 0;
  @Input() public numberOfSelectableItem: number = 1;
  @Input() public selectedItems: any[] | any;
  @Output() selectedItemsChange = new EventEmitter<any>();
  @Output() activatedItem = new EventEmitter<any>();

  @Input() public hoverable: boolean = true;
  @Input() public enableFilter: boolean = false;
  @Input() public filter: string = '';
  @Input() public showHeader: boolean = true;
  @Input() public highLightSelectedItems: boolean = true;
  @Output() public filterChange = new EventEmitter<number>();

  @Input() public paging: boolean = true;
  @Input() public page: number = 1;
  @Output() public pageChange = new EventEmitter<number>();

  public pageSize: number = environment.numberOfItemPerPage;
  public isLoading: boolean = false;

  @ViewChild('inputFilter') inputFilter: TemplateRef<any>;
  @ViewChild('inputFilter', { read: ElementRef }) inputFilterElement: ElementRef;

  @Input() public advancedMetadata;
  @ViewChild('listOption') listOption: ListOptionComponent;

  @ContentChild('listTemplate') listTemplate: TemplateRef<any>;
  @ContentChild('listGroupTemplate') listGroupTemplate: TemplateRef<any>;
  @ContentChild('rightOptionalButton') rightOptionalButton: TemplateRef<any>;

  private filterDebounceTimeout: Subscription;

  private originalSelectionEnabled: boolean;

  constructor() { }

  public async ngOnInit(): Promise<void> {
    await this.updateData();

    this.originalSelectionEnabled = this.selectionEnabled;
  }

  public activateItem(item) {
    if (!this.selectionEnabled) {
      this.activatedItem.emit(item);
    } else if (this.numberOfSelectableItem === 1) {
      if (item !== this.selectedItems) {
        this.selectedItems = item;
        this.selectedItemsChange.emit(item);
      } else {
        this.selectedItems = null;
        this.selectedItemsChange.emit(null);
        this.selectionEnabled = false;
      }
    } else if (this.numberOfSelectableItem !== 1) {
      let index = _.findIndex(this.selectedItems, item);

      if (this.selectedItems.length < this.numberOfSelectableItem || this.numberOfSelectableItem === -1) {
        if (index === -1) {
          this.selectedItems.push(item);
        } else {
          this.selectedItems.splice(index, 1);
        }
      }

      if (this.selectedItems.length === 0) {
        this.selectionEnabled = false;
      }

      this.selectedItemsChange.emit(this.selectedItems);
    }
  }

  public async updateData() {
    if (this.displayLoading)
      this.isLoading = true;

    let dataSourceFunctionResult: ListDataSourceFunctionResult;

    if (this.originalSelectionEnabled != undefined)
      this.selectionEnabled = this.originalSelectionEnabled;

    if (this.dataSource) {
      dataSourceFunctionResult = await this.dataSource(this);
    } else if (this.items) {
      dataSourceFunctionResult = new ListDataSourceFunctionResult({
        itemCount: this.items.length,
        items: this.items
      });
    }

    this.dataSourceResult = new ListDataSourceResult({
      itemCount: dataSourceFunctionResult.itemCount
    });

    if (this.dataSourceResult.itemCount > 0) {
      let { sortByProperties, sortByOrder, groupByProperties } = this.prepareSortGroupProperties();

      let sortedItems: object = this.sortItems(dataSourceFunctionResult.items, sortByProperties, sortByOrder);

      // Once the entire array is sorted, we can get the required number of item
      // according to the configuration, from that point on, we work with a subset
      // of the data.
      let pagedItems = this.keepCurrentPageItems(sortedItems);

      // Add custom properties to items as defined by the list
      // option mapItemFunction.
      let mappedPagedItems = this.addCustomItemProperties(pagedItems);

      // Group remaining items into an object which each key represent the group and the
      // value the array of items.
      this.setGroups(groupByProperties, mappedPagedItems);

      // Add custom properties to items as defined by the list
      // option mapItemFunction.
      this.addCustomGroupProperties();

      //console.log(this.dataSourceResult);
    }

    this.dataSourceResultChange.emit(this.dataSourceResult);

    if (this.displayLoading) {
      this.isLoading = false;
    }
  }

  private addCustomItemProperties(pagedItems: any[]) {
    if (this.listOptions?.mapItemFunction) {
      let mapItemFunction = new Function("item", "properties", this.listOptions?.mapItemFunction);

      pagedItems = pagedItems.map((item) => {
        return mapItemFunction(item, { template: ListComponent.templatePropertyField });;
      });
    }
    return pagedItems;
  }

  private addCustomGroupProperties() {
    if (this.listOptions?.mapGroupFunction) {
      let mapGroupFunction = new Function("group", "properties", this.listOptions?.mapGroupFunction);

      this.dataSourceResult.groups = this.dataSourceResult.groups.map((group) => {
        return mapGroupFunction(group, { template: ListComponent.templatePropertyField });
      });
    }
  }

  private sortItems(items: any[], sortByProperties: any[], sortByOrder: any[]) {
    let sortedItems: object;
    if (sortByProperties && sortByProperties.length > 0) {
      sortedItems = _.orderBy(items, sortByProperties, sortByOrder);
    } else {
      sortedItems = items;
    }
    return sortedItems;
  }

  public toggleItemSelection(event, item) {
    if (this.canToggleSelection) {
      this.selectionEnabled = true;
    }

    this.activateItem(item);

    if (this.canToggleSelection) {
      this.selectionEnabled = this.numberOfSelectableItem === 1 ? !!this.selectedItems : this.selectedItems.length > 0;
    }

    // this.activateItem() is already called here, so we don't need to call it again
    // while the click event is still bubbling up the DOM tree.
    event.stopPropagation();
  }

  public disableSelection() {
    this.selectedItems = [];
    this.selectedItemsChange.emit(this.selectedItems);

    if (this.canToggleSelection)
      this.selectionEnabled = false;
  }

  public isSelectedItem(item) {
    if (this.numberOfSelectableItem === 1) {
      return this.selectedItems === item;
    } else {
      return _.findIndex(this.selectedItems, item) !== -1
    }
  }

  private prepareSortGroupProperties() {
    let orderedGroupBy = _.sortBy(this.listOptions.groupBy, "position");
    let orderedSortBy = _.sortBy(this.listOptions.sortBy, "position");

    let groupByProperties = [];
    let sortByProperties = [];
    let sortByOrder = [];

    // Because lodash orderBy require both the properties and corresponding order
    // in two different arrays in which the index determine de corelation, we build
    // those arrays from the ordered position.
    // Sort by must include group by properties as it must implicitly be sorted
    // and avoid user to define the properties in both in general most usecase.
    // Additionnals properties could be added to support cases where we don't want
    // to sort by group first.
    for (let item of orderedGroupBy) {
      groupByProperties.push(item.property);
      sortByProperties.push(item.property);
      sortByOrder.push(item.order);
    }

    for (let item of orderedSortBy) {
      sortByProperties.push(item.property);
      sortByOrder.push(item.order);
    }
    return { sortByProperties, sortByOrder, groupByProperties };
  }

  private keepCurrentPageItems(sortedItems: any): any[] {
    let pagedItems = this.getPagedItems(sortedItems, this.page, this.pageSize);

    // Add a property on every object that define the type of the object. In this
    // case an item which is used in the HTML template to display correctly each item.
    // The property is basically a constant guid to avoid any conflict with a dynamic
    // property when originating from user generated content.
    pagedItems = pagedItems.map((item) => {
      return item;
    });

    return pagedItems;
  }

  private getPagedItems(items, page, pageSize) {
    if (!this.paging) {
      return items;
    }

    let startIndex = (page - 1) * pageSize;
    let endIndex = startIndex + pageSize;

    return items.slice(startIndex, endIndex);
  }

  private setGroups(groupByProperties: any[], pagedItems: any) {
    let groupedItems = {};
    if (groupByProperties && groupByProperties.length > 0) {
      groupedItems = _.groupBy(pagedItems, (object) => {
        return _.reduce(groupByProperties, (groupKey, property) => groupKey += '.' + object[property], '');
      });
    } else {
      groupedItems[ListComponent.noGroupTypeField] = pagedItems;
    }


    this.dataSourceResult.groupedItems = groupedItems;

    // Build  groups for reference in HTML with the values
    // of the first item which will contain all groupped properties
    // that could be displayed. We create a clone so the original item
    // which is also referenced in the groupedItems in not modified and
    // add the itemTypeProperty to groupType be able to make a conditionnal case
    // in the HTML.
    this.dataSourceResult.groups = Object.keys(groupedItems).map((groupKey) => {
      let properties = _.clone(groupedItems[groupKey][0]);

      let group = {
        key: groupKey,
        visible: true,
        properties: properties
      };

      return group;
    });
  }

  public filterChanged() {
    if (this.filterDebounceTimeout) {
      this.filterDebounceTimeout.unsubscribe();
    }

    this.filterDebounceTimeout = timer(environment.defaultDebounceMilliseconds).subscribe(async () => {
      await this.updateData();
      this.filterDebounceTimeout.unsubscribe();
    });
  }
}
