<app-popup #popup (visibleChange)="onVisibilityChange()" [canClose]="true" [boxFixed]="true">
  

  <div class="layout-vertical">
    <div class="layout-top">
      <div class="layout-horizontal color-set-blue height-normal">
        <div class="layout-left padding-left">
          {{ 'groups.addUser' | translate}}
        </div>

        <div class="layout-right">
          <button (click)="popup.close()"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
    
    <div class="layout-center">
      <app-list #list class="listDefaultStyle" [items]="users" [numberOfSelectableItem]="-1" [numberOfRequiredItems]="0" 
        [(selectedItems)]="selectedUsers" [hoverable]="true" [selectionEnabled]="true">
        <ng-template #listTemplate let-item>
          <div class="layout-horizontal defaultPadding">
            <div class="layout-left">
              <button class="round compact color-set-gray margin-right">
                <i>{{userService.getInitials(item.name)}}</i>
              </button>
              <div>
                <div class="name font-bold">
                  {{item.name}}
                </div>
                <div class="title">
                  {{item.title}}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal color-set-white padding-compact">
        <div class="layout-right">
          <button name="createButton" [class.color-set-green]="selectedUsers.length" [disabled]="!selectedUsers.length" (click)="addUsersToGroup()">
            <div>
              <span>{{ 'groups.add' | translate}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>