import { Component, OnInit } from '@angular/core';
import { HealthHttpClient } from 'src/app/core/security/healthHttpClient';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {

  public healthChecks: any[] = [];
  public loading = false;

  constructor(
    private healthHttpClient: HealthHttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    let result;
    try {
      result = await this.healthHttpClient.getHealth();
    } catch (error) {
      result = error.error;
    }

    for (let key in result.entries) {
      this.healthChecks.push({
        name: key,
        description: result.entries[key].description,
        status: result.entries[key].status,
        info: result.entries[key].data?.Content,
      });
    }

    this.loading = false;
  }
}