import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private adminCompressionLevel: number
  // Parameter set by the user in the PictureBox, can be null
  private userCompressionLevel: number;

  public ConvertImageToJpegAndCompress(imageAsDataUrl: string, pictureBoxUpdate: Function) {
    let img = new Image;
    img.onload = (e: Event) => {
      let c = document.createElement("canvas")  
      let ctx = c.getContext("2d");
      c.width = img.width;                      
      c.height = img.height;
      ctx.drawImage(img, 0, 0)
      let imageAsDataUrl = c.toDataURL("image/jpeg", this.getCompressionLevel());
      pictureBoxUpdate(imageAsDataUrl);
    };
    img.src = imageAsDataUrl
  }

  public setUserCompressionLevel(newLevel: number): void {
    // the image quality level set by the user with a slider between 1 and 100
    this.userCompressionLevel = (newLevel / 100);
  }

  public setAdminCompressionLevel(newLevel: number): void {
    // The compression level from Nspek admin (set in Nspek windows)
    this.adminCompressionLevel = 1 - (newLevel / 100);
  }

  private getCompressionLevel(): number {
    return this.getUserCompressionLevel() || this.getAdminCompressionLevel() || this.getCompanyCompressionLevel();
  }

  private getCompanyCompressionLevel(): number {
    return environment.compressionLevel;
  }

  private getAdminCompressionLevel(): number {
    return this.adminCompressionLevel;
  }

  public getUserCompressionLevel(): number {
    return this.userCompressionLevel;
  }
}
