// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DocumentCheckboxProperty extends ChangeTrackingEntity {
  public static table: Dexie.Table<DocumentCheckboxProperty, string>;
  public static tableName: string = 'DocumentCheckboxProperty';

  public name: string;  public isDefault: boolean;  public trueType: number;  public falseType: number;  public undefinedType: number;  public trueValueAsString: string;  public falseValueAsString: string;  public undefinedValueAsString: string;  public trueValueAsImage: string;  public falseValueAsImage: string;  public undefinedValueAsImage: string;  public keyIdentifier: string;  public id: string;  public isDeleted: boolean;  public timeStamp: Date;

  public constructor(args?: Partial<DocumentCheckboxProperty>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DocumentCheckboxProperty.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DocumentCheckboxProperty.tableName);
      this.table.mapToClass(DocumentCheckboxProperty);
  }
}
