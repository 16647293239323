import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';


@Injectable()
export class NavigatorGuard implements CanActivateChild {
  locks: any = {};

  constructor() {
    window.addEventListener('beforeunload', (event) => {
      if (this.hasRoutingLocks()) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  canActivateChild() {
    if (this.hasRoutingLocks()) {
      if (confirm('Leave site?')) {
        this.removeAllRoutingLocks();
        return true;
      }
      return false;
    }
    return true;
  }

  setRoutingLock(key: string) {
    this.locks[key] = true;
  }

  removeRoutingLock(key: string) {
    delete this.locks[key];
  }

  removeAllRoutingLocks() {
    this.locks = {};
  }

  hasRoutingLocks(): boolean {
    return !!Object.keys(this.locks).length;
  }
}