import { UserAccountRepository } from "src/app/core/data/repositories/userAccountRepository";
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";
import { UserAccountMapper } from "src/app/pages/audit/userAccountMapper";
import { Injectable } from "@angular/core";
import { AuditRepository } from "src/app/core/data/repositories/auditRepository";
import { Audit } from "src/app/core/data/models/database/audit.database";
import { CreateAuditResult } from "src/app/pages/audit/createAuditResult";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Program } from "src/app/core/data/models/database/program.database";
import { AuditNumber } from "src/app/core/data/models/database/auditNumber.database";
import { SharedParameter } from "src/app/core/data/models/database/sharedParameter.database";
import { Form } from "src/app/core/data/models/form/form";
import { AuditDataTable } from "src/app/core/data/models/database/auditDataTable.database";
import { FormService } from "./formService";
import { ArrayUtility } from "src/app/core/arrayUtility";
import { Control } from "src/app/core/data/models/form/control";
import { ProgramVersionRepository } from "src/app/core/data/repositories/programVersionRespository";
import { environment } from "src/environments/environment";
import { Section } from "src/app/core/data/models/form/section";
import { FormGroup } from "@angular/forms";
import { UserAudit } from "src/app/core/data/models/databaseLocal/userAudit.database";
import { SectionState } from "./audit-sections/sectionState";
import { DynamicTabTemplate } from "src/app/core/data/models/database/dynamicTabTemplate.database";
import { DynamicTabAuditTemplate } from "src/app/core/data/models/database/dynamicTabAuditTemplate.database";
import Dexie from "dexie";
import { DynamicTabStructureItem } from "src/app/core/data/models/database/dynamicTabStructureItem.database";
import { AuditSectionState } from "src/app/core/data/viewModels/auditSectionState";
import { UserAccount } from "src/app/core/data/models/database/userAccount.database";
import { WFStep } from "src/app/core/data/models/database/wFStep.database";
import { Mobile } from "src/app/core/data/models/database/mobile.database";
import { AuditSubscription } from "src/app/core/data/models/database/auditSubscription.database";
import { MobileAudit } from "src/app/core/data/models/database/mobileAudit.database";
import { DynamicTabFolderViewModel } from "./audit-sections/dynamicTabFolderViewModel";
import { CreateDynamicTabInstanceResult } from "./createDynamicTabInstanceResult";
import { DynamicSection } from "src/app/core/data/models/form/dynamicSection";
import { DynamicInstance } from "src/app/core/data/models/form/dynamicInstance";
import { AutomaticAuditToExport } from "src/app/core/data/models/database/automaticAuditToExport.database";
import { AuditAlertSummary } from "src/app/core/data/models/database/auditAlertSummary.database";
import { SynchronizationContext } from "src/app/core/data/synchronization/synchronizationContext";
import { AuditAdditionalInfo } from "src/app/core/data/models/database/auditAdditionalInfo.database";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { TemplateSection } from "src/app/core/data/models/form/templateSection";

@Injectable()
export class AuditService {
  constructor(
    private userAccountRepository: UserAccountRepository,
    private auditRepository: AuditRepository,
    private authenticatedUser: AuthenticatedUser,
    private programVersionRepository: ProgramVersionRepository,
    private baseRepository: BaseRepository,
    private formService: FormService,
    private synchronizationContext: SynchronizationContext
  ) { }

  public async getUsers(): Promise<DataSourceItem<string>[]> {
    const users = await this.userAccountRepository.getUsers();

    return new UserAccountMapper().mapToDataSourceItems(users);
  }

  public async getAuditByNumber(number: string): Promise<Audit> {
    return await this.auditRepository.getByNumber(number);
  }

  public async saveAudit(audit: Audit): Promise<unknown> {
    await this.updateAuditHeader(audit);
    await this.auditRepository.save(Audit.table, audit);
		await this.addAutomaticAuditToExportToQueue(audit);

		return;
  }

  public async createAudit(programId: string, externalNumber: string = null, additionalDataInfos: any[] = null): Promise<CreateAuditResult> {
    const result = new CreateAuditResult();

    const audit = new Audit();

    const program = await this.baseRepository.get<Program>(
      Program.table,
      programId
    );

    let form;

    if (environment.databaseScenario === "synchronization") {
      let programVersion = await this.programVersionRepository.getLatestProgramVersion(programId);
      form = JSON.parse(programVersion.webModel) as Form;
    } else {
      form = this.formService.getFormByAudit(programId);
    }

    let defaultStepId = await this.getDefaultStepId(form);

    audit.createdById = this.authenticatedUser.id;
    audit.number = await this.getNextNumber(program);
    audit.externalNumber = externalNumber;
    audit.programId = programId;
    audit.stepId = defaultStepId;
    audit.responsibleId = this.authenticatedUser.id;
    audit.createdDate = new Date(Date.now());
    audit.effectiveDate = new Date(Date.now());
    audit.updatedDate = new Date(Date.now());

    try {
      let savedAudit = await this.baseRepository.insert<Audit>(Audit.table, audit) as Audit;

      await this.addSubscription(savedAudit.id);
      await this.addMobileAudit(savedAudit.id);
      await this.setDefaultValues(savedAudit, form);
			await this.addAutomaticAuditToExportToQueue(savedAudit);

      if (additionalDataInfos)
        await this.addAdditionalInfos(savedAudit.id, additionalDataInfos);

      result.audit = audit;
      result.success = true;

      await this.createAuditHeader(audit);
    } catch (e) {
      result.success = false;
      result.message = e.message;
    }

    return result;
  }

  async addAdditionalInfos(auditId: string, additionalDataInfos: any[]) {
    for (const additionalDataInfo of additionalDataInfos) {
      await this.baseRepository.insert(AuditAdditionalInfo.table,
        new AuditAdditionalInfo(
          {
            propertyName: additionalDataInfo.name.substring(0, 50),
            value: additionalDataInfo.value.substring(0, 250),
            auditId: auditId
          }));
    }
  }

	public async addAutomaticAuditToExportToQueue(audit: Audit){
		let automaticAuditToExport = new AutomaticAuditToExport();

		automaticAuditToExport.auditId = audit.id;
		automaticAuditToExport.timeStamp = new Date(Date.now());

		return await this.baseRepository.insert<AutomaticAuditToExport>(AutomaticAuditToExport.table, automaticAuditToExport);
	}

  public async setDefaultValues(audit: Audit, form: Form) {
    let auditDataTables = new Array<AuditDataTable>();

    for (const section of form.sections) {
      if (!section.controls) continue;

      let flattenControls: Array<Control> = Section.getflatControls(section.controls);

      let groupedControls: Map<string, Control[]> = ArrayUtility.groupBy(
        flattenControls,
        (x) => x.dataTableName
      );

      for (const groupedControl of groupedControls) {
        let auditDataTable = new AuditDataTable();

        let key = groupedControl[0];
        let controls = groupedControl[1];

        auditDataTable.tableName = key;
        auditDataTable.auditId = audit.id;
        auditDataTable.tableId = Dexie.Observable.createUUID();
        auditDataTable.id = auditDataTable.tableId;

        this.setSectionDefaultValues(controls, auditDataTable);

        auditDataTables.push(auditDataTable);
      }
    }

    await this.baseRepository.insert<AuditDataTable>(
      AuditDataTable.table,
      auditDataTables
    );
  }

  /**
  * Set a default value for each configured control this way.
  */
  setSectionDefaultValues(controls: Control[], auditDataTable: AuditDataTable) {
    let state: SectionState = SectionState.Valid;

    for (const control of controls) {
      let value: any = Control.getExtendedProperty(control, "DefaultValue");

      if (value !== null && value !== undefined)
        auditDataTable[control.dataColumnName] = value;

      if (control.required) {
        if (!auditDataTable[control.dataColumnName]) {
          state = SectionState.EmptyRequiredFields;
        }
      }
    }

    auditDataTable["State"] = state;
  }

  // This method is only intended to be used when creating a new audit.
  private async addSubscription(auditId: string) {
    let subscriptionStartDate = new Date();
    let subscriptionEndDate = new Date();

    subscriptionEndDate.setDate(subscriptionStartDate.getDate() + environment.auditSubscriptionDays);

    let auditSubcriptions = await this.baseRepository.getAll(AuditSubscription.table)

    let localExistingSubscription = auditSubcriptions.filter((auditSubcription) => {
      return auditSubcription.auditId === auditId && auditSubcription.userAccountId === this.authenticatedUser.id;
    });

    if (localExistingSubscription.length > 0) {
      await this.baseRepository.update(AuditSubscription.table, new AuditSubscription({
        id: localExistingSubscription[0].id,
        auditId: auditId,
        userAccountId: this.authenticatedUser.id,
        startDate: subscriptionStartDate,
        endDate: subscriptionEndDate
      }));
    } else {
      await this.baseRepository.insert(AuditSubscription.table, new AuditSubscription({
        auditId: auditId,
        userAccountId: this.authenticatedUser.id,
        startDate: subscriptionStartDate,
        endDate: subscriptionEndDate
      }));
    }
  }

  public async addMobileAudit(auditId: string) {
    await this.baseRepository.insert(MobileAudit.table, new MobileAudit({
      mobileId: this.synchronizationContext.mobileId,
      auditId: auditId,
      timeStamp: new Date()
    }));
  }

  private async getDefaultStepId(form: Form) {
    return form.steps.sort(x => x.number)[0].id;
  }

  public async updateAuditHeader(audit: Audit) {
    let userAudit = await UserAudit.table.get(audit.id);

    await this.mapAuditHeaderFields(userAudit, audit);

    // The baseRepository should not be used in this case because this table
    // is not in the ChangeTracking process. Put will insert or update the record
    // in the IndexedDB database without propagating the data to the server through
    // the synchronization process.
    // This does not need to be tracked through changeTracking
    // since its a table only meant to represent all existing audits
    // and is refreshed every synchronization from the server. Updating
    // the information is required to be able to interact correctly
    // with the data, mostly to search form, until it is refresh again
    //  next synchronzation.
    await UserAudit.table.put(userAudit);
  }

  public async createAuditHeader(audit: Audit) {
    const userAudit = new UserAudit();

    await this.mapAuditHeaderFields(userAudit, audit);

    userAudit.id = audit.id;
    userAudit.number = audit.number;
    userAudit.createdDate = new Date();
    userAudit.synchronized = true;

    await this.baseRepository.insert(UserAudit.table, userAudit);
  }

  private async mapAuditHeaderFields(userAudit: UserAudit, audit: Audit) {
    let program: Program = await this.baseRepository.get(Program.table, audit.programId);
    let responsible: UserAccount = await this.baseRepository.get(UserAccount.table, audit.responsibleId);
    let step: WFStep = await this.baseRepository.get(WFStep.table, audit.stepId);

    userAudit.updatedDate = new Date();
    userAudit.externalNumber = audit.externalNumber;
    userAudit.responsibleId = audit.responsibleId;
    userAudit.responsibleName = responsible.name;
    userAudit.programId = program.id;
    userAudit.programDescription = program.description;
    userAudit.stepId = audit.stepId;
    userAudit.stepName = step.name;
  }

  private async getMobilePrefix(): Promise<string> {
    let parameter: SharedParameter = await (
      await this.baseRepository.queryAll<SharedParameter>(SharedParameter.table)
    )
      .filter((x) => {
        return x.name === "MobileAuditNumberPrefix";
      })
      .toArray()[0];

    let mobilePrefixNumber = parameter ? parameter.valueAsString : "M";

    let mobile: Mobile = await this.baseRepository.get(Mobile.table, this.synchronizationContext.mobileId);

    return mobilePrefixNumber + mobile.number;
  }

  public async getNextNumber(program: Program): Promise<string> {
    let result: string = "";

    const nextNumber = await this.reserveNext(program.id);

    if (program.numberPrefix) result = program.numberPrefix + "-";

    result += (await this.getMobilePrefix()) + "-";

    if (program.numberPadding)
      result += nextNumber.toString().padStart(program.numberPadding, "0");
    else result += nextNumber;

    return result;
  }

  private async reserveNext(programId: string): Promise<number> {
    const auditNumbers = await this.baseRepository.queryAll<AuditNumber>(
      AuditNumber.table
    );

    let auditNumber = await auditNumbers
      .filter((x) => {
        return x.programId === programId;
      })
      .first();

    if (auditNumber == null) {
      auditNumber = new AuditNumber();
      auditNumber.programId = programId;
      auditNumber.next = 1;

      await this.baseRepository.insert(AuditNumber.table, auditNumber);
    }

    const result = auditNumber.next;

    auditNumber.next += 1;

    await this.baseRepository.update(AuditNumber.table, auditNumber);

    return result;
  }

  public getInvalidControls(form: FormGroup) {
    const invalid = [];
    const controls = form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }

    }
    return invalid;
  }

  async validateSectionRequiredFields(audit: Audit, form: Form, section: Section): Promise<boolean> {
    let auditDataTables = await (
      await this.baseRepository.queryAll<AuditDataTable>(
        AuditDataTable.table
      )
    )
      .filter((x) => {
        return (
          x.auditId == audit.id &&
          x.tableName == section.controls[0].dataTableName
        );
      }).toArray();

    for (const auditDataTable of auditDataTables) {
      if (!await this.validateControlRequiredFields(section.controls, auditDataTable))
        return false;
    }

    return true;
  }

  async validateControlRequiredFields(controls: Control[], auditDataTable: AuditDataTable) {
    for (const control of controls) {
      if (control.required) {
        let property = auditDataTable[control.dataColumnName];

        if (!property)
          return false;
      }

      if (control.children) {
        if (!await this.validateControlRequiredFields(control.children, auditDataTable)) {
          return false;
        }
      }
    }

    return true;
  }

  async validateRequiredFields(audit: Audit, form: Form): Promise<boolean> {
    for (const section of form.sections) {
      if (section.type == SectionType.CustomFields){
        if (section.controls && section.controls.length > 0) {
          if (! await this.validateSectionRequiredFields(audit, form, section))
            return false;
        }
      }
      else if (section.type == SectionType.DynamicTab){
        let dynamicSection: DynamicSection = section as DynamicSection;

        for (const template of dynamicSection.templates) {
          if (template.controls && template.controls.length > 0) {
            if (! await this.validateSectionRequiredFields(audit, form, template))
              return false;
          }
        }
      }
    }

    for (const alertTemplate of form.alertTemplates) {
      if (alertTemplate.controls && alertTemplate.controls.length > 0) {
        if (! await this.validateSectionRequiredFields(audit, form, alertTemplate))
          return false;
      }
    }

    return true;
  }

  /**
  * Remove the existing audit data table related information and dynamic tab audit template instance.
  */
  async removeDynamicTabAuditTemplate(customTableId: string, auditId: string) {
    const auditDataTableItems = await (await this.baseRepository.queryAll(AuditDataTable.table)).filter(x => x.tableId === customTableId).toArray();

    if (auditDataTableItems.length > 0)
      await this.baseRepository.delete(AuditDataTable.table, auditDataTableItems[0]);

    let dynamicTabAuditTemplateInstances = await (await this.baseRepository.queryAll(DynamicTabAuditTemplate.table)).where("auditId").equals(auditId).filter(x => x.customTableId == customTableId).toArray()

    if (dynamicTabAuditTemplateInstances.length > 0) {
      await this.baseRepository.delete(DynamicTabAuditTemplate.table, dynamicTabAuditTemplateInstances[0]);
    }
  }

  async deleteDynamicTabAuditTemplate(auditId: string, instanceId: string) {
    let instance = await this.baseRepository.get(DynamicTabAuditTemplate.table, instanceId);

    let auditDataTable = await (await this.baseRepository.queryAll(AuditDataTable.table)).filter(x => x.tableId === instance.customTableId).first();

    await this.baseRepository.delete(AuditDataTable.table, auditDataTable);
    await this.baseRepository.delete(DynamicTabAuditTemplate.table, instance);

    let auditAlerts = await AuditAlertSummary.table.where("auditId").equals(auditId).toArray();

    for (const auditAlert of auditAlerts) {
      let tokens = auditAlert.controlKey.split(".");

      if (tokens.length === 3){
        if (tokens[2] === auditDataTable.tableId)
          this.baseRepository.delete(AuditAlertSummary.table, auditAlert);
      }
    }
  }

  /**
  * Create a new instance of dynamic tab template with an instance of the related custom field table.
  */
  async addDynamicTabAuditTemplate(auditId: string, section: Section, structureItemId: string, referenceTableId: string, number?: string, description?: string, position?: number): Promise<CreateDynamicTabInstanceResult> {
    let structureItem = await this.baseRepository.get(DynamicTabStructureItem.table, structureItemId);
    let auditDataTable = new AuditDataTable();

    auditDataTable.tableName = section.dataTableName;
    auditDataTable.tableId = Dexie.Observable.createUUID();
    auditDataTable.id = auditDataTable.tableId;
    auditDataTable.auditId = auditId;

    this.setSectionDefaultValues(Section.getflatControls(section.controls), auditDataTable);

    await this.baseRepository.insert(
      AuditDataTable.table,
      auditDataTable
    );

    let instance = new DynamicTabAuditTemplate();

    instance.auditId = auditId;
    instance.dynamicTabStructureItem = structureItem.keyIdentifier;
    instance.customTableId = auditDataTable.tableId;
    instance.number = number;
    instance.description = description;
    instance.position = position || 0;

    // When the dynamic tab instance is to hold data from alert template.
    if (referenceTableId) {
      instance.referenceTableId = referenceTableId;
    }

    await this.baseRepository.insert(
      DynamicTabAuditTemplate.table,
      instance);

    return new CreateDynamicTabInstanceResult({ auditDataTable: auditDataTable, dynamicTabAuditTemplate: instance });
  }

  public static createAuditSectionState(auditDataTable: AuditDataTable) {
    let auditSectionState = new AuditSectionState();

    auditSectionState.dataTableName = auditDataTable.tableName;
    auditSectionState.state = auditDataTable["State"];
    auditSectionState.customTableId = auditDataTable.tableId;

    return auditSectionState;
  }

  public async createDynamicTabInstance(folder: DynamicTabFolderViewModel, auditId: string, form: Form, number: string, description: string, position: number): Promise<CreateDynamicTabInstanceResult> {
    let parentSection = form.sections.find(x => x.id === folder.sectionId) as DynamicSection;

    let templateSection = parentSection.templates.find(x => x.templateId === folder.templateId);

    let newDynamicTab = await this.addDynamicTabAuditTemplate(auditId, templateSection, folder.id, null, number, description, position);

    let audit = await Audit.table.get(auditId);

    audit.updatedDate = new Date();

    await this.baseRepository.update(Audit.table, audit);

    return newDynamicTab;
  }

  public async updateDynamicTabInstance(id: string, number: string, description: string) {
    let dynamicTabAuditTemplate = await this.baseRepository.get(DynamicTabAuditTemplate.table, id);

    dynamicTabAuditTemplate.number = number;
    dynamicTabAuditTemplate.description = description;

    let audit = await Audit.table.get(dynamicTabAuditTemplate.auditId);
    audit.updatedDate = new Date();

    await this.baseRepository.update(DynamicTabAuditTemplate.table, dynamicTabAuditTemplate);
    await this.baseRepository.update(Audit.table, audit);
  }

  public getNextDynamicTabInstanceNumber(folder: DynamicTabFolderViewModel): number {
    let maxNumber = 0;

    for (const instance of folder.instances) {
      let instanceNumber = Number(instance.number);

      if (instanceNumber !== NaN) {
        if (instanceNumber > maxNumber)
          maxNumber = instanceNumber;
      }
    }

    return maxNumber + 1;
  }

	public async loadDynamicTabInstances(auditId: string, form: Form){
		let dynamicTabInstances = await DynamicTabAuditTemplate.table.filter((x) => x.auditId === auditId).toArray();

		for (const section of form.sections) {
			if ((section as DynamicSection).folders) {
        let dynamicSection = section as DynamicSection;

        for (const folder of dynamicSection.folders) {
					let folderInstances = dynamicTabInstances.filter(x => x.dynamicTabStructureItem == folder.idKey);

					folder.instances = [];

					for (const instance of folderInstances.sort(x => x.position)) {
						folder.instances.push(new DynamicInstance({
							id: instance.id,
							number: instance.number,
							description: instance.description,
							customTableId: instance.customTableId,
							dataTableName: folder.tableName,
              position: instance.position
						}));
					}
        }
      }
		}
	}

  public async validateAuditNumberExist(auditNumber: string): Promise<boolean> {
    if (!(await Audit.table.toArray()).find(audit => audit.number === auditNumber)) {
      return false;
    }
    return true;
  }
}
