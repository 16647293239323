import { FormGroup } from "@angular/forms";

export class ModelUtility {
  // Retourne une date dans un format reconnu par les contrôles de type Input Html.
  public static getInputDate(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  // Appliquer les données d'un modèle dans un FormGroup.
  public static applyValuesToFormGroup(formGroup: FormGroup, model: any): void {
    for (let key in model) {
      if (Object.prototype.hasOwnProperty.call(model, key)) {
        if (formGroup.controls[key]) {
          let value: any = model[key];

          if (value instanceof Date) {
            value = ModelUtility.getInputDate(value);
          }

          formGroup.controls[key].setValue(value);
        }
      }
    }
  }

  public static applyValuesToModel(model: any, formGroup: FormGroup): void{
    for (let key in model) {
      if (Object.prototype.hasOwnProperty.call(model, key)) {
        if (formGroup.controls[key]) {
          let value = formGroup.controls[key].value;
          
          if (model[key] instanceof Date) {
            value = new Date(value);
          }

          model[key] = value;
        }
      }
    }
  }
}
