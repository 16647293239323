<div [formGroup]="form" class="field-zone">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>
  
  <dx-date-box
    [type]="type"
    [formControlName]="input.key"
    [displayFormat]="displayFormat"
		[showDropDownButton]="!hideDropdownButton"
    [readOnly]="readOnly">
  </dx-date-box>
</div>
