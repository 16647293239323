import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from "src/app/core/data/models/formField";
import { DataType } from "src/app/core/data/models/form/dataType";
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { Subscription } from 'rxjs';
import { AuditState } from 'src/app/pages/audit/auditState';
import { TranslateService } from '@ngx-translate/core';
import { StringUtility } from 'src/app/core/stringUtility';
import { CustomFieldControlType } from 'src/app/core/data/models/form/customFieldControlType';
import dxTextBox from 'devextreme/ui/text_box';
import { DxNumberBoxComponent, DxTextAreaComponent, DxTextBoxComponent } from 'devextreme-angular';
import { ButtonDataSourceSelectedEventArgs } from 'src/app/pages/audit/buttonDataSourceSelectedEventArgs';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import _, { startsWith } from 'lodash';
import { Section } from 'src/app/core/data/models/form/section';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: [
    './textbox.component.scss',
    '../../controls.scss'
  ]
})
export class TextboxComponent extends BaseControlComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: FormGroup = new FormGroup({});

  @ViewChild(DxTextBoxComponent) control: DxTextBoxComponent
  @ViewChild(DxTextAreaComponent) controlMultiline: DxTextAreaComponent
  @ViewChild(DxNumberBoxComponent) controlNumeric: DxNumberBoxComponent

  dataTypes = DataType;
  controlTypes = CustomFieldControlType;

  public dataType: DataType;
  public maxLength: number;
  public minimumValue?: number;
  public maximumValue?: number;
  public displayFormat: string;
  public controlHeight: number;
  public multiLine: boolean;
  public inputMask: string;
  public readOnly: boolean;

  private alertConditionChangedSubscription: Subscription;
  private templateTextSubsription: Subscription;

  private templateLinkMemberName: string;
  private templatePropertyName: string;
  private templateInsertAtCaret: boolean;

  private valueChangesDebounceHandler;

  constructor(
    private readonly auditState: AuditState,
    private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dataType = super.getExtendedProperty("DataType") as DataType;

    if (super.hasExtendedProperty("MaxLength"))
      this.maxLength = super.getExtendedProperty("MaxLength") as number;

    if (super.hasExtendedProperty("MinimumValue"))
      this.minimumValue = super.getExtendedProperty("MinimumValue") as number;

    if (super.hasExtendedProperty("MaximumValue"))
      this.maximumValue = super.getExtendedProperty("MaximumValue") as number;

    this.processDisplayFormat();

    if (super.hasExtendedProperty("Multiline"))
      this.multiLine = super.getExtendedProperty("Multiline") as boolean;

    if (super.hasExtendedProperty("ReadOnly") || this.auditState.readonly)
      this.readOnly = super.getExtendedProperty("ReadOnly") as boolean || this.auditState.readonly;

    if (this.multiLine) {
      this.controlHeight = super.getExtendedProperty("Height") as number;
    }

    if (this.input.alert) {
      this.alertConditionChangedSubscription = this.input.$onAlertConditionChanged.subscribe(args => {
        let value = this.formControl.value;

        this.auditState.addAlertSummary(this.input, args.condition?.id, value, this.auditState.instanceId ? this.auditState.customTableId : "");
      });
    }

    if (super.hasExtendedProperty("TemplateText.DataSource.LinkMemberName")) {
      this.templateLinkMemberName = super.getExtendedProperty("TemplateText.DataSource.LinkMemberName") as string;

      if (this.templateLinkMemberName) {
        this.templateTextSubsription = this.auditState.$buttonDataSourceSelected.subscribe(this.onButtonDataSourceSelected.bind(this));

        this.templateInsertAtCaret = super.getExtendedProperty("TemplateText.DataSource.InsertAtCaret") as boolean;

        this.templatePropertyName = super.getExtendedProperty("TemplateText.DataSource.PropertyName") as string;
      }
    }

    this.valueChangesDebounceHandler = _.debounce(() => {
      this.input.referenceTableId = this.auditState.customTableId;

      this.input.value = this.formControl.value;
    }, 250);
  }

  private async onButtonDataSourceSelected(args: ButtonDataSourceSelectedEventArgs) {
    let newValue;

    if (args.name === this.templateLinkMemberName && Section.getAllControls(this.auditState.section).find(x => x.dataColumnName === this.templateLinkMemberName)) {
      let resultItem = await DataSourceImportationResult.table.get(args.dataSourceResultItemId);

      let value = resultItem[this.templatePropertyName];

      if (!value)
        return;

      if (this.templateInsertAtCaret) {
        let element;

        if (this.control) {
          element = this.control.instance.element();
        }
        else if (this.controlMultiline) {
          element = this.controlMultiline.instance.element();
        }
        else if (this.controlNumeric) {
          element = this.controlNumeric.instance.element();
        }

        var control = element.getElementsByClassName("dx-texteditor-input")[0] as HTMLTextAreaElement;

        var startPos = control.selectionStart;
        var endPos = control.selectionEnd;

        newValue =
          control.value.substring(0, startPos)
          + value
          + control.value.substring(endPos, control.value.length);
      }
      else {
        newValue = value;
      }

      this.formControl.setValue(newValue);
      this.formControl.markAsDirty();
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.lastValue = this.formControl.value;
  }

  onValueChanged(event) {
    if (this.dataType === DataType.Decimal) {
      this.valueChangesDebounceHandler();
    }
  }

  private processDisplayFormat() {
    if (this.dataType != DataType.Decimal) {
      let inputMask: string;

      if (super.hasExtendedProperty("InputMask"))
        inputMask = super.getExtendedProperty("InputMask") as string | undefined;

      this.inputMask = inputMask;
    }
    else {
      let displayFormat: string;

      if (super.hasExtendedProperty("DisplayFormat"))
        displayFormat = super.getExtendedProperty("DisplayFormat") as string | undefined;

      if (!displayFormat) {
        const defaultNumericFormat = "f";

        displayFormat = defaultNumericFormat;
      }

      const defaultDecimalPartLength: number = 2;

      let decimalParts: number;

      if (super.hasExtendedProperty("DecimalParts"))
        decimalParts = super.getExtendedProperty("DecimalParts") as number;

      if (this.isPredefinedFormat(displayFormat)) {
        let displayFormatRightPart: string = displayFormat.substr(1);

        if (!decimalParts) {
          if (displayFormatRightPart) {
            decimalParts = Number(displayFormatRightPart);
          }

          if (isNaN(decimalParts))
            decimalParts = defaultDecimalPartLength;
        }
        else {
          if (!decimalParts) {
            decimalParts = defaultDecimalPartLength;
          }
        }

        this.displayFormat = this.getPredefinedFormatSymbol(displayFormat) + "#,##0." + "0".repeat(decimalParts);
      }
      else {
        this.displayFormat = displayFormat;
      }
    }
  }

  private getPredefinedFormatSymbol(value: string) {
    let firstCharacter = value.substr(0, 1).toLowerCase();

    switch (firstCharacter) {
      case "c":
        return "$ ";
      case "p":
        return "% ";
      case "f":
        return "";
    }
  }

  private isPredefinedFormat(value: string) {
    let firstCharacter = value.substr(0, 1).toLowerCase();

    switch (firstCharacter) {
      case "c":
      case "p":
      case "f":
        return true;
      default:
        return false;
    }
  }

  private lastValue: any;

  focusOut() {
    let hasMinimumValue = this.minimumValue !== undefined;
    let hasMaximumValue = this.maximumValue !== undefined;

    if (!hasMinimumValue && !hasMaximumValue) {
      return;
    }

    let value = this.formControl.value;

    let isValid: boolean = true;

    if (hasMinimumValue) {
      if (value < this.minimumValue) {
        isValid = false;
      }
    }

    if (isValid && hasMaximumValue) {
      if (value > this.maximumValue) {
        isValid = false;
      }
    }

    if (!isValid) {
      if (hasMinimumValue && hasMaximumValue) {
        alert(this.translate.instant("components.customFields.textbox.minmaxInvalidMessage", { min: this.minimumValue.toString(), max: this.maximumValue.toString() }));
      }
      else if (hasMinimumValue) {
        alert(this.translate.instant("components.customFields.textbox.minInvalidMessage", { min: this.minimumValue.toString() }));
      }
      else if (hasMaximumValue) {
        alert(this.translate.instant("components.customFields.textbox.maxInvalidMessage", { max: this.maximumValue.toString() }));
      }

      this.formControl.setValue(this.lastValue);
    }
    else {
      this.lastValue = this.formControl.value;
    }
  }

  ngOnDestroy(): void {
    this.alertConditionChangedSubscription?.unsubscribe();
    this.templateTextSubsription?.unsubscribe();
  }
}
