import { Injectable } from "@angular/core";
import { AuthenticationService } from "src/app/authentication/authentication.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationMiddleware {
  constructor(
    private authenticationService: AuthenticationService,
  ) { }

  async run(): Promise<boolean> {
    if (navigator.onLine) {
      return await this.authenticationService.checkAuthentication();
    }

    return true;
  }
}
