import { EntityState } from "./entityState";

export abstract class ChangeTrackingEntity {
    id: string | number;
    entityState: EntityState;

    abstract getDatabaseTableName();
    abstract getDatabasePrimaryKeyType();

		public markAsUpdated(){
			if (this.entityState === EntityState.Default)
				this.entityState = EntityState.Modified;
		}
}