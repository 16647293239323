// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class DataChange extends ChangeTrackingEntity {
  public static table: Dexie.Table<DataChange, number>;
  public static tableName: string = 'DataChange';

  public id: number;  public tableName: string;  public columnName: string;  public tableId: number;  public tableIdGuid: string;  public mobileId: string;  public userAccountId: string;  public dateTime: Date;  public dataChangeActionTypeId: number;  public changedFrom: string;  public changedTo: string;  public dataChangeMarker: string;  public timeStamp: Date;

  public constructor(args?: Partial<DataChange>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return DataChange.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'number';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(DataChange.tableName);
      this.table.mapToClass(DataChange);
  }
}
