import { Injectable } from "@angular/core";
import { DatabaseContext } from "./databaseContext";
import { DatabaseTableInformation } from "./databaseTableInformation";
import { DatabaseTableLocal } from "./databaseTableLocal";
import { DatabaseTableServer } from "./databaseTableServer";

@Injectable({
  providedIn: "root"
})
export class DatabaseTableAll {
  constructor(
    private databaseTableLocal: DatabaseTableLocal,
    private databaseTableServer: DatabaseTableServer) { }

  public getTableInformations(): DatabaseTableInformation[] {
    return [].concat(this.databaseTableLocal.tableInformations)
      .concat(this.databaseTableServer.tableInformations);
  }

  public registerAllTables(databaseContext: DatabaseContext) {
    this.databaseTableLocal.registerAllTables(databaseContext);
    this.databaseTableServer.registerAllTables(databaseContext);
  }

  public getTableNames() {
    return this.getTableInformations().map((tableInformation) => tableInformation.tableName);
  }

  public getAuditRelatedTables(): DatabaseTableInformation[] {
    return this.getTableInformations().filter((tableInformation) => tableInformation.isAuditRelated);
  }
}
