/**
* Represents an in-memory data about a modified field that is not part of the current section.
*
* @remarks
* All visible controls are automatically handled by the Reactive Form and save when leaving the page.
* However, calculated fields from other sections could now be updated based on changes from fields of the current
* section. This class will hold changes to apply to those external fields and will be used to apply change in
* AuditDataTable immediately after saving changes.
*/
export class OtherSectionDataItem{
  dataTableName: string;
  dataColumnName: string;
  value: any;
}
