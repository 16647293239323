import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import _, { forEach } from 'lodash';
import { ListComponent } from 'src/app/components/list/list.component';
import { ListDataSourceFunctionResult } from 'src/app/components/list/listDatasourceFunctionResult';
import { EntitySecurityGroup } from 'src/app/core/data/models/database/entitySecurityGroup.database';
import { UserGroup } from 'src/app/core/data/models/database/userGroup.database';
import { ComparatorService } from 'src/app/core/services/comparator.service';

@Component({
  selector: 'app-entity-security-group',
  templateUrl: './entity-security-group.component.html',
  styleUrls: ['./entity-security-group.component.scss']
})
export class EntitySecurityGroupComponent implements OnInit {
  constructor() { }

  @ViewChild(ListComponent) list: ListComponent;

  @Input() source: string;

  private validateCallBack: Function;
  public visible: boolean;
  public selectedItem: any;
  public dataSource: Function;
  private result: Promise<Boolean>;
  private entityId: string;
  private onResolve: Function;

  public close(){
    this.selectedItem = null;
    this.visible = false;
  }

  public async add(){
    if (await this.validateCallBack()){
      this.visible = false;
      this.onResolve(true);
    }
  }

  public selectUserGroup(item: any){
    this.selectedItem = item;
  }

  ngOnInit(): void {
    this.dataSource = async (context) => {
      let userGroupsToDisplay = [];

      if (this.entityId){
        let userGroups = await UserGroup.table.toArray()
        let existingSecurityGroups = await EntitySecurityGroup.table.where("entityId").equals(this.entityId).toArray();

        existingSecurityGroups = existingSecurityGroups.filter(x => x.source === this.source);
    
        for (const userGroup of userGroups) {
          if (!existingSecurityGroups.find(x => x.userGroupId == userGroup.id)){
            userGroupsToDisplay.push({id: userGroup.id, text: userGroup.name})
          }
        }

        if (context.filter){
          userGroupsToDisplay = userGroupsToDisplay.filter(x => ComparatorService.stringMatch(x.text, context.filter));
        }
      }
  
      return new ListDataSourceFunctionResult({
        itemCount: userGroupsToDisplay.length,
        items: userGroupsToDisplay
      });
    }
  }

  public async show(entityId: string, validateCallBack: Function): Promise<Boolean>{
    this.entityId = entityId;

    this.list.updateData();

    this.result = new Promise<Boolean>((resolve) => { this.onResolve = resolve });

    this.visible = true;

    this.validateCallBack = validateCallBack;

    return this.result;
  }
}
