import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import moment from "moment";
import { environment } from "src/environments/environment";
import { AuthenticatedUser } from "../../security/authenticatedUser";
import { UserService } from "../../services/userService";
import { ValidationDictionary } from "../../validationDictionary";
import { SynchronizationServerToMobileTable } from "../models/database/synchronizationServerToMobileTable.database";
import { UserAccount } from "../models/database/userAccount.database";
import { LocalParameter } from "../models/databaseLocal/localParameter.database";
import { FeedMobileCommand } from "./feedMobileCommand";
import { InitializeMobileCommand } from "./initializeMobileCommand";
import { InitializeUserCommand } from "./initializeUserCommand";
import { SynchronizationContext } from "./synchronizationContext";
import { SynchronizationHttpClient } from "./synchronizationHttpClient";
import { SynchronizationService } from "./synchronizationService";
import { SynchronizationStatus } from "./synchronizationStatus";
// import { ManageDeletedMobileCommand } from "./manageDeletedMobileCommand";
export const CHANGE_TRACKING_NUMBER_PARAMETER_NAME = "ChangeTrackingNumber";

@Injectable({
  providedIn: 'root',
})
export class SynchronizationMiddleware {
  constructor(
    // private manageDeletedMobileCommand: ManageDeletedMobileCommand,
    private synchronizationContext: SynchronizationContext,
    private synchronizationService: SynchronizationService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private initializeUserCommand: InitializeUserCommand,
    private initializeMobileCommand: InitializeMobileCommand,
    private feedMobileCommand: FeedMobileCommand,
    private authenticatedUser: AuthenticatedUser,
    private userService: UserService,
    private translate: TranslateService
  ) { }

  public async run(): Promise<ValidationDictionary> {
    // await this.manageDeletedMobileCommand.run();

    let validationDictionary: ValidationDictionary;

    validationDictionary = await this.initializeUserCommand.run();

    if (!validationDictionary.isValid())
      return validationDictionary;

    validationDictionary = await this.initializeMobileCommand.run();

    if (!validationDictionary.isValid())
      return validationDictionary;

    validationDictionary = await this.feedMobileCommand.run();

    if (!validationDictionary.isValid())
      return validationDictionary;
    
    let userIdParameter = await LocalParameter.table.get(LocalParameter.USER_ID_PARAMETER_NAME);
    let userAccount = await UserAccount.table.get(userIdParameter.value);
    this.authenticatedUser = _.merge(this.authenticatedUser, userAccount);
    this.authenticatedUser.initials = this.userService.getInitials(this.authenticatedUser.name);

    if (this.authenticatedUser["language"]) {
      moment.locale(this.authenticatedUser["language"]);
    }
    else {
      this.setLanguage(environment.language);
    }

    return validationDictionary;
  }

  private setLanguage(language) {
    this.translate.setDefaultLang(language);
    moment.locale(language);
  }
}