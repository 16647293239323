import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from "src/app/core/data/models/formField";
import { CustomFieldControlType } from "src/app/core/data/models/form/customFieldControlType";
import { Subscription } from 'rxjs';
import { AlertTemplateService } from 'src/app/core/services/alertTemplateService';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent implements OnInit, OnDestroy {
  private alertConditionChangingSubscription: Subscription;

  customFieldControlTypes = CustomFieldControlType;

  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: FormGroup = new FormGroup({});
  @Input() showRequiredFieldNotification: boolean = true;

  constructor(private alertTemplateService: AlertTemplateService) { }

  ngOnInit(): void {
    this.showRequiredFieldNotification = this.input.controlType != this.customFieldControlTypes.Panel && this.input.controlType != this.customFieldControlTypes.GroupBox;

    if (this.input.alert){
      this.alertConditionChangingSubscription = this.input.$onAlertConditionChanging.subscribe(async args => {
        await this.alertTemplateService.configure(args.currentAlertTemplate, args.newAlertTemplate);

        args.callBack();
      });
    }
  }

  ngOnDestroy(): void {
    this.alertConditionChangingSubscription?.unsubscribe();
  }
}
