import { Injectable } from "@angular/core";
import { CustomFieldValueListRepository } from "src/app/core/data/repositories/customFieldValueListRepository";
import { CustomFieldValueListViewModel } from "./customFieldValueListViewModel";
import { CustomFieldValueListMapper } from "./customFieldValueListMapper";
import { CustomFieldValueListViewModelMapper } from "./customFieldValueListViewModelMapper";
import { CustomFieldValueList } from "src/app/core/data/models/database/customFieldValueList.database";
import { BaseRepository } from "src/app/core/data/baseRepository";

@Injectable()
export class CustomFieldValueListService {
    constructor(        
        private customFieldValueListMapper: CustomFieldValueListMapper,
        private customFieldValueListViewModelMapper: CustomFieldValueListViewModelMapper,
        private baseRepository: BaseRepository
    ) { }


    public async getAll(){
        let result = await this.baseRepository.getAll(CustomFieldValueList.table);

        return result.map(x => {
            return this.customFieldValueListMapper.map(x)
        });
    }
    
    public async insert(newCustomFieldValueListViewModel: CustomFieldValueListViewModel): Promise<CustomFieldValueListViewModel> {
        let customFieldValueListToInsert = this.customFieldValueListViewModelMapper.map(newCustomFieldValueListViewModel);

        let result = await this.baseRepository.insert<CustomFieldValueList>(CustomFieldValueList.table, customFieldValueListToInsert);

        return this.customFieldValueListMapper.map(result as CustomFieldValueList);
    }

    public async update(customFieldValueListViewModel: CustomFieldValueListViewModel): Promise<CustomFieldValueListViewModel> {
        let result = await this.baseRepository.update(CustomFieldValueList.table, this.customFieldValueListViewModelMapper.map(customFieldValueListViewModel));

        return this.customFieldValueListMapper.map(result as CustomFieldValueList);
    }

    public async delete(customFieldValueListViewModel: CustomFieldValueListViewModel): Promise<void> {
        let customFieldValueListToDelete = this.customFieldValueListViewModelMapper.map(customFieldValueListViewModel);

        await this.baseRepository.delete(CustomFieldValueList.table, customFieldValueListToDelete);
    }
}
