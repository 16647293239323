<div class="layout-vertical">
  <div class="layout-center">
    <div class="padding-normal">
      <label>Url</label>

      <dx-text-box id="name" class="text-box" [(ngModel)]="url" valueChangeEvent="keyup">
      </dx-text-box>
    </div>
    
    <div class="padding-normal">
      <label>Data Source Importation Id</label>

      <dx-text-box id="name" class="text-box" [(ngModel)]="dataSourceImportationId" valueChangeEvent="keyup">
      </dx-text-box>
    </div>
  </div>

  <div class="layout-bottom">
    <div class="layout-horizontal height-normal padding-horizontal-normal">
      <div class="layout-right space">
        <button type="submit" class="color-set-green compact"
          (click)="sendClick()">
          <div><span>Send</span></div>
        </button>
      </div>
    </div>

    <div *ngIf="result">Here is the result: {{result}}</div>
  </div>
</div>