import { UserAccount } from "src/app/core/data/models/database/userAccount.database";
import { DataSourceItem } from "src/app/core/data/viewModels/dataSourceItem";

export class UserAccountMapper {
  public mapToDataSourceItems(users: UserAccount[]): DataSourceItem<string>[]{
    return users.map((value) =>{
      return new DataSourceItem<string>(value.id, value.name);
    });
  }
}
