<div class="layout-vertical">
  <div class="layout-top">
    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-left">
        {{ 'dataSourceImportation.list.title' | translate}} ({{ itemCount }})
      </div>
      <div class="layout-right">
        <button class="color-set-transparent" [disabled]="!selectedDataSource" (click)="deleteClick()">
          <i class="fas fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="layout-center">
    <app-list #list [dataSource]="dataSource" (activatedItem)="navigateToDatasource($event)"
      (selectedItemsChange)="selectedItemsChange($event)" [numberOfSelectableItem]="1" [canToggleSelection]="true">
      <ng-template #listTemplate let-item>
        <div class="layout-vertical">
          <div class="layout-center">
            <div class="layout-horizontal defaultPadding">
              <div class="layout-left">
                <div>
                  {{item.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-list>
  </div>
</div>

<button class="big round shadow fixed color-set-orange" type="button" (click)="addClick()"><i
    class="fas fa-plus"></i></button>

<app-modal #modalPopup></app-modal>