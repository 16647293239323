import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HealthHttpClient {
  private configUrl = `${environment.apiBaseUrl}/Health`;

  constructor(private http: HttpClient) { }

  async getHealth(): Promise<any> {
    return await this.http.get(this.configUrl, { responseType: 'json' }).toPromise();
  }
}