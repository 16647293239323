import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { IFilterExpressionFormatter } from "./iFilterExpressionFormatter";

export class UserFilterExpressionFormatter implements IFilterExpressionFormatter{
  private _authenticatedUser: AuthenticatedUser;

  constructor(authenticatedUser: AuthenticatedUser){
    this._authenticatedUser = authenticatedUser;
  }

  public execute(value: string): string{
    return this.replaceUserToken(value);
  }

  private replaceUserToken(value: string) {
    return value.replaceAll("@currentUser", "'" + this._authenticatedUser.code + "'");
  }
}