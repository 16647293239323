import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { Audit } from 'src/app/core/data/models/database/audit.database';
import { Program } from 'src/app/core/data/models/database/program.database';
import { UserAudit } from 'src/app/core/data/models/databaseLocal/userAudit.database';
import { SynchronizationHttpClient } from 'src/app/core/data/synchronization/synchronizationHttpClient';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { AuditService } from '../audit/auditService';

@Component({
  selector: 'app-formtemplate-fill-redirect',
  templateUrl: './formtemplate-fill-redirect.component.html',
  styleUrls: ['./formtemplate-fill-redirect.component.scss']
})
export class FormTemplateFillRedirectComponent implements OnInit {
  private EXTERNAL_NUMBER: string = 'externalNumber';
  private program: Program;
  public failed = false;
  public failedMessage: string;
  public message: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auditService: AuditService,
    private synchronizationService: SynchronizationService,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private translateService: TranslateService
  ) {
    this.message = this.translateService.instant("formtemplate.fill-redirect.defaultMessage");
    this.failedMessage = this.translateService.instant("formtemplate.fill-redirect.failedMessage");
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async (queryParams) => {
      const systemParameters = ["id", "prefix", this.EXTERNAL_NUMBER];

      let programId = queryParams.get('id');
      let programPrefix = queryParams.get('prefix');
      let externalNumber = queryParams.get(this.EXTERNAL_NUMBER);

      let additionalDataInfos: any[] = [];

      for (const parameterName of queryParams.keys) {
        if (!systemParameters.find(x => x === parameterName)){
          additionalDataInfos.push({ name: parameterName, value: queryParams.get(parameterName) });
        }
      }

      // Synchronize to ensure the form template is available and up to date
      // as well as the audit itself if it was created by an other mobile with a specific external number.
      await this.synchronizationService.getUpdates(false, false);

      if (externalNumber) {
        if (externalNumber.toString().length > 15) {
          this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.maximumExternalNumberLength"));
          return;
        }
      }

      if (externalNumber) {
        let existingUserAudit = await UserAudit.table.where(this.EXTERNAL_NUMBER).equals(externalNumber).first();

        if (existingUserAudit) {
          if (await this.tryGetProgramById(existingUserAudit.programId)) {
            if (this.program.allowDuplicateExternalNumber)
              this.message = this.translateService.instant("formtemplate.fill-redirect.existingFormDuplicate");
            else
              this.message = this.translateService.instant("formtemplate.fill-redirect.existingForm");

            let existingAudit = await Audit.table.where(this.EXTERNAL_NUMBER).equals(externalNumber).first();

            if (!existingAudit) {
              await this.synchronizationHttpClient.subscribeToAudit(existingUserAudit.number);
              await this.synchronizationService.getUpdates(false, false);
              existingAudit = await Audit.table.where(this.EXTERNAL_NUMBER).equals(externalNumber).first();
            }

            if (existingAudit.isDeleted) {
              this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.auditDeleted"))
              return;
            }

            if (!this.program.allowDuplicateExternalNumber) {
              this.navigateToAudit(this.program, existingAudit);

              return;
            }
          }
        }
      }

      this.message = this.translateService.instant("formtemplate.fill-redirect.newForm");

      if (!programId && !programPrefix) {
        this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.noProgramIdOrPrefixNumber"));
        return;
      }

      if (programId) {
        if (!await this.tryGetProgramById(programId.toUpperCase()))
          return;
      } else if (programPrefix) {
        if (!await this.tryGetProgramByPrefix(programPrefix))
          return;
      }

      let creationResult = await this.auditService.createAudit(this.program.id, externalNumber, additionalDataInfos);

      if (!creationResult.success) {
        this.failed = true;
        return;
      }

      this.navigateToAudit(this.program, creationResult.audit);
    });
  }

  private async tryGetProgramByPrefix(prefix: string) {
    // There can be more than one program with the same prefix but only one not deleted.
    // However, there can also be more than one delete program with the same prefix.
    // First, try to find the one not deleted.
    // If nothing found, then try to take the first one deleted.
    var programs = await Program.table.filter(x => x.numberPrefix == prefix).toArray();

    if (programs.length > 1) {
      this.program = programs.find(x => !x.isDeleted);

      if (!this.program) {
        this.program = programs[0];
      }
    }
    else {
      this.program = programs[0];
    }

    return this.validateProgram(this.program);
  }

  private async tryGetProgramById(programId: string) {
    this.program = await Program.table.get(programId.toUpperCase());

    return this.validateProgram(this.program);
  }

  private validateProgram(program: Program) {
    if (!this.program) {
      this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.programNotExist"));
      return false;
    }

    if (this.program.isDeleted) {
      this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.programDeleted"));
      return false;
    }

    if (!this.program.isActive) {
      this.markAsFailed(this.translateService.instant("formtemplate.fill-redirect.programNotActive"));
      return false;
    }

    return true;
  }

  private markAsFailed(message: string) {
    this.failed = true;
    this.failedMessage = message;
  }

  private navigateToListFilteredByExtenalNumber(externalNumber: string) {
    let auditRoute = `/forms?${this.EXTERNAL_NUMBER}=${externalNumber}`;
    this.router.navigateByUrl(auditRoute);
  }

  private navigateToAudit(program: Program, audit: Audit) {
    let auditRoute = `/forms/${audit.number}`;
    this.router.navigateByUrl(auditRoute);
  }

  public goToList() {
    this.router.navigateByUrl("/forms");
  }
}
