import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  @Input() visible = false;
  @Input() canClose = true;
  @Input() boxFixed = false;
  @Input() topCentered = false;
  @Input() vertical = false;

  @Input() width = "";
  @Input() height = "";

  @Output() visibleChange: EventEmitter<any> = new EventEmitter();

  private promiseResolver = (boolean) => {};

  constructor() { }

  public async display() {
    this.visible = true;
    this.visibleChange.emit(true);

    return new Promise<boolean>((resolve) => {
      this.promiseResolver = resolve;
    })
  }

  public close() {
    if (this.canClose) {
      this.visible = false;
      this.visibleChange.emit(false);
    }
  }

  resolve(value: boolean){
    this.promiseResolver(value);
  }
}
