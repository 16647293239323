import { Component, OnInit, ViewChild } from '@angular/core';
import _ from 'lodash';
import { ListDataSourceResult } from 'src/app/components/list/listDatasourceResult';
import { ListComponent } from 'src/app/components/list/list.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { ProgramDataTemplate } from 'src/app/core/data/models/database/programDataTemplate.database';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { StringUtility } from 'src/app/core/stringUtility';
import { SystemInformationComponent } from '../system-information/system-information.component';
import { DataImportationHttpClient } from './dataImportationHttpClient';
import { DataImportationSupervisor } from './dataImportationSupervisor';

@Component({
  selector: 'app-data-importation',
  templateUrl: './data-importation.component.html',
  styleUrls: ['./data-importation.component.scss']
})
export class DataImportationComponent implements OnInit {
  selectedProgramDataTemplateId: string;
  selectedDataSearchItem: string;

  programId: string;
  auditId: string;

  templateDataSource: any;
  searchDataSource: any = [];

  canImportDataSource: boolean = false;

  importationInProgress: boolean = false;

  @ViewChild(PopupComponent) popup: PopupComponent;

  @ViewChild('dataTemplateList') dataTemplateList: ListComponent;
  @ViewChild('dataSearchList') dataSearchList: ListComponent;

  constructor(
    private dataImportationHttpClient: DataImportationHttpClient,
    private synchronizationService: SynchronizationService,
    private supervisor: DataImportationSupervisor) {
  }

  ngOnInit(): void {
    this.templateDataSource = async (args) => {
      let dataSource = await (ProgramDataTemplate.table.filter(x => x.programId === this.programId)).toArray();

      if (args.filter) {
        dataSource = dataSource.filter(x => ComparatorService.stringMatch(x.name, args.filter));
      }

      return new ListDataSourceResult({ groupedItems: _.sortBy(dataSource, x => x.name), itemCount: dataSource.length })
    };
  }

  async display(programId: string, auditId: string) {
    this.popup.width = "96%";
    this.popup.height = "50%";

    this.programId = programId;
    this.auditId = auditId;

    this.dataTemplateList.updateData();

    return await this.popup.display();
  }

  async dataSearchClick(item: any) {
    this.selectedDataSearchItem = item.Search;

    this.canImportDataSource = true;
  }

  async programDataTemplateClick(item: ProgramDataTemplate) {
    this.selectedProgramDataTemplateId = item.id;

    let dataSource = this.supervisor.getDataSource(item);

    this.dataSearchList.items = dataSource;

    await this.dataSearchList.updateData();

    this.canImportDataSource = false;
  }

  async okButtonClick() {
    this.popup.close();

    this.importationInProgress = true;

    await this.dataImportationHttpClient.applyDataTemplate(this.auditId, this.selectedProgramDataTemplateId, this.selectedDataSearchItem);

    this.importationInProgress = false;

    await this.synchronizationService.getUpdates(true, false);

    await this.resetUI();

    this.popup.resolve(true);
  }

  async cancelButtonClick() {
    await this.resetUI();

    this.popup.resolve(false);
    this.popup.close();
  }

  private async resetUI(){
    this.dataSearchList.items = [];

    await this.dataSearchList.updateData();
    
    this.selectedDataSearchItem = null;
    this.selectedProgramDataTemplateId = null;
  }
}
