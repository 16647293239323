import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from '../../popup/popup.component';
import { ListDataSourceFunctionResult } from '../listDatasourceFunctionResult';
import { FieldMetadata } from './field-metadata';

@Component({
  selector: 'app-list-option',
  templateUrl: './list-option.component.html',
  styleUrls: ['./list-option.component.scss']
})
export class ListOptionComponent {

  @Input() fieldsMetadata: FieldMetadata[];

  public dataSource: Function;

  @ViewChild('listOptionPopup') public popup: PopupComponent

  constructor() {
    this.dataSource = async (): Promise<ListDataSourceFunctionResult> => {
      return new ListDataSourceFunctionResult({
        itemCount: this.fieldsMetadata.length,
        items: this.fieldsMetadata
      });
    }
  }
}
