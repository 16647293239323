export class EntitySecurityGroupPermission{
  userIds: string[];
  entityId: string;

  /**
  * Indicates whether the user is in the list of allowed user to this entity.
  */
  hasAccess(userId: string){
    let user = this.userIds.find(x => x === userId);

    return user != null;
  }
}