import { FormField } from "src/app/core/data/models/formField";
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from "@angular/core";
import { Section } from "src/app/core/data/models/form/section";
import { Control } from "src/app/core/data/models/form/control";
import { BaseRepository } from "../data/baseRepository";
import { AlertCondition } from "../data/models/database/alertCondition.database";
import { AlertControl } from "src/app/components/customFields/controls/alert/alertControl";
import { AlertConditionDetail } from "../data/models/database/alertConditionDetail.database";
import { AlertControlCondition } from "src/app/components/customFields/controls/alert/alertControlCondition";
import { CustomFieldControlType } from "../data/models/form/customFieldControlType";
import { DataType } from "../data/models/form/dataType";
import { Color } from "./color";
import { ExtendedProperty } from "../data/models/form/extendedProperty";
import { TextBoxFormFieldValueFormatter } from "../data/models/textBoxFormFieldValueFormatter";
import * as _ from "lodash"

@Injectable({
  providedIn: 'root'
})
export class FormFieldControlService {
  constructor(private baseRepository: BaseRepository) { }

  toFormGroup(inputs: FormField<any>[]): FormGroup {
    const group: any = {};

    this.toFormGroupFromControls(inputs, group);

    return new FormGroup(group);
  }

  private getRequiredValidator(field: FormField<any>): ValidatorFn{
    let result: ValidatorFn = Validators.required;

    if (field.controlType === CustomFieldControlType.CheckBox){
      let extendedProperties: ExtendedProperty[] = field.options.find(x => x.key === "extendedProperties").value as ExtendedProperty[];

      let threeStatesOption = extendedProperties.find(x => x.key === "ThreeState");

      if (!threeStatesOption?.value) {
        result = Validators.requiredTrue;
      }
    }

    return result;
  }

  private toFormGroupFromControls(fields: FormField<any>[], group: any) {
    fields.forEach(input => {
      const validator: ValidatorFn[] = input.required ? [this.getRequiredValidator(input)] : [];

      if (input.validators) {
        for (let inputValidator of input.validators) {
          switch (inputValidator) {
            case "email":
              validator.push(Validators.email);
              break;
            default:
              break;
          }
        }
      }

      let formControl = validator.length > 0 ? new FormControl(input.value || '', validator)
        : new FormControl(input.value || '');

      formControl.setValue(input.value);

      group[input.key] = formControl;

      if (input.children && input.children.length > 0) {
        this.toFormGroupFromControls(input.children, group);
      }
    });
  }

  // TODO ST: Don't retreive the entire list of alerts from the database but just the ones from the form fields list.
  async getAlertControls() : Promise<Array<AlertControl>> {
    let alerts = await this.baseRepository.getAll<AlertCondition>(AlertCondition.table);
    let alertConditions = await this.baseRepository.getAll<AlertConditionDetail>(AlertConditionDetail.table);

    let alertControls = new Array<AlertControl>();

    for (const alert of alerts) {
      let alertControl = new AlertControl();

      alertControl.id = alert.id;
      alertControl.description = alert.description;

      let currentConditions = alertConditions.filter(x => x.alertConditionId === alert.id && !x.isDeleted);

      for (const condition of currentConditions) {
        let alertControlCondition = new AlertControlCondition();

        alertControlCondition.id = condition.id;
        alertControlCondition.name = condition.name || alert.description;
        alertControlCondition.description = condition.description;
        alertControlCondition.minimumValue = _.isNumber(condition.minimumValue) ? condition.minimumValue : null;
        alertControlCondition.maximumValue = _.isNumber(condition.maximumValue) ? condition.maximumValue : null;
        alertControlCondition.booleanValue = this.getCheckBoxChoice(condition.checkBoxChoiceId);
        alertControlCondition.listValue = condition.programDropDownListEntryId;
        alertControlCondition.color = Color.toColor(condition.color).toHex();

        alertControl.conditions.push(alertControlCondition);
      }

      alertControls.push(alertControl);
    }

    return alertControls;
  }

  getCheckBoxChoice(choiceId: number): boolean | null {
    switch (choiceId) {
      case CheckBoxChoices.indetermined:
        return null;
      case CheckBoxChoices.checked:
        return true;
      case CheckBoxChoices.unchecked:
        return false;
    }
  }

  async getFormFields(section: Section): Promise<FormField<any>[]> {
    const alertControls = await this.getAlertControls();

    return this.getFormFieldsFromControls(section.controls, alertControls, section);
  }

  private getFormFieldsFromControls(controls: Control[], alertControls: Array<AlertControl>, section: Section): FormField<any>[] {
    const result: FormField<any>[] = new Array();

    for (let control of controls) {
      const formField = new FormField<any>();

      formField.key = control.name;
      formField.controlType = control.type;
      formField.description = control.description;
      formField.options.push({ key: "extendedProperties", value: control.extendedProperties });
      formField.dataColumnName = control.dataColumnName;
      formField.dataTableName = control.dataTableName;
      formField.font = control.font;
      formField.required = control.required;
      formField.alertId = control.alertId;
      formField.generateLabel = control.generateLabel;

      if (control.alertId)
        formField.alert = alertControls.find(x => x.id === control.alertId);

      formField.instructionId = control.instructionId;
      formField.alertTemplates = control.alertTemplates;
      formField.requiredSteps = control.requiredSteps;
      formField.readOnly = false;
      formField.validators = control.validators;
      formField.sectionKey = section.name;
      formField.sectionDescription = section.description;
      formField.section = section;

      if (control.type === CustomFieldControlType.TextBox && control.extendedProperties.find(x => x.key === "DataType").value === DataType.String)
        formField.valueFormatter = new TextBoxFormFieldValueFormatter();

      result.push(formField);

      if (control.children && control.children.length > 0) {
        formField.children.push(...this.getFormFieldsFromControls(control.children, alertControls, section));
      }
    }

    return result;
  }
}

enum CheckBoxChoices {
  unchecked = 1,
  checked = 2,
  indetermined = 3
}

