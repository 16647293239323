<div class="layout-vertical" [class.compact]="!paging">
  <div class="layout-top">
    <div id="list-menu" class="layout-horizontal height-normal color-set-white"
      *ngIf="(numberOfSelectableItem !== 1 || enableFilter) && showHeader">
      <div class="layout-left">
        <button *ngIf="(canToggleSelection || selectionEnabled) && showCheckBoxes" [disabled]="!selectionEnabled"
          [class.color-set-transparent]="!selectionEnabled" [class.color-set-transparent-blue]="selectionEnabled"
          (click)="disableSelection()">
          <i class="fa-regular fa-circle"></i>
        </button>
        <button *ngIf="enableFilter"><i class="fas fa-filter"></i></button>
      </div>
      <div class="layout-center fill padding-normal-right" *ngIf="enableFilter">
        <input #inputFilter class="basic textFilter" type="text" (ngModelChange)="filterChanged()" [(ngModel)]="filter">
      </div>
      <div class="layout-right">
        <ng-container [ngTemplateOutlet]="rightOptionalButton">
        </ng-container>
      </div>
    </div>
  </div>
  <div class="layout-center">
    <div *ngIf="isLoading" class="layout-horizontal rightPadding">
      <div class="layout-left">
        <button><i class="fas fa-spin fa-sync"></i>
          <div><span>{{ 'controls.dropdown.loading' | translate }}</span></div>
        </button>
      </div>
    </div>
    <div *ngIf="!isLoading && !dataSourceResult?.itemCount && filter" class="layout-horizontal rightPadding">
      <div class="layout-left">
        <button>
          <i class="fas fa-exclamation-triangle"></i>
          <div><span> {{ 'controls.dropdown.emptyFilter' | translate}}</span></div>
        </button>
      </div>
    </div>
    <div *ngIf="showInstructions && !isLoading && !dataSourceResult?.itemCount && !filter"
      class="layout-horizontal rightPadding">
      <div class="layout-left">
        <button><i class="fas fa-exclamation-triangle"></i></button> {{ 'controls.dropdown.empty' | translate }}
      </div>
    </div>

    <div class="list" [class.selectable]="numberOfSelectableItem !== 0" [class.hoverable]="hoverable"
      *ngIf="!isLoading">
      <div *ngFor="let group of dataSourceResult?.groups">
        <div *ngIf="group.key !== noGroupType">
          <div *ngIf="listOptions.mapGroupFunction && group[templateProperty]" [innerHtml]="group[templateProperty]">
          </div>
        </div>
        <div class="item" [class.selected]="isSelectedItem(item) && highLightSelectedItems"
          *ngFor="let item of dataSourceResult?.groupedItems[group.key]" (click)="activateItem(item)">
          <div class="layout-horizontal">
            <div class="layout-left" *ngIf="showCheckBoxes && (canToggleSelection || selectionEnabled)">
              <button class="color-set-transparent-blue" (click)="toggleItemSelection($event, item)">
                <i class="fa-regular" [class.fa-circle-check]="isSelectedItem(item)"
                  [class.fa-circle]="!isSelectedItem(item)"></i>
              </button>
            </div>
            <div class="layout-center align-left">
              <div *ngIf="listOptions.mapItemFunction && item[templateProperty]" [innerHtml]="item[templateProperty]" class="width-100">
              </div>
              <div *ngIf="!listOptions.mapItemFunction" class="width-100">
                <ng-container class="item" *ngTemplateOutlet="listTemplate; context: { $implicit: item }">
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="layout-bottom" *ngIf="paging">
    <app-paging [(page)]="page" (pageChange)="updateData()" [itemCount]="dataSourceResult?.itemCount || 0">
    </app-paging>
    <div class="layout-horizontal" *ngIf="showInstructions && selectionEnabled && numberOfRequiredItems === 1"
      [class.color-set-orange]="!selectedItems" [class.color-set-green]="selectedItems">
      <div class="layout-left">
        <button>
          <i class="fas" [class.fa-check]="selectedItems" [class.fa-warning]="!selectedItems"></i>
          <div><span> {{ 'controls.dropdown.requiredItem' | translate }} </span></div>
        </button>
      </div>
    </div>
    <div class="layout-horizontal" *ngIf="showInstructions && selectionEnabled && numberOfRequiredItems > 1"
      [class.color-set-orange]="selectedItems.length < numberOfRequiredItems"
      [class.color-set-green]="selectedItems.length >= numberOfRequiredItems">
      <div class="layout-left">
        <button>
          <i class="fas" [class.fa-check]="selectedItems.length >= numberOfRequiredItems"
            [class.fa-warning]="selectedItems.length < numberOfRequiredItems"></i>
          <div><span> Selected {{selectedItems.length}} of {{numberOfRequiredItems}} required items</span></div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="advancedMetadata">
  <app-list-option #listOption [fieldsMetadata]="advancedMetadata"></app-list-option>
</ng-container>