import { Injectable } from "@angular/core";
import { ParamMap, Router } from "@angular/router";
import { Parameter } from "src/app/core/data/models/database/parameter.database";
import { AuditListSearchOptions } from "./auditListSearchOptions";

const AUDIT_LIST_SEARCH_OPTION_PARAMTER_ID = "6691B9B6-18A4-4330-915F-B7D6C73F1C2D";

@Injectable()
export class AuditListSearchOptionsQueryParamUrlService {
  constructor(
    private router: Router
  ) { }

  async parseQueryParameters(params: ParamMap): Promise<AuditListSearchOptions> {
    let auditListSearchOptions = params?.get('search');

    if (!auditListSearchOptions) {
      return new AuditListSearchOptions();
    }

    return JSON.parse(auditListSearchOptions);
  }

  async restore(): Promise<AuditListSearchOptions> {
    let searchOptions = await Parameter.table.get(AUDIT_LIST_SEARCH_OPTION_PARAMTER_ID);

    if (!searchOptions) {
      return new AuditListSearchOptions();
    }

    return JSON.parse(searchOptions.value);
  }

  async save(auditListSearchOptions: AuditListSearchOptions) {
    await Parameter.table.put(new Parameter({
      id: AUDIT_LIST_SEARCH_OPTION_PARAMTER_ID,
      name: "AuditListSearchOptions",
      value: JSON.stringify(auditListSearchOptions)
    }));
  }

  buildUrl(auditListSearchOptions: AuditListSearchOptions): string {
    return `/forms?search=${JSON.stringify(auditListSearchOptions)}`
  }

  navigate(auditListSearchOptions: AuditListSearchOptions): void {
    this.router.navigateByUrl(this.buildUrl(auditListSearchOptions));
  }
}
