import { Injectable, Injector } from "@angular/core";
import { LogLevel } from "./logLevel";
import { environment } from "src/environments/environment";
import { BaseRepository } from "../data/baseRepository";
import { ChangeOperation } from "../data/changeTracking/changeOperation";
import Dexie from "dexie";
import { SynchronizationContext } from "../data/synchronization/synchronizationContext";
import { ApplicationLog } from "../data/models/database/applicationLog.database";

@Injectable({
  providedIn: 'root',
})
export class Logger {
  // TODO: add logRepository, but currently gives recursive dependency...
  private baseRepository: BaseRepository;
  private synchronizationContext: SynchronizationContext;

  constructor(
    private injector: Injector
  ) {
    setTimeout(() => {
      this.baseRepository = this.injector.get(BaseRepository)
      this.synchronizationContext = this.injector.get(SynchronizationContext)
    });
  }

  public async logTrace(message, data = undefined, location = undefined) {
    this.log(LogLevel.trace, message, data, location, console.trace);
  }

  public async logDebug(message, data = undefined, location = undefined) {
    this.log(LogLevel.debug, message, data, location, console.debug);
  }

  public async logInformation(message, data = undefined, location = undefined) {
    this.log(LogLevel.information, message, data, location, console.info);
  }

  public async logWarning(message, data = undefined, location = undefined) {
    this.log(LogLevel.warning, message, data, location, console.warn);
  }

  public async logError(message, data = undefined, location = undefined) {
    this.log(LogLevel.error, message, data, location, console.error);
  }

  public async logCritical(message, data = undefined, location = undefined) {
    this.log(LogLevel.critical, message, data, location, console.error);
  }

  public async log(logLevel: number, message, data, location, logMethod: Function = console.error) {
    try {
      if (this.canLog(logLevel)) {
        logMethod(this.formatMessage(logLevel, location, message, data));

        if (this.baseRepository && this.synchronizationContext) {
          let log = new ApplicationLog({
            id: Dexie.Observable.createUUID(),
            mobileId: this.synchronizationContext?.mobileId,
            logLevel: logLevel,
            message: message,
            timeStamp: new Date()
          });

          // Insert ChangeTrackings without adding the log to the local database
          // and without triggering a synchronization
          await this.baseRepository.insertChangeTrackings(ApplicationLog.table, log, ChangeOperation.Insert);
        } else {
          console.warn("Logger not initialized correctly. Logging to database failed.");
        }
      }
    } catch (error) {
      console.error("IMPORTANT: Application log failed to be written, application will continue to run.", error);
    }
  }

  private canLog(logLevel: number) {
    return environment.logLevel >= logLevel;
  }

  private formatMessage(logLevel: LogLevel, location, message, data) {
    let date = new Date();
    let dateTimeString = date.getFullYear() + "-"
      + (date.getMonth() + 1).toString().padStart(2, '0') + "-"
      + date.getDate().toString().padStart(2, '0') + " "
      + date.getHours().toString().padStart(2, '0') + ":"
      + date.getMinutes().toString().padStart(2, '0') + ":"
      + date.getSeconds().toString().padStart(2, '0') + "."
      + date.getMilliseconds().toString().padStart(3, '0');

    let logLevelString;

    switch (logLevel) {
      case LogLevel.trace:
        logLevelString = 'TRACE';
        break;
      case LogLevel.debug:
        logLevelString = 'DEBUG';
        break;
      case LogLevel.information:
        logLevelString = 'INFO';
        break;
      case LogLevel.warning:
        logLevelString = 'WARNING';
        break;
      case LogLevel.error:
        logLevelString = 'ERROR';
        break;
      case LogLevel.critical:
        logLevelString = 'CRITICAL';
        break;
      default:
        logLevelString = 'UNKNOWN';
    }

    let formattedMessage = `${dateTimeString} | ${logLevelString} | MOBILE: ${this.synchronizationContext?.mobileId || "UNKNOWN MOBILE"} | ${location || "UNKNOWN LOCATION"} | ${message}`

    if (data) {
      formattedMessage += `| ${JSON.stringify(data)}`;
    }

    return formattedMessage;
  }
}
