import { Injectable } from "@angular/core";
import { ProgramDataTemplate } from "src/app/core/data/models/database/programDataTemplate.database";

@Injectable({
  providedIn: 'root',
})
export class DataImportationSupervisor{
  /**
  * Returns the searchable table in the data source.
  */
  private getSearchableTable(item: ProgramDataTemplate) {
    let dataSource = JSON.parse(item.dataSource);

    if (dataSource) {
      for (let table of dataSource.Tables) {
        if (table.IsSearchable) {
          return table;
        }
      }
    }

    throw new Error("There is no searchable table in the related data source.");
  }

  /**
  * Returns the data source associated with the selected template.
  */
  getDataSource(item: ProgramDataTemplate) {
    let result: any[] = [];

    if (item.dataSource) {
      let table = this.getSearchableTable(item);

      for (const row of table.Rows) {
        let searchItem = new DataSourceItem();

        for (const column of table.Columns) {
          // For the moment, only the searchable column is displayed in the Web interface.
          // There is only one column searchable in the searchable sheet because this is the relation column
          // with other sheets in the spreadsheets.
          // NOTE ST: We have to find a way to display multiple columns in a ListComponent dynamically.
          if (column.IsSearchable) {
            searchItem[column.Name] = "";

            break;
          }
        }

        for (const value of row.Values) {
          let valueObject: any;

          if (value.ValueAsBoolean !== null)
            valueObject = value.ValueAsBoolean;
          else if (value.ValueAsInteger !== null)
            valueObject = value.ValueAsInteger;
          else if (value.ValueAsDouble !== null)
            valueObject = value.ValueAsDouble;
          else if (value.ValueAsGuid !== null)
            valueObject = value.ValueAsGuid;
          else if (value.ValueAsDateTime !== null)
            valueObject = value.ValueAsDateTime;
          else if (value.ValueAsString !== null)
            valueObject = value.ValueAsString;
          else
            valueObject = "";

          searchItem[value.ColumnName] = valueObject;
        }

        result.push(searchItem);
      }
    }

    return result;
  }
}

class DataSourceItem{
  getInfo(){
    let properties = [];

    for (const property in this) {
      if (Object.prototype.hasOwnProperty.call(this, property)) {
        const element = this[property];
    
        properties.push(element);
      }
    }

    return properties.join("<br>");
  }
}