import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppInfoService {
  constructor(private translate: TranslateService) {}

  public get title() {
    return this.translate.instant("appInfo.title");
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}
