declare var window: any;

export const environment = {
  production: window.config?.production,
  apiBaseUrl: window.config?.apiBaseUrl,
  logLevel: window.config?.logLevel,
  numberOfItemPerPage: window.config?.numberOfItemPerPage, // Should be moved to userPreference when available.
  databaseScenario: window.config?.databaseScenario,
  mobileIdHeaderKey: window.config?.mobileIdHeaderKey,
  auditSubscriptionDays: window.config?.auditSubscriptionDays,
  canEditProfile: window.config?.canEditProfile,
  language: window.config?.language,
  version: window.config?.version,
  databaseVersion: window.config?.databaseVersion,
  defaultDebounceMilliseconds: window.config?.defaultDebounceMilliseconds,
  requireSynchroronizationCheckInterval: window.config?.requireSynchroronizationCheckInterval,
  synchronizationDebounceMilliseconds: window.config?.synchronizationDebounceMilliseconds,
  synchronizationMaxDataValuesSize: window.config?.synchronizationMaxDataValuesSize,
  authentication: window.config?.authentication,
  enableDataSourceImportation: window.config?.enableDataSourceImportation,
  currentLocation: window.config?.currentLocation,
  enableFeatures: {
    dataSourceImportations: window.config?.enableFeatures.dataSourceImportations,
    tasks: window.config?.enableFeatures.tasks,
    securityGroups: window.config?.enableFeatures.securityGroups
  },
  UI: {
    threeStatesCheckboxesOrder: window.config?.UI.threeStatesCheckboxesOrder
  },
  disableNativeCamera: window.config?.disableNativeCamera,
  cameraFlash: window.config?.cameraFlash,

  constants: {
    USERID_PARAMETER_NAME: "UserId"
  },
  compressionLevel: window.config?.compressionLevel
};
