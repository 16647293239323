import { DataSourceImportationResult } from "src/app/core/data/models/database/dataSourceImportationResult.database";
import { ComparatorService } from "src/app/core/services/comparator.service";

export class DataSourcePropertyService{
  /**
  * Indicates whether the specified value is a reserved keyword based on fields presents in the
  * database table DataSourceImportationResult.
  */
  public static isReservedKeyword(value: string){
    let reservedKeywords = ["Id", "DataSourceImportationId", "Data", "IdKey", "TimeStamp"];

    for (const reservedKeyword of reservedKeywords) {
      if (ComparatorService.localeCompare(value, reservedKeyword) == 0){
        return false;
      }
    }

    return true;
  }
}