<app-popup #popup>
  <div class="layout-vertical">
    <div class="layout-horizontal padding-left height-normal color-set-lightblue">
      <div class="layout-left">
        <h1>{{ 'dataSourceSelector.title' | translate}}</h1>
      </div>
    </div>

    <div class="layout-center">
      <app-list #list 
      [dataSource]="dataSource" 
      (activatedItem)="navigateToDatasource($event)"
        [numberOfRequiredItems]="1" 
        [selectionEnabled]="true" 
        [showCheckBoxes]="false"
        (selectedItemsChange)="selectedItemsChange($event)" 
        [showInstructions]="false"
        [enableFilter]="true" >
        <ng-template #listTemplate let-item>
          <div class="layout-vertical">
            <div class="layout-center">
              <div class="layout-horizontal defaultPadding">
                <div class="layout-left">
                  <div>
                    {{item.title}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-list>
    </div>

    <div class="layout-bottom">
      <div class="layout-horizontal height-normal padding-horizontal-normal">
        <div class="layout-right space">
          <button type="submit" [disabled]="!selectedItem" class="color-set-green compact"
            (click)="okButtonClick()">
            <div><span>{{ 'dataSourceSelector.apply' | translate}}</span></div>
          </button>

          <button class="color-set-gray compact" (click)="cancelButtonClick()">
            <div><span>{{ 'dataSourceSelector.cancel' | translate}}</span></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-popup>