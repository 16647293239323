export class FieldMetadata {
  public key: string;
  public label: string;
  public labelDescription: string;
  public type: string;
  public range: boolean;
  public translate: boolean = false;

  public constructor(args?: Partial<FieldMetadata>) {
    Object.assign(this, args);
  }
}