export enum DataType {
    AnsiString = 0,
    Binary = 1,
    Byte = 2,
    Boolean = 3,
    Currency = 4,
    Date = 5,
    DateTime = 6,
    Decimal = 7,
    Double = 8,
    Guid = 9,
    Int16 = 10,
    Int32 = 11,
    Int64 = 12,
    Object = 13,
    SByte = 14,
    Single = 15,
    String = 16,
    Time = 17,
    UInt16 = 18,
    UInt32 = 19,
    UInt64 = 20,
    VarNumeric = 21,
    AnsiStringFixedLength = 22,
    StringFixedLength = 23,
    Unknown = 24,
    Custom = 25,
    ExternalId16 = 26,
    Identity16 = 27,
    SystemExternalId16 = 28,
    RowPosition = 29,
    TimeStamp = 30,
    Percentage = 31,
    Detail = 32,
    Identity32 = 33,
    Identity64 = 34,
    ExternalId32 = 35,
    ExternalId64 = 36,
    SystemExternalId32 = 37,
    SystemExternalId64 = 38,
    Float = 39,
    Image = 40,
    Real = 41,
    SqlVariant = 42,
    Xml = 43,
    SmallDateTime = 44,
    UniqueIdentifier = 45
}