import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { AuditState } from 'src/app/pages/audit/auditState';
import { ThreeStatesCheckBoxOrder } from './threeStatesCheckBoxOrder';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [
    './checkbox.component.scss',
    '../../controls.scss'
  ]
})
export class CheckboxComponent extends BaseControlComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private readonly auditState: AuditState,
		private readonly translate: TranslateService){
    super();

    let threeStatesCheckboxesOrder: string = environment.UI.threeStatesCheckboxesOrder || ThreeStatesCheckBoxOrder.IndeterminateChecked;

    this.threeStatesOrder = ThreeStatesCheckBoxOrder[threeStatesCheckboxesOrder as string];
  }

  private alertConditionChangedSubscription: Subscription;

  private threeStatesOrder: ThreeStatesCheckBoxOrder;

  text: string;

  readOnly: boolean = false;

  // Define checkBox with three state | DevExpress Support
  // https://supportcenter.devexpress.com/ticket/details/t482022/define-checkbox-with-three-state
  // Le Three states n'est pas supporté de base automatiquement.
  // Il faut donc simuler en détectant si le mode three states est activé et si oui, si la case à cocher
  // est cochée lorsqu'elle est à l'état checked et si oui, transitionné vers l'état undetermined.
  handleValueChange(e) {
    if (e.component.skipOnValueChanged) {
      e.component.skipOnValueChanged = false;

      return;
    }

    if (this.threeState){
      if (this.threeStatesOrder == ThreeStatesCheckBoxOrder.IndeterminateUnchecked){
        if (e.previousValue === undefined){
          e.component.skipOnValueChanged = true;

          this.formControl.setValue(false);
        }
        else if (!e.previousValue)
          this.formControl.setValue(true);
        else
        {
          e.component.skipOnValueChanged = true;

          this.formControl.setValue(undefined);
        }
      }
      else{
        if (e.previousValue === undefined){
          this.formControl.setValue(true);
        }
        else if (!e.previousValue){
          e.component.skipOnValueChanged = true;

          this.formControl.setValue(undefined);
        }
        else
        {
          this.formControl.setValue(false);
        }
      }

      this.checkForAlert();

      // The return is important here because this indicates to DevExpress to stop trying to change the value.
      // This is the hack to handle three states.
      return;
    }

		this.checkForAlert();
  }

  private checkForAlert(){
    const controlValue = this.formControl.value;

    this.input.referenceTableId = this.auditState.customTableId;

    this.input.value = controlValue == undefined ? null : controlValue;
  }

  threeState : boolean;

	ngAfterViewInit(): void {
		super.ngAfterViewInit();

		// Three state checkbox with DevExpress requires 'undefined' for the undetermined state.
		// However, the value stored in IndexedDB is null for this state.
		// Therefore, the value must be set to undefined in the control.
		if (this.input.value === null)
			this.formControl.setValue(undefined);
  }

  async ngOnInit(): Promise<void>{
    super.ngOnInit();

    this.threeState = super.getExtendedProperty("ThreeState", false) as boolean;

    if (!super.getExtendedProperty("HideText", false) as boolean) {
      this.text = this.input.description;
    }

    this.readOnly = this.auditState.readonly || this.input.readOnly;

    if (this.input.alert){
      this.alertConditionChangedSubscription = this.input.$onAlertConditionChanged.subscribe(async args => {
				let formattedValue = this.formControl.value;

				if (formattedValue === undefined || formattedValue === null)
					formattedValue = "";
				else
        	formattedValue = formattedValue ? this.translate.instant("alerts.yes") : this.translate.instant("alerts.no");

        this.auditState.addAlertSummary(this.input, args.condition?.id, formattedValue, this.auditState.instanceId ? this.auditState.customTableId : "");
      });
    }
  }

  ngOnDestroy(): void {
    this.alertConditionChangedSubscription?.unsubscribe();
  }
}
