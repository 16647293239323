<div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
  <div class="layout-left">
    {{ 'profilePage.infoSection.toolbarTitle' | translate}}
  </div>
</div>
<div class="zone-content">
  <div class="img-holder" *ngIf="false">
    <img class="image" [src]="userAccount?.imagePath ? userAccount?.imagePath : './assets/placeholderImage_40.png' "
      alt="{{'profilePage.infoSection.imageAltText' | translate}}" />
    <div class="img-overlay">
      <button class="btn-image" (click)="goToProfilePicture()">
        <i class="bi bi-plus btn-image-icon"></i>
      </button>
    </div>
  </div>

  <label for="name" class="label">{{"profilePage.infoSection.name" | translate}}</label>
  <dx-text-box id="name" class="text-box" [maxLength]="50" [readOnly]="!canEditProfile" [showClearButton]="true"
    [value]="userAccount?.name">
    <dx-validator (onValidated)="nameValueChanged($event)">
      <dxi-validation-rule type="required" message="{{'profilePage.infoSection.nameRequiredMessage' | translate}}">
      </dxi-validation-rule>
    </dx-validator>
  </dx-text-box>

  <label for="user-role-text-box" class="label">{{"profilePage.infoSection.title" | translate}}</label>
  <dx-text-box id="user-role-text-box" class="text-box" [maxLength]="50" [readOnly]="!canEditProfile"
    [showClearButton]="true" [value]="userAccount?.title" (onValueChanged)="roleValueChanged($event)">
  </dx-text-box>
  <label for="email" class="label">{{"profilePage.infoSection.email" | translate}}</label>
  <dx-text-box id="email" class="text-box" [maxLength]="100" [readOnly]="!canEditProfile" [showClearButton]="true"
    [value]="userAccount?.email">
    <dx-validator (onValidated)="emailValueChanged($event)">
      <dxi-validation-rule type="email" message="{{'profilePage.infoSection.emailInvalidMessage' | translate}}">
      </dxi-validation-rule>
    </dx-validator>
  </dx-text-box>

  <label for="phone-text-box" class="label">{{"profilePage.infoSection.phone" | translate}}</label>
  <div class="phone-input-area">
    <dx-text-box id="phone-text-box" class="text-box" mask="(000) 000-0000"
      maskInvalidMessage="{{'profilePage.infoSection.phoneInvalidMessage' | translate}}" [readOnly]="!canEditProfile"
      [value]="userAccount?.phone" [useMaskedValue]="true">
      <dx-validator (onValidated)="phoneValueChanged($event)">
        <dxi-validation-rule type="pattern" [pattern]="phonePattern"
          message="{{'profilePage.infoSection.phoneInvalidMessage' | translate}}"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
    <label class="ext-label">ext.</label>
    <dx-text-box id="ext-text-box" class="text-box" [maxLength]="10" [readOnly]="!canEditProfile" mask="##########"
      showMaskMode="onFocus" maskChar=" " [value]="userAccount?.extension"
      (onValueChanged)="extensionValueChanged($event)">
    </dx-text-box>
  </div>
</div>

<div>
  <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
    <div class="layout-left">
      {{ 'profilePage.accessSection.title' | translate}}
    </div>
  </div>
  <div class="zone-content">
    <label for="user-type-text-box" class="label">{{"profilePage.infoSection.type" | translate}}</label>
    <dx-select-box id="user-type-text-box" class="text-box" [dataSource]="userTypes" [readOnly]="!isAdministrator"
      valueExpr="id" displayExpr="text" [value]="userAccount?.typeId" (onValueChanged)="typeValueChanged($event)">
    </dx-select-box>
  </div>
</div>

<div>
  <div class=" layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
    <div class="layout-left">
      {{ 'profilePage.parameters.title' | translate}}
    </div>
  </div>
  <div class="zone-content">
    <div>
      <label>{{ 'profilePage.parameters.language' | translate }}</label>
    </div>
    <select (change)="changeLanguage($event.target.value)" [(ngModel)]="language">
      <option value="fr">Français</option>
      <option value="en">English</option>
    </select>
  </div>
</div>

<div>
  <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
    <div class="layout-left">
      Accessibility
    </div>
  </div>
  <div class="zone-content">
    <div>
      <label> Taille de l'interface</label>
    </div>
    <input type="range" id="volume" name="volume" min="50" max="300" [(ngModel)]="fontSize"
      (change)="changeFontSize($event.target.value)">
    <label>{{fontSize}}%</label>
  </div>
</div>