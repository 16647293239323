import _ from "lodash";
import { DynamicTabAuditTemplate } from "src/app/core/data/models/database/dynamicTabAuditTemplate.database";
import { DynamicInstance } from "src/app/core/data/models/form/dynamicInstance";
import { DynamicSection } from "src/app/core/data/models/form/dynamicSection";
import { Folder } from "src/app/core/data/models/form/folder";
import { Section } from "src/app/core/data/models/form/section";
import { DynamicTabFolderViewModel } from "./dynamicTabFolderViewModel";
import { DynamicTabInstanceViewModel } from "./dynamicTabInstanceViewModel";
import { SectionViewModel } from "./sectionViewModel";

export class SectionMapper {
  async map(sections: Section[]) {
    let result = new Array<SectionViewModel>();

    for (let section of sections) {
      const sectionViewModel = new SectionViewModel({
        id: section.id,
        description: section.description,
        type: section.type,
        dataTableName: section.dataTableName,
        instancesVisible: true
      });

      if ((section as DynamicSection).folders) {
        let dynamicSection = section as DynamicSection;

        for (const folder of _.sortBy(dynamicSection.folders, x => x.position)) {
          sectionViewModel.folders.push(this.mapFolder(folder, section.id))
        }
      }

      result.push(sectionViewModel);
    }

    return result;
  }

  public mapFolder(folder: Folder, sectionId: string) {
    const folderViewModel = new DynamicTabFolderViewModel({
      id: folder.id,
      idKey: folder.idKey,
      description: folder.description,
      sectionId: sectionId,
      templateId: folder.templateId,
      instancesVisible: true,
			tableName: folder.tableName,
			maximumItems: folder.maximumItems
    });

		if (folder.instances){
			folderViewModel.instances = [];
	
			for (const instance of _.sortBy(folder.instances, x => x.position)) {
				folderViewModel.instances.push(new DynamicTabInstanceViewModel({
					id: instance.id,
					number: instance.number,
					description: instance.description,
					folder: folderViewModel,
					customTableId: instance.customTableId
				}))
			}
		}

    return folderViewModel;
  }
}