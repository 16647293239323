<div [ngSwitch]="input.controlType" class="zone" [formGroup]="form" appSectionFieldRequired [fieldControlName]="input.key" [sectionFieldRequiredVisible]="showRequiredFieldNotification">
  <div *ngSwitchCase=" customFieldControlTypes.TextBox">
    <app-textbox [input]="input" [form]="form"></app-textbox>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.MaskedEditTextBox">
    <app-textbox [input]="input" [form]="form"></app-textbox>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.Label">
    <app-label [input]="input" [form]="form"></app-label>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.CheckBox">
    <app-checkbox [input]="input" [form]="form"></app-checkbox>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.GroupBox">
    <app-groupbox [input]="input" [form]="form"></app-groupbox>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.Panel">
    <app-panel [input]="input" [form]="form"></app-panel>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.FormattedLinkLabel">
    <app-formattedlabel [input]="input" [form]="form"></app-formattedlabel>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.DateTimePicker">
    <app-datetimepicker [input]="input" [form]="form"></app-datetimepicker>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.ComboBox">
    <app-customfield-dropdown [input]="input" [form]="form"></app-customfield-dropdown>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.PictureBox">
    <app-picture-box [input]="input" [form]="form"></app-picture-box>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.RadioButton">
    <app-radiobutton [input]="input" [form]="form"></app-radiobutton>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.Signature">
    <app-signature [input]="input" [form]="form"></app-signature>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.RichTextBox">
    <app-richtexteditor [input]="input" [form]="form"></app-richtexteditor>
  </div>

  <div *ngSwitchCase="customFieldControlTypes.Button">
    <app-custom-field-button [input]="input" [form]="form"></app-custom-field-button>
  </div>

  <div *ngSwitchDefault class="alert alert-warning">
    Contrôle non-géré: {{input.key}}/{{input.description}}
  </div>
</div>

<app-alert-template *ngIf="input.alertControlCondition" [input]="input">
</app-alert-template>

<app-instructions-button *ngIf="input.instructionId" [input]="input"></app-instructions-button>