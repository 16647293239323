import { Component, OnInit } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from "devextreme/data/array_store";
import { AuditState } from '../auditState';
import { AlertSummaryMapper } from './alertSummaryMapper';
import { UserService } from 'src/app/core/services/userService';


@Component({
  selector: 'app-alert-summary',
  templateUrl: './alert-summary.component.html',
  styleUrls: ['./alert-summary.component.scss']
})
export class AlertSummaryComponent implements OnInit {
  public dataSource: DataSource;

  constructor(
    public readonly auditState: AuditState,
    public userService: UserService,
    private readonly mapper: AlertSummaryMapper
  ) { }

  async ngOnInit(): Promise<void> {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        data: await this.mapper.map(this.auditState.alertSummary),
        key: "id"
      }),
      group: "category",
      searchExpr: ["controlDescription", "value", "createdBy"]
    });
  }
}