<div class="layout-vertical">
  <div class="layout-center">
    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.systemInformationSection' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.versionTitle' | translate }}</label>
      <div class="font-small font-gray">{{ 'systemInformation.versionDescription' | translate }}</div>
      <div>{{environment.version}}</div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.storageLabel' | translate }}</label>
      <div class="font-small font-gray">{{ 'systemInformation.storageDescription' | translate }} </div>
      <div>{{storageState.availableStorage}}</div>
    </div>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.deviceInformationSection' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.browserNameLabel' | translate }}</label>
      <div class="font-small font-gray">{{ 'systemInformation.browserNameDescription' | translate }}</div>
      <div>{{ platform.name }}</div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.browserVersionLabel' | translate }}</label>
      <div class="font-small font-gray">{{ 'systemInformation.browserVersionDescription' | translate }}</div>
      <div>{{ platform.version }}</div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.operatingSystemLabel' | translate }}</label>
      <div class="font-small font-gray">{{ 'systemInformation.operatingSystemDescription' | translate }}</div>
      <div>{{ platform.os }}</div>
    </div>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.synchronizationSection' | translate }}
      </div>
    </div>

    <div class="padding-normal">
      <label>{{ 'systemInformation.synchronizationStatusLabel' | translate }}</label>
      <div class="font-small font-gray">{{ 'systemInformation.synchronizationStatusDescription' | translate }}</div>
      <div>{{ synchronizationContext.status }}</div>
    </div>

    <div class="padding-normal">
      <button id="synchronizationButton" class="pill color-set-orange" (click)="synchronizationService.getUpdates(true, false)"
        [disabled]="!navigator.onLine && !isDatabaseAvailable">
        <div>
          <span> {{'synchronizationContext.synchronize' | translate}}</span>
        </div>
      </button>
    </div>

    <div class="layout-horizontal height-normal padding-horizontal-normal color-set-lightblue">
      <div class="layout-left">
        {{ 'systemInformation.databaseSection' | translate }}
      </div>
    </div>

    <div>
      <div class="layout-horizontal padding-horizontal-normal height-normal color-set-lightblue">
        <div class="layout-left">
          {{ 'profilePage.administration.title' | translate}}
        </div>
      </div>
      <div class="zone-content">
        <div>
          <label for="user-type-text-box" class="label">{{ 'profilePage.administration.databaseAdministration' |
            translate}}</label>
        </div>
        <div class="layout-horizontal colors-set-white">
          <div class="layout-left">
            <button name="resetButton" class="color-set-invert-red" (click)="databaseResetPopup.display()">
              <div><span>{{ 'profilePage.resetDatabasePopup.reset' | translate}}</span></div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-popup #databaseResetPopup>
      <div class="layout-horizontal color-set-blue">
        <div class="layout-center align-left">
          <button><i class="fas fa-trash"></i>
            <div><span> {{ 'profilePage.resetDatabasePopup.title' | translate }}</span></div>
          </button>
        </div>
        <div class="layout-right">
          <button><i class="fas fa-times" (click)="databaseResetPopup.close()"></i></button>
        </div>
      </div>
      <div class="content">
        <div>
          {{ 'profilePage.resetDatabasePopup.message' | translate}}
        </div>
        <div class="layout-horizontal color-set-white padding-vertical-compact">
          <div class="layout-right space">
            <button name="cancelButton" class="compact color-set-blue" (click)="databaseResetPopup.close()">
              <div><span>{{ 'profilePage.resetDatabasePopup.cancel' | translate}}</span></div>
            </button>
            <button name="resetButton" class="compact color-set-invert-red" (click)="databaseContext.resetDatabase()">
              <div><span>{{ 'profilePage.resetDatabasePopup.confirmReset' | translate}} </span></div>
            </button>
          </div>
        </div>
      </div>
    </app-popup>
  </div>