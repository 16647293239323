import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { AuditMessage } from 'src/app/core/data/models/database/auditMessage.database';
import { FormField } from 'src/app/core/data/models/formField';
import { InstructionState } from '../instructionState';

@Component({
  selector: 'app-instructions-button',
  templateUrl: './instructions-button.component.html',
  styleUrls: ['./instructions-button.component.scss']
})
export class InstructionsButtonComponent implements OnInit {

  @Input() input: FormField<any> = new FormField<any>();

  summary: string;

  constructor(private baseRepository: BaseRepository, private instructionState: InstructionState) { }

  async ngOnInit(): Promise<void> {
    let auditMessage = await this.baseRepository.get(AuditMessage.table, this.input.instructionId)

    this.summary = auditMessage.summary;
  }

  showInstructionsPopup() {
    this.instructionState.popupShowInstructionEvent.next("__" + this.input.key + "__");
  }
}
