<div class="field-zone" [style.width.px]="width + 100">
  <label class="field" *ngIf="input.generateLabel">{{input.description}}</label>

  <div class="layout-horizontal height-normal color-set-lightblue" *ngIf="!readOnly">
    <div class="layout-right">
      <button *ngIf="allowEditImage && canEditImage" (click)="onReturnToOriginalImageButtonClick()"
        title="{{'components.customFields.pictureBox.revertToOriginal' | translate}}">
        <i class="fas fa-backspace"></i></button>
      <button *ngIf="(allowTakePhoto || allowSelectImage) && allowEditImage && canEditImage" class="pictureBoxButton"
        (click)="cropButtonClick()" title="{{'components.customFields.pictureBox.crop' | translate}}">
        <i class="fas fa-crop-alt"></i></button>
      <button *ngIf="allowEditImage && canEditImage" class="pictureBoxButton" (click)="showMarkerArea()"
        title="{{'components.customFields.pictureBox.annotate' | translate}}">
        <i class="fas fa-highlighter"></i></button>
      <button *ngIf="allowEditImage && canEditImage" class="pictureBoxButton" (click)="onLeftRotationClick()"
        title="{{'components.customFields.pictureBox.rotateLeft' | translate}}">
        <i class="fa fa-undo"></i></button>
      <button *ngIf="allowEditImage && canEditImage" class="pictureBoxButton" (click)="onRightRotationClick()"
        title="{{'components.customFields.pictureBox.rotateRight' | translate}}">
        <i class="fa fa-redo"></i></button>
      <button class="pictureBoxButton" (click)="openCompressPopup()"
        title="{{'components.customFields.pictureBox.qualityChange' | translate}}">
        <i class="fa fa-file-zipper"></i></button>
      <button *ngIf="allowTakePhoto" (click)="camera.open()" title="{{ 'components-customFields-pictureBox-takePicture' | translate}}">
        <i class="fa fa-camera"></i>
      </button>
      <button *ngIf="allowSelectImage" (click)="imageInput.click()" class="pictureBoxButton"
        title="{{'components.customFields.pictureBox.openFile' | translate}}">
        <i class="fa fa-image"></i></button>
      <button *ngIf="allowTakePhoto" (click)="deleteClick()" title="{{'components-customFields-pictureBox-clearImage' | translate}}">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>

  <div [style.width.px]="width + 80">
    <!--
             Two images are required in order to restore the state
             of the annotations once they have been rendered.

             https://markerjs.com/demos/save-state
        -->
    <div class="images" style="position: relative; display: flex;">
      <img [id]="'source-img-' + input.dataColumnName" [src]="originalImageContainer.value" class="border scale-image"
        style="visibility: hidden;" />
      <img [id]="'sample-img-' + input.dataColumnName" style="position: absolute;" [src]="image"
        class="border scale-image" />
    </div>

    <app-camera #camera (photoChange)="onPictureTaken($event)">
    </app-camera>

    <input #imageInput style="display: none" type="file" accept="image/*" (change)="selectImage($event)" (click)="resetImageInputValue()" #cameraInput>
  </div>

  <dx-popup [width]="'auto'" [height]="'auto'" [deferRendering]="false" [dragEnabled]="false"
    [closeOnOutsideClick]="true" [showCloseButton]="false" [(visible)]="clearAnnotationsPopupVisible">
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="center"
      [options]="clearAnnotationsPopupConfirmButtonOptions">
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="center"
      [options]="clearAnnotationsPopupCloseButtonOptions">
    </dxi-toolbar-item>
    <dxo-position at="center" my="center">
    </dxo-position>
    <div *dxTemplate="let data of 'title'">
      <div class="container-header noPadding"></div>
    </div>
    <div class="popup-content">
      {{clearAnnotationsPopupMessage}}
    </div>
  </dx-popup>
</div>

<app-compression-popup #compressionPopup></app-compression-popup>