import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserHttpClient {
  private configUrl = `${environment.apiBaseUrl}/User`;

  constructor(private http: HttpClient) { }

  async getUserId(): Promise<string> {
    return await this.http.get<string>(`${this.configUrl}/GetUserInfo`).toPromise();
  }
}