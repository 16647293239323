import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class StorageState {
  public available: boolean;
  public isPersisted: boolean;
  public estimatedQuota: number;
  public estimatedUsage: number;
  public estimatedPercentage: number;

  public availableStorage: string
}