// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class WFStepExecutor extends ChangeTrackingEntity {
  public static table: Dexie.Table<WFStepExecutor, string>;
  public static tableName: string = 'WFStepExecutor';

  public canModify: boolean;  public canDelete: boolean;  public canAttributeStep: boolean;  public id: string;  public userGroupId: string;  public wFStepId: string;  public timeStamp: Date;

  public constructor(args?: Partial<WFStepExecutor>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return WFStepExecutor.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(WFStepExecutor.tableName);
      this.table.mapToClass(WFStepExecutor);
  }
}
