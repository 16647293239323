import { EntitySecurityGroup } from "src/app/core/data/models/database/entitySecurityGroup.database";
import { EntitySecurityGroupPermission } from "./entitySecurityGroupPermission";
import { UserAccountGroup } from "src/app/core/data/models/database/userAccountGroup.database";
import _ from "lodash";
import { Injectable } from "@angular/core";

@Injectable()
export class EntitySecurityGroupPermissionRepository{
  static TaskType: string = "TaskType";
  static OptionListAllowedEditors: string = "OptionListAllowedEditors";
  static OptionListVisibleBy: string = "OptionListVisibleBy";

  /**
  * Returns a list of all entity security group associated to a specific source and 
  * fill each of those items with all allowed users.
  * 
  * @param source - Source of the entity which contains all entity groups of the same type.
  * @param excludedEntitySecurityGroupIds - Optional list of entity security group ids to exclude.
  */
  async loadEntitySecurityGroupPermissions(source: string, excludedEntitySecurityGroupIds?: string[]) : Promise<EntitySecurityGroupPermission[]>{
    let entitySecurityGroups = await EntitySecurityGroup.table.where("source").equals(source).toArray();
    let userAccountGroups = await UserAccountGroup.table.toArray();

    if (excludedEntitySecurityGroupIds){
      entitySecurityGroups = entitySecurityGroups.filter(x => !excludedEntitySecurityGroupIds.includes(x.id));
    }

    let result: EntitySecurityGroupPermission[] = [];

    for (const entitySecurityGroup of entitySecurityGroups) {
      let entityUserAccountGroups = userAccountGroups.filter(x => x.groupId === entitySecurityGroup.userGroupId);

      let item: EntitySecurityGroupPermission;

      item = result.find(x => x.entityId == entitySecurityGroup.entityId);

      let userIds = _.map(entityUserAccountGroups, x => x.userId);

      if (item){
        item.userIds.push(...userIds);
      }
      else{
        item = new EntitySecurityGroupPermission();

        item.entityId = entitySecurityGroup.entityId;
        item.userIds = userIds;
      }
      
      result.push(item);
    }

    return result;
  }

  /**
  * Indicates whether the user has access to the specified entity.
  * 
  * @param entityId - Id of the entity to validate access.
  * @param userId - Id of the user to validate access.
  * @param source - Source of the entity which contains all entity groups of the same type.
  * @param excludedEntitySecurityGroupIds - Optional list of entity security group ids to exclude.
  */
  async hasAccess(entityId: string, userId: string, source: string, excludedEntitySecurityGroupIds?: string[]){
    let permissions = this.loadEntitySecurityGroupPermissions(source, excludedEntitySecurityGroupIds);

    let permission = (await permissions).find(x => x.entityId == entityId)

    if (permission){
      return permission.hasAccess(userId);
    }
    else{
      return true;
    }
  }

  /**
  * Indicates whether the user in the the specified group.
  */
  async inInGroup(userId: string, groupId: string){
    let userAccountGroups = await UserAccountGroup.table.toArray();

    return !!userAccountGroups.find(x => x.userId == userId && x.groupId == groupId);
  }
}