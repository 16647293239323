import { Component, OnInit } from '@angular/core';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { ApplicationLog } from 'src/app/core/data/models/database/applicationLog.database';
import { AuditDataTable } from 'src/app/core/data/models/database/auditDataTable.database';
import { ChangeTracking } from 'src/app/core/data/models/database/changeTracking.database';
import { Mobile } from 'src/app/core/data/models/database/mobile.database';
import { SynchronizationService } from 'src/app/core/data/synchronization/synchronizationService';
import { SynchronizationStatus } from 'src/app/core/data/synchronization/synchronizationStatus';
import { AuthenticatedUser } from 'src/app/core/security/authenticatedUser';
import { SynchronizationContext } from 'src/app/core/data/synchronization/synchronizationContext';

@Component({
  selector: 'app-synchronization-error',
  templateUrl: './synchronization-error.component.html',
  styleUrls: ['./synchronization-error.component.scss']
})
export class SynchronizationErrorComponent implements OnInit {
  public mobile: Mobile;
  public changeTrackingMessage: string;
  public unreachableMessage: string;
  public authenticatedUser: AuthenticatedUser;

  public SynchronizationStatus = SynchronizationStatus;

  constructor(
    public synchronizationContext: SynchronizationContext,
    public synchronizationService: SynchronizationService,
    public baseRepository: BaseRepository) { }

  async ngOnInit(): Promise<void> {
    if (this.synchronizationContext.mobileId) {
      this.mobile = await this.baseRepository.get(Mobile.table, this.synchronizationContext.mobileId);

      let changeTrackings = await ChangeTracking.table.toArray()
      let changeTrackingWithoutLogs = changeTrackings.filter(changeTracking => changeTracking.tableName !== ApplicationLog.name);

      if (await changeTrackingWithoutLogs.length > 0) {
        let tableName = changeTrackingWithoutLogs[0].tableName === AuditDataTable.tableName ? JSON.parse(changeTrackingWithoutLogs[0].dataValues).TableName : changeTrackingWithoutLogs[0].tableName;
        this.changeTrackingMessage = `Change #${changeTrackingWithoutLogs[0].id} for table ${tableName}`;
      }
    }
  }
}