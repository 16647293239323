import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Resolve, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY } from "rxjs";
import { BaseRepository } from "src/app/core/data/baseRepository";
import { Program } from "src/app/core/data/models/database/program.database";
import { ProgramTemplateDocument } from "src/app/core/data/models/database/programTemplateDocument.database";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { ProgramVersionRepository } from "src/app/core/data/repositories/programVersionRespository";

import { SynchronizationHttpClient } from "src/app/core/data/synchronization/synchronizationHttpClient";
import { SynchronizationService } from "src/app/core/data/synchronization/synchronizationService";
import { SectionConst } from "src/app/core/sections/sectionConst";
import { SectionStateService } from "src/app/core/services/sectionStateService";
import { SynchronizationContext } from "src/app/core/data/synchronization/synchronizationContext";
import { AuditService } from "src/app/pages/audit/auditService";
import { AuditState } from "src/app/pages/audit/auditState";

@Injectable()
export class AuditResolver implements Resolve<void> {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private auditService: AuditService,
    private auditState: AuditState,
    private baseRepository: BaseRepository,
    private synchronizationService: SynchronizationService,
    private synchronizationContext: SynchronizationContext,
    private synchronizationHttpClient: SynchronizationHttpClient,
    private programVersionRepository: ProgramVersionRepository,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    let auditNumber = route.paramMap.get('auditNumber');

    if (navigator.onLine) {
      await this.synchronizationHttpClient.subscribeToAudit(auditNumber);
      
      // Check if the route is forms/:auditNumber/copy or forms/:auditNumber/delete
      // to hide synchronization popup 
      let splitUrl = this.currentUrl.split("/");
      let lastUrlElement = splitUrl[splitUrl.length - 1];
      if (lastUrlElement === "copy" || lastUrlElement === "delete")
        await this.synchronizationService.getUpdates(false, false);
      else
        await this.synchronizationService.getUpdates(true, false);
    }

    let audit = await this.auditService.getAuditByNumber(auditNumber);

    if (!audit) {
      this.router.navigateByUrl(`forms/notfound/${auditNumber}`);

      return EMPTY.toPromise();
    }

    let program = await this.baseRepository.get(Program.table, audit.programId);

    // This is currently done to fix an issue where auditState seems to already exists
    // while it should be a new instance. In this case I reset the active section to null
    // to ensure it update with the default first section of the form opened.
    this.auditState.section = null;

    this.auditState.audit = audit;
    await this.auditState.updateWorkflowStep(audit.stepId);

    this.auditState.program = program;

    // TODO AC: Extract logic to service as mutitple places do the same
    this.auditState.auditNumber = audit.number

    let programVersion = await this.programVersionRepository.getLatestProgramVersion(this.auditState.audit.programId);

    let form = JSON.parse(programVersion.webModel);

    this.auditState.form = form;

    this.auditState.documents = await ProgramTemplateDocument.table.where({ programId: form.id }).filter(x => !x.isDeleted && !x.dynamicTabTemplateKey).toArray()

    this.loadSystemSections(form);

    await this.auditService.loadDynamicTabInstances(audit.id, form);

    await this.auditState.refreshState();

    return;
  }

  private loadSystemSections(form: any) {
    let informationTitle = this.translateService.instant("audit.informationSectionTitle");

    form.sections.unshift(
      new Section({
        id: SectionConst.information,
        name: informationTitle,
        description: informationTitle,
        type: SectionType.Undefined,
      })
    );

    if (this.auditState.documents.length > 0) {
      let reportTitle = this.translateService.instant("audit.reportSectionTitle");

      form.sections.push(new Section({
        id: SectionConst.reports,
        name: reportTitle,
        description: reportTitle,
        type: SectionType.Documents
      }));
    }
  }
}
