<form class="layout-vertical" [formGroup]="groupForm">
  <app-header-detail class="layout-top" [title]="pageTitle" [subTitle]="'groups.title' | translate" [useBackButton]="true"
    (leftButtonClick)="backLeave()" (profilButtonClick)="profilLeave()"></app-header-detail>

  <div class="layout-center">
    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-horizontal-normal">
        {{ 'groups.informations' | translate }}
      </div>
    </div>

    <div class="zone-content">
      <label class="label" fieldControlName="name" translate="groups.infoSection.name"></label>
      <dx-text-box class="text-box" [maxLength]="20" [showClearButton]="true" formControlName="name">
      </dx-text-box>

      <label class="label" fieldControlName="description" translate="groups.infoSection.description"></label>
      <dx-text-box class="text-box" [maxLength]="100" [showClearButton]="true" formControlName="description">
      </dx-text-box>
    </div>

    <div class="layout-horizontal height-normal color-set-lightblue">
      <div class="layout-left padding-horizontal-normal">
        {{ 'groups.permissions' | translate }}
      </div>
    </div>

    <div *ngFor="let permissionListGroup of permissionsListGroup; let i = index">
      <div class="padding-horizontal-normal color-set-gray">
        {{ permissionListGroup.code | translate }}
      </div>

      <app-list attr.id="permissionList{{i}}" class="listDefaultStyle" [items]="permissionsList[i]"
        [numberOfSelectableItem]="-1" [numberOfRequiredItems]="0" [hoverable]="false" [selectionEnabled]="true"
        [(selectedItems)]="selectedPermissionsList[i]" (selectedItemsChange)="permissionSelected()" [showHeader]="false" [highLightSelectedItems]="false">

        <ng-template #listTemplate let-item>
          <div class="layout-horizontal defaultPadding">
            {{item.code | translate}}
          </div>
        </ng-template>

      </app-list>
    </div>

    <div class="padding-vertical-normal">
      <div class="layout-horizontal height-normal color-set-lightblue">
        <div class="layout-left padding-horizontal-normal">
          {{ 'groups.usersTitle' | translate }}
        </div>

        <div class="layout-right">
          <button (click)="addUser()">
            <i class="fas fa-plus"></i>
          </button>

          <button class="color-set-transparent" (click)="deleteUser()" [disabled]="!selectedUsers?.length">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <app-list #userList class="listDefaultStyle" [dataSource]="userDatasource" [numberOfSelectableItem]="-1"
      [numberOfRequiredItems]="0" [(selectedItems)]="selectedUsers" [hoverable]="true" [selectionEnabled]="true" [showHeader]="false" [highLightSelectedItems]="false">

      <ng-template #listTemplate let-item>
        <div class="layout-horizontal defaultPadding">
          <div class="layout-left">
            <button class="round compact color-set-gray margin-right">
              <i>{{userService.getInitials(item.name)}}</i>
            </button>

            <div>
              <div class="name font-bold">
                {{item.name}}
              </div>
              <div class="title">
                {{item.title}}
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </app-list>
  </div>

  <div class="layout-bottom color-set-lightblue height-normal">
    <div class="layout-horizontal padding-horizontal-normal height-normal">
      <div class="layout-right">
        <div *ngIf="!groupForm.dirty">
          Aucun changement
        </div>
        <button class="color-set-transparent" (click)="save()" [disabled]="!groupForm.dirty">
          <i class="fas fa-save"></i>
        </button>
      </div>
    </div>
  </div>

  <app-add-user #addUserComponent (usersAdded)="updateUsers($event)"></app-add-user>
</form>

<app-modal #canLeavePopup [canClose]="true" [boxFixed]="true"></app-modal>