import { Form } from "src/app/core/data/models/form/form";
import { Section } from "src/app/core/data/models/form/section";
import { SectionType } from "src/app/core/data/models/form/sectionType";
import { SectionConst } from "src/app/core/sections/sectionConst";
import mockJson from "./mockForm.json"

export class FormService {
  private static form: Form;

  getFormByAudit(auditId: string): Form {
    if (!FormService.form) {
      let result = mockJson as Form;

      result.sections.unshift(
        new Section({
          id: SectionConst.information,
          name: "Informations",
          description: "Informations",
          type: SectionType.Undefined,
        })
      );

      FormService.form = result;
    }

    return FormService.form;
  }
}
