export class FormFieldExtendedValue{
  key: string;
  dataColumnName: string;
  value: any;

  public getValue(): any{
    return this.value;
  }

  public constructor(args?: Partial<FormFieldExtendedValue>) {
    Object.assign(this, args);
  }
}
