import { Injectable } from "@angular/core";
import { AuthenticatedUser } from "src/app/core/security/authenticatedUser";
import { IFilterExpressionFormatter } from "./iFilterExpressionFormatter";
import { UserFilterExpressionFormatter } from "./userFilterExpressionFormatter";

@Injectable({providedIn: "root"})
export class FilterExpressionFormatterFactory{
  private _authenticatedUser: AuthenticatedUser;

  constructor(authenticatedUser: AuthenticatedUser) {
    this._authenticatedUser = authenticatedUser;  
  }

  public create(): IFilterExpressionFormatter[]{
    let filterExpressionFormatters = [];

    filterExpressionFormatters.push(new UserFilterExpressionFormatter(this._authenticatedUser));

    return filterExpressionFormatters
  }
}