import { ListSortGroup } from "./listSortGroup";

export class ListOptions {
  public groupBy: ListSortGroup[] = [];
  public sortBy: ListSortGroup[] = [];

  public mapItemFunction: string;
  public mapGroupFunction: string;

  public createMapItemFunction(): Function{
    return new Function("item", "properties", this.mapItemFunction);
  }

  public createMapGroupFunction(): Function{
    return new Function("group", "properties", this.mapGroupFunction);
  }

  public constructor(args?: Partial<ListOptions>) {
    Object.assign(this, args);
  }
}