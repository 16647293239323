import { PrimaryPopupButton, SimplePopupButton } from "./simplePopupButton";

export class SimplePopupOptions {
  titleIcon: string = "fas fa-info-circle";
  title: string;
  content: string;
  buttons = new Array<SimplePopupButton>(new PrimaryPopupButton({ key: "ok", description: "OK", class: "primary" }));
  onModalResult: Function;

	asyncResultExecutor: Function;
	asyncResult: Promise<string> = new Promise<string>((result) =>{
		this.asyncResultExecutor = result;
	});

  public constructor(args?: Partial<SimplePopupOptions>) {
    Object.assign(this, args);
  }
}