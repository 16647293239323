<div class="list-header container-header">
    <div class="container-header-left-section">
        <p class="container-header-title noPadding">{{'customFieldValueListPage.listTitle' | translate}}</p>
    </div>
    <div class="container-header-center-section"></div>
    <a class="container-header-right-section">
        <div class="container-header-icon-container" (click)="baseListDetailsState.goToNew()">
            <i class="bi bi-plus-lg container-header-icon"></i>
        </div>           
    </a>
</div>
<div class="list section">
    <dx-list class="data-sources"
        [dataSource]="baseListDetailsState.dataSource"
        [searchEnabled]="true"
        searchExpr="name"
        searchMode="contains"
        pageLoadMode="scrollBottom"
        (onItemClick)="baseListDetailsState.onListItemClick($event)"
        height="750px">
        <dxo-search-editor-options
            placeholder="{{'customFieldValueListPage.searchPlaceholder' | translate}}">
        </dxo-search-editor-options>
        <div *dxTemplate="let data of 'item'">
            <div class="list-item-content">{{data.name}}</div>
        </div>
    </dx-list>
</div>