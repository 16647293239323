import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-compression-popup',
  templateUrl: './compression-popup.component.html',
  styleUrls: ['./compression-popup.component.scss']
})
export class CompressionPopupComponent implements OnInit {

  @ViewChild("popup") popup: PopupComponent;
  @ViewChild("slider") slider: ElementRef;

  public compressionLevel: number = 0;

  constructor(private imageService: ImageService) { }

  ngOnInit(): void {
  }

  public display(): void {
    this.slider.nativeElement.value = String(this.imageService.getUserCompressionLevel() * 100);
    this.popup.display();
  }

  public close(): void {
    this.popup.close();
  }

  public saveCompressionLevel(): void {
    this.imageService.setUserCompressionLevel(parseInt(this.slider.nativeElement.value));
    this.close();
  }
}
