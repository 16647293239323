import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from "src/app/core/data/models/formField";

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  @Input() input: FormField<any> = new FormField<any>();
  @Input() form: FormGroup = new FormGroup({});
}
