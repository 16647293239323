import { Component, OnInit, Input } from '@angular/core';
import { BaseControlComponent } from "src/app/components/customFields/controls/basecontrol/basecontrol.component";
import { StringUtility } from 'src/app/core/stringUtility';
import { AuditState } from 'src/app/pages/audit/auditState';

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss']
})
export class DatetimepickerComponent extends BaseControlComponent implements OnInit {
  constructor(private auditState: AuditState) {
    super();
  }

  type: string = "date";
  displayFormat: string = this.convertDisplayFormat("");
	hideDropdownButton: boolean;
  readOnly: boolean = false;
	
  ngOnInit(): void {
    super.ngOnInit();

    this.displayFormat = this.convertDisplayFormat(super.getExtendedProperty("DisplayFormat", ""))

    let inputMask = (super.getExtendedProperty("InputMask", "") as string);

    if (inputMask) {
			let type = inputMask;

			type = StringUtility.replaceAll(inputMask, "{", "");
			type = StringUtility.replaceAll(type, "}", "");
			type = StringUtility.replaceAll(type, " ", "");
			type = StringUtility.replaceAll(type, "-", "");

      this.type = type;
    }

    this.readOnly = this.auditState.readonly;

		this.hideDropdownButton = super.getExtendedProperty("HideDropdownButton", false);
  }

  private convertDisplayFormat(format: string): string {
    switch (format) {
      case "":
      case "d":
        return "dd-MM-yyyy";
      case "g":
        return "dd-MM-yyyy HH:mm";
      case "t":
        return "HH:mm";
			case "f":
				return "EEEE, MMM dd yyyy";
      default:
        return format;
    }
  }
}
