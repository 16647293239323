// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Audit extends ChangeTrackingEntity {
  public static table: Dexie.Table<Audit, string>;
  public static tableName: string = 'Audit';

  public number: string;  public languageId: number;  public effectiveDate: Date;  public createdDate: Date;  public isDeleted: boolean;  public isCreatedFromAlertEvent: boolean;  public updatedDate: Date;  public id: string;  public programId: string;  public versionId: string;  public createdById: string;  public stepId: string;  public externalNumber: string;  public responsibleId: string;  public timeStamp: Date;

  public constructor(args?: Partial<Audit>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Audit.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Audit.tableName);
      this.table.mapToClass(Audit);
  }
}
