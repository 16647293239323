// AUTO-GENERATED
// Auto-generated file from modelUpdater.ps1, it can be edited manually, however
// be carefull, if you do so, you expect to update those change every time the model
// is updated while comparing file differences in git.

import { Dexie } from "dexie";
import { ChangeTrackingEntity } from "../../changeTracking/changeTrackingEntity";
import { DatabaseContext } from "../../databaseContext";

export class Program extends ChangeTrackingEntity {
  public static table: Dexie.Table<Program, string>;
  public static tableName: string = 'Program';

  public description: string;  public useNotApplicable: boolean;  public createdDate: Date;  public otherProgramType: string;  public note: string;  public typeId: number;  public numberPrefix: string;  public numberPadding: number;  public name: string;  public hasChangesToApply: boolean;  public tabStyle: number;  public tabViewStyle: number;  public tabLayoutStyle: number;  public useCustomFieldKeys: boolean;  public isDeleted: boolean;  public keyIdentifier: string;  public activeTabColor: number;  public hotTrackingTabColor: number;  public tabFontSize: number;  public id: string;  public defaultCheckboxAppearanceId: string;  public defaultCheckboxStyleId: string;  public defaultImageSizeId: string;  public isActive: boolean;  public allowDuplicateExternalNumber: boolean;  public timeStamp: Date;  public hasVersions: boolean;

  public constructor(args?: Partial<Program>) {
    super();
    Object.assign(this, args);
  }

  public getDatabaseTableName() {
    return Program.tableName;
  }

  public getDatabasePrimaryKeyType()  {
    return 'string';
  }

  public static selfRegister(databaseContext: DatabaseContext) {
      this.table = databaseContext.table(Program.tableName);
      this.table.mapToClass(Program);
  }
}
