
import { CustomFieldValueItem } from "src/app/core/data/models/database/customFieldValueItem.database";
import { DataType } from "src/app/core/data/models/form/dataType";

export class CustomFieldValueListViewModel {
    public id: string;
    public name: string;
    public dataType: DataType;
    public nbrDecimals?: number;
    public attribute1?: string;
    public attribute2?: string;
    public attribute3?: string;
    public attribute4?: string;
    public attribute5?: string;
    public emailNameAttribute?: number;
    public emailAddressAttribute?: number;
    public customFieldValueItems?: CustomFieldValueItem[];

    public constructor(args?: Partial<CustomFieldValueListViewModel>) {
        Object.assign(this, args);
    }
  }
