export class StringUtility {
  /**
  * String.replace only replace the first occurrence found.
  * This will replace all occurrences.
  */
  static replaceAll(replaceString: string, searchValue: string, replaceWith: string): string {
    return replaceString.replace(new RegExp(searchValue, "g"), replaceWith);
  }

  /**
  * Returns a string with each {x} tags are replaced with the corresponding indexed value.
  * This is the same as string.format in C#.
  */
  static formatString(value: string, ...args: string[]): string {
    return value.replace(/{(\d+)}/g, (match, index) => args[index] || '');
  }

	static turnToCamelCase(value: string) {
		if (value)
    	return value.substr(0, 1).toLowerCase() + value.substr(1);
		else
			return "";
  }
}
