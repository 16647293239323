import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StorageState } from "./storageState";

@Injectable()
export class StorageService {
  private readonly storageLimiter = 0.7;

  constructor(
    private storageState: StorageState,
    private translateService: TranslateService
  ) { }

  public async persist() {
    let result: boolean = false;

    if (navigator.storage && navigator.storage.persisted){
      result = await navigator.storage.persisted();

      if (!result){
        navigator.storage.persist && await navigator.storage.persist();
      }

      result = await navigator.storage.persisted();

      if (!result){
        if (navigator.permissions && navigator.permissions.query){
          const permission = await navigator.permissions.query({
            name: "persistent-storage"
          });

          if (permission.state === "prompt") {
            alert(this.translateService.instant("navigator.storage.notificationNotAllowedWarning"))
          }
        }
      }
    }

    return result;
  }

  public async updateEstimatedQuota() {
    if (navigator.storage && navigator.storage.estimate) {
      this.storageState.available = true;
      const estimation = await navigator.storage.estimate();
      // Reduce estimated quota by 30% to avoid exceeding quota limit
      this.storageState.estimatedQuota = estimation.quota * this.storageLimiter;
      // Buff estimated usage by 30% to avoid exceeding quota limit
      this.storageState.estimatedUsage = estimation.usage / this.storageLimiter;
      this.storageState.availableStorage = this.humanFileSize(this.storageState.estimatedQuota - this.storageState.estimatedUsage);
    } else {
      this.storageState.available = false;
      this.storageState.estimatedQuota = null;
      this.storageState.estimatedUsage = null;
    }

    this.storageState.isPersisted = await this.isStoragePersisted();
  }

  public async isStoragePersisted() {
    return navigator.storage && navigator.storage.persisted && await navigator.storage.persisted();
  }

  // Copied code from link below to get human readable file size
  // https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
  private humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }
}