/**
* Represents a point on the map returns by the Google Api.
*/
export class MapWaypoint{
  public constructor(args?: Partial<MapWaypoint>) {
    Object.assign(this, args);
  }

  index: number;
  text: string;
  distance: string;
  duration: string;
  letter: string;
}