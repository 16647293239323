<div class="popup" [class.show]="visible">
  <div class="mainOverlay">
    <div class="box ">
      <div class="layout-horizontal color-set-blue">
        <div class="layout-left">
          <button><i class="fas fa-sync fa-spin"></i></button>
          <span>
            {{title}}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="margin-bottom-normal">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar"
              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </div>
        <div>
          <div>
            {{message}}
          </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</div>