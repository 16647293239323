<nav class="layout-horizontal height-large color-set-blue" *ngIf="!headerState.isAlert">
  <div class="layout-left" [ngSwitch]="headerState.leftButtonType">
    <button *ngSwitchCase="leftButtonTypes.audit" [routerLink]="headerState.formSectionUrl">
      <i class="fa fa-bars"></i>

      <!-- <span class="fa-stack">
        <i class="fa fa-bars fa-stack-2x"></i>
        <i class="fas fa-clipboard-check fa-stack-1x cornered-lr"></i>
      </span> -->
    </button>
    <button *ngSwitchCase="leftButtonTypes.backButton" (click)="onBackButtonClick()">
      <i class="fas fa-solid fa-chevron-left"></i>
    </button>
    <button *ngSwitchCase="leftButtonTypes.default" [routerLink]="headerState.formSectionUrl">
      <i class="fas fa-bars"></i>
    </button>
  </div>
  <div class="layout-center">
    <h1 id="title" *ngIf="headerState.title" [innerHTML]="headerState.title"></h1>
    <img *ngIf="!headerState.title" src="./assets/logo-white.png" class="logo">
  </div>
  <div class="layout-right">
    <button *ngIf="headerState.useBackButton" (click)="onBackButtonClick()">
      <i class="fas fa-times"></i>
    </button>
    <button *ngIf="!headerState.useBackButton" class="round compact color-set-gray margin-right" [routerLink]="['/profile']">
      <i>{{authenticatedUser.initials}}</i>
    </button>
  </div>
</nav>