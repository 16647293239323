import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { BaseFormComponent } from 'src/app/components/forms/BaseForm.component';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import { PopupUtility } from 'src/app/components/popup/popup.utility';
import { SimplePopupComponent } from 'src/app/components/popup/simplePopup/simplePopup.component';
import { BaseRepository } from 'src/app/core/data/baseRepository';
import { DataSourceImportation } from 'src/app/core/data/models/database/dataSourceImportation.database';
import { DataSourceImportationResult } from 'src/app/core/data/models/database/dataSourceImportationResult.database';
import { CustomFieldControlType } from 'src/app/core/data/models/form/customFieldControlType';
import { ComparatorService } from 'src/app/core/services/comparator.service';
import { StringUtility } from 'src/app/core/stringUtility';
import { DataSourcePropertyService } from './dataSourcePropertyService';

@Component({
  selector: 'app-data-source-property',
  templateUrl: './data-source-property.component.html',
  styleUrls: ['./data-source-property.component.scss']
})
export class DataSourcePropertyComponent extends BaseFormComponent implements OnInit {
  controlTypes: any;

  private fieldTitle;
  private fieldTitles;

  @ViewChild(PopupComponent) popup: PopupComponent;
  @ViewChild(SimplePopupComponent) modalPopup: SimplePopupComponent;

  private promiseResolver = (boolean) => {};
  public isNew: any;
  public showKeyChangeWarning: boolean = false;
  private formLoaded: boolean  = false;

  constructor(
		baseRepository: BaseRepository,
		private formBuilder: FormBuilder,
		public translateService: TranslateService,
    router: Router) {

    super(translateService, router, baseRepository);

		this.form = new FormGroup({
			key: new FormControl("key", [Validators.required, Validators.maxLength(250)]),
			value: new FormControl("value"),
			controlType: new FormControl("controlType"),
		});

    this.form.controls.key.valueChanges.subscribe(x => {
      if (!this.isNew && this.formLoaded)
        this.showKeyChangeWarning = this.form.controls.key.value !== this.fieldTitle.key;
    });
	}

  ngOnInit(): void {
    this.controlTypes = [
      {id: CustomFieldControlType.TextBox , description: this.translateService.instant("dataSourceProperty.controlType.textBox")}, 
      {id: CustomFieldControlType.RichTextBox, description: this.translateService.instant("dataSourceProperty.controlType.richTextBox")}
    ]
  } 

  public async display(fieldTitle, fieldTitles, isNew, dataSourceImportation: DataSourceImportation){
    this.showKeyChangeWarning = false;
    this.isNew = isNew;
    
    this.fieldTitles = fieldTitles;
  
    this.fieldTitle = fieldTitle;

    this.form.controls.key.setValue(fieldTitle.key);
    this.form.controls.value.setValue(fieldTitle.value);
    this.form.controls.controlType.setValue(fieldTitle.controlType);

    // The type of the key of the datasource cannot be anything else than a textbox.
    super.setControlState(this.form.controls.controlType, fieldTitle.key !== dataSourceImportation.fieldTitlesKey)

    this.formLoaded = true;

    this.popup.width = "90%"
    
    this.popup.display();

    return new Promise<boolean>((resolve) => {
      this.promiseResolver = resolve;
    })
  }

  public saveButtonClick(){
    if (_.find(this.fieldTitles, x => x.key === this.form.controls.key.value && x.id !== this.fieldTitle.id)){
      this.showMessage(this.translateService.instant("dataSourceProperty.validations.existingKey"));
      return;
    }

    if (!this.isAlphaCharacter(this.form.controls.key.value[0])){
      this.showMessage(this.translateService.instant("dataSourceProperty.validations.keyFirstChar"));
      return false;
    }

    // This validation will force the user to have a key that could be very similar to the one made in development
    // which will help in the future if we have to use this key in a restrictive environment.
    if (!this.isAlphaNumeric(this.form.controls.key.value)){
      this.showMessage(this.translateService.instant("dataSourceProperty.validations.validChars"))
      return;
    }

    if (!DataSourcePropertyService.isReservedKeyword(this.form.controls.key.value)){
      this.showMessage(this.translateService.instant("dataSourceImportation.edit.validations.reservedKeyword", { keyword: this.form.controls.key.value }));
      return false;
    }    

    // The key must immediatly be with a lowercase because the key is used as a property in the IndexedDB database
    // and the current standard with the synchronization model is to be in camelCase.
    this.fieldTitle.key = this.form.controls.key.value[0].toLowerCase() + this.form.controls.key.value.substring(1);
    this.fieldTitle.value = this.form.controls.value.value;
    this.fieldTitle.controlType = this.form.controls.controlType.value;

    this.popup.close();
    this.promiseResolver(true);
  }

  public cancelButtonClick(){
    this.popup.close();
    this.promiseResolver(false);
  }

  private showMessage(message: string){
    PopupUtility.displayInformation(this.modalPopup, this.translateService, 
      "Validations", message);
  }

  private isAlphaNumeric(value) {
    var i, len;
  
    for (i = 0, len = value.length; i < len; i++) {
      if (!this.isNumericCharacter(value[i]) && !this.isAlphaCharacter(value[i]))
        return false;
    }
    
    return true;
  }

  private isNumericCharacter(value) {
    var code = value.charCodeAt(0); 

    return code > 47 && code < 58;
  }

  private isAlphaCharacter(value) {
    var code = value.charCodeAt(0); 

    return (code > 64 && code < 91) || (code > 96 && code < 123);
  }
}
