import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AppMiddleware } from './app.middleware';

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<void>  {

  constructor(
    private appMiddleware: AppMiddleware
  ) { }

  async resolve(): Promise<void> {
    let validationDictionary = await this.appMiddleware.run();

    if (!validationDictionary.isValid()){
      // TODO: Redirect to a user-friendly page where the user could have a meaningful information
      // about what happened and what he can do.
    }
  }
}
