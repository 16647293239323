export enum CustomFieldControlType {
    Undefined = 0,
    CheckBox = 1,
    ComboBox = 2,
    DateTimePicker = 3,
    GroupBox = 4,
    Label = 5,
    TextBox = 6,
    Panel = 7,
    RichTextBox = 8,
    Grid = 9,
    MaskedEditTextBox = 10,
    Tab = 11,
    PictureBox = 12,
    Signature = 13,
    Score = 14,
    RadioButton = 15,
    FormattedLinkLabel = 16,
    FlexiGrid = 17,
    Line = 18,
    TabControl = 19,
    Button = 20
}